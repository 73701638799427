<template>
    <div>
        <nav class="nav navbar-expand-sm" id="top-nav">
            <router-link  class="navbar-brand mx-4 pb-1" to="/">OW Cloud</router-link>
            <div class="collapse navbar-collapse pb-1 mx-3" id="mynav">
                <div class="pt-2 yellow-highlight mr-auto" v-if="!showLoader && foundPrediction && !errored"  style="margin-left: 35px"> {{objectDisplayName}} occults  {{foundPrediction.star.name}} on {{foundPrediction.evt.midTime | formatDate}}</div>
                <ThemeSelector />
                <LoggedInUserActions 
                    :viewPortVisible="false" 
                    :delayedShow="true" 
                    :redirectToCurrent="true" 
                    ref="logginComponent"/>                
            </div>
        </nav>           
        <div class="container-float mx-4" v-if="!showLoader && foundPrediction && !errored">
            <div class="row py-2 justify-content-center">
                <div class="col-lg-8 pt-2">
                    <div class="row">
                        <div class="col-12">
                            <div class="pt-2">
                                <span 
                                    class="small px-2 panel-light" 
                                    style="color: orange; border: 1px solid #676767"
                                    v-if="eventHasPassed"
                                >This event has passed</span>

                                <span 
                                    class="small px-2 panel-light" 
                                    style="color: orange; border: 1px solid #676767"
                                    v-if="currentUser && !eventHasPassed && isTagged === false"
                                >Submitting a station will tag the event as 'OwPersonal'</span>

                                <span 
                                    class="small px-2 panel-light" 
                                    style="color: orange; border: 1px solid #676767"
                                    v-if="!currentUser && !eventHasPassed && isTagged"
                                >Login to submit a station</span>

                                <span 
                                    class="small px-2 panel-light" 
                                    style="color: orange; border: 1px solid #676767"
                                    v-if="currentUser && isTagged && isCustomPreferredPrediction"
                                >Custom preferred prediction</span>

                                <a 
                                    class="small px-2 panel-light" 
                                    style="border: 1px solid #676767; cursor: pointer"
                                    title="Set as preferred prediction"
                                    @click="setAsPreferredPrediction"
                                    v-if="currentUser && isTagged && canBeMadePreferredPrediction"
                                >Set as preferred prediction</a>

                                <b-spinner v-if="showSubmitLoader" 
                                        class="justify-content-center mt-1 ml-2" 
                                        varient="secondary" 
                                        style="width: 1rem; height: 1rem;" ></b-spinner>

                                <ChangePredictionDropdown 
                                    :eventId="this.eventId" 
                                    :preds="foundPrediction.preds" 
                                    class="pull-right"
                                    v-if="foundPrediction.preds && foundPrediction.preds.length > 1" />
                            </div>

                            <EventGoogleMap 
                                :location="{ lng: foundPrediction.earth.subStLng, lat: foundPrediction.earth.subStLat }" 
                                :fPlane="foundPrediction.fPlane"
                                :occel="foundPrediction.data"
                                :midTime="foundPrediction.evt.midTime"
                                :toTime="foundPrediction.evt.to"
                                :stations="foundPrediction.stations"
                                :eventId="this.eventId"
                                :tags="foundPrediction.tags"
                                :currentUser="currentUser"
                                :defaultStationLocation="this.defaultStationLocation"
                                :star="foundPrediction.star"
                                />

                            <div class="pt-2">
                                <small v-if="foundPrediction.tags && foundPrediction.tags.length > 0">Tagged as: </small><TagList :tags="foundPrediction.tags" />
                                <TagAgreements :tags="foundPrediction.tags" />                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="row pt-1">
                        <div class="col-lg-12">
                            Prediction
                        </div>  
                        <div class="col-lg-12">
                            <div class="row pl-4">
                                <div class="col-lg-6">
                                    <small>
                                        <span class="text-muted">Last Updated:</span> {{foundPrediction.updated | formatDateHourMinute}} UT<br>
                                        <span class="text-muted">Data Sources:</span> {{foundPrediction.src | formatPredictionSource}} <br>
                                        <span class="text-muted">Error (path widths):</span> {{foundPrediction.err.pw - 1 | formatFixed3}} <br>
                                        <span class="text-muted">Err. Ellipse:</span> <a href="#errelps">{{foundPrediction.err.elps_maj}}&quot; x {{foundPrediction.err.elps_min}}&quot;</a><br>
                                        <span class="text-muted">Err. Basis:</span> {{foundPrediction.err.basis}}<br>
                                    </small>
                                </div>
                                <div class="col-lg-6">
                                    <small>
                                        <span class="text-muted">Computed By:</span> {{foundPrediction.user}}<br>
                                        <span class="text-muted">Orbit Date:</span> 
                                            {{foundPrediction.orbDate | formatDate}} 
                                            <span v-if="foundPrediction.orbSlnId && foundPrediction.orbSlnId.startsWith('JPL')" class="text-muted">
                                                (<strong class="text-light" :title="foundPrediction.orbSlnId">{{foundPrediction.orbSlnId | formatJPLNo}}</strong>)
                                            </span>
                                            <br>
                                        <span class="text-muted">Error in time:</span> {{foundPrediction.err.time / 60.0 | formatMinSecDuration}} <br>
                                        <span class="text-muted">Err. Ellipse PA:</span> {{foundPrediction.err.elps_pa}}&deg;<br>
                                        <span v-if="foundPrediction.id">
                                            <span class="text-muted">OWC Id:</span> {{foundPrediction.id}}<br>
                                        </span>
                                    </small>                                    
                                </div>
                            </div> 
                        </div> 
                    </div>      
                    <div class="row py-2">
                        <div class="col-lg-12">
                            Event
                        </div>  
                        <div class="col-lg-12">
                            <div class="row pl-4">
                                <div class="col-lg-6">
                                    <small>
                                        <span class="text-muted">From:</span> {{foundPrediction.evt.from | formatHourMinuteSec}} UT<br>
                                        <span class="text-muted">Combined Mag:</span> {{foundPrediction.combMag | formatMag}}<br>
                                        <span class="text-muted">Mag Drop (V):</span> {{foundPrediction.dropV | formatMag}}<br>
                                        <span class="text-muted">Shadow Width:</span> {{foundPrediction.obj.dia / foundPrediction.evt.vsf | formatFixed1}} km<br>
                                        <span class="text-muted">Solar Elong.:</span> {{foundPrediction.solarElng | formatFixed0}}&deg;<br>
                                     </small>                                    
                                </div>
                                <div class="col-lg-6">
                                    <small>
                                        <span class="text-muted">To:</span> {{foundPrediction.evt.to | formatHourMinuteSec}} UT<br>
                                        <span class="text-muted">Max Duration:</span> {{foundPrediction.maxDur / 60.0 | formatMinSecDuration}}<br>                                        
                                        <span class="text-muted">Mag Drop (R):</span> {{foundPrediction.dropR | formatMag}}<br>
                                        <span class="text-muted">Moon Phase:</span> {{foundPrediction.moonPhase | formatFixed0}}% sunlit<br>
                                        <span class="text-muted">Moon Elong.:</span> {{foundPrediction.moonElng | formatFixed0}}&deg;<br>
                                    </small>                                    
                                </div>
                            </div> 
                        </div> 
                    </div>                                     
                    <div class="row py-2">
                        <div class="col-lg-12">
                            Target Star
                        </div>  
                        <div class="col-lg-12">
                            <div class="row pl-4">
                                <div class="col-lg-6">
                                    <small>
                                        <span class="text-muted">Name:</span> <StarLink :starName="foundPrediction.star.name" /><br>
                                        <span class="text-muted">Constellation:</span> {{foundPrediction.star.constl}}<br>
                                        <span class="text-muted">Diameter:</span> <span v-if="foundPrediction.star.dia_mas">{{foundPrediction.star.dia_mas | formatFixed2}} mas</span><span v-if="!foundPrediction.star.dia_mas"></span><br>
                                        <span class="text-muted">RUWE:</span> <a href="https://gea.esac.esa.int/archive/documentation/GDR2/Gaia_archive/chap_datamodel/sec_dm_main_tables/ssec_dm_ruwe.html" title="See definition of RUWE" target=_blank>{{foundPrediction.star.ruwe | formatFixed2}}</a><br>
                                        <span class="text-muted" v-if="foundPrediction.star.srcid">Gaia SourceId:</span> <StarLink :gaiaStar="foundPrediction.gaia"/><br>                                        
                                        <span class="text-muted">RA [ICRS]:</span> <RaValue :val="foundPrediction.star.ra_2000" /><br>
                                        <span class="text-muted">Dec [ICRS]:</span> <DecValue :val="foundPrediction.star.de_2000" /><br>
                                     </small>                                    
                                </div>
                                <div class="col-lg-6">
                                    <small>
                                        <span class="text-muted">V mag:</span> {{foundPrediction.star.mV | formatFixed2}}<br>
                                        <span class="text-muted">R mag:</span> {{foundPrediction.star.mR | formatFixed2}}<br>
                                        <span class="text-muted">B mag:</span> {{foundPrediction.star.mB | formatFixed2}}<br>
                                        <span class="text-muted">Flags:</span> {{foundPrediction.star.dbl_info}} <i v-if="foundPrediction.star.dbl_info" class="fa fa-warning warn-soft"></i><br>
                                        <span class="text-muted" v-if="foundPrediction.star.srcid">Gaia Flags:</span> {{foundPrediction.star.gflags}}<br>                                        
                                        <span class="text-muted">RA [aprnt]:</span> <RaValue :val="foundPrediction.star.ra" v-if="foundPrediction.star.apparent"/><br>
                                        <span class="text-muted">Dec [aprnt]:</span> <DecValue :val="foundPrediction.star.de" v-if="foundPrediction.star.apparent"/><br>
                                    </small>                                    
                                </div>
                            </div> 
                        </div> 
                    </div>   
                    <div class="row py-2">
                        <div class="col-lg-12">
                            Object
                        </div>  
                        <div class="col-lg-12">
                            <div class="row pl-4" >
                                <div class="col-lg-6">
                                    <small>
                                        <span class="text-muted">Name:</span> <ObjectLink :isAster="foundPrediction.obj.isAster" :number="foundPrediction.obj.num" :name="objectDisplayName" /><br>
                                        <span class="text-muted">Diameter:</span> {{foundPrediction.obj.dia}} <span v-if="foundPrediction.obj.diaErr > 0">&plusmn; {{foundPrediction.obj.diaErr}}</span> km<span class="text-light pl-1" id="objDiaSrc" v-if="foundPrediction.obj.diasrc">({{foundPrediction.obj.diasrc}})</span><br>
                                        <span class="text-muted">Distance:</span> {{foundPrediction.obj.dist}} au<br>
                                        <span class="text-muted">Motion RA:</span> {{foundPrediction.obj.dRaHr * 15 * Math.cos(foundPrediction.star.de_2000 * Math.PI / 180) | formatFixed2}} "/hr<br>
                                        <span class="text-muted">Moons:</span> {{foundPrediction.obj.moons}}<br>
                                    </small>                                    
                                    <b-tooltip target="objDiaSrc" triggers="hover" placement="right" variant="prinary" v-if="isOwcAdmin && foundPrediction.obj.dianfo">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td><small>Diameter</small></td>
                                                    <td><small>Source</small></td>
                                                </tr>    
                                                <tr>
                                                    <td colspan="2">
                                                        <div class="text-muted mb-2" style="text-align: left; border-top:1px solid #95999c!important;"></div>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>                                        
                                                <tr style="text-align: left;" v-for="diaRow in foundPrediction.obj.dianfo.split('\r\n')" :key="diaRow">
                                                    <td class="pr-3" :class="diaRow.split('-')[1].includes(foundPrediction.obj.diasrc) ? 'text-highlight' : 'text-muted'"><small>{{ diaRow.split('-')[0].trim() }} km</small></td>
                                                    <td :class="diaRow.split('-')[1].includes(foundPrediction.obj.diasrc) ? 'text-highlight' : 'text-muted'"><small>{{ diaRow.split('-')[1].trim() }}</small></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="text-muted mt-2" style="text-align: left; border-top:1px solid #95999c!important;"><small>* at the time of generation</small></div>
                                    </b-tooltip>                                       
                                </div>
                                <div class="col-lg-6">
                                    <small>
                                        <span class="text-muted">Class:</span> {{foundPrediction.obj.class}}<br>      
                                        <span class="text-muted">Diameter (augm):</span> {{foundPrediction.obj.diaArcSec * 1000 | formatFixed2}} mas<br>
                                        <span class="text-muted">Mag:</span> {{foundPrediction.obj.mag | formatFixed1}} <i v-if="foundPrediction.obj.isAster" class="fa fa-warning warn-soft" title="This magnitude does not factor in rotational variations"></i><br>
                                        <span class="text-muted">Motion Dec:</span> {{foundPrediction.obj.dDecHr | formatFixed2}} &quot;/hr<br>
                                        <span class="text-muted">Rings:</span> {{foundPrediction.obj.rings}}<br>
                                    </small>                                    
                                </div>
                            </div> 
                        </div> 
                    </div>                     
                </div>            
            </div>                
            <div class="row pb-4 justify-content-center">
                <div class="col-12">
                    <hr style="height:1px; width:100%; border-width:0; color: rgb(0, 0, 65); background-color: rgb(65, 65, 65)">
                    <div class="row">
                        <div class="col-lg-7 py-2">
                            <a name="errelps" />
                            Error Ellipses<br/><br/>
                            <div class="row">
                                <div class="col-lg-3">
                                    <ErrorEllipses :cls="'occ'" :err="this.occEllipse" title="Combined"/>
                                </div>
                                <div class="col-lg-3" v-if="this.foundPrediction.err.starElps">
                                    <ErrorEllipses :cls="'star'" :err="this.starEllipse" title="Star"/>
                                </div>
                                <div class="col-lg-3" v-if="this.foundPrediction.err.astElps">
                                    <ErrorEllipses :cls="'ast'" :err="this.astEllipse" title="Asteroid"/>
                                </div>      
                                <div class="col-lg-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <small>
                                                <div v-if="this.foundPrediction.err.jplErr">
                                                    <span class="text-muted">Horizons SMAA (3-&sigma;):</span> {{this.foundPrediction.err.jplErr.smaa}} &quot;<br>
                                                    <span class="text-muted">Horizons SMIA (3-&sigma;):</span> {{this.foundPrediction.err.jplErr.smia}} &quot;<br>
                                                    <span class="text-muted">Horizons &Theta;:</span> {{this.foundPrediction.err.jplErr.theta}} &deg;<br>
                                                </div>
                                                <div v-if="this.foundPrediction.err.star">
                                                    <span class="text-muted">Star SDev RA:</span> {{this.foundPrediction.err.star.ra}} mas<br>
                                                    <span class="text-muted">Star SDev DE:</span> {{this.foundPrediction.err.star.de}} mas<br>
                                                    <span class="text-muted">Star SDev pmRA:</span> {{this.foundPrediction.err.star.pmRa}} mas/yr<br>
                                                    <span class="text-muted">Star SDev pmDE:</span> {{this.foundPrediction.err.star.pmDe}} mas/yr<br>
                                                    <span class="text-muted">Star SDev Parallax:</span> {{this.foundPrediction.err.star.plx}} mas<br>
                                                    <span class="text-muted">Star Position Epoch:</span> {{this.foundPrediction.err.star.epoch | formatFixed3}}<br>
                                                </div>
                                            </small>
                                        </div>
                                    </div>
                                </div>                           
                            </div>                           
                        </div>
                        <div class="col-lg-5 py-2">
                                                       
                        </div>
                    </div>
                </div>
            </div>              
            <div class="row justify-content-center">           
                 <div class="col-lg-12">
                    <hr style="height:1px; width:100%; border-width:0; color: rgb(65, 65, 65); background-color: rgb(65, 65, 65)">
                    Occultation Elements
                    <button class="btn-sm btn-secondary mx-2" v-clipboard="() => foundPrediction.occel" title="Copy to clipboard"><i class="fa fa-clipboard"></i></button>
                    <pre class="mt-3">
------ occelmnt file for Occult  BEGIN -----------------------------------
{{foundPrediction.occel}}
------ occelmnt file for Occult  END   -----------------------------------                        
                    </pre>
                 </div>                
            </div>          
        </div>        
        <div class="text-center">
            <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
            <h5 class="text-danger mt-3" v-if="!foundPrediction && !showLoader">Event prediction not found</h5>
            <h5 class="text-danger mt-3" v-if="errored && !showLoader">Error getting prediction {{this.predSrc}} for event {{this.eventId}}</h5>
        </div>             
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import RaValue from '@/components/RaValue.vue'
import DecValue from '@/components/DecValue.vue'
import EventGoogleMap from '@/components/EventGoogleMap.vue'
import TagList from '@/components/TagList.vue'
import ChangePredictionDropdown from '@/components/ChangePredictionDropdown.vue'
import TagAgreements from '@/components/TagAgreements.vue'
import ObjectLink from '@/components/ObjectLink.vue'
import StarLink from '@/components/StarLink.vue'
import ErrorEllipses from '@/components/ErrorEllipses.vue'
import ThemeSelector from '@/components/ThemeSelector.vue'
import LoggedInUserActions from '@/components/LoggedInUserActions.vue'
import * as owcMixin from '@/mixins/owcMixin.js';

export default {
    data() {
        return {
            eventId: null,
            predSrc: null,
            showLoader : false,
            showSubmitLoader: false,
            errored : false,
            locData: null,
            defaultStationLocation: null
        }
    },
    components: {
        RaValue,
        DecValue,
        EventGoogleMap,
        TagList,
        ChangePredictionDropdown,
        TagAgreements,
        ObjectLink,
        StarLink,
        ErrorEllipses,
        ThemeSelector,
        LoggedInUserActions
    },     
    methods: {
        ...mapActions(['searchEventPrediction', 'searchMyEventPrediction', 'setPreferredPrediction']),
        mapData(){
            this.locData = this.$store.getters.selectedMapLocation;
            console.log(this.locData);
        },
        async setAsPreferredPrediction(){
            console.log(`SetPreferredPrediction(${this.eventId}, ${this.foundPrediction.id}, ${this.currentUser.id})`);

            this.showSubmitLoader = true;
            try
            {
                await this.setPreferredPrediction({eventId: this.eventId, predictionId: this.foundPrediction.id});

                this.showToast(`Prediction '${this.predSrc}' set as preferred. Sync OW Desktop to get it.`, 'SUCCESS');
                setTimeout(() => {
                    // Refresh page
                    this.$router.go(0);
                    }, 4000);                   
            }
            catch(error)
            {
                this.showSubmitLoader = false;
                this.showToast(error.message, 'ERROR');                    
            }            
            finally
            {
                this.showSubmitLoader = false;
            }            
        }
    },
    async mounted() {
        this.eventId = this.$route.params.eventId;
        this.predSrc = this.$route.params.predSrc;

        if (this.eventId !== undefined && this.predSrc !== undefined)
        {
            this.showLoader = true;

            try 
            {
                let form = { eventId: this.eventId, predSrc: this.predSrc };
                
                if (this.currentUser)
                    await this.searchMyEventPrediction(form);
                else
                    await this.searchEventPrediction(form);
                
                this.showLoader = false;
                this.$refs.logginComponent.readyToShow = true;

                var editStationData = localStorage.getItem("editStationsData");
                if (editStationData)
                {
                    var data = JSON.parse(editStationData);
                    if (data.predictionId == this.foundPrediction.id)
                    {
                        this.defaultStationLocation = {lat: data.lat, lng: data.lng};
                    }
                    localStorage.removeItem("editStationsData");
                }
                
            }
            catch(error)
            {
                this.showLoader = false;
                this.errored = true;
                this.showToast(error.message, 'ERROR');                    
            }
        }
    },
    computed: {
        foundPrediction(){
            return this.$store.getters.currentPrediction;
        },
        eventHasPassed(){
            return this.foundPrediction && owcMixin.hasPassedUtc(this.foundPrediction.evt.to);
        },
        isCustomPreferredPrediction(){
            return this.foundPrediction 
                && this.foundPrediction.preds.find(x => x.preferred === true && x.default === false && x.id === this.foundPrediction.id);
        },
        isNotDefaultPreferredPrediction(){
            return this.foundPrediction && 
                this.foundPrediction.preds.find(x => x.preferred === false && x.default === false && x.id === this.foundPrediction.id);
        },
        preferredPredictionIsNotDefault(){
            return this.foundPrediction && 
                this.foundPrediction.preds.find(x => x.preferred === true && x.default === false && x.id !== this.foundPrediction.id);
        },
        canBeMadePreferredPrediction() {
            return this.isNotDefaultPreferredPrediction || this.preferredPredictionIsNotDefault;
        },
        isTagged(){
            return this.foundPrediction && this.foundPrediction.tags && this.foundPrediction.tags.length > 0;
        },
        objectDisplayName() {
            if (this.foundPrediction && this.foundPrediction.obj)
            {
                let obj = this.foundPrediction.obj;
                if (obj.isAster && obj.num > 0)
                    return `(${obj.num}) ${obj.name}`;
                else
                    return obj.name;
            }

            return '';
        },
        imageSpinnerUrl()
        {
            return require('@/assets/images/imageLoadSpinner.gif');
        },
        maxSMaj(){
            return this.foundPrediction.err.elps_maj * 1000;
        },
        occEllipse(){
            return {
                maj: this.foundPrediction.err.elps_maj * 1000, 
                min: this.foundPrediction.err.elps_min * 1000, 
                pa: this.foundPrediction.err.elps_pa,
                dispRat: 1
            }
        },
        starEllipse(){
            return {
                maj: this.foundPrediction.err.starElps.SemiMajorMas, 
                min: this.foundPrediction.err.starElps.SemiMinorMas, 
                pa: this.foundPrediction.err.starElps.PADeg,
                dispRat: this.maxSMaj / this.foundPrediction.err.starElps.SemiMajorMas
            }
        },
        astEllipse(){
            return {
                maj: this.foundPrediction.err.astElps.SemiMajorMas, 
                min: this.foundPrediction.err.astElps.SemiMinorMas, 
                pa: this.foundPrediction.err.astElps.PADeg,
                dispRat: this.maxSMaj / this.foundPrediction.err.astElps.SemiMajorMas
            }
        },
        currentUser(){
            return this.$store.getters.currentUser;
        },
        isOwcAdmin(){
            return this.$store.getters.isOwcAdmin;
        }
    }    
}
</script>

<style scoped>

</style>