<template>
  <div v-resize:debounce="onResize" v-resize:initial="onResize">
    <OwcHeader v-if="this.$route.meta.headerless !== true" />
    <router-view />  
  </div>
</template>

<script>
import OwcHeader from '@/components/OwcHeader.vue'
import resize from 'vue-resize-directive'
import { mapActions } from 'vuex';

export default {
  components: {
    OwcHeader
  },
  directives: {
    resize
  },
  methods: {
      ...mapActions(['setViewPortSize']),
      onResize() {         
          let vp = 'xxl';
          // https://stackoverflow.com/a/8876069
          const width = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0
          )
          if (width <= 576) vp = 'xs'
          else if (width <= 768) vp = 'sm'
          else if (width <= 992) vp = 'md'
          else if (width <= 1200) vp = 'lg'
          else if (width < 1900) vp = 'xl'

          if (this.$store.getters.viewPortSize != vp)
          {
            this.setViewPortSize(vp);
          }
      }
  }
}
</script>

<style>
    @import './assets/css/font-awesome.min.css';
</style>
