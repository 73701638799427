export class FundPlane {
    constructor(data)
    {
        this.asteroidDiameterAugmented = data.asteroidDiameterAugmented;
        this.halfWidthAugmentation = data.halfWidthAugmentation; 
        this.fPlaneDecRad = data.fPlaneDecRad;
        this.substellarLong = data.substellarLong;
        this.subSolarLat_OfDate = data.subSolarLat_OfDate;
        this.subSolarLng_OfDate = data.subSolarLng_OfDate;
        this.midTime = data.midTime;
        this.XatMin = data.XatMin;
        this.dX = data.dX;
        this.d2X = data.d2X;
        this.d3X = data.d3X;
        this.YatMin = data.YatMin;
        this.dY = data.dY;
        this.d2Y = data.d2Y;
        this.d3Y = data.d3Y;
        this.tFrom = data.tFrom;
        this.tTo = data.tTo;

        let md  = Math.sqrt((this.XatMin * this.XatMin) + (this.YatMin * this.YatMin));
        if (md < 0.9988)
            this.verticalScaleFactor = Math.sqrt(1.0 - (md * md));
        else
            this.verticalScaleFactor = 0.05;
    }

    centrePathByTime(ta, limitId) {

        let dist = 0;
        let factor = 1;
        if (limitId != 0)
        {
            dist = Math.sign(limitId) * this.asteroidDiameterAugmented / 2.0;
            if (Math.abs(limitId) == 2)
            {
                factor = this.halfWidthAugmentation;
            }            
        }

        return this.chordPathByTime(ta, dist, factor);
    }

    chordPathByTime(ta, distKm, factor) {
        let rv = {
            valid : false,
            latDeg : NaN,
            lngDeg : NaN
        };

        let num2 = 0;
        let num3 = 0;
        let sign = Math.sign(distKm);
        if (distKm != 0)
        {
            let num6 =  sign * distKm / 6378.137;
            let x = this.dX + 2.0 * this.d2X * ta + 3.0 * this.d3X * ta * ta;
            let num7 = Math.atan2(this.dY + 2.0 * this.d2Y * ta + 3.0 * this.d3Y * ta * ta, x);
            num3 = num6 * Math.cos(num7 - Math.PI / 2.0) * sign;
            num2 = num6 * Math.sin(num7 - Math.PI / 2.0) * sign;
            if (factor && factor != 1)
            {
                num3 = factor * num3;
                num2 = factor * num2;
            }
        }

        let num8 = Math.sin(this.fPlaneDecRad);
        let num9 = 0.996647 * Math.cos(this.fPlaneDecRad);
        let num10 = Math.sqrt(num8 * num8 + num9 * num9);
        let num11 = num8 / num10;
        let num12 = num9 / num10;
        let num13 = -this.substellarLong / (180.0 / Math.PI) + 0.2625161 * ta;
        let num14 = this.XatMin + this.dX * ta + this.d2X * ta * ta + this.d3X * ta * ta * ta + num3;
        let num15 = (this.YatMin + this.dY * ta + this.d2Y * ta * ta + this.d3Y * ta * ta * ta + num2) / num10;
        let d = 1.0 - num14 * num14 - num15 * num15;

        if (d > 0.0)
        {
            rv.valid = true;
            let num16 = Math.sqrt(d);
            let y = num14;
            let x1 = -num15 * num11 + num16 * num12;
            let num17 = num15 * num12 + num16 * num11;
            rv.latDeg = 180.0 / Math.PI * Math.atan(num17 / Math.sqrt(y * y + x1 * x1) / 0.996647);
            rv.lngDeg = 180.0 / Math.PI * (Math.atan2(y, x1) - num13);
            while (rv.latDeg < -180.0) rv.latDeg += 360.0;
            while (rv.lngDeg > 180.0) rv.lngDeg -= 360.0;           
        }

        return rv;
    }

    pathByTime(ta) {
        let rv = { 
            validPath: false
        };

        for (let limitId = -2; limitId <= 2; ++limitId)
        {
            let path = this.centrePathByTime(ta, limitId);
            if (path.valid)
            {
                rv.validPath = true;
                switch (limitId)
                {
                case -2:
                    rv.lngLeftSigma = path.lngDeg;
                    rv.latLeftSigma = path.latDeg;
                    continue;
                case -1:
                    rv.lngLeft = path.lngDeg;
                    rv.latLeft = path.latDeg;
                    continue;
                case 0:
                    {
                        rv.lngCentre = path.lngDeg;
                        rv.latCentre = path.latDeg;
                        rv.tCentre = this.midTime + ta;
                        rv.centreLineValid = true;
                        let num1 = Math.atan(0.996647 * Math.tan(path.latDeg / (180.0 / Math.PI)));
                        let num2 = Math.cos(num1);
                        let num3 = 0.996647 * Math.sin(num1);
                        let num4 = -this.substellarLong / (180.0 / Math.PI) + 0.2625161 * ta + path.lngDeg / (180.0 / Math.PI);
                        let num5 = 180.0 / Math.PI * Math.atan2(-Math.cos(this.fPlaneDecRad) * Math.sin(num4), num2 * Math.sin(this.fPlaneDecRad) - num3 * Math.cos(num4) * Math.cos(this.fPlaneDecRad));
                        if (num5 < 0.0) num5 += 360.0;
                        rv.starAz = num5;
                        rv.starAlt = 180.0 / Math.PI * Math.asin(num3 * Math.sin(this.fPlaneDecRad) + num2 * Math.cos(num4) * Math.cos(this.fPlaneDecRad));
                        rv.sunAlt = 180.0 / Math.PI * Math.asin(num3 * Math.sin(this.subSolarLat_OfDate / (180.0 / Math.PI)) + num2 * Math.cos(num4 + (this.substellarLong - this.subSolarLng_OfDate) / (180.0 / Math.PI)) * Math.cos(this.subSolarLat_OfDate / (180.0 / Math.PI)));
                    }
                    continue;
                case 1:
                    rv.lngRight = path.lngDeg;
                    rv.latRight = path.latDeg;
                    continue;
                case 2:
                    rv.lngRightSigma = path.lngDeg;
                    rv.latRightSigma = path.latDeg;
                    continue;
                default:
                    continue;
                }
            }
        }

        return rv;
    }
    
    getTforMinimumDistance(lngDeg, latDeg, altMsl) {
        const RADIAN = 180 / Math.PI;
        const EARTH_RADIUS = 6378.137;

        let geoidAltitude = 0;

        var geoidHeight = this.getGeoidHeight(lngDeg, latDeg);

        if (altMsl != null) geoidAltitude = altMsl + geoidHeight;

        let num2 = 1.0 / Math.sqrt(Math.cos(latDeg / RADIAN) * Math.cos(latDeg / RADIAN) + 0.993305620009859 * Math.sin(latDeg / RADIAN) * Math.sin(latDeg / RADIAN));
        let tP = 0.993305620009859 * num2;
        let pCos = Math.cos(latDeg / RADIAN) * (num2 + geoidAltitude / 6378137.0);
        let pSin = Math.sin(latDeg / RADIAN) * (tP + geoidAltitude / 6378137.0);

        //let tP = Math.atan(0.996647 * Math.tan(latDeg / RADIAN));
        //let pCos = Math.cos(tP);
        //let pSin = 0.996647 * Math.sin(tP);

        let f = 0;
        let g = 0;
        let f1 = 0;
        let g1 = 0;
        let n = 0;
        let N2 = 0;
        let D = 0;
        let loopCounter = 0;
        let ta = 0;
        let tY = 0;
        let X = 0;
        let Y = 0;
        do
        {
            let H1 = -this.substellarLong / RADIAN + 0.2625161 * ta + lngDeg / RADIAN;

            X = this.XatMin + this.dX * ta + this.d2X * ta * ta + this.d3X * ta * ta * ta;
            Y = this.YatMin + this.dY * ta + this.d2Y * ta * ta + this.d3Y * ta * ta * ta;

            f = X - pCos * Math.sin(H1);
            g = Y - pSin * Math.cos(this.fPlaneDecRad) + pCos * Math.cos(H1) * Math.sin(this.fPlaneDecRad);
            f1 = this.dX + 2 * this.d2X * ta - 0.2625161 * pCos * Math.cos(H1);
            g1 = this.dY + 2 * this.d2Y * ta - 0.2625161 * pCos * Math.sin(H1) * Math.sin(this.fPlaneDecRad);

            N2 = f1 * f1 + g1 * g1;
            n = Math.sqrt(N2);
            D = f * f1 + g * g1;
            tY = D / N2;
            if (Math.abs(tY) > 1) tY = Math.sign(tY);
            ta = ta - tY;
            loopCounter++;
        }
        while (Math.abs(tY) > 0.00001 & loopCounter < 30);

        return {
            ta: ta,
            x : X,
            y : Y,
            minD : -(f * g1 - g * f1) * EARTH_RADIUS / n
        }
    }    

    getGeoidHeight(lngDeg, latDeg)
    {
	    while (lngDeg < -180.0) lngDeg += 360.0;
	    while (lngDeg > 180.0) lngDeg -= 360.0;
	    if (lngDeg < -180.0) ++lngDeg;
	    if (lngDeg > 180.0) --lngDeg;
	    let lngDeg1 = Math.trunc(Math.floor(lngDeg));
	    let latDeg1 = Math.trunc(Math.floor(latDeg));
	    let geoidHeightPoint1 = this.getSingleGeoidHeightPoint(lngDeg1, latDeg1);
	    let geoidHeightPoint2 = this.getSingleGeoidHeightPoint(lngDeg1 + 1, latDeg1);
	    let geoidHeightPoint3 = this.getSingleGeoidHeightPoint(lngDeg1, latDeg1 + 1);
	    let geoidHeightPoint4 = this.getSingleGeoidHeightPoint(lngDeg1 + 1, latDeg1 + 1);
	    let num1 = (lngDeg1 + 1) - lngDeg;
	    let num2 = geoidHeightPoint1 * num1 + geoidHeightPoint2 * (lngDeg - lngDeg1);
	    let num3 = geoidHeightPoint3 * ( (lngDeg1 + 1) - lngDeg) + geoidHeightPoint4 * (lngDeg - lngDeg1);
	    let num4 = (latDeg1 + 1) - latDeg;
	    return num2 * num4 + num3 * (latDeg - latDeg1);        
    }

    getSingleGeoidHeightPoint(lngDeg, latDeg)
    {
        let GEOID_HEIGHT_DATA = [15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,
            15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,
            15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,
            15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,
            13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,15,15,15,15,15,15,15,15,15,15,15,15,15,16,16,16,16,16,16,16,16,16,16,16,16,16,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,
            17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,15,15,15,15,15,15,15,15,15,15,15,15,15,
            15,15,15,15,15,15,15,15,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,
            13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,11,11,11,11,11,11,11,11,12,12,12,12,12,12,13,13,13,13,13,13,13,14,14,14,14,14,14,14,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,15,
            15,15,15,15,15,15,15,15,15,15,15,15,15,16,16,16,16,16,16,16,16,17,17,17,17,17,17,18,18,18,18,18,18,18,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,18,18,18,18,18,18,18,18,17,17,17,17,17,17,17,17,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,17,
            17,17,17,17,17,17,17,17,17,17,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,17,17,17,17,17,17,17,17,17,17,17,16,16,16,16,16,16,16,16,16,16,16,16,16,16,15,15,15,15,15,15,15,15,15,15,15,15,15,15,14,14,14,14,14,14,14,14,14,14,13,13,13,13,13,13,13,13,13,
            12,12,12,12,12,12,12,12,12,12,12,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,11,12,12,12,12,12,12,12,12,12,12,12,12,12,11,11,11,11,11,11,11,11,11,11,11,11,11,11,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,11,11,11,11,11,11,11,11,
            11,11,11,11,11,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,13,13,13,13,13,13,13,13,13,13,13,14,14,14,14,14,14,14,15,15,15,15,15,15,15,16,16,16,16,16,16,16,16,16,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,18,19,19,19,19,19,
            19,19,19,19,19,19,19,19,19,19,19,19,19,19,19,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,20,20,20,20,20,20,20,
            20,20,19,19,19,19,19,19,19,18,18,18,18,18,18,18,18,18,17,17,17,17,17,17,17,17,17,17,17,16,16,16,16,16,16,16,16,16,15,15,15,15,15,15,15,15,14,14,14,14,14,14,14,13,13,13,13,13,13,13,12,12,12,12,12,12,12,11,11,11,11,11,11,11,10,10,10,10,10,10,10,10,10,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,10,10,
            10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,11,11,11,11,11,11,11,11,11,11,11,10,10,10,10,10,10,10,10,10,11,11,11,11,11,11,11,11,11,11,11,11,12,12,12,12,12,12,12,12,13,13,13,13,13,13,13,14,14,14,14,14,14,
            14,14,14,14,15,15,15,15,15,15,15,15,15,15,16,16,16,16,16,16,16,16,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,17,18,18,18,18,18,18,19,19,19,19,19,20,20,20,20,20,20,20,20,21,21,21,21,21,21,21,21,21,21,20,20,20,20,20,20,20,20,20,20,20,20,21,21,21,21,21,21,21,21,21,21,21,21,21,21,22,22,22,22,22,
            22,22,22,22,22,22,22,22,22,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,23,22,22,22,22,22,22,22,22,22,22,21,21,21,21,21,21,21,21,21,20,20,20,20,20,20,19,19,19,19,19,18,18,18,18,18,18,17,17,17,17,17,17,17,17,16,16,16,16,16,16,16,16,15,15,15,15,15,
            15,14,14,14,14,14,14,13,13,13,13,13,13,13,13,12,12,12,12,12,12,12,12,12,12,11,11,11,11,11,11,11,10,10,10,10,10,10,9,9,9,9,9,9,9,9,9,8,8,8,8,8,9,9,9,9,9,9,9,9,9,9,9,10,10,10,10,10,10,10,10,10,10,10,10,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,10,10,
            9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,10,10,10,10,10,10,10,10,11,11,11,11,11,11,11,11,11,11,12,12,12,12,12,12,13,13,13,13,13,13,13,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,15,15,15,15,15,15,15,15,15,15,15,16,16,16,16,16,16,16,16,16,16,16,16,16,16,16,17,
            17,17,18,18,18,19,19,19,19,20,20,20,20,20,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,21,22,22,22,22,22,22,22,23,23,23,23,23,23,24,24,24,24,24,24,24,24,24,24,24,24,24,24,24,24,24,25,25,25,25,25,25,25,25,25,25,25,25,25,25,25,26,26,26,26,26,26,26,26,26,26,26,26,26,26,26,25,25,25,25,25,
            25,24,24,24,24,24,23,23,23,22,22,22,22,22,21,21,21,21,20,20,20,20,19,19,19,19,18,18,18,17,17,17,17,16,16,16,16,16,15,15,15,15,15,15,15,15,14,14,14,14,14,14,14,14,13,13,13,13,13,13,13,13,13,13,13,13,12,12,12,12,12,12,12,12,11,11,11,11,11,11,11,11,11,11,10,10,10,10,10,10,10,10,9,9,9,9,9,9,9,9,
            9,8,8,8,8,8,8,7,7,7,7,7,7,7,7,7,7,7,7,7,7,7,7,8,8,8,8,8,8,8,8,8,8,8,8,8,8,7,7,7,7,7,7,7,7,7,7,7,7,8,8,8,8,8,8,8,9,9,9,9,7,7,7,8,8,8,8,8,8,8,8,8,8,8,8,8,8,9,9,9,9,8,8,8,8,8,8,8,8,8,8,9,9,9,9,9,9,9,9,9,
            9,9,9,9,9,8,9,9,9,9,9,9,9,9,9,9,9,9,9,9,10,10,10,11,11,11,11,12,12,12,12,12,12,12,13,13,13,13,13,13,13,13,13,13,14,14,14,14,14,14,15,15,15,15,16,16,16,16,16,17,17,17,17,18,18,18,19,19,19,19,20,20,20,20,20,20,20,20,21,21,21,22,22,22,23,23,23,24,24,24,25,25,25,25,25,25,25,25,25,25,
            25,25,25,25,25,26,26,26,26,27,27,27,27,27,27,27,27,27,26,26,26,26,26,26,26,26,26,27,27,27,27,27,28,28,28,28,28,28,28,28,28,28,28,28,28,28,27,27,27,27,27,27,26,26,26,25,25,25,24,24,24,24,23,23,23,23,22,22,22,22,21,21,21,21,20,20,20,20,19,19,19,19,18,18,18,18,18,17,17,17,16,16,16,15,15,15,15,14,14,14,
            14,14,14,14,14,14,14,13,13,13,13,12,12,12,12,12,12,11,11,11,11,11,10,10,10,10,10,10,10,10,10,10,10,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,8,8,8,8,8,8,8,8,8,7,7,7,7,7,7,7,7,7,6,6,6,6,6,5,5,5,5,5,5,5,5,5,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6,
            6,5,5,5,5,5,5,6,6,6,6,6,6,6,6,6,6,7,7,7,6,6,6,6,6,6,6,6,6,6,6,6,6,6,6,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,6,6,6,6,6,6,6,6,7,7,7,7,7,7,7,7,7,7,7,7,7,8,8,8,9,9,9,9,10,10,11,
            11,12,12,12,13,13,13,14,14,14,15,15,16,16,16,17,17,18,18,18,19,19,19,19,19,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,20,21,21,21,22,22,23,23,24,24,25,25,26,26,26,27,27,28,28,29,29,30,30,30,30,30,30,30,30,30,30,30,29,29,29,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,31,31,31,31,
            30,30,30,29,29,29,28,28,28,28,27,27,27,26,26,26,26,25,25,25,25,24,24,24,24,23,23,23,23,22,22,22,22,22,22,22,21,21,21,21,20,20,20,19,19,18,18,18,18,17,17,16,16,15,15,15,14,14,13,13,13,13,13,13,14,14,15,15,15,15,15,15,15,14,14,13,12,12,11,11,11,10,10,10,10,10,9,9,9,8,8,8,8,7,7,7,7,7,7,7,
            6,6,6,6,6,6,7,7,7,7,7,7,7,7,7,7,6,6,6,6,6,6,6,6,6,5,5,5,5,5,5,4,4,4,4,4,4,4,4,4,4,4,4,4,5,5,4,4,4,4,4,4,4,4,4,4,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,6,6,6,6,6,6,6,6,6,4,4,4,4,4,4,4,4,4,4,3,3,3,3,3,3,4,4,4,4,
            4,4,4,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,5,5,5,5,5,4,4,4,4,4,5,5,5,5,5,5,5,6,6,6,6,6,7,7,8,8,9,10,10,11,11,11,11,11,12,12,12,12,13,13,13,14,14,15,15,16,16,17,17,18,18,19,19,19,19,19,19,19,19,19,18,18,18,17,17,16,16,16,
            16,16,17,17,18,18,19,19,19,20,20,21,21,22,23,24,25,27,28,29,30,31,31,31,32,32,32,32,32,32,32,32,31,31,30,29,29,28,27,27,27,27,28,28,29,30,31,32,32,32,32,32,32,31,31,31,31,31,32,32,32,32,32,32,31,31,31,31,31,31,30,30,29,29,28,27,27,26,26,26,25,25,25,25,24,24,24,24,24,24,24,24,24,24,24,24,24,23,23,22,
            22,21,21,20,20,19,19,19,18,18,18,17,17,16,16,16,15,15,14,14,14,13,13,13,13,13,13,12,12,12,12,12,12,12,11,11,10,10,10,9,9,9,9,8,8,8,8,8,8,8,7,7,7,7,6,6,6,6,6,6,6,6,5,5,5,4,4,4,4,4,4,4,4,5,5,5,5,5,5,5,5,5,5,5,5,5,4,4,4,3,3,3,3,3,3,3,3,3,3,3,
            3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,4,3,3,3,3,3,3,3,3,3,3,3,3,2,2,2,2,2,2,1,1,1,1,1,1,1,1,1,1,1,1,2,2,2,1,1,1,1,1,2,2,2,2,2,2,2,1,1,1,1,1,1,1,1,1,1,1,1,
            2,2,2,2,2,3,3,4,5,6,6,7,7,8,8,8,8,7,7,7,7,7,7,7,8,9,9,10,10,10,10,10,10,10,11,11,12,12,13,13,14,14,15,16,17,18,18,18,17,16,15,14,13,12,11,11,12,12,13,15,16,17,19,20,21,22,23,23,24,25,25,26,27,28,29,30,31,31,32,33,34,35,35,35,35,35,35,35,35,35,35,35,35,34,33,33,32,32,32,32,
            32,31,31,31,31,31,31,31,32,32,32,32,33,33,33,33,33,33,33,34,34,34,35,35,34,34,33,33,32,32,32,32,32,32,32,32,31,31,30,30,29,28,28,28,27,27,27,26,26,26,25,25,24,24,23,23,22,22,21,21,20,20,19,19,19,18,18,18,17,17,16,15,15,14,14,13,13,12,12,12,11,11,10,10,9,9,8,8,7,7,6,6,6,6,6,6,6,6,6,6,
            6,6,5,5,4,4,4,4,4,4,4,4,4,4,4,5,5,5,6,6,6,6,6,6,5,5,4,3,3,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,3,3,2,2,2,1,1,1,1,1,1,1,1,2,2,2,2,2,2,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,4,
            3,3,3,3,3,3,3,3,3,3,2,2,2,2,2,2,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,255,255,255,255,255,255,255,254,254,254,255,255,255,0,0,0,1,1,2,3,4,5,6,6,6,5,5,4,3,3,2,2,2,3,3,3,4,5,6,6,7,8,9,9,9,9,8,7,7,6,6,7,8,9,10,
            11,12,12,12,12,11,11,10,9,8,7,7,8,9,10,11,13,14,16,17,19,20,21,22,24,24,25,26,27,28,28,29,30,30,31,31,32,32,33,34,34,35,35,35,36,36,37,37,38,38,37,36,35,34,33,33,32,32,32,32,32,32,32,31,31,31,31,31,32,33,34,35,36,36,36,36,36,36,36,36,36,36,36,36,36,36,36,36,36,35,35,35,35,34,34,33,32,31,30,29,
            29,28,28,28,27,27,26,26,25,24,24,24,23,23,23,22,21,20,20,19,18,18,17,17,16,16,16,15,15,14,13,12,12,11,10,10,10,9,9,8,8,7,7,6,6,5,5,4,3,3,2,2,2,2,3,3,3,3,3,3,2,2,2,1,1,1,1,1,1,1,1,1,1,1,2,2,3,3,3,3,3,3,3,3,3,3,3,2,2,2,1,1,1,1,1,1,1,1,2,2,
            2,1,1,1,0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,1,1,1,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,1,1,0,0,0,0,1,1,1,1,1,1,2,2,2,3,3,3,3,3,3,3,3,3,2,2,2,2,2,2,2,2,2,2,2,2,2,1,1,0,0,0,0,0,255,255,255,255,255,255,255,255,255,255,254,254,254,
            253,253,253,254,254,254,253,253,253,253,253,253,254,254,254,254,254,255,255,255,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,2,2,3,3,4,5,5,5,5,5,5,6,7,8,9,10,10,10,10,9,8,7,7,7,8,10,11,12,13,15,16,18,20,21,23,24,24,25,26,26,27,28,28,28,29,29,30,30,31,32,32,33,33,34,35,
            35,35,36,36,36,37,37,37,37,37,36,35,34,34,32,31,30,29,28,28,28,28,29,30,30,31,31,31,31,32,33,35,36,37,37,37,37,37,37,37,37,37,37,37,37,38,38,38,37,37,37,36,36,35,35,34,34,33,33,31,30,29,28,27,26,25,25,24,23,23,22,21,21,20,20,19,18,18,17,16,16,15,15,14,14,13,13,12,12,11,10,10,9,9,8,8,8,7,7,7,
            6,5,4,3,3,2,1,1,1,1,0,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,255,255,255,255,255,255,0,0,0,0,0,0,0,0,0,1,1,1,1,1,2,2,2,2,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,4,4,4,
            4,4,4,4,3,3,2,2,1,1,1,0,0,1,1,1,2,2,2,3,3,3,3,3,3,3,2,2,2,2,3,3,3,4,3,3,3,3,2,1,1,0,0,1,0,0,0,255,255,255,255,255,255,254,254,253,253,253,252,252,252,252,251,251,251,251,251,251,251,252,252,252,253,253,254,254,255,255,255,254,254,254,254,254,254,254,254,254,254,254,253,253,253,253,253,253,254,253,253,253,
            253,253,254,255,255,255,255,255,255,0,0,0,1,2,4,5,7,8,9,10,11,12,11,10,9,9,9,10,12,14,16,17,19,20,21,22,23,24,25,26,27,28,28,29,30,30,30,30,30,31,31,31,31,32,32,33,34,34,34,35,35,35,36,36,37,38,39,39,40,39,39,38,37,37,35,34,32,30,30,30,30,31,31,31,31,31,32,33,34,35,36,37,37,38,38,39,39,39,39,39,
            39,39,39,39,40,40,39,39,39,39,38,38,37,35,34,33,32,31,31,30,30,29,29,28,27,26,24,23,22,21,21,20,19,19,18,17,17,16,16,15,14,14,13,12,12,11,11,10,9,9,8,8,7,7,7,7,6,6,5,4,4,3,3,3,3,2,1,0,0,0,255,255,255,255,255,0,0,255,255,255,254,254,253,253,252,252,252,252,252,252,252,253,253,253,253,253,253,254,254,254,
            254,254,255,255,0,0,0,0,0,0,0,0,0,255,255,255,255,255,255,255,255,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,2,2,3,3,3,3,3,3,4,4,4,4,4,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,2,2,2,2,2,2,2,2,2,2,2,2,1,1,1,1,2,2,2,2,2,2,1,1,1,1,1,
            0,0,0,0,0,0,0,0,255,255,255,254,254,254,253,253,252,252,252,251,251,250,250,250,249,249,250,250,250,251,251,252,253,254,254,254,253,252,252,251,252,252,252,251,251,250,250,249,249,249,249,249,250,250,250,251,251,250,250,250,250,251,252,253,253,254,254,254,254,255,255,0,1,3,5,6,6,7,7,8,9,9,10,10,9,9,9,10,11,13,14,16,17,19,20,22,23,24,24,24,
            24,25,25,26,27,28,29,30,31,31,32,32,32,32,33,33,34,34,35,35,36,37,38,39,39,40,41,42,43,43,43,43,43,43,42,39,36,34,32,32,33,34,34,34,34,35,36,38,39,40,41,41,42,42,42,41,41,41,41,41,41,41,42,42,42,42,41,41,40,40,40,39,38,37,36,35,33,32,31,30,29,29,28,28,27,26,25,24,23,22,21,20,19,18,17,17,16,16,15,14,
            13,12,12,11,10,10,9,9,8,7,7,7,6,6,5,5,5,4,4,4,4,3,3,2,2,2,2,2,1,0,0,255,254,254,254,254,253,253,253,253,253,252,252,251,251,251,251,250,250,250,250,251,251,251,251,251,251,251,252,252,252,252,253,253,253,254,254,254,254,255,255,255,255,255,255,255,255,255,255,255,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,1,2,
            2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,3,3,3,3,2,2,2,2,3,3,2,2,2,2,1,1,1,2,1,1,1,0,0,0,0,0,1,1,1,1,1,2,2,1,1,0,0,0,0,0,0,0,255,255,254,254,254,254,254,254,254,253,253,252,251,251,251,251,250,250,249,249,249,249,249,250,251,252,252,252,252,251,251,250,249,249,249,249,
            249,249,249,249,249,249,250,249,248,248,247,247,247,247,248,248,248,248,249,249,250,250,251,251,251,252,253,253,254,255,255,255,0,255,0,0,0,1,3,4,5,6,7,9,11,13,14,14,14,15,15,16,16,17,17,18,18,19,19,20,21,22,23,25,26,27,28,29,31,31,32,32,33,33,34,34,34,35,36,37,38,39,39,40,41,42,43,43,44,45,46,47,48,47,45,43,40,39,38,38,
            38,38,39,39,40,41,42,43,43,44,44,44,45,44,44,43,42,42,42,42,43,43,43,43,43,43,43,43,42,41,40,39,39,40,40,39,37,35,32,31,30,30,29,28,27,26,25,24,23,22,21,20,19,19,18,17,16,15,14,13,12,11,11,10,9,9,8,8,7,7,6,6,5,5,5,5,4,4,4,4,4,4,4,3,3,2,1,0,0,255,254,253,252,252,252,251,250,250,250,250,
            250,250,250,250,249,249,249,248,249,249,249,249,249,249,250,250,250,250,249,249,249,250,250,250,251,251,252,252,253,253,254,254,254,254,253,253,253,253,253,253,253,253,254,254,254,254,255,255,255,0,0,0,0,0,0,0,0,1,2,2,2,2,1,1,1,1,1,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,3,3,2,2,2,2,2,2,2,2,2,2,2,2,2,1,1,1,
            1,1,1,2,2,2,3,3,3,4,3,3,2,1,0,0,0,0,0,0,0,255,254,254,253,253,253,254,254,253,253,252,252,252,252,252,251,251,250,249,249,249,248,248,248,247,248,249,250,251,253,253,253,251,249,247,246,246,246,246,245,245,245,245,246,246,246,245,245,244,244,244,243,243,243,243,244,244,244,245,246,247,248,249,249,250,250,251,251,252,253,254,255,0,1,3,4,5,6,6,
            6,6,7,9,12,14,15,16,17,18,19,20,20,19,19,18,18,17,17,18,19,20,21,22,23,25,27,29,30,32,32,33,33,34,35,36,37,37,37,38,38,39,39,40,40,41,42,43,44,45,46,46,46,46,46,47,47,48,48,47,45,44,44,45,47,47,47,47,47,47,46,46,46,46,46,46,45,44,44,44,44,44,44,44,44,44,44,43,43,42,41,40,40,40,40,40,39,37,35,33,
            31,30,29,28,27,26,24,23,22,21,21,20,19,18,17,17,16,15,14,13,12,11,10,9,9,8,8,7,6,6,5,4,4,3,3,3,3,4,5,5,4,2,0,0,0,255,254,253,253,253,252,252,252,251,251,250,250,250,250,250,250,250,250,250,250,250,250,250,250,250,250,249,249,249,248,248,248,248,248,248,248,248,248,248,248,248,248,249,249,250,250,251,251,251,251,251,251,251,251,251,
            252,252,252,252,252,253,253,253,253,254,254,254,254,255,255,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,1,1,1,1,1,1,1,1,1,1,1,1,1,1,2,2,2,2,1,1,2,2,2,1,1,1,1,1,1,1,1,1,1,1,0,0,0,1,1,2,3,3,2,2,1,1,1,1,0,0,254,253,252,252,252,252,252,252,252,251,251,251,251,251,250,250,250,250,250,249,
            249,248,248,247,247,247,247,248,249,250,251,252,252,250,248,247,247,247,247,246,245,245,245,245,243,242,242,242,241,241,240,240,240,240,240,241,241,241,241,242,244,244,244,245,246,247,247,248,247,247,248,248,248,249,250,250,251,251,252,254,0,1,3,6,9,11,13,14,15,16,16,16,16,17,19,21,22,21,20,19,18,19,21,23,24,25,27,29,31,32,33,34,35,36,36,37,38,38,38,39,
            39,40,41,42,43,45,46,46,47,47,48,48,47,47,48,48,48,49,49,50,50,50,51,51,51,51,50,49,49,49,49,48,48,48,48,48,47,47,47,47,46,46,45,45,45,45,46,45,45,44,43,42,41,40,39,39,38,36,35,33,31,30,28,26,25,24,23,22,21,20,20,19,18,17,16,16,15,14,13,12,12,11,10,9,8,7,7,6,5,5,4,3,3,3,3,3,4,4,3,1,
            0,0,255,254,254,253,253,253,252,252,252,252,251,251,250,250,250,249,249,248,248,249,249,249,248,248,248,247,247,247,247,247,247,247,247,248,248,247,246,246,246,246,246,246,246,246,246,246,246,246,247,247,247,248,248,249,249,249,249,250,250,250,250,251,251,251,251,251,251,252,252,253,253,254,254,254,255,255,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,
            1,1,1,1,1,2,2,2,2,2,2,2,2,2,1,2,2,2,1,1,2,2,2,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,255,255,254,252,251,251,250,250,249,249,249,249,249,249,249,249,249,249,249,249,249,249,248,248,248,249,249,250,250,250,249,248,247,246,246,247,247,247,246,245,245,244,244,242,241,240,239,238,238,237,237,237,237,237,237,237,238,239,
            241,241,242,243,244,245,245,245,246,246,246,246,246,247,247,248,250,252,253,253,254,0,2,3,5,6,9,12,14,14,14,14,15,16,17,18,20,23,24,23,21,20,21,22,23,25,26,28,31,33,35,37,37,38,38,38,39,40,41,42,42,43,44,46,47,48,49,49,50,50,50,50,49,50,50,50,50,51,51,52,53,53,53,53,53,52,51,51,51,51,51,51,51,51,51,50,50,50,49,49,
            49,49,49,48,48,47,47,46,45,44,43,43,42,42,41,39,38,35,33,31,31,29,28,26,24,23,22,21,20,19,18,17,17,16,16,15,15,14,13,12,11,10,9,8,7,6,6,5,4,3,2,2,2,3,4,4,3,1,0,0,255,254,253,253,252,252,251,251,251,251,250,250,250,250,249,249,249,249,248,248,248,248,248,247,247,246,246,245,245,246,246,246,246,246,246,245,245,245,245,245,
            245,245,245,244,244,244,245,245,246,246,246,246,246,247,247,247,247,247,248,248,249,249,249,249,250,250,250,250,250,250,250,251,252,252,253,253,254,254,254,255,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,3,3,3,2,2,2,2,2,2,2,2,2,3,2,2,2,1,1,1,1,1,1,1,1,1,0,0,0,1,1,1,1,
            1,0,0,0,255,255,254,254,253,251,250,249,249,249,249,249,249,249,249,249,249,249,250,250,250,249,250,251,252,252,252,250,248,246,246,247,247,247,247,246,245,244,244,243,242,241,240,240,239,238,237,236,236,235,235,235,234,234,234,236,237,238,239,239,239,240,241,240,240,240,241,242,243,244,245,245,247,248,249,249,250,252,255,0,1,2,3,5,7,9,11,12,13,14,15,16,17,19,21,23,
            25,26,26,26,25,26,28,31,33,34,35,36,37,38,39,39,40,41,42,43,44,45,46,48,49,49,50,51,52,52,52,51,52,53,54,54,53,53,54,55,56,55,55,54,54,54,54,54,54,54,53,53,53,53,53,53,52,52,52,51,50,50,50,49,48,47,46,45,44,43,42,41,41,41,41,40,38,36,34,33,31,29,27,26,25,23,22,21,20,19,19,18,17,16,16,15,14,13,12,11,
            10,9,8,8,7,7,6,5,5,3,2,2,3,4,3,1,0,0,255,254,254,253,252,251,251,250,250,250,250,249,249,249,249,249,249,249,249,248,248,247,246,246,246,246,246,245,244,244,244,244,244,244,244,244,243,243,244,244,245,245,244,244,243,243,244,245,246,247,247,247,247,247,247,247,247,248,248,248,248,248,248,249,249,250,250,250,250,250,250,250,250,250,251,252,252,253,253,254,254,254,
            255,255,0,0,0,0,0,1,1,1,1,2,2,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,3,3,3,3,2,2,2,3,3,3,3,3,3,3,3,2,2,1,1,2,2,2,2,2,1,1,0,0,0,0,0,0,0,0,0,0,255,255,255,255,255,254,254,253,253,253,254,255,255,253,252,252,251,251,251,251,252,253,254,254,253,251,250,248,247,246,245,245,245,244,243,243,
            242,242,241,240,240,240,240,240,238,236,234,233,233,233,233,232,232,231,231,232,233,233,234,235,236,238,238,237,236,236,236,237,237,238,239,240,241,241,243,244,245,246,248,251,253,255,0,1,3,3,4,5,8,10,12,14,16,18,20,22,24,25,26,27,29,29,28,27,28,30,32,34,36,37,39,40,40,41,42,44,45,47,48,49,50,51,52,54,54,54,54,54,54,53,54,54,54,54,55,57,
            58,57,56,57,58,58,58,57,57,56,56,56,55,55,54,54,53,52,52,51,51,50,48,47,46,46,45,44,44,43,43,42,41,40,40,39,37,35,33,31,30,29,28,27,26,24,23,22,21,21,19,18,17,16,16,15,14,13,12,11,9,9,9,9,8,7,6,5,4,3,2,2,2,2,1,1,0,0,255,253,252,251,250,250,249,249,249,249,248,248,248,247,247,247,247,247,248,248,247,246,
            246,245,245,244,244,243,243,243,243,243,243,243,243,243,243,243,243,244,245,245,246,246,246,245,245,246,247,247,248,248,248,248,247,247,247,247,248,248,248,249,249,250,250,250,250,251,250,249,249,249,250,250,251,252,252,253,253,254,254,255,255,255,0,0,0,0,0,1,1,2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,3,3,3,2,2,2,2,2,3,3,
            2,2,2,2,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,255,255,255,0,255,255,255,255,254,253,253,253,253,253,252,251,250,250,249,248,248,247,246,245,245,245,244,243,242,240,240,239,239,239,238,238,237,235,232,230,230,229,229,228,228,229,229,229,229,230,230,229,230,231,232,232,232,232,231,231,232,232,233,234,236,236,236,237,
            238,240,241,243,246,248,251,254,0,1,3,4,4,5,7,10,14,17,19,21,23,25,26,27,28,28,28,27,26,27,29,32,34,36,38,40,41,42,43,45,46,47,49,51,52,53,55,56,57,57,56,56,57,58,58,58,58,57,58,59,59,59,59,60,60,59,59,59,58,57,56,55,55,53,51,51,52,51,50,49,49,48,48,47,46,46,45,44,44,43,42,41,40,40,39,38,37,36,34,31,
            30,29,28,27,25,25,25,24,23,21,20,19,18,17,16,15,14,14,13,12,11,10,9,8,8,7,6,5,4,3,3,2,2,1,0,0,255,255,255,254,254,252,251,250,249,249,249,248,248,247,247,247,247,247,247,246,246,246,245,245,244,244,243,243,243,243,243,243,243,244,245,245,245,245,244,244,243,243,243,243,243,243,243,243,244,245,246,247,247,248,248,248,247,246,246,246,246,247,247,248,
            248,249,249,249,248,247,247,248,249,250,251,251,252,252,253,254,254,255,0,0,0,0,0,0,0,1,1,2,2,2,3,3,3,3,3,2,2,3,3,3,3,4,4,4,4,4,3,3,3,3,3,3,3,3,3,3,3,2,2,2,3,3,2,2,1,1,1,1,1,1,2,1,1,1,1,1,1,2,3,4,4,4,3,3,3,3,3,3,3,3,3,4,4,6,7,8,7,6,6,4,
            3,1,255,253,252,252,251,251,251,251,250,249,249,248,248,249,249,249,248,247,246,244,242,240,239,238,237,236,236,234,231,229,227,226,226,225,225,225,226,226,225,225,225,226,226,226,227,227,228,229,229,229,229,229,230,232,233,233,233,233,234,235,236,238,240,242,245,248,250,253,0,0,0,1,5,10,14,15,16,18,21,24,26,27,27,26,24,23,23,25,28,31,34,36,37,40,41,42,44,46,
            47,48,50,52,53,54,55,55,56,57,59,60,60,60,60,59,59,59,60,60,61,60,60,61,61,60,59,59,59,58,57,56,54,53,52,51,51,50,49,49,48,48,47,47,46,46,45,43,42,42,40,39,39,39,38,37,35,33,32,31,31,30,29,28,27,26,25,24,22,21,20,20,19,18,17,16,15,14,13,12,11,10,10,9,9,8,7,6,5,4,3,3,2,1,0,0,0,255,255,254,
            253,252,252,251,251,250,250,249,248,247,246,246,245,245,245,245,244,244,244,244,243,243,243,242,242,242,242,243,243,242,241,242,243,243,243,244,244,243,242,242,241,240,241,242,242,242,243,243,243,244,244,245,245,245,245,246,246,246,246,247,247,247,246,244,244,243,244,247,250,252,252,252,252,253,254,254,255,0,0,0,0,1,1,1,1,2,2,2,3,4,4,4,4,4,4,4,4,4,4,4,
            5,5,5,6,6,6,6,6,5,5,5,5,5,5,5,5,5,4,4,4,5,4,3,2,2,1,1,1,1,1,1,0,1,1,1,1,2,3,4,4,4,4,4,5,7,8,8,8,8,8,9,10,10,9,8,8,8,7,6,5,4,4,3,1,0,255,254,254,254,253,252,251,251,250,249,249,248,247,247,246,245,243,241,240,238,236,234,232,231,229,227,226,225,225,225,224,223,223,222,221,
            221,222,222,223,223,223,224,226,227,227,227,227,226,226,227,229,230,231,230,230,230,231,232,234,236,238,240,242,245,248,252,255,1,3,4,7,9,12,15,17,20,22,24,26,26,25,25,26,28,29,31,34,36,38,40,42,44,45,47,48,49,49,52,55,56,56,56,56,56,57,58,59,59,59,59,59,60,61,60,61,62,63,62,62,62,61,60,59,59,58,57,56,55,54,53,52,51,51,50,50,
            49,49,48,47,46,45,44,42,42,41,40,41,41,39,36,32,31,31,32,32,31,29,28,27,25,24,22,21,21,20,19,18,18,19,19,18,17,16,15,14,12,11,10,10,10,9,9,8,7,5,5,4,2,2,1,0,0,0,0,255,254,253,252,251,251,251,251,251,250,248,246,245,243,243,243,243,242,242,242,242,242,241,241,241,241,241,241,241,241,240,240,239,240,241,241,241,241,240,241,241,
            241,241,241,241,241,241,241,241,241,241,242,242,243,243,244,244,245,246,246,246,246,246,245,244,242,242,243,247,251,253,253,252,252,253,254,254,255,0,1,2,2,2,1,1,1,2,3,3,4,5,5,5,5,5,5,5,5,6,6,6,6,6,6,7,7,7,7,8,8,7,6,6,5,6,6,6,6,6,6,6,4,4,4,3,2,2,2,2,2,2,2,2,2,2,2,3,3,4,4,5,
            5,5,6,7,8,9,9,9,9,10,10,9,9,8,7,7,7,7,7,7,7,6,4,4,3,2,0,255,255,254,253,252,252,251,249,248,247,246,244,243,241,240,240,239,238,237,235,233,232,231,230,228,226,224,224,223,222,221,221,220,220,220,220,220,221,222,223,224,225,225,226,226,225,225,225,226,227,228,228,228,228,229,229,231,233,234,236,238,240,243,247,250,253,1,5,8,8,9,12,16,
            18,20,22,23,25,25,27,29,30,31,33,36,39,41,44,46,47,48,48,49,49,50,53,55,57,57,56,57,57,58,58,59,60,60,61,61,62,63,63,63,64,64,63,63,63,63,62,60,59,58,57,57,56,54,53,52,51,51,51,50,49,49,48,47,45,44,42,42,43,42,41,40,38,35,33,31,31,31,31,31,29,28,26,24,23,22,21,20,18,17,18,18,17,17,17,18,18,17,16,15,
            14,13,12,11,10,9,7,7,7,7,5,4,3,2,1,1,0,0,255,255,254,252,251,250,252,253,252,251,249,247,245,244,243,242,241,241,241,241,241,241,240,240,240,240,240,239,239,239,239,238,237,237,238,238,238,238,237,237,238,238,237,237,238,239,239,239,239,239,240,240,240,241,242,242,243,244,244,244,245,245,245,245,245,244,243,243,246,250,252,252,252,252,253,253,254,255,0,0,1,3,
            4,3,4,5,6,5,4,3,4,5,5,6,6,6,6,6,6,6,7,7,8,8,8,8,8,8,9,9,9,8,8,7,7,6,6,6,6,6,6,5,4,4,4,4,4,3,3,3,4,4,4,4,4,4,5,5,5,5,6,6,7,8,8,8,8,9,9,9,10,10,10,9,9,9,9,9,9,9,8,8,8,8,6,6,5,4,2,0,255,253,252,251,250,250,249,247,245,244,242,240,
            240,239,239,239,238,237,236,234,233,232,230,228,227,225,224,223,221,220,220,219,219,218,218,218,219,220,221,222,221,221,222,223,223,223,223,223,223,223,224,225,226,227,228,230,231,233,234,236,238,241,243,246,248,251,0,4,7,8,9,13,17,20,20,22,25,27,28,30,31,33,34,36,40,42,44,46,48,49,48,48,48,48,49,51,53,55,57,58,58,59,60,61,61,61,63,64,64,65,65,65,
            65,66,66,65,65,65,64,63,62,60,58,58,56,54,53,53,53,52,51,51,50,49,47,46,45,44,43,43,42,41,39,39,37,35,34,34,32,31,30,29,28,25,23,21,20,19,18,18,18,18,18,17,17,18,18,17,16,15,15,15,14,13,12,12,12,10,8,6,6,6,6,4,2,2,1,0,0,0,255,254,253,251,252,253,253,251,249,248,246,245,244,243,241,240,240,240,239,239,240,239,
            238,238,238,238,238,238,237,236,235,235,235,235,235,234,235,236,235,234,234,235,235,235,235,236,236,237,237,238,239,240,240,241,242,242,242,241,241,242,242,242,242,242,242,242,242,243,246,249,251,253,253,253,253,254,0,0,0,1,3,4,5,6,6,5,6,8,9,8,8,6,5,5,6,7,7,7,7,8,8,9,9,9,10,10,10,10,10,10,9,9,9,8,7,7,6,6,6,5,5,5,
            4,4,4,4,4,4,4,4,5,5,5,5,5,6,6,7,7,7,8,8,9,9,9,9,9,10,10,10,11,11,11,11,11,11,12,12,12,12,11,10,11,11,10,9,8,7,7,7,6,4,3,1,254,251,248,247,246,244,243,242,241,240,238,237,237,236,235,234,232,231,229,228,226,224,223,223,221,220,219,218,217,217,216,216,217,217,218,219,219,219,221,220,220,220,221,222,223,223,221,222,
            223,225,227,229,231,232,234,236,238,240,243,247,249,250,252,255,1,4,7,12,16,18,19,21,23,26,28,28,27,29,33,37,40,44,47,49,50,50,48,46,44,45,48,51,55,56,56,56,57,58,59,60,61,62,64,65,65,66,66,67,67,68,68,67,67,66,64,63,63,61,60,58,57,56,55,53,53,52,52,51,51,50,48,46,44,44,43,41,40,40,40,39,38,36,34,32,31,30,29,27,
            25,23,20,19,18,18,18,17,18,18,18,17,16,17,17,18,17,17,15,13,13,12,11,10,9,8,8,7,7,7,6,5,4,2,0,0,0,255,254,0,0,0,254,252,250,248,246,246,245,244,243,242,241,240,239,238,237,238,238,237,236,236,236,236,236,236,235,234,234,234,234,235,234,233,232,232,232,232,233,233,233,232,232,233,235,235,235,235,236,237,238,239,239,240,240,239,238,239,239,239,
            240,240,240,240,239,240,243,247,250,252,253,254,255,0,0,1,2,3,3,4,5,6,6,7,8,9,9,10,10,9,8,7,7,8,8,8,9,9,10,10,10,10,11,11,11,11,11,11,10,9,9,8,7,7,7,6,5,5,5,5,3,4,4,5,5,5,5,5,5,6,6,5,5,6,6,7,7,7,8,9,10,10,10,10,10,11,12,12,12,12,13,14,14,14,13,13,13,13,12,11,
            11,10,10,9,8,7,6,6,6,5,4,2,1,255,251,249,248,247,245,242,241,240,238,236,235,234,233,232,230,229,228,227,225,223,223,222,220,218,217,215,214,214,214,214,215,215,216,217,217,217,217,217,217,218,219,219,220,221,221,221,222,223,225,226,227,229,231,234,238,241,244,246,249,253,255,255,255,1,7,12,14,15,16,19,21,23,25,28,29,29,32,37,40,44,47,50,51,51,48,46,
            46,50,53,53,53,54,54,55,57,58,59,61,62,63,64,65,65,66,67,67,67,68,67,66,66,64,63,63,62,61,60,58,57,56,54,52,52,52,51,51,50,49,47,44,43,42,43,43,42,41,40,39,36,34,32,31,30,30,28,26,23,21,19,18,18,18,18,18,18,18,18,17,16,16,16,15,15,15,14,14,14,13,12,11,10,9,9,9,8,6,6,5,4,2,1,0,0,255,255,0,
            255,254,251,249,248,246,246,245,244,243,242,241,240,239,238,236,236,236,235,236,236,235,234,234,234,234,233,232,232,232,233,233,233,232,231,230,230,230,230,229,228,228,229,229,230,231,231,231,232,233,234,235,236,236,237,237,236,237,238,238,239,239,239,240,241,241,242,244,246,247,247,248,251,254,0,2,3,4,6,6,6,6,7,8,8,9,10,10,11,11,10,10,10,10,9,10,11,11,12,12,
            12,12,12,12,12,11,11,11,10,9,9,8,8,7,7,6,5,4,4,4,5,5,5,5,5,5,5,6,6,6,7,7,7,7,7,8,8,9,10,11,11,11,11,11,12,12,12,14,15,16,16,16,17,17,17,17,17,15,13,13,12,11,10,9,8,7,6,4,4,3,3,2,1,0,254,252,249,246,245,244,242,239,237,236,234,232,230,229,228,228,227,226,225,223,222,221,219,218,217,215,
            214,213,212,212,212,212,212,212,212,213,213,213,214,215,216,216,217,218,219,220,221,223,224,225,226,227,229,231,234,238,241,244,247,248,251,254,0,3,7,10,11,12,14,16,18,20,23,25,28,30,32,37,42,45,48,51,51,49,46,47,50,51,51,52,53,54,55,56,58,59,61,62,63,64,65,66,65,65,65,64,63,63,63,62,61,61,61,61,62,61,60,59,59,57,55,54,52,51,50,50,
            48,48,48,47,45,45,45,44,44,43,42,40,38,35,33,32,31,29,25,22,20,18,18,18,18,18,18,18,18,18,17,16,15,15,14,14,14,14,13,12,12,12,12,12,11,10,9,10,10,8,6,4,2,2,2,1,0,0,255,255,254,251,249,248,247,246,245,244,243,242,241,239,238,237,236,235,235,234,234,234,233,233,232,232,232,232,231,231,231,231,231,231,231,231,230,229,229,228,228,227,
            228,228,228,228,227,228,228,228,229,230,231,232,233,233,234,234,233,235,237,238,238,239,241,242,243,244,244,245,245,246,247,248,249,251,252,253,255,1,5,7,9,9,8,8,10,11,11,11,12,12,12,12,11,11,12,13,13,13,14,13,13,12,12,11,12,12,11,10,10,10,10,9,9,8,8,7,6,6,6,5,5,5,5,5,6,6,6,7,7,7,7,7,8,9,9,9,10,10,11,11,
            11,12,12,12,12,14,15,15,12,9,10,16,20,19,17,17,18,19,18,16,14,12,11,10,8,7,7,5,4,3,1,1,0,0,255,253,249,246,244,243,242,240,237,235,233,230,229,228,227,226,225,224,224,223,222,221,218,217,216,215,214,213,212,211,210,210,209,209,209,209,210,211,213,213,213,214,215,217,218,219,221,223,224,227,229,230,232,233,233,234,236,238,241,244,247,252,0,3,7,9,
            10,10,13,15,17,20,22,23,26,29,31,33,38,42,45,48,49,46,47,49,50,50,51,52,53,54,56,57,59,61,62,63,64,65,65,65,65,64,64,64,63,62,62,61,60,60,60,60,60,60,59,59,58,57,56,54,52,50,49,50,50,49,48,47,46,46,46,47,47,46,43,40,37,36,34,32,30,27,24,22,21,20,19,19,18,19,18,18,19,18,17,17,17,16,15,14,14,14,13,12,
            12,11,12,11,11,10,9,9,8,7,6,4,2,1,0,0,0,255,254,255,254,251,248,247,246,245,244,242,240,239,238,237,236,235,235,234,233,232,232,231,230,230,230,229,229,230,230,230,230,230,230,229,228,229,229,228,228,227,226,226,226,226,226,227,227,228,228,228,228,229,230,231,232,232,232,233,235,237,237,235,235,239,243,244,246,246,247,248,249,250,252,254,255,0,0,1,2,5,7,9,
            9,10,12,12,12,12,12,13,13,13,14,14,14,14,14,15,15,14,13,13,13,13,13,12,11,11,11,10,11,11,11,11,10,8,6,4,2,3,5,6,5,5,4,4,6,7,7,7,8,8,8,9,10,10,11,11,11,11,12,12,13,13,13,14,14,14,15,15,11,6,8,12,14,15,17,18,18,19,20,20,19,15,12,10,9,7,6,5,3,2,1,0,0,255,253,252,248,245,244,243,
            241,239,236,234,233,231,229,228,227,226,225,224,224,223,222,220,219,218,216,215,215,213,212,211,209,209,209,208,208,209,210,211,212,212,212,213,214,215,217,218,220,221,222,224,227,229,231,233,233,234,235,237,238,240,244,250,254,0,3,6,8,9,11,13,16,19,21,23,25,27,29,31,34,36,38,43,45,46,48,49,49,50,51,53,53,55,57,58,60,62,63,64,65,65,65,64,64,64,63,62,
            61,61,61,60,60,59,59,60,59,58,58,58,57,56,55,53,52,51,51,51,49,47,47,46,45,46,46,47,46,44,41,38,36,34,32,31,29,26,23,22,20,19,19,19,19,19,17,16,16,16,16,16,15,15,15,14,12,11,12,12,11,11,11,10,10,10,9,8,8,7,6,5,4,2,1,0,255,254,253,254,254,251,248,246,245,244,242,240,239,238,237,236,235,233,233,232,231,229,230,229,
            228,228,228,228,228,228,228,228,227,226,227,228,227,227,226,225,225,224,223,224,224,225,225,226,227,227,227,227,228,228,229,230,231,230,230,231,232,235,237,238,239,242,244,246,247,249,250,251,252,253,255,0,1,2,3,3,5,7,9,10,11,12,12,13,13,14,14,15,15,15,16,16,16,16,17,16,15,13,13,13,14,14,13,12,12,12,12,12,11,11,11,10,8,5,3,1,0,0,1,3,
            1,3,5,5,5,7,8,8,8,9,9,10,11,11,12,12,12,12,13,14,15,15,14,15,15,15,15,13,10,10,14,14,13,13,12,12,12,11,11,12,11,12,12,11,9,8,6,4,2,2,0,255,254,253,252,250,247,244,244,243,241,240,239,236,235,233,231,229,228,227,226,225,224,223,222,221,220,218,217,216,215,214,213,212,210,209,208,208,208,209,210,211,210,210,210,212,213,214,215,215,
            215,216,219,223,225,227,230,231,232,233,234,235,238,241,244,249,252,0,2,3,5,7,9,12,14,17,19,22,25,27,29,30,33,36,39,42,44,46,47,48,49,50,51,53,54,56,58,59,61,63,64,64,64,64,64,63,63,62,61,61,61,60,60,60,60,60,59,58,57,57,56,56,55,56,55,54,54,53,51,49,48,47,46,45,44,44,45,46,45,42,41,39,36,33,31,29,27,25,23,21,
            19,19,20,20,19,18,16,16,15,15,16,16,15,15,16,15,13,12,11,11,12,11,11,12,11,10,9,7,7,7,6,5,4,3,2,0,0,254,254,254,253,249,246,246,244,242,241,240,238,237,236,234,233,232,231,231,230,229,228,227,227,226,226,226,226,225,225,225,224,223,223,224,225,226,225,224,223,222,223,223,223,223,224,225,225,226,226,227,228,229,229,229,229,229,229,231,233,236,238,239,
            240,242,245,247,249,251,253,254,255,0,0,2,3,4,5,5,6,8,10,11,12,12,12,12,14,14,14,14,15,16,16,17,17,17,17,16,15,14,14,14,14,15,15,14,13,13,12,11,8,8,8,4,0,0,0,255,255,0,0,0,0,1,4,6,6,7,8,9,9,10,10,11,12,12,12,13,13,14,14,15,15,15,15,15,16,15,14,12,11,10,11,12,12,10,8,7,6,7,7,8,
            7,6,8,9,7,6,5,4,2,2,1,0,254,253,252,249,246,245,244,242,240,239,238,237,237,236,233,230,229,227,226,225,224,223,223,222,221,221,219,217,217,215,214,212,210,210,208,207,208,208,209,209,209,209,209,211,211,211,213,213,213,216,218,221,223,224,227,228,230,230,231,233,236,239,242,246,250,252,0,3,5,6,7,10,12,15,18,21,23,25,28,31,33,36,38,40,43,45,47,48,
            49,50,51,53,55,57,59,61,63,64,64,64,64,63,63,62,61,61,61,61,61,60,60,60,59,59,58,56,56,56,55,55,56,56,56,55,54,52,50,48,47,46,45,45,44,44,44,45,44,42,40,38,35,33,31,29,28,26,23,21,20,19,20,20,20,19,18,17,17,16,15,14,15,16,17,16,15,14,14,14,13,13,12,11,10,9,8,8,7,6,6,5,3,3,1,0,0,255,254,254,
            252,249,246,244,243,241,240,239,237,236,235,233,232,230,230,229,228,227,227,226,225,225,224,223,223,223,223,223,222,221,220,221,222,223,223,223,223,222,222,221,221,221,222,223,225,226,225,226,227,227,229,229,229,231,234,236,238,238,238,239,241,243,246,249,251,252,254,0,1,1,2,4,5,7,8,8,10,11,12,13,13,13,13,13,13,13,14,15,16,16,16,17,17,18,17,17,16,16,16,16,
            16,17,17,16,14,12,10,8,6,5,6,5,1,0,1,0,0,0,0,1,1,1,1,2,4,5,7,9,10,10,11,11,12,12,13,14,14,15,15,15,15,15,15,15,15,14,13,12,11,12,14,13,10,6,6,7,8,6,5,4,4,3,3,3,4,3,2,3,3,1,0,0,255,254,253,251,249,245,243,242,241,240,238,237,236,234,233,233,231,229,228,227,226,225,224,224,223,222,221,220,
            219,217,216,215,213,212,211,210,210,209,209,208,208,209,209,210,210,210,211,213,214,215,217,219,221,222,224,226,228,229,230,232,235,238,241,245,248,249,250,254,3,5,6,9,11,14,17,19,22,24,27,30,33,35,38,40,42,44,45,47,49,51,53,54,56,58,60,62,63,63,63,63,63,62,62,61,61,61,61,61,61,61,60,59,59,60,59,57,56,56,56,57,58,57,56,55,53,50,49,48,
            48,47,46,44,43,43,42,42,41,39,38,37,35,34,32,31,29,27,25,23,21,21,20,20,20,19,20,20,18,17,16,16,15,16,16,15,15,14,14,13,12,11,11,10,9,9,9,8,7,6,5,4,2,1,0,0,255,254,253,253,252,249,246,243,242,240,239,237,235,234,233,232,231,230,228,228,227,227,226,225,224,223,223,222,222,222,222,222,221,220,220,219,219,220,220,219,218,217,217,217,
            218,220,221,222,224,224,223,224,224,226,228,231,233,235,237,238,240,241,242,243,245,246,249,251,253,0,1,3,4,6,8,9,10,10,10,11,11,12,14,14,14,13,13,13,13,14,15,15,16,16,16,16,16,16,17,16,17,18,18,18,18,19,18,16,13,10,9,9,7,6,6,6,3,2,2,1,1,1,1,1,2,2,2,3,3,3,5,10,11,11,12,12,12,13,14,14,15,15,15,15,
            14,14,15,15,12,11,13,15,15,14,11,8,7,8,8,8,7,6,5,4,2,1,0,0,1,2,0,0,0,0,0,255,254,253,252,250,249,247,245,243,242,241,239,238,237,235,235,234,232,231,229,228,228,227,227,226,225,224,223,222,221,219,218,216,215,215,212,211,210,210,210,209,209,209,209,209,209,210,210,212,214,214,215,217,219,221,222,224,227,228,229,231,234,238,241,244,246,247,248,250,
            255,4,6,8,11,13,16,18,21,24,27,29,32,34,36,40,41,44,46,47,49,51,53,55,58,59,61,61,62,62,62,61,61,61,61,61,61,61,62,62,61,60,60,60,60,60,59,57,55,55,56,58,58,57,56,56,54,52,50,48,47,46,45,44,43,42,42,41,40,39,39,38,37,36,34,33,31,28,26,25,23,23,23,22,21,21,21,20,19,19,18,17,16,17,16,15,13,13,13,13,
            12,11,9,9,8,8,8,7,6,5,4,2,0,255,254,252,252,252,251,251,250,248,245,243,241,239,238,236,235,234,232,231,229,228,228,227,226,225,224,223,223,223,222,222,221,221,221,220,219,218,219,218,218,218,218,217,216,217,217,217,218,219,221,221,222,222,223,223,224,227,231,233,234,235,236,238,240,243,245,246,247,248,250,252,254,0,2,5,6,8,9,10,11,12,12,12,14,15,15,14,
            14,14,14,15,15,16,17,18,17,18,18,17,17,16,17,18,19,20,21,21,21,19,18,15,11,10,10,9,8,7,7,5,3,2,2,1,1,1,1,2,2,3,3,3,3,3,3,4,5,7,10,12,13,13,14,15,15,15,16,16,17,16,14,13,12,13,13,11,7,6,7,9,9,9,8,7,6,6,4,3,2,0,0,254,253,255,0,255,254,254,253,252,251,251,250,249,248,246,244,243,
            242,241,239,238,238,237,236,235,233,232,230,229,228,228,228,227,227,226,225,224,223,222,220,219,217,216,215,214,213,212,211,210,209,209,209,209,210,210,211,212,213,213,214,216,218,220,222,224,225,227,230,230,232,236,240,242,245,246,247,248,251,0,5,7,8,11,14,17,20,23,26,28,31,34,37,39,41,43,46,48,50,52,54,56,58,60,61,61,61,61,61,61,61,61,61,61,62,63,63,61,
            61,61,61,62,62,60,58,56,55,56,57,58,58,57,56,55,54,52,50,48,47,45,44,44,43,42,42,41,41,41,39,38,37,36,35,34,32,30,28,26,25,24,24,24,23,22,22,21,20,20,18,17,17,18,17,16,16,15,14,13,12,10,9,8,8,7,6,6,5,4,2,1,0,255,252,251,251,251,250,250,249,247,244,241,240,238,237,235,234,233,232,231,229,228,227,226,225,224,223,223,
            222,222,222,222,221,221,221,221,220,219,218,218,219,218,217,217,217,216,216,216,217,218,219,220,221,223,224,224,226,228,229,231,232,234,236,238,240,243,246,248,249,250,251,253,255,0,2,4,6,9,11,12,13,14,14,15,15,15,15,14,14,14,15,15,16,18,18,18,18,19,19,19,18,18,18,19,21,23,24,23,22,19,15,9,7,8,9,9,8,7,6,4,3,2,2,1,0,0,0,0,
            0,1,3,3,4,5,6,6,6,8,9,11,13,14,14,15,17,17,18,18,17,15,14,13,11,10,9,10,11,12,11,9,8,7,6,6,4,3,2,1,0,0,255,253,251,251,252,253,252,251,251,250,249,248,247,247,246,245,244,243,241,240,240,240,239,238,237,235,234,233,232,231,230,229,229,228,228,227,226,225,224,223,222,220,218,217,216,215,214,214,213,211,211,210,210,211,211,212,212,212,
            212,213,214,216,218,220,222,224,226,228,230,232,234,236,239,241,242,244,245,247,249,252,0,3,8,12,15,16,19,22,25,28,31,34,37,38,41,43,45,48,50,52,54,56,58,60,60,60,61,60,60,61,61,61,61,61,61,60,60,60,61,62,61,60,59,58,57,56,56,56,58,58,57,57,56,55,55,53,51,49,46,44,43,42,42,41,41,41,41,41,40,39,38,36,35,34,33,32,30,28,
            27,26,25,25,25,25,24,22,22,21,20,19,17,17,16,16,15,15,14,12,11,10,8,7,7,6,6,5,4,2,1,1,0,255,253,251,249,248,248,249,247,245,243,240,239,237,235,234,234,232,231,230,228,227,225,224,223,222,222,221,221,222,221,221,220,220,219,220,221,221,220,218,218,219,220,219,219,219,219,217,217,217,218,220,222,223,224,225,225,224,227,231,233,235,237,239,240,243,245,246,
            248,249,252,254,255,1,3,4,6,7,10,13,14,15,14,15,15,15,15,16,16,15,16,17,16,16,17,18,18,19,19,19,20,19,19,20,21,25,26,23,22,21,12,1,255,1,8,12,10,6,5,3,2,1,0,255,1,4,3,2,4,1,2,3,4,5,5,6,7,8,10,11,13,16,17,18,17,13,9,7,8,8,8,8,9,10,11,11,11,11,11,10,9,7,5,4,3,1,0,0,
            255,254,253,252,251,249,248,250,249,248,249,248,248,248,246,244,244,244,243,244,244,243,242,240,239,238,237,235,234,234,233,233,232,231,231,231,229,228,227,226,226,225,223,221,220,219,218,218,217,215,214,213,212,212,211,211,212,212,213,213,213,214,214,216,218,220,222,224,226,228,230,232,234,236,237,238,239,241,242,244,248,252,0,2,6,10,14,15,18,21,24,27,30,32,35,38,40,42,44,46,
            49,51,53,56,58,60,60,60,60,60,61,61,61,61,61,61,61,60,60,61,61,61,61,60,59,58,58,58,59,60,59,58,58,57,56,55,54,53,51,48,46,45,43,42,42,41,40,40,39,40,40,39,39,37,36,35,34,32,30,29,29,29,28,28,27,26,25,24,23,21,19,18,17,17,16,16,15,13,11,10,9,9,8,7,7,6,5,4,3,2,0,0,0,254,252,249,247,246,247,248,
            246,243,241,239,237,235,234,233,231,230,230,229,227,226,224,223,222,220,219,219,218,218,218,218,218,218,217,217,219,220,220,218,217,217,218,219,221,221,221,220,218,218,218,219,222,223,223,224,223,223,227,230,233,235,236,238,240,242,244,246,248,251,252,254,0,1,4,6,7,8,10,12,13,14,16,17,16,16,16,17,18,17,16,18,17,15,16,17,18,18,19,19,19,20,20,21,22,25,26,25,
            21,14,4,0,3,5,2,1,2,4,5,3,2,2,2,3,3,2,2,5,5,2,1,2,2,3,4,6,8,10,13,14,14,10,4,1,1,2,5,7,9,10,11,12,12,11,11,10,9,8,7,6,6,5,3,3,1,0,255,254,252,251,250,249,248,247,245,245,247,246,245,246,246,246,246,245,244,243,243,244,244,244,244,243,240,239,238,236,235,235,235,235,235,234,234,233,232,231,230,229,
            227,226,224,223,222,222,221,219,218,217,216,215,213,213,212,212,212,212,212,212,212,213,214,216,218,220,223,224,226,228,230,232,233,235,238,239,241,242,244,247,250,254,0,3,7,10,12,15,19,21,23,25,28,31,34,36,39,41,43,45,47,50,52,54,56,58,59,60,60,60,61,60,61,61,62,62,61,60,60,60,61,60,60,59,59,60,62,61,60,60,58,58,57,57,56,55,55,53,51,49,
            46,45,44,43,42,42,42,41,41,41,41,41,40,39,37,36,34,33,31,30,30,30,29,28,28,26,26,24,22,21,19,19,18,17,17,16,14,12,11,10,9,8,8,7,5,4,4,3,1,0,0,255,254,253,251,248,246,246,246,245,243,240,238,237,235,233,233,232,231,230,229,228,227,225,224,222,220,219,218,218,217,217,217,217,216,215,216,217,217,218,217,216,216,217,217,217,218,219,221,222,
            222,220,219,219,220,221,221,220,221,225,228,230,232,234,237,238,240,242,245,247,250,252,254,0,1,3,4,6,7,9,11,13,15,17,18,18,17,17,18,18,19,19,18,18,17,16,16,17,18,18,18,19,20,20,21,22,23,25,25,22,18,6,254,1,8,9,9,5,0,255,0,2,4,7,5,2,1,2,1,3,4,3,3,5,7,7,7,7,6,3,1,255,254,0,3,6,9,10,11,11,
            11,11,10,10,10,10,9,8,7,6,6,5,4,2,1,0,0,255,253,252,250,249,248,247,246,245,245,243,243,243,243,243,243,245,246,245,244,243,242,243,243,243,244,246,246,242,239,238,237,237,237,237,237,235,236,235,234,233,233,230,227,226,225,224,224,224,223,222,221,219,218,216,215,214,214,215,214,214,214,213,213,214,215,217,218,220,222,224,226,227,229,231,234,237,239,239,241,242,244,247,
            249,252,0,1,4,9,12,14,18,20,22,23,26,29,32,34,36,39,42,45,47,49,51,52,54,56,57,58,60,61,62,62,62,62,62,62,62,62,62,61,61,59,58,57,58,61,61,60,60,60,58,58,57,56,55,54,54,52,50,48,46,45,44,44,44,43,44,44,45,45,44,44,43,42,41,40,38,37,35,33,33,32,30,29,27,27,27,26,23,21,22,24,21,19,18,16,14,13,12,11,
            10,9,7,7,6,3,2,0,255,254,252,251,250,248,247,245,243,243,245,244,241,239,236,234,232,232,232,231,230,229,228,227,225,224,223,221,220,219,217,216,216,216,215,214,214,214,215,215,217,218,219,217,217,215,215,216,217,218,219,220,221,222,221,219,218,217,218,221,224,225,227,229,232,233,236,238,241,243,246,248,251,253,0,1,3,5,6,8,9,11,12,14,16,18,19,19,18,18,19,20,
            20,20,19,19,18,17,16,17,18,19,19,19,20,21,22,23,23,26,25,13,2,0,3,7,8,9,9,7,6,3,0,254,252,255,1,5,5,3,5,5,249,250,250,249,248,246,246,247,250,252,255,2,5,7,9,10,10,10,11,10,10,10,9,8,7,7,6,5,4,3,3,2,1,0,0,255,254,252,251,250,248,247,245,244,243,243,242,242,241,240,240,240,242,243,244,244,245,244,243,242,
            241,242,242,243,245,244,240,239,238,238,238,238,237,236,236,236,236,235,234,233,230,228,227,227,226,226,224,223,222,221,220,219,218,217,217,217,216,216,215,216,215,215,216,217,219,221,222,224,225,228,231,233,235,236,237,239,240,240,242,244,247,249,252,255,3,8,11,13,16,20,24,24,25,28,31,34,36,39,41,43,46,48,50,52,54,55,57,58,59,61,63,63,64,63,63,63,63,63,63,62,
            61,60,59,58,57,59,60,59,58,60,59,58,56,55,55,54,53,51,49,46,45,45,44,45,45,45,46,47,48,48,47,47,45,44,43,43,42,40,39,38,37,36,34,31,29,29,29,30,27,25,25,25,23,20,18,16,14,13,12,11,11,9,7,6,4,3,0,253,251,248,247,245,243,243,241,240,239,238,241,241,239,236,234,233,231,230,230,229,229,227,226,225,224,223,221,219,218,217,217,215,
            214,214,215,216,217,217,217,218,218,218,218,215,214,214,214,214,215,216,217,218,218,218,219,220,220,220,221,221,223,225,228,230,232,233,236,238,240,243,246,249,252,255,1,3,5,7,8,10,11,12,13,16,17,19,20,20,20,20,20,22,21,20,20,21,19,17,18,18,18,19,19,19,20,22,22,23,25,26,20,7,1,5,8,8,8,8,6,6,6,4,4,3,1,0,253,252,249,246,249,250,
            251,251,252,253,255,0,1,2,3,5,6,6,7,8,9,9,9,9,8,8,8,7,7,6,5,4,4,3,2,1,0,0,255,254,253,252,251,249,248,247,245,244,243,242,241,240,240,239,239,239,238,237,237,241,243,242,241,242,242,242,241,241,242,242,243,244,242,240,239,239,240,240,240,238,238,238,237,237,236,234,233,232,230,228,228,227,226,225,223,223,222,221,219,218,218,217,217,218,218,219,
            217,217,217,218,220,221,223,224,226,228,231,233,234,234,234,235,236,238,239,241,244,247,252,1,4,8,10,13,16,22,24,23,23,26,29,32,35,38,40,42,44,47,50,51,53,54,56,57,59,61,62,64,64,64,64,63,63,63,63,63,62,61,60,58,57,57,56,57,58,59,59,58,56,55,54,53,52,50,48,46,45,45,45,45,45,47,48,49,48,48,48,47,47,47,45,44,44,44,42,42,
            40,37,35,33,30,32,32,31,31,29,27,25,23,22,19,16,14,12,11,11,11,10,8,5,4,0,252,249,249,247,245,244,242,242,242,240,237,236,239,240,238,235,233,231,229,228,228,227,226,225,224,223,223,222,221,219,218,216,215,213,213,212,212,214,215,216,217,218,218,217,216,215,213,210,211,212,213,215,215,216,216,216,217,217,218,218,219,221,223,225,228,230,232,234,236,238,241,244,246,249,
            253,0,2,4,6,9,11,11,12,13,15,17,19,20,21,21,20,21,22,23,23,20,21,22,21,19,18,19,19,20,20,21,21,21,22,25,25,16,6,2,6,8,9,8,7,6,4,4,3,2,2,3,1,1,1,1,1,0,254,252,0,1,1,2,2,3,4,4,5,6,6,6,6,7,7,7,7,7,6,6,5,5,4,4,3,2,1,1,0,255,254,253,252,251,250,249,248,246,245,244,
            243,241,240,239,239,238,238,237,237,237,236,236,235,235,238,240,240,237,238,241,241,241,241,242,242,243,243,243,243,242,241,241,240,240,240,239,239,238,237,236,235,234,232,230,229,228,227,225,224,223,222,221,219,219,219,219,218,219,219,218,218,218,219,219,220,221,222,223,224,227,230,230,231,234,237,238,238,237,239,241,243,248,254,2,4,7,9,12,15,21,24,22,23,26,29,33,35,37,39,41,
            43,45,47,49,52,53,55,56,58,60,62,63,65,65,64,65,64,64,63,63,63,62,60,59,58,57,56,56,58,58,58,56,55,54,53,52,51,50,49,48,47,46,45,44,46,47,47,48,48,48,49,47,46,47,47,47,46,43,43,43,43,40,38,36,33,32,33,34,32,30,29,26,24,23,21,18,17,16,13,11,10,9,7,5,2,254,252,250,248,246,243,242,241,241,240,238,236,235,238,239,
            235,233,231,228,226,226,226,225,225,224,222,221,220,218,218,217,217,217,217,215,213,212,212,211,211,213,214,214,216,215,214,212,212,211,211,212,212,212,213,214,214,214,215,216,217,217,218,220,223,225,228,230,232,234,236,239,241,244,247,251,254,0,3,4,7,9,11,13,13,15,16,18,19,20,20,20,22,23,24,24,24,21,22,22,21,21,20,20,20,21,20,21,21,21,25,24,12,2,3,8,
            9,9,8,7,5,4,2,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,2,3,3,4,4,4,4,4,5,4,4,4,4,3,3,2,1,1,0,0,255,254,254,253,252,251,249,248,247,246,245,243,242,241,240,239,238,237,236,236,235,236,235,235,235,235,235,234,234,234,237,236,236,240,239,238,239,240,241,242,243,244,243,243,242,242,242,241,240,240,239,239,239,236,
            235,233,232,230,230,229,228,226,226,226,224,222,221,220,219,220,220,220,220,219,219,219,220,221,223,224,224,225,227,230,230,230,233,235,236,237,238,239,240,241,243,248,253,0,2,5,8,10,12,17,20,25,27,29,32,35,36,37,37,40,42,44,46,49,51,52,54,56,58,60,62,64,65,66,65,65,64,63,63,63,62,61,61,60,58,58,56,55,54,53,52,52,53,54,53,51,50,50,49,48,
            47,46,45,46,47,47,49,49,50,48,47,47,45,45,46,47,46,44,44,44,43,41,39,39,38,36,34,33,32,31,30,28,26,24,23,21,20,18,16,14,12,10,7,4,1,254,252,249,245,244,243,241,241,239,238,236,236,236,237,236,233,231,228,226,224,224,224,223,223,221,219,218,216,216,215,214,214,214,214,214,212,211,210,207,206,205,206,207,209,211,213,212,209,208,209,209,211,212,213,214,
            213,213,213,214,215,216,217,220,223,225,227,229,232,234,236,239,241,244,248,252,0,2,3,5,7,10,11,13,15,17,18,19,19,20,21,22,23,25,26,26,23,21,23,23,22,22,20,20,20,20,20,21,21,24,25,15,3,6,11,10,9,9,7,5,3,2,1,254,254,0,253,253,255,254,255,255,255,255,0,0,254,255,255,0,0,0,0,1,1,1,2,2,2,2,2,2,2,2,2,1,
            0,0,0,0,254,254,252,252,251,250,249,248,247,246,244,243,242,241,240,238,237,236,235,234,234,233,233,233,233,234,233,233,233,232,233,232,234,236,237,239,237,237,238,239,241,242,243,245,244,246,245,245,243,243,241,241,241,240,238,236,235,233,232,231,230,230,228,227,228,226,225,224,221,220,220,220,220,220,220,221,220,220,221,222,222,223,225,226,228,229,229,231,233,235,236,237,238,239,240,242,
            243,246,249,253,0,3,6,8,11,15,17,20,25,28,32,35,36,36,36,39,41,44,45,48,50,52,54,56,57,60,61,63,65,66,66,66,65,64,62,62,62,61,61,59,59,57,56,55,53,50,50,50,49,48,48,50,50,49,48,48,47,47,47,47,48,49,50,50,49,49,50,52,52,51,50,49,47,45,44,43,42,42,42,42,40,39,36,33,32,31,29,28,26,24,23,21,20,17,14,13,
            11,9,7,5,3,0,253,252,249,246,244,243,242,240,238,237,236,235,234,232,230,229,227,225,223,223,222,221,220,219,218,216,214,214,213,212,211,209,209,209,209,208,208,209,209,210,208,204,203,205,208,211,211,209,208,207,209,210,212,213,214,214,213,213,214,215,217,220,221,224,226,229,232,235,237,240,243,246,249,253,0,2,4,6,7,9,12,14,16,18,19,20,20,21,23,24,26,27,27,25,
            24,24,25,25,23,21,20,21,21,21,22,22,25,24,15,5,9,13,12,11,9,7,5,3,1,0,0,254,250,254,253,251,253,253,253,253,253,253,254,254,252,253,253,254,254,254,255,255,255,0,0,0,0,0,0,0,0,0,0,0,255,254,253,253,252,251,250,249,248,247,246,245,244,243,243,241,240,239,238,236,235,234,233,232,232,231,231,231,231,232,232,232,232,232,231,231,234,236,237,237,
            236,236,238,241,241,243,245,245,246,247,246,245,243,242,241,241,241,240,238,236,235,233,232,231,231,231,229,230,229,227,226,225,223,222,222,221,220,219,219,220,220,221,221,222,223,224,224,225,228,229,231,232,232,233,235,237,238,239,240,242,244,245,248,251,255,2,4,7,10,14,17,20,23,27,29,30,31,33,35,38,40,42,44,47,49,52,54,55,57,60,61,63,65,66,66,66,65,64,63,62,
            61,61,60,59,58,57,55,54,52,50,49,48,48,47,46,46,48,49,48,48,48,49,50,50,50,49,50,54,54,50,50,51,48,46,46,47,46,45,45,44,43,43,43,44,42,40,38,32,31,30,29,26,24,23,21,19,16,13,13,12,11,9,7,6,4,1,0,254,251,249,247,246,243,242,240,238,235,233,231,230,228,227,226,224,222,221,220,219,218,216,215,215,214,213,211,210,209,206,206,206,
            206,207,207,208,206,201,196,196,197,199,203,204,206,208,208,206,207,209,208,208,209,211,212,212,214,215,217,218,220,223,225,229,232,235,239,242,244,247,251,254,0,2,4,6,8,10,12,15,17,19,21,22,22,23,24,25,28,28,26,25,25,26,26,26,24,23,23,23,23,24,25,24,17,7,4,10,13,12,11,9,7,5,3,1,0,0,255,253,250,252,252,248,251,251,251,251,251,251,252,252,
            251,251,251,252,252,252,253,253,253,253,254,254,254,254,255,254,254,254,253,253,253,252,251,251,250,249,248,247,246,245,244,243,242,241,240,239,238,237,236,234,234,232,231,231,230,229,229,229,229,230,231,231,231,230,230,230,234,235,236,237,237,239,241,242,243,244,245,247,248,249,250,246,245,244,242,243,241,240,237,235,234,233,232,232,230,230,230,228,226,225,224,221,221,219,221,222,221,220,220,220,
            221,221,222,222,223,225,227,227,229,230,231,231,232,233,234,235,236,238,239,240,242,244,247,250,253,255,3,7,10,14,17,20,21,24,26,28,30,32,34,37,39,41,44,47,49,51,53,55,57,60,61,63,65,65,65,65,64,64,63,62,61,59,58,59,59,57,56,56,54,53,50,48,46,45,45,45,44,46,47,47,47,48,50,53,53,51,53,55,46,41,40,41,42,42,44,45,46,46,46,45,
            44,44,44,42,39,37,34,32,33,32,30,29,27,25,25,21,17,15,14,13,12,12,12,8,4,1,255,252,250,248,246,245,243,241,239,237,235,234,231,229,227,227,225,223,222,220,219,218,217,216,215,213,213,212,211,209,209,208,208,212,216,214,205,201,198,193,191,190,192,196,199,201,201,201,202,205,205,206,208,208,208,209,210,210,211,214,215,217,220,222,225,228,232,235,240,243,246,249,252,255,
            0,2,5,7,9,11,14,16,19,20,22,23,24,24,26,28,28,27,26,27,28,28,28,28,28,26,25,27,28,24,13,1,254,2,10,12,11,10,8,6,5,3,2,0,255,254,253,252,249,248,253,249,247,250,248,249,249,250,250,250,249,249,249,250,250,251,251,251,251,251,252,252,253,253,252,252,252,251,251,251,250,250,249,249,248,247,246,245,244,243,242,241,240,239,238,237,236,235,235,233,
            232,231,230,230,229,228,228,228,228,228,229,229,229,229,228,229,233,235,236,237,238,239,240,240,242,245,246,247,246,248,251,247,245,247,243,243,244,241,237,234,232,232,231,231,230,229,228,228,227,224,221,221,220,220,222,223,221,221,221,221,220,220,220,221,223,226,228,228,229,229,230,231,232,234,234,234,234,236,238,239,243,244,245,246,249,250,254,3,9,12,16,18,18,21,24,26,28,30,33,35,
            38,40,44,46,48,51,53,54,57,59,61,63,64,64,64,64,63,63,63,61,60,58,58,59,56,55,55,53,50,47,48,51,51,49,48,49,46,46,48,48,48,49,50,52,51,50,52,53,49,46,46,44,42,41,42,43,44,46,47,47,46,45,45,42,41,39,38,37,36,33,32,29,24,21,20,16,16,14,12,15,21,23,20,15,7,2,255,251,248,247,246,245,244,243,241,239,237,234,231,230,
            228,226,225,223,221,220,218,218,219,217,216,214,213,212,213,213,211,210,210,209,207,207,205,205,205,202,196,191,194,199,199,199,200,200,198,197,200,202,202,204,205,207,208,211,212,213,215,218,219,222,225,229,232,235,239,243,247,251,253,255,0,3,5,8,10,13,16,18,21,22,23,25,25,26,28,28,27,26,27,28,29,31,31,31,30,30,31,29,19,3,254,2,8,12,12,12,10,8,6,4,
            4,2,0,0,254,253,252,252,250,247,249,247,246,249,247,247,248,248,248,249,247,247,248,248,248,248,249,249,250,250,250,250,250,250,250,250,250,249,249,249,248,248,247,246,246,245,244,244,243,241,240,239,238,237,236,236,235,234,233,232,231,230,229,228,227,227,227,227,226,227,227,227,227,228,227,228,233,234,236,237,237,238,239,240,241,242,243,244,246,247,248,247,244,245,244,242,241,239,238,236,
            233,231,230,231,230,228,228,227,226,225,223,222,222,222,223,223,223,222,221,221,220,221,221,222,223,225,225,227,229,229,230,230,231,232,233,234,235,236,236,236,237,238,239,243,248,249,250,255,4,10,13,17,18,19,20,23,25,28,31,34,37,40,43,45,47,50,52,54,56,59,61,62,63,62,62,62,62,62,61,60,58,58,58,56,56,53,51,52,55,53,54,56,56,57,57,56,54,52,50,52,
            53,52,51,50,49,49,48,46,45,47,48,48,49,47,43,42,43,43,43,45,46,46,46,45,43,42,41,39,36,31,29,28,25,22,20,20,19,19,17,13,15,20,24,24,19,13,7,0,249,248,247,246,245,243,240,239,237,234,232,230,229,228,225,224,223,220,217,215,214,215,217,216,216,215,214,215,215,217,216,215,214,212,209,207,207,210,209,205,197,193,194,193,192,195,200,200,199,201,201,202,
            204,206,208,209,211,214,215,217,219,223,226,229,232,236,239,244,248,251,254,0,1,3,6,9,12,15,17,20,22,23,24,25,26,27,28,27,26,27,28,28,32,33,31,31,28,28,22,11,3,6,12,15,15,14,12,11,9,7,5,3,2,0,255,254,253,252,251,250,249,246,249,248,245,248,247,246,246,247,247,247,246,245,246,246,246,247,247,248,248,248,248,248,248,249,249,248,248,248,247,247,
            247,246,246,245,244,244,243,242,241,241,239,238,237,236,235,234,233,232,231,230,229,228,228,227,227,226,225,225,225,226,226,226,226,227,227,227,231,234,234,235,237,238,239,240,241,242,243,243,243,244,243,243,243,244,245,243,239,238,238,236,235,233,232,232,229,228,228,225,225,224,223,224,223,223,223,223,222,221,221,221,222,223,224,223,224,226,225,227,227,228,229,229,230,231,232,231,231,231,231,231,
            233,235,237,240,244,247,249,251,0,4,6,9,12,15,18,21,23,26,29,32,35,38,41,44,46,48,51,53,56,58,60,62,61,61,61,60,60,60,58,58,57,55,54,53,53,51,51,50,51,52,52,56,57,58,57,54,54,54,52,51,51,51,52,50,47,47,45,46,46,49,47,48,48,50,48,46,46,43,39,37,41,46,45,45,45,43,41,40,38,33,30,28,27,30,31,28,25,21,19,19,
            17,16,20,22,21,16,14,12,0,247,245,244,245,245,243,241,237,234,231,230,229,227,225,224,222,221,218,215,214,213,218,223,222,221,222,222,221,219,219,220,219,213,203,201,200,199,196,196,196,194,195,195,195,193,194,196,199,201,200,201,201,203,204,207,209,212,214,218,222,225,228,231,235,238,241,244,248,251,254,0,2,4,6,10,13,17,20,22,25,26,25,23,23,24,25,26,27,28,29,31,
            34,33,24,20,16,11,9,10,14,17,17,16,15,13,11,9,7,5,4,2,0,255,254,253,252,251,250,249,248,245,248,247,244,247,246,246,245,245,246,246,245,245,245,245,245,246,246,246,246,247,247,247,247,247,248,247,247,246,246,246,245,245,245,244,243,242,242,241,240,239,239,237,236,235,234,233,232,231,230,229,228,227,226,226,225,225,224,224,224,224,225,225,225,226,225,225,228,232,232,235,
            236,236,237,238,239,240,240,240,241,244,244,243,242,245,247,242,238,237,236,234,234,232,231,230,229,227,225,224,224,223,224,224,224,223,222,223,221,221,223,223,222,224,223,223,223,223,224,226,225,225,226,226,228,229,227,226,228,229,229,230,232,233,236,238,241,244,247,250,253,0,3,7,9,12,16,18,21,24,27,30,33,37,40,42,45,47,50,53,56,58,60,61,61,61,60,59,58,57,57,56,
            54,52,52,51,51,50,49,48,48,48,50,54,56,56,56,55,54,54,53,52,51,50,49,47,46,45,45,45,46,48,47,46,46,47,48,48,48,45,40,35,37,43,43,43,42,42,41,39,38,37,36,36,37,37,36,35,32,30,28,26,25,26,28,26,24,19,11,7,6,250,239,239,245,245,244,243,240,235,232,230,228,227,225,224,222,221,219,217,214,213,216,212,208,213,219,221,222,223,221,213,
            203,198,196,194,192,191,193,195,196,195,195,194,194,195,196,198,199,201,200,201,201,202,204,207,209,211,215,216,219,224,230,234,237,240,243,246,248,251,254,0,3,4,7,11,15,18,20,24,26,27,23,23,24,25,26,27,29,30,31,32,35,36,29,18,12,11,16,19,19,19,18,16,14,12,10,8,6,4,3,1,0,255,253,252,251,250,249,248,247,244,250,249,243,246,246,245,245,245,245,246,
            245,244,245,245,244,245,245,246,245,246,246,247,246,246,246,246,246,245,245,244,244,244,243,243,242,242,241,240,238,238,236,235,235,233,232,231,230,228,227,227,225,225,224,223,222,222,221,221,221,220,220,220,221,221,222,223,226,229,230,234,234,234,235,236,238,238,238,238,240,242,240,241,242,245,245,240,237,236,234,233,232,231,230,230,227,225,224,224,224,224,223,224,224,223,222,222,223,223,222,222,
            223,224,222,223,223,223,223,222,222,222,223,223,223,223,223,224,224,226,227,229,230,232,234,236,239,242,245,248,251,255,1,4,7,10,13,16,19,23,25,29,32,35,38,41,44,46,49,52,55,57,59,59,60,59,59,58,57,56,54,53,52,51,51,51,49,49,47,46,46,46,49,54,56,56,55,54,53,52,53,53,51,49,48,49,48,45,45,44,46,48,46,44,44,44,45,46,45,39,38,34,
            34,37,39,40,40,40,40,40,39,39,39,38,38,37,36,35,35,34,33,33,32,31,28,27,23,21,21,11,3,249,239,235,238,241,241,242,239,236,233,230,228,226,224,223,221,220,220,219,217,216,217,216,219,220,223,221,216,213,212,209,203,198,196,195,193,192,191,191,191,191,191,191,191,193,195,198,202,202,201,200,202,204,205,208,210,212,213,216,220,225,229,232,237,241,243,246,248,249,253,0,
            2,5,9,12,15,17,20,23,24,23,23,24,25,26,29,30,31,31,32,34,38,41,37,24,15,17,21,21,20,18,17,15,12,10,8,7,5,3,2,0,255,254,253,252,250,249,248,247,246,244,246,242,243,245,246,245,245,244,244,244,244,245,244,244,244,244,244,245,245,246,245,246,246,245,245,245,245,244,244,244,243,243,242,242,241,240,240,239,238,236,235,235,234,233,232,231,229,227,226,225,
            224,223,222,222,222,221,221,220,220,219,220,220,221,220,221,221,223,228,227,230,234,233,234,235,236,236,237,237,239,240,238,240,241,244,245,242,237,235,233,232,231,230,230,228,226,224,224,224,224,224,225,225,224,224,223,222,223,223,222,223,225,224,223,224,222,221,220,220,218,218,219,219,219,220,221,222,224,225,227,229,230,231,233,235,237,241,243,247,250,253,0,2,5,8,11,14,17,21,24,27,
            30,34,36,39,43,45,49,51,54,56,57,58,59,58,57,56,55,54,52,51,51,50,49,49,49,49,51,51,48,49,53,54,55,55,54,54,54,54,53,52,50,49,49,47,45,44,44,44,45,46,44,43,43,42,43,43,44,37,30,29,26,28,33,38,39,40,40,39,39,39,39,38,37,36,36,35,35,34,32,31,30,29,27,26,25,21,19,14,11,255,243,239,239,238,236,238,238,236,232,230,
            228,225,224,222,221,220,219,222,224,224,222,224,228,230,228,221,211,207,207,208,207,205,201,199,197,196,195,193,194,199,203,203,201,203,205,207,209,211,212,210,207,206,207,207,210,212,216,218,221,225,228,232,238,240,243,244,246,249,252,255,2,6,10,12,15,18,21,24,24,23,25,25,26,28,30,31,31,32,34,37,39,42,38,25,16,20,22,21,20,18,15,14,11,10,7,6,4,2,0,0,
            254,254,252,251,250,249,248,247,246,244,242,243,243,244,245,245,245,244,244,244,245,244,244,244,244,245,244,244,244,245,245,245,245,245,245,245,245,244,244,243,243,242,242,241,240,240,239,238,237,236,235,234,234,232,231,230,228,227,225,224,223,223,222,221,221,220,220,219,219,218,218,218,218,218,219,220,220,224,225,226,231,234,232,232,233,234,235,236,239,237,237,239,241,243,242,240,236,234,233,231,
            231,230,228,227,226,225,225,225,225,226,227,227,226,224,223,223,223,223,224,225,225,224,224,222,220,220,218,216,215,216,217,217,217,218,219,221,223,225,226,228,229,230,232,234,236,239,242,245,249,252,255,1,4,6,9,13,16,20,23,27,30,33,37,39,42,45,48,51,53,54,55,56,56,55,56,55,53,52,51,50,49,48,49,47,48,48,50,49,45,44,48,53,55,56,55,52,51,52,52,50,
            49,47,46,45,44,43,42,42,42,43,43,43,43,44,42,43,37,32,29,25,23,25,29,36,39,39,38,38,37,36,36,37,37,36,35,36,34,33,30,28,26,24,24,26,25,21,18,16,14,4,248,244,245,243,241,239,242,243,238,231,228,226,224,222,220,219,219,219,214,212,217,226,228,230,229,227,223,212,205,205,204,204,204,203,202,202,203,209,212,212,209,204,199,197,199,205,211,212,213,214,
            215,214,212,209,211,213,215,217,221,224,227,232,237,240,242,242,246,249,253,0,3,7,10,12,16,18,21,23,26,26,26,27,28,29,31,30,33,36,36,38,42,41,36,24,17,21,23,21,19,16,14,12,10,8,6,5,3,1,0,255,254,253,253,252,250,248,248,247,247,245,247,249,243,244,245,246,246,246,245,244,245,245,245,244,244,244,244,244,244,244,244,244,244,244,244,244,244,243,243,243,
            242,242,241,241,240,239,239,237,237,236,235,234,233,232,231,230,228,227,225,225,223,222,221,221,220,220,219,218,218,218,217,217,217,217,218,218,219,221,224,225,225,231,232,230,231,231,232,235,234,234,236,236,237,239,241,240,237,235,232,230,229,228,228,228,227,227,227,228,227,226,227,228,227,226,225,225,226,225,225,225,223,225,224,221,218,219,216,214,214,214,214,214,215,216,218,220,222,223,224,226,
            228,230,231,233,235,237,240,244,246,250,253,0,2,5,8,12,16,19,23,26,29,33,35,38,42,45,47,50,51,51,51,52,52,52,53,53,50,48,48,48,46,46,46,47,47,51,52,48,47,50,50,52,52,51,48,47,49,51,50,47,46,45,45,45,44,43,43,44,43,43,43,42,42,40,40,40,31,29,28,27,23,21,25,31,36,38,37,34,30,30,31,29,34,35,33,28,28,28,26,25,
            23,21,19,18,19,19,17,16,13,9,5,255,248,247,247,247,247,245,244,241,234,228,226,224,222,219,218,215,213,211,216,226,229,229,229,228,229,229,225,217,211,207,208,209,213,217,219,218,217,215,213,213,208,203,200,201,204,205,209,212,213,215,216,216,214,216,218,220,223,226,227,232,236,240,242,242,246,250,253,0,4,7,10,13,16,18,21,23,26,29,27,27,29,32,32,33,35,38,39,42,
            44,39,28,17,18,23,22,21,19,16,14,12,10,8,6,4,2,1,1,0,255,253,253,251,250,249,248,248,247,246,244,245,245,244,245,246,247,246,246,245,246,246,246,245,245,244,244,244,244,243,243,243,243,243,244,244,244,243,243,242,242,242,241,241,240,240,239,237,237,236,235,234,233,232,231,230,228,227,226,225,224,223,222,221,220,220,218,218,218,217,217,217,217,217,217,217,218,219,220,224,
            223,226,229,227,229,229,230,233,234,234,237,237,236,237,239,239,237,233,230,229,228,228,229,228,227,227,228,228,229,228,228,228,228,227,227,227,226,225,225,223,225,225,221,220,218,218,214,213,212,213,213,214,214,216,217,219,220,222,223,226,226,228,229,231,233,236,239,241,245,248,251,254,0,4,7,11,14,18,21,25,28,31,35,38,41,44,46,47,47,48,48,49,49,49,48,48,47,45,44,43,
            42,42,43,45,48,49,46,46,47,45,43,45,45,42,42,43,45,48,47,47,47,48,48,46,45,44,44,44,43,42,40,39,40,38,37,38,32,28,29,26,20,14,11,18,28,30,29,25,17,12,16,14,21,27,25,23,26,26,24,22,21,19,16,13,10,11,14,15,12,11,7,7,5,0,255,252,248,246,244,242,240,235,230,228,228,229,228,228,225,221,230,231,230,234,230,228,231,234,235,234,
            231,226,226,226,225,223,222,221,219,218,218,217,216,215,214,212,213,212,213,215,213,214,215,215,216,218,221,221,223,226,229,231,236,240,241,242,246,250,255,1,4,8,10,13,16,19,22,24,27,29,28,28,30,33,34,35,36,40,44,42,38,32,19,17,22,23,22,21,19,16,14,12,10,8,6,5,4,2,2,0,255,253,252,251,250,249,248,247,247,247,245,246,246,244,246,246,246,246,247,246,
            247,247,247,247,246,245,245,244,244,244,244,244,244,244,244,244,244,244,243,243,242,242,241,241,241,240,240,238,237,236,235,235,234,233,232,230,229,228,226,226,225,224,223,222,221,219,219,218,217,217,217,216,216,216,216,216,217,217,218,221,223,225,225,226,226,227,228,230,233,233,233,235,236,236,237,236,233,232,230,230,229,230,230,230,227,224,226,227,229,229,229,228,228,229,227,227,227,227,224,225,
            225,223,220,219,218,215,212,210,210,211,211,212,213,215,217,218,219,221,223,224,225,226,228,229,232,234,237,239,242,246,249,253,0,3,6,9,13,16,20,24,27,30,34,37,39,41,42,43,44,44,45,45,46,44,44,43,42,42,41,40,41,42,44,47,48,46,46,48,47,44,43,41,40,40,41,44,49,50,50,50,49,48,47,44,42,38,36,38,38,38,37,36,36,36,34,34,33,29,28,27,
            23,17,12,10,16,20,17,9,5,5,7,7,17,27,25,19,24,25,24,21,18,16,14,10,6,4,8,11,11,10,7,2,0,254,251,248,246,246,244,242,240,237,235,235,235,237,236,235,232,228,223,221,221,227,229,234,236,235,234,233,232,232,230,228,226,225,224,222,221,220,219,219,218,217,217,217,217,218,218,219,219,219,220,220,219,218,220,221,222,223,226,230,235,236,238,242,246,250,254,2,
            5,8,11,14,17,20,23,25,28,29,30,31,33,35,36,37,37,38,41,40,34,22,13,20,24,24,22,21,19,16,15,12,10,8,7,5,4,3,1,0,0,254,252,250,249,248,248,247,247,247,245,249,252,245,247,247,247,247,248,247,247,247,247,247,247,245,245,245,245,245,244,245,245,245,245,245,245,244,244,243,243,242,242,242,241,241,240,239,238,237,236,235,234,233,232,231,230,228,228,226,
            226,225,224,222,221,220,219,218,217,217,216,216,216,216,216,215,216,216,217,218,220,220,223,226,225,225,226,227,228,231,233,235,235,235,236,234,233,233,231,229,228,228,230,231,228,227,229,230,230,230,229,229,229,228,227,227,226,227,226,225,224,221,219,218,215,210,209,208,208,209,209,211,212,213,215,218,219,220,222,223,224,225,227,228,229,232,234,238,241,244,248,251,254,1,4,8,12,15,19,22,
            26,30,32,35,36,38,39,40,41,42,42,42,42,42,40,38,38,38,38,39,40,42,43,44,46,45,43,42,41,40,37,38,43,45,46,48,52,52,51,50,48,46,45,42,39,35,31,29,30,32,33,34,33,33,33,32,31,29,28,26,25,23,19,13,9,9,5,1,1,5,7,10,13,14,15,18,27,25,22,19,17,15,11,6,2,0,4,6,6,7,7,4,1,254,252,250,248,246,246,244,
            240,237,238,239,239,238,236,236,234,230,223,215,209,211,219,227,238,238,236,233,232,231,231,230,228,227,226,224,223,222,222,221,219,219,219,219,218,219,220,221,222,222,222,222,221,220,220,222,223,225,229,232,234,236,239,242,246,251,255,1,5,8,11,15,18,21,23,26,28,29,31,32,32,35,37,37,40,37,37,40,39,24,11,23,26,26,24,21,19,17,15,13,11,9,7,5,4,4,3,2,
            0,255,252,251,249,249,248,248,248,247,245,246,248,245,248,249,248,248,248,249,248,247,247,246,247,247,246,246,246,246,246,246,246,246,246,246,246,246,245,244,244,243,243,242,242,242,242,241,240,239,237,236,235,233,233,231,230,229,229,228,227,225,224,223,222,221,220,219,218,218,217,216,216,216,216,215,216,216,216,217,219,220,220,224,223,222,224,225,226,228,230,232,234,233,235,235,233,235,232,230,
            228,227,227,229,231,229,229,230,230,230,229,228,227,227,227,227,229,228,226,224,223,221,218,217,213,209,207,206,206,207,208,210,211,213,214,218,219,219,221,222,222,223,225,226,228,230,233,235,239,241,245,249,252,0,2,7,10,13,17,21,25,28,30,32,33,34,36,37,38,38,39,40,39,38,38,37,35,35,35,36,39,43,45,48,45,43,41,42,42,41,40,45,49,50,53,55,53,52,50,48,
            45,42,40,39,36,32,30,30,29,31,33,33,33,33,32,30,29,28,27,27,28,28,29,23,15,9,7,7,7,9,11,14,15,16,16,20,23,22,21,19,16,12,9,4,0,253,252,255,254,1,5,4,1,0,253,252,249,247,245,245,243,239,237,237,238,236,234,235,232,230,222,212,209,208,210,215,228,241,240,236,233,231,231,230,229,228,227,226,225,224,223,222,222,221,220,219,218,220,221,223,
            223,224,224,225,224,220,220,222,224,226,229,231,233,236,239,242,246,251,255,1,4,8,12,15,18,21,23,26,28,31,32,33,31,34,35,34,34,35,37,41,42,33,15,24,27,27,25,22,20,17,16,14,12,10,9,7,6,5,5,3,1,255,253,251,250,249,248,248,248,248,247,247,250,248,248,248,248,248,248,248,248,247,248,248,247,247,248,247,247,247,247,247,247,247,247,247,247,248,247,246,
            246,245,244,243,243,243,243,242,242,240,239,237,236,235,233,232,231,230,229,228,227,226,225,224,223,221,221,219,219,217,217,217,216,216,215,215,215,215,216,216,216,218,218,221,224,221,222,224,226,227,229,230,232,232,233,234,231,233,232,231,230,229,228,229,230,229,230,230,230,230,230,229,228,228,229,229,229,228,226,224,222,221,218,216,213,211,207,206,206,206,207,208,210,212,214,219,218,218,220,221,
            221,221,222,223,226,228,231,233,236,240,243,247,250,254,1,4,8,12,16,20,23,24,27,28,30,31,33,34,34,35,36,36,38,34,34,33,33,33,34,36,38,39,40,41,40,40,42,43,43,44,45,48,49,51,56,55,51,48,47,44,41,39,38,36,33,30,28,28,29,30,32,33,32,33,32,30,29,27,27,27,30,30,28,25,21,18,14,12,11,12,16,19,17,17,16,20,21,20,18,18,
            14,11,7,3,0,252,248,245,244,245,254,0,255,255,254,252,251,247,245,244,243,239,237,235,234,232,233,232,231,228,220,212,213,213,211,209,212,228,238,237,235,233,232,231,230,229,228,227,227,225,224,223,222,221,221,220,219,220,222,223,224,225,225,227,223,216,217,220,224,229,232,234,234,236,240,243,248,251,0,2,6,9,13,16,18,21,24,26,29,30,32,31,26,31,32,33,35,37,38,42,
            40,33,17,24,28,28,26,24,21,19,16,15,13,12,10,9,7,7,6,4,1,0,254,253,251,250,249,249,248,248,249,247,249,251,248,248,248,248,248,248,249,249,249,249,249,249,249,249,249,249,249,249,249,249,249,249,249,249,248,248,247,246,245,244,244,244,244,243,242,242,241,239,237,236,234,233,232,231,230,229,229,227,226,225,224,222,221,220,219,218,217,216,216,215,215,215,215,215,215,215,
            215,216,217,218,223,223,221,222,224,227,229,230,231,232,233,234,234,233,232,233,233,233,230,230,229,228,228,229,229,229,229,228,227,228,228,228,228,228,227,226,224,221,218,214,213,213,209,206,205,205,206,207,209,211,212,213,215,217,218,218,218,219,221,222,224,226,229,231,234,238,241,245,249,252,0,3,7,11,14,18,19,22,23,25,27,28,29,30,31,33,32,33,32,32,31,31,31,32,33,34,
            35,37,38,38,39,41,44,44,44,43,46,51,54,55,53,50,48,46,43,41,39,36,34,32,29,27,27,27,27,28,29,31,32,31,30,29,29,29,28,27,27,26,25,23,21,21,21,19,16,15,15,15,16,17,17,17,19,18,16,15,12,8,6,2,0,252,247,243,241,241,246,253,255,253,253,252,251,249,243,243,242,238,235,234,231,231,231,232,231,227,221,208,210,212,212,210,209,216,227,236,
            237,235,234,232,231,230,229,229,228,227,225,224,223,223,223,221,221,222,222,224,225,226,228,226,217,214,215,218,221,227,232,235,235,238,241,244,249,253,0,3,6,10,13,17,19,22,24,27,29,30,31,29,26,30,33,35,37,37,39,42,42,35,20,23,29,28,26,24,22,19,17,16,14,13,11,10,9,7,6,4,3,0,255,254,253,252,250,249,249,249,249,249,248,248,249,249,250,249,248,248,
            250,248,249,249,250,250,251,251,251,252,252,251,251,252,251,251,251,251,250,249,248,247,247,245,245,245,244,244,243,242,241,240,240,238,237,236,234,233,232,230,229,228,227,225,224,223,222,220,219,217,217,215,215,215,214,214,213,214,214,214,215,215,216,217,219,222,221,222,224,225,227,229,232,232,233,233,234,234,233,233,234,233,231,229,228,228,228,229,229,229,230,229,229,229,229,228,228,228,227,227,
            225,221,218,215,210,211,210,208,205,205,205,207,208,210,211,212,214,215,215,216,216,217,219,221,222,224,227,229,232,236,239,243,247,251,254,1,5,9,12,14,16,18,20,22,23,25,26,28,29,30,30,33,33,30,31,30,30,31,32,33,34,35,36,37,39,40,41,44,42,40,43,48,49,48,48,46,44,42,39,37,34,31,30,29,28,27,27,26,26,27,28,30,30,31,31,30,29,29,28,27,
            26,24,23,23,22,21,20,18,17,16,16,15,16,17,18,18,19,17,15,13,11,8,5,2,0,253,249,245,242,239,239,243,250,250,249,251,251,251,244,240,242,238,234,233,232,229,229,228,223,224,218,207,209,210,211,210,209,207,209,215,223,230,233,235,232,231,230,230,228,227,226,225,225,224,224,221,224,225,223,224,226,227,226,217,213,216,216,219,224,229,233,235,235,238,242,246,249,254,0,5,
            8,11,14,17,20,23,25,28,30,30,30,26,26,32,34,36,37,38,39,42,42,35,24,22,30,30,28,26,23,21,20,17,15,14,12,11,9,8,6,4,3,2,0,255,254,253,252,251,251,250,250,250,250,250,251,250,250,250,250,251,252,254,252,251,252,251,253,253,254,254,254,255,255,255,254,254,253,253,252,251,250,250,248,248,247,246,245,245,244,244,242,241,240,240,238,237,235,234,232,231,
            230,228,227,226,225,223,222,221,219,218,216,215,214,214,213,213,213,213,213,213,214,215,215,215,216,219,221,221,222,224,226,228,232,233,233,233,234,235,235,233,233,231,230,229,229,230,230,231,231,232,232,232,228,228,230,229,229,228,228,228,225,222,220,215,210,211,210,209,207,205,205,206,207,209,210,211,212,212,213,213,214,216,217,218,220,223,225,228,231,234,238,241,245,249,253,0,3,7,9,11,
            13,15,17,18,20,22,23,25,26,28,28,28,28,28,29,29,29,29,31,31,33,34,38,38,37,36,43,43,41,41,43,43,40,40,40,41,40,38,36,33,30,28,28,29,29,28,27,27,27,28,29,29,30,31,31,31,30,29,28,26,25,24,23,23,21,19,17,16,15,16,15,15,17,16,20,17,18,15,14,12,11,8,6,3,0,254,250,246,242,238,236,235,243,244,244,246,249,252,246,240,
            239,240,235,232,231,228,228,225,216,219,212,206,208,209,210,210,208,206,203,200,202,207,218,232,234,232,231,229,227,226,227,227,227,227,224,218,224,226,225,224,226,227,226,221,216,215,218,222,226,228,233,236,237,240,244,247,251,254,1,6,9,12,15,18,21,24,27,29,30,30,26,24,29,33,35,36,38,39,40,42,43,37,35,24,31,31,30,27,25,23,21,19,17,15,13,11,9,9,7,5,
            4,3,2,0,0,254,254,253,252,251,251,250,250,250,250,251,251,251,251,251,252,253,0,255,0,254,252,254,255,0,0,0,1,0,0,0,0,255,255,254,253,252,251,249,249,248,247,246,245,244,243,243,241,241,240,238,237,235,233,232,230,229,227,226,225,223,222,221,219,218,217,215,214,214,213,213,212,212,212,212,213,213,214,214,214,219,220,221,221,223,225,228,232,234,233,234,235,236,236,234,
            232,231,229,229,230,229,230,231,231,232,231,230,227,226,227,231,232,231,229,228,225,223,220,218,212,212,211,209,207,205,205,206,207,209,210,210,210,210,210,211,212,213,215,216,219,221,223,226,229,232,236,239,243,247,251,255,2,4,6,8,10,11,13,15,17,19,20,21,23,25,26,26,26,26,27,27,27,27,28,30,31,34,39,42,43,41,41,39,40,39,39,39,38,37,36,36,36,34,32,29,
            28,28,27,28,29,28,28,28,29,28,29,30,30,30,30,30,29,29,28,26,25,23,22,21,20,16,15,15,14,14,14,13,16,15,15,14,16,15,13,12,12,10,8,4,0,254,250,246,242,238,233,231,233,237,238,237,237,238,239,238,238,236,233,227,226,226,226,221,215,214,211,209,210,210,210,210,208,204,201,197,194,193,197,204,206,213,225,231,231,230,229,228,230,222,210,205,209,220,218,222,
            224,226,225,224,220,220,223,226,228,231,235,237,239,241,244,248,252,0,3,8,11,13,16,20,23,26,29,31,31,29,25,26,33,34,35,37,38,40,41,42,44,39,48,33,30,33,33,30,27,24,22,21,19,17,15,13,11,9,8,6,5,4,3,1,0,0,255,254,253,252,251,251,250,250,250,251,251,252,252,252,253,252,254,255,0,254,254,254,255,0,1,2,2,3,3,2,2,1,1,0,
            0,255,254,252,251,249,248,248,246,245,244,243,242,241,240,239,237,236,234,233,231,229,228,226,225,224,222,221,219,218,216,215,214,213,212,212,212,211,211,211,212,212,213,214,214,215,219,221,222,221,224,226,230,234,234,235,236,237,237,236,235,232,231,232,229,226,227,228,228,228,230,231,231,230,228,230,232,231,231,230,228,226,224,219,213,213,212,209,207,205,205,205,207,208,208,208,208,208,208,209,
            209,211,213,214,216,219,222,224,228,231,234,238,242,245,249,253,0,1,3,6,7,9,10,12,14,15,16,18,20,21,23,23,25,26,26,26,25,26,28,29,32,34,35,33,32,32,36,39,39,39,40,39,38,35,34,34,34,32,30,27,26,26,25,26,28,29,30,29,29,29,29,29,29,29,28,28,28,30,29,26,24,22,21,20,18,15,14,14,13,13,13,13,13,15,13,12,12,15,14,13,
            11,11,8,5,0,255,251,246,241,237,232,230,228,229,231,230,227,229,237,234,234,232,230,227,226,227,224,217,213,213,211,211,212,211,209,209,206,203,200,196,193,191,189,189,190,192,198,206,209,212,214,212,213,207,200,201,205,211,212,219,222,224,224,224,225,226,226,227,229,233,235,238,240,243,247,250,254,1,5,9,12,15,18,21,24,28,30,31,32,26,24,29,33,35,35,37,38,40,41,43,
            44,41,45,35,32,35,34,31,29,28,25,23,22,18,16,15,13,11,9,8,7,5,4,3,1,0,0,255,254,253,252,251,251,251,251,251,252,252,253,253,253,254,254,255,255,0,6,2,4,6,4,4,5,3,4,4,3,3,3,2,2,1,0,255,254,252,251,249,247,246,245,244,242,241,241,239,238,237,235,234,232,230,229,227,225,224,222,221,219,218,216,215,214,213,212,211,211,211,211,211,
            211,212,212,213,214,215,216,220,221,222,223,226,229,234,236,236,236,237,239,238,237,233,232,234,230,225,226,228,228,229,229,231,231,232,231,229,232,232,232,231,229,226,224,218,213,214,212,210,207,205,204,205,206,207,207,207,207,206,206,207,208,210,211,213,215,218,220,223,226,229,233,236,240,244,247,251,253,255,1,2,4,6,8,9,11,12,13,15,17,18,21,22,23,24,24,24,25,26,27,29,
            32,35,34,31,31,34,36,39,39,40,40,37,35,32,32,32,32,30,28,27,26,25,25,27,31,32,34,34,33,32,30,30,29,27,26,26,27,29,28,26,23,21,20,20,17,15,14,13,13,13,13,13,12,13,14,11,11,11,12,11,12,9,8,4,0,255,253,247,242,236,231,229,227,226,225,223,226,231,235,233,231,230,227,226,225,223,217,214,213,211,211,212,211,209,209,208,203,201,200,197,
            196,194,193,191,190,191,193,195,199,203,206,209,210,207,205,208,207,210,214,218,220,222,223,225,226,226,227,229,230,233,236,238,241,244,249,251,255,3,7,11,14,17,20,23,26,28,29,29,28,25,28,33,36,37,36,37,39,40,42,43,45,42,39,36,39,38,36,34,32,28,27,26,24,22,19,17,14,13,12,10,9,7,6,5,3,2,1,0,0,254,254,253,252,252,252,252,252,252,253,253,
            254,255,0,0,0,0,1,1,5,6,8,7,14,8,3,4,4,4,4,4,3,3,2,1,0,255,253,251,249,247,246,244,243,242,241,240,238,237,236,234,233,231,229,227,226,224,223,221,219,218,217,215,214,213,212,211,211,210,210,210,211,211,212,212,214,215,216,218,221,222,222,225,227,231,236,237,238,238,240,243,240,237,235,235,229,225,225,227,229,230,230,229,229,231,233,230,233,234,233,233,
            229,229,226,224,219,214,213,210,208,206,205,205,205,206,207,206,206,205,205,206,208,209,210,212,214,216,219,222,224,228,231,234,238,242,246,249,251,253,255,0,1,3,5,6,8,9,11,13,15,17,18,20,21,21,22,22,23,25,26,28,30,33,33,31,34,34,38,40,38,38,38,35,33,31,30,30,29,28,27,27,28,28,28,30,33,35,36,36,36,35,31,29,29,27,25,25,28,28,27,25,
            23,21,19,17,15,15,15,14,14,13,13,13,13,11,13,11,9,10,11,10,10,9,7,4,1,0,252,248,241,235,230,227,226,225,224,223,229,229,228,226,226,225,224,222,218,215,213,213,212,210,210,210,209,209,208,205,203,202,200,198,197,197,196,195,194,195,196,198,201,202,204,205,207,207,208,204,209,211,215,217,219,221,223,225,226,226,226,228,230,233,235,239,243,246,250,253,0,5,8,12,
            15,19,20,22,25,27,27,25,24,29,33,36,35,36,37,37,39,41,43,44,45,46,42,34,37,38,35,34,34,31,28,27,27,24,22,19,17,15,14,13,10,9,8,7,5,3,2,1,0,0,255,255,254,254,254,253,253,253,253,253,255,0,0,0,1,1,2,3,4,4,5,6,7,12,13,10,8,5,5,4,4,4,4,3,3,1,255,252,250,248,247,245,244,243,241,240,238,237,236,235,
            233,231,229,228,226,225,222,221,220,218,217,216,215,214,212,212,211,210,210,210,210,210,211,212,213,214,216,217,220,222,224,224,226,230,234,238,239,241,242,243,244,240,237,234,229,226,226,229,229,228,229,233,236,234,233,231,232,232,231,231,231,231,230,226,223,218,214,212,210,207,206,205,205,205,205,205,205,204,205,206,207,208,209,211,213,216,219,221,223,227,229,232,236,240,243,246,248,250,252,254,
            255,0,2,3,5,7,10,11,14,16,17,18,19,20,20,22,23,24,25,27,28,30,31,33,35,38,40,39,38,37,36,33,32,30,30,28,27,27,26,28,29,31,31,33,34,36,37,36,34,34,33,29,27,26,24,25,28,28,27,27,23,20,18,14,14,16,18,16,14,14,13,12,12,11,12,11,8,7,8,9,9,7,6,4,2,0,252,246,239,233,229,227,225,224,224,223,227,230,227,221,
            219,218,215,212,210,209,212,211,210,208,209,208,206,205,205,203,202,202,201,199,198,197,197,197,199,200,200,202,201,202,202,203,204,206,203,202,210,211,215,216,219,221,222,224,226,227,227,229,231,234,237,241,244,247,251,255,2,6,10,15,17,24,16,17,21,24,26,26,29,33,35,36,38,37,37,38,39,42,44,46,46,48,45,34,29,36,37,36,34,32,32,30,28,26,24,22,20,19,17,16,
            14,12,11,9,7,5,4,3,3,1,1,0,0,0,0,0,255,255,254,255,0,0,0,1,2,2,4,4,5,7,7,7,6,6,7,9,10,10,12,7,4,4,3,3,3,2,0,254,251,249,247,246,245,243,241,240,239,237,236,234,233,231,230,228,226,224,223,222,220,219,217,216,215,214,212,211,211,210,210,210,210,210,211,212,212,214,215,217,219,220,224,224,226,229,232,237,240,242,243,244,
            244,242,240,236,231,228,230,231,230,232,237,241,241,239,238,234,234,235,235,234,233,232,233,230,227,220,218,213,210,208,207,206,206,205,205,205,204,204,205,206,207,208,209,211,213,215,218,220,223,226,228,231,235,238,241,244,246,248,249,251,253,255,0,2,4,7,9,11,14,15,16,17,17,18,20,21,22,23,24,25,26,27,28,33,36,38,38,39,38,35,34,32,31,29,28,26,26,26,27,27,
            30,31,32,33,34,35,37,33,32,32,30,29,26,25,24,26,28,28,27,27,22,19,16,13,14,17,17,16,14,13,11,11,11,10,11,10,8,6,6,6,8,7,5,3,1,254,249,244,237,231,227,225,224,224,223,224,224,227,230,228,219,217,213,211,212,209,210,209,207,206,205,204,202,201,200,201,199,199,198,198,199,199,197,197,200,201,200,200,200,201,201,203,204,207,203,204,210,213,214,216,
            219,220,222,224,226,227,229,230,232,235,239,242,245,249,253,0,4,8,12,17,19,25,19,17,18,22,26,29,32,34,35,36,37,38,39,40,40,42,44,47,47,48,48,40,32,27,31,35,36,36,33,31,29,31,26,25,22,21,19,16,14,14,12,11,9,8,7,5,4,3,2,2,1,1,1,0,1,0,0,0,2,1,1,2,2,3,4,6,6,7,8,9,9,9,9,9,8,8,7,8,
            14,11,8,1,0,0,0,254,252,250,248,247,246,244,242,241,239,237,236,234,233,232,230,228,226,225,223,222,220,219,217,216,215,213,213,212,210,210,210,209,210,210,210,211,212,214,215,217,219,221,223,225,226,228,232,234,240,242,244,245,245,243,241,238,233,232,233,233,239,241,242,242,243,244,239,236,239,240,239,236,236,235,233,229,224,221,219,215,214,208,206,206,207,206,206,205,205,205,206,206,
            207,208,210,212,213,215,217,219,221,223,227,230,233,236,240,243,244,246,247,249,251,253,255,0,3,6,8,11,14,15,17,17,17,18,19,21,22,23,24,24,24,25,27,33,35,36,37,37,37,35,33,32,30,29,27,26,26,26,28,29,30,31,32,33,32,32,32,30,30,30,28,27,25,24,23,24,27,28,28,27,20,17,14,13,14,17,16,14,13,12,11,10,10,10,9,10,9,6,5,4,
            6,6,5,2,255,252,247,240,234,230,226,224,223,223,223,223,222,224,225,227,221,216,215,212,213,208,205,204,204,204,204,202,199,198,198,198,197,197,198,197,197,196,195,195,196,199,199,198,198,200,201,202,203,207,206,206,210,214,215,216,219,220,222,224,226,227,229,231,233,237,240,243,247,251,254,1,5,10,14,17,19,22,23,24,26,28,31,32,34,35,35,36,37,39,39,40,41,44,45,47,
            48,48,49,47,43,32,26,32,37,37,36,34,32,30,27,26,23,22,19,19,18,16,15,13,11,11,8,7,7,6,5,4,3,3,2,4,2,2,3,2,3,2,2,2,3,4,5,6,7,8,9,9,9,10,9,9,9,9,8,7,7,6,12,16,9,0,255,254,252,251,249,248,246,245,243,241,240,238,237,235,234,232,230,228,227,225,223,222,221,219,218,216,215,214,212,211,211,210,209,209,
            210,210,211,211,212,214,215,217,219,221,223,225,226,227,229,235,240,242,245,247,248,248,244,242,238,235,236,237,243,243,243,244,245,246,239,238,237,237,235,234,233,235,235,232,229,224,216,213,212,212,209,206,206,206,205,205,206,206,206,207,208,210,212,213,213,215,216,218,221,223,226,228,231,235,238,241,242,243,245,247,249,251,253,255,1,4,7,11,14,16,18,18,18,19,20,21,22,23,24,24,
            24,25,28,32,34,35,35,35,35,33,32,31,29,28,28,27,27,27,29,30,31,31,32,32,31,30,28,27,26,28,27,26,24,22,22,22,25,29,29,24,17,14,14,13,14,15,15,13,12,11,10,9,10,9,9,9,8,6,4,3,5,6,4,0,254,249,245,238,233,229,226,224,222,222,221,221,221,223,225,223,217,214,211,209,207,202,201,200,199,200,201,199,197,195,195,194,194,194,193,192,
            193,192,193,194,195,197,197,195,198,200,201,201,203,206,206,206,211,215,216,217,219,221,223,225,227,229,230,232,235,237,242,246,249,253,0,4,8,12,15,18,19,21,27,25,29,31,32,34,35,36,36,37,38,39,40,41,42,44,46,48,48,49,48,49,47,44,35,30,37,37,37,34,33,32,29,29,26,25,23,20,19,18,16,16,14,12,12,11,9,8,7,6,5,4,5,5,5,6,4,4,
            4,4,4,4,4,5,6,8,8,9,9,10,9,9,9,9,9,9,8,8,8,7,5,5,19,13,255,254,253,252,250,248,247,246,244,242,240,239,237,235,234,233,231,229,228,226,223,222,221,219,218,216,215,214,213,212,211,210,210,209,209,210,211,211,213,214,215,217,219,221,223,225,227,228,228,237,242,243,246,248,250,252,251,247,242,238,240,241,244,245,246,247,248,244,241,242,240,239,239,238,
            237,236,235,234,232,231,222,221,216,213,209,205,199,195,192,194,198,200,204,207,210,211,212,211,212,215,216,217,219,221,224,227,230,233,236,239,240,242,243,245,247,249,251,254,0,2,6,9,13,15,17,18,19,20,21,23,24,25,25,25,25,26,28,31,32,34,34,34,33,32,31,30,29,28,27,27,27,26,29,30,31,32,31,31,29,28,26,25,25,27,27,25,23,21,21,21,22,23,23,20,
            15,12,13,13,13,13,13,13,12,10,9,8,9,8,7,6,7,5,3,2,1,3,4,0,253,247,242,237,233,229,226,224,223,221,220,220,221,223,222,219,215,209,207,207,205,202,201,199,196,194,197,193,192,191,191,190,190,189,189,189,189,191,191,192,195,195,194,193,195,196,197,201,203,205,204,205,211,215,216,218,219,222,224,226,228,230,231,233,235,240,244,248,251,255,2,5,9,13,17,20,
            22,23,30,27,31,33,34,35,36,37,38,39,39,40,41,41,44,46,47,48,49,49,49,50,49,46,41,31,36,40,41,38,35,33,31,28,28,27,24,21,22,21,19,18,15,15,13,13,11,10,9,7,7,6,6,5,6,6,5,5,6,5,5,6,6,7,8,9,8,9,9,9,9,9,8,8,8,8,7,7,7,7,7,5,12,9,255,255,254,252,250,249,248,247,245,243,242,240,238,236,
            234,233,232,230,228,226,224,222,221,219,218,217,215,214,213,212,211,211,210,210,210,210,211,212,213,214,216,218,220,222,223,225,227,229,229,232,239,244,244,245,250,252,254,251,244,244,243,246,246,247,248,249,250,245,244,243,244,243,241,240,238,235,233,230,229,229,230,231,231,229,222,212,201,195,196,202,201,197,195,200,205,209,209,210,212,214,216,217,218,220,223,225,228,232,235,236,238,240,241,243,
            245,247,249,252,255,1,4,8,11,14,16,17,18,20,22,23,24,26,27,26,27,26,26,29,33,34,35,32,31,31,29,29,28,28,27,26,27,27,28,29,30,30,30,28,26,25,24,24,25,28,26,24,22,20,20,19,18,18,17,15,13,12,12,12,12,12,12,12,10,9,7,7,7,6,6,5,5,5,1,1,0,0,2,1,253,247,242,238,233,229,227,225,224,222,221,220,221,222,220,215,
            214,207,207,205,203,201,199,197,194,190,192,190,189,187,187,187,186,185,186,185,185,188,189,192,191,190,188,189,191,192,194,197,197,200,203,207,211,214,216,218,220,222,224,227,229,231,233,235,238,243,247,250,254,0,4,6,10,15,20,22,24,30,33,30,33,35,37,38,39,39,40,40,41,41,42,43,45,46,47,49,49,49,49,51,50,48,42,34,33,39,38,38,37,35,33,31,28,26,26,22,
            22,20,21,19,17,19,15,16,14,12,11,11,10,8,8,7,7,6,6,5,6,6,6,7,8,9,9,8,9,9,8,9,9,9,8,8,8,7,6,7,7,7,7,6,3,1,0,255,254,252,251,250,249,247,246,244,242,241,239,237,235,233,232,230,229,227,225,223,221,220,218,217,216,215,214,213,212,211,211,210,210,211,211,212,213,214,216,218,220,222,224,226,227,229,232,234,234,240,243,249,
            249,250,253,254,246,245,244,246,247,249,250,251,249,246,246,245,245,245,246,244,242,241,241,241,237,233,231,228,228,224,218,215,213,214,215,212,211,214,208,196,199,206,209,210,212,213,215,217,218,220,222,224,227,230,233,234,236,238,239,241,244,245,248,251,254,0,3,7,10,12,15,16,18,20,22,24,24,26,26,27,26,26,26,29,33,33,35,31,30,29,28,27,27,27,27,27,27,27,29,29,
            28,28,27,25,24,24,23,23,24,27,25,23,21,20,19,17,16,15,14,12,12,12,11,12,12,11,11,11,9,8,7,6,6,6,4,4,3,3,2,0,0,255,255,0,254,247,243,239,235,231,229,229,227,225,224,224,223,221,217,213,209,203,205,203,201,199,195,192,191,188,185,187,186,183,183,183,182,183,182,182,182,183,186,188,185,181,183,186,188,190,194,197,198,198,201,206,209,213,216,218,
            220,223,224,225,227,233,235,237,240,244,248,251,253,1,5,9,13,18,22,25,31,41,37,32,36,38,39,40,41,41,41,42,42,43,44,45,46,47,49,49,50,50,50,51,51,50,44,36,31,39,41,42,37,36,33,32,30,28,26,25,24,23,21,21,20,18,18,17,16,14,12,14,13,11,10,9,8,7,7,7,7,6,6,7,7,7,8,8,9,9,9,10,10,9,9,8,8,7,7,6,
            6,5,5,5,4,2,0,255,254,253,252,251,249,248,246,245,243,242,240,238,237,235,233,231,230,228,226,224,223,221,219,218,217,216,214,213,212,212,211,211,211,211,212,213,213,215,217,218,220,222,224,226,228,230,233,235,237,237,238,243,248,252,255,0,253,248,250,253,254,252,252,254,252,249,249,249,251,251,250,249,247,245,243,240,238,236,231,227,226,224,219,216,214,212,212,212,214,215,216,202,
            195,204,208,208,209,210,213,215,216,218,221,223,226,229,232,233,235,236,238,240,242,244,247,250,253,0,2,5,8,10,13,15,17,18,22,29,25,27,25,26,26,26,27,30,32,32,33,31,28,27,27,27,27,28,28,28,28,29,28,28,27,27,26,24,22,23,22,22,23,24,24,22,20,19,17,16,14,13,11,10,12,12,13,13,12,11,10,10,8,7,6,5,5,4,3,2,2,0,2,0,
            255,254,252,253,254,249,244,241,238,236,235,234,232,231,228,225,221,218,215,213,207,202,202,201,198,195,193,188,186,185,182,180,181,180,179,180,179,178,179,178,177,180,182,179,177,177,180,184,187,190,194,197,197,197,199,206,209,213,216,219,220,224,226,227,230,232,237,240,242,246,249,252,1,4,8,12,17,21,25,28,35,43,42,34,40,43,43,42,43,43,44,44,44,44,45,47,48,49,50,50,
            51,51,51,52,53,52,49,38,32,40,44,41,40,36,37,33,31,30,28,27,25,24,23,24,21,20,19,18,17,16,14,13,12,11,10,9,9,9,8,7,7,7,7,7,8,8,7,8,8,9,10,10,10,10,10,9,8,8,7,6,5,4,4,4,3,1,0,255,254,253,252,251,250,248,247,246,244,242,241,240,238,236,235,233,231,229,228,225,223,222,220,218,217,216,215,214,213,212,212,211,
            211,212,212,213,214,215,217,219,221,222,224,227,228,230,233,235,238,239,242,243,243,245,249,255,253,249,251,254,0,253,254,0,255,255,0,0,255,254,254,252,250,247,244,243,240,237,234,231,230,227,225,223,220,218,216,215,215,216,215,213,196,202,208,210,211,210,211,213,215,217,219,222,225,228,231,232,233,235,237,239,241,243,246,249,252,254,0,2,5,8,10,13,15,18,19,22,23,31,25,24,
            25,26,27,30,30,32,33,32,28,28,28,29,28,28,28,28,28,29,28,27,25,23,24,23,21,21,21,21,22,22,22,20,18,17,16,14,13,12,11,11,11,11,11,12,11,10,9,9,7,6,5,5,3,3,2,1,0,0,1,0,253,253,251,250,252,248,245,242,240,238,236,234,231,228,226,224,221,218,213,211,205,201,201,198,195,192,190,186,183,182,179,177,177,177,177,177,176,174,173,173,
            174,176,175,173,173,176,179,182,185,189,193,196,198,196,201,207,210,213,217,220,222,225,226,227,230,234,238,243,246,248,251,0,4,7,13,16,19,24,29,31,41,45,40,33,41,45,44,45,45,45,46,46,46,46,47,48,49,50,51,51,51,51,51,53,54,54,52,39,37,43,43,40,41,36,35,36,32,30,29,28,27,25,24,25,23,22,20,20,18,17,16,15,13,13,12,11,11,10,9,8,
            9,9,9,8,8,8,8,8,8,9,9,10,10,11,10,9,9,8,7,6,5,4,4,3,2,1,0,255,254,253,253,252,251,250,248,247,245,244,243,242,240,239,237,235,233,231,229,226,225,222,221,219,218,217,216,215,213,213,212,212,212,212,213,213,215,216,217,219,221,223,225,226,228,231,233,236,238,240,242,244,246,247,247,247,247,247,251,252,1,4,3,2,3,5,4,3,1,0,0,254,
            251,249,247,243,242,239,236,236,233,229,227,225,223,220,219,217,218,217,215,216,201,201,208,207,208,208,210,213,214,216,219,221,224,227,229,231,233,234,236,238,240,242,244,247,249,252,254,0,3,6,8,10,13,15,18,21,25,25,21,22,23,25,28,30,29,31,32,33,30,30,30,30,29,28,28,28,29,28,26,24,23,22,23,22,20,21,20,20,20,21,20,20,17,16,14,14,13,12,10,10,
            10,9,11,10,10,9,8,7,6,5,4,3,2,1,0,0,0,0,0,1,253,251,250,249,252,249,245,243,242,239,236,233,230,228,225,223,221,217,213,207,203,200,199,196,193,190,187,184,181,178,176,175,173,174,172,174,174,173,170,169,170,169,168,170,172,174,178,181,184,189,193,196,197,195,201,207,210,214,217,221,223,226,228,230,233,236,241,246,250,252,255,1,6,10,14,18,23,27,32,31,
            40,44,42,42,44,42,46,47,47,47,48,48,48,49,50,50,51,51,52,52,52,52,52,53,55,55,51,37,39,44,44,41,41,38,36,35,31,30,29,29,29,28,25,26,23,23,23,19,17,19,16,15,14,13,13,12,12,11,10,9,9,10,10,9,9,9,9,9,9,9,10,11,11,11,11,10,10,8,8,7,6,5,4,3,2,1,0,255,255,254,253,253,252,251,250,249,247,246,245,243,
            242,240,238,236,234,232,230,228,225,224,222,220,219,218,216,215,215,213,213,213,213,213,213,214,215,216,218,220,221,223,225,227,229,231,234,236,239,241,242,245,246,248,249,250,251,250,248,250,252,255,0,2,4,5,6,5,4,3,1,0,254,251,249,245,242,239,236,234,232,228,224,222,221,221,220,220,220,219,216,218,210,204,208,208,209,210,211,213,214,215,217,219,222,225,228,231,233,234,236,237,
            239,241,243,246,248,250,252,255,1,3,6,8,11,13,15,17,17,18,19,20,22,23,26,29,30,31,31,32,30,31,31,31,30,29,29,28,29,27,25,25,24,23,23,22,21,20,19,19,20,21,20,19,17,15,13,13,12,11,10,10,9,8,9,9,9,8,6,4,4,3,3,1,0,0,0,255,254,255,255,0,254,251,249,247,249,248,246,241,238,235,233,231,228,226,223,221,219,216,212,207,
            203,199,197,194,191,188,185,182,179,176,174,173,171,170,170,169,172,172,171,168,167,166,167,169,170,171,177,180,184,188,192,195,195,197,199,207,211,214,218,222,224,227,231,233,236,240,243,248,253,255,0,3,8,13,17,21,25,30,33,34,37,45,48,50,53,45,46,49,50,50,50,50,50,50,51,52,52,53,53,53,53,52,53,55,54,55,43,33,42,44,43,42,40,38,37,34,36,33,31,30,
            29,30,27,26,27,24,24,24,22,19,17,17,15,14,14,14,14,12,11,10,10,10,11,11,10,10,10,10,10,10,10,10,12,12,12,11,11,10,9,8,7,6,5,4,3,2,1,0,0,255,254,254,253,252,251,250,249,247,246,244,243,241,239,237,235,233,231,229,227,225,223,222,221,219,218,217,215,215,214,214,214,214,214,215,216,217,218,220,221,224,225,227,229,232,234,237,239,241,243,245,
            246,248,249,250,251,252,253,252,251,252,253,0,0,4,7,6,5,4,2,1,0,253,251,249,245,240,235,233,231,231,227,223,219,217,216,220,222,219,216,218,209,209,211,209,211,212,212,213,213,214,216,218,220,223,226,230,232,233,235,237,239,241,242,244,246,249,251,254,0,2,4,7,9,11,13,15,16,17,17,19,20,21,23,29,30,31,31,31,31,31,32,32,31,29,29,28,28,27,26,26,
            25,24,23,22,21,20,20,20,21,21,20,19,18,15,14,13,12,11,10,8,7,6,7,7,8,6,3,3,2,2,1,0,0,255,254,254,253,254,0,254,253,249,248,246,245,243,240,237,235,233,231,229,226,223,221,217,215,213,210,205,200,198,195,192,189,186,183,180,177,174,171,170,169,167,168,167,170,171,171,168,165,164,164,167,170,171,175,179,184,189,192,195,195,196,200,207,210,216,220,223,
            225,228,232,235,237,241,246,251,255,1,3,6,11,15,19,24,28,32,36,38,38,48,50,52,53,46,45,52,53,53,52,52,51,52,52,54,54,55,55,54,53,52,53,54,53,46,32,39,47,46,44,42,40,40,38,36,34,34,32,31,30,30,29,28,26,25,24,22,21,21,20,18,17,16,15,15,14,13,12,12,11,10,11,11,11,11,11,11,11,11,11,12,12,13,13,12,11,10,10,9,
            8,8,6,5,5,4,3,2,1,0,0,255,254,253,252,251,250,249,247,245,244,242,240,238,236,234,232,230,229,226,225,223,222,220,219,218,217,216,215,215,215,215,215,216,217,218,219,220,222,224,226,228,230,232,234,237,239,241,243,245,246,248,249,249,251,253,253,255,0,0,255,254,254,0,5,8,7,5,4,3,0,0,253,251,247,241,237,238,241,236,234,233,231,227,223,221,225,220,219,215,
            208,208,212,209,210,211,212,212,213,214,215,216,219,222,225,228,231,233,235,237,238,241,242,244,246,248,251,253,255,1,3,6,8,10,12,14,15,16,17,18,19,20,22,27,28,29,30,31,33,33,33,32,31,29,29,29,28,27,26,26,25,24,22,22,20,20,21,21,22,21,21,19,18,16,14,13,12,10,8,8,6,5,5,4,4,3,1,0,0,0,0,0,254,253,253,252,252,254,254,254,
            251,249,247,245,242,240,238,236,234,231,229,227,223,220,217,214,212,211,207,203,200,197,194,190,187,184,181,178,175,172,169,168,167,165,164,166,167,169,165,165,164,160,163,165,168,171,174,178,183,189,193,194,195,196,203,208,211,217,221,225,228,230,233,238,240,244,247,253,2,4,6,9,13,18,22,27,31,36,38,43,49,51,53,56,58,61,48,52,55,56,55,54,54,54,54,55,56,56,56,54,
            53,48,46,48,39,36,42,47,47,47,44,43,42,40,39,38,36,35,33,33,31,30,31,29,28,29,26,26,24,23,22,20,19,18,17,16,15,14,13,12,12,11,11,11,11,11,11,11,11,11,12,12,12,13,14,13,13,12,11,10,9,9,8,7,6,5,4,3,2,1,0,0,255,254,253,252,251,250,248,246,244,243,241,239,237,235,233,232,229,228,227,225,223,222,221,219,218,218,217,216,
            216,216,216,217,218,219,220,222,224,225,227,228,231,232,235,237,239,241,243,244,246,247,248,250,251,252,254,255,0,1,2,3,1,0,6,8,9,6,3,1,0,255,254,251,249,248,1,254,244,239,241,240,235,234,231,227,227,225,219,215,211,212,212,210,210,211,212,212,212,213,214,216,218,220,222,226,230,232,235,237,239,240,242,244,247,249,251,253,255,0,2,5,7,9,10,12,14,15,16,16,
            17,19,20,26,29,29,31,33,35,34,32,32,31,29,29,29,28,26,26,25,24,23,22,23,21,22,23,23,23,23,21,19,18,17,14,13,11,10,7,6,4,3,2,2,1,0,0,254,254,254,255,254,253,252,252,251,252,254,254,253,250,247,245,243,240,237,236,235,234,231,230,224,217,213,210,210,209,208,205,203,199,195,192,189,186,182,179,176,173,170,167,166,166,164,162,163,163,164,162,164,
            162,158,161,163,167,169,173,178,183,189,193,193,195,194,204,209,213,219,223,227,230,232,235,239,242,246,250,254,1,6,9,12,16,21,26,30,35,40,42,48,51,54,55,60,61,65,55,51,58,58,57,57,56,56,56,58,58,58,59,51,51,44,38,41,46,51,50,50,47,46,44,43,42,41,40,40,38,39,35,34,34,33,32,29,29,28,27,28,25,23,25,22,21,20,19,17,16,15,15,13,
            13,12,12,12,10,10,11,10,11,12,12,12,12,13,14,15,14,13,12,11,11,10,9,8,7,6,6,5,4,2,1,0,0,255,255,253,252,251,249,248,246,244,242,240,238,236,234,233,231,229,228,226,225,224,222,221,220,219,218,218,218,218,218,218,219,220,221,223,224,226,228,230,231,233,235,237,239,241,242,244,245,247,248,249,251,253,254,255,0,1,1,3,5,4,6,11,14,10,6,2,
            1,1,0,253,254,252,253,0,245,242,247,244,241,241,235,229,226,217,213,215,215,216,215,213,211,212,212,212,212,213,214,216,218,220,222,225,228,231,235,238,241,242,244,246,248,250,252,254,0,1,3,5,7,9,10,12,13,15,16,16,17,19,21,26,29,31,31,33,33,33,33,32,32,30,29,28,27,27,26,24,24,22,24,24,23,23,23,23,24,24,21,19,18,16,14,12,10,8,6,4,
            2,0,0,0,0,255,255,253,252,251,252,252,251,250,250,252,251,251,250,250,248,245,244,243,239,237,236,234,232,229,225,220,213,209,208,206,206,207,206,203,199,195,191,188,185,181,178,174,171,169,166,164,163,162,160,160,162,162,161,161,160,158,160,163,165,169,172,178,183,189,194,194,196,194,204,211,215,221,226,229,232,235,238,241,245,248,252,0,3,8,11,15,19,24,29,33,37,40,45,50,
            52,55,55,63,64,64,64,49,59,60,60,59,58,58,59,60,60,60,60,56,61,56,55,53,53,55,54,51,51,46,45,44,44,43,42,41,40,39,38,36,35,36,33,32,30,28,28,26,25,26,26,23,22,21,20,19,18,17,15,14,14,13,13,12,11,10,10,10,11,11,11,12,12,14,13,14,14,14,13,13,12,12,11,10,9,8,7,6,5,4,3,2,1,0,0,255,253,252,250,248,
            247,245,243,241,240,238,236,234,232,231,229,228,226,225,224,223,222,221,220,220,220,219,220,220,221,222,223,224,225,227,228,230,232,233,235,237,239,240,242,244,245,247,248,250,251,252,254,255,0,0,1,3,4,6,6,7,11,16,13,10,12,14,10,2,2,254,255,254,245,252,249,245,244,242,239,234,228,224,222,223,224,221,217,217,215,213,215,213,213,214,215,216,218,220,222,224,227,231,234,238,
            241,243,245,247,249,251,253,255,0,2,3,5,7,8,10,12,13,15,17,18,20,19,21,23,25,29,32,32,30,33,34,35,34,30,29,29,27,27,26,25,24,24,25,25,25,24,23,24,23,22,20,19,18,16,14,12,9,5,4,1,0,0,255,0,255,254,253,252,251,250,249,250,249,248,249,251,250,250,251,250,246,245,242,241,238,235,233,230,228,226,222,215,208,206,205,204,206,206,205,202,
            199,195,191,187,183,180,177,173,170,167,165,162,161,161,159,158,161,161,160,160,161,161,159,162,165,169,173,179,183,190,195,196,198,196,205,212,217,224,228,231,235,237,240,244,247,251,254,2,6,10,13,17,23,27,31,35,38,42,50,50,54,56,53,61,62,65,70,51,60,62,62,61,61,62,62,62,61,62,59,60,60,61,61,58,55,56,56,54,55,51,55,47,51,46,43,43,43,42,40,38,
            36,36,34,32,31,29,30,30,29,25,27,26,22,22,21,19,18,18,17,15,15,14,13,13,12,11,10,10,11,11,12,12,13,13,14,14,14,15,14,14,14,13,12,12,10,10,8,8,7,6,5,4,2,1,0,0,254,253,251,249,248,246,244,243,241,239,238,235,234,232,231,230,229,227,226,225,223,223,222,222,222,222,222,222,222,223,224,225,226,228,229,230,232,234,235,237,239,241,242,244,
            246,247,248,249,251,252,253,254,0,0,1,2,4,5,7,9,11,12,15,16,15,15,17,12,7,3,1,3,0,253,247,248,246,245,242,239,236,233,231,228,227,226,224,222,220,219,220,218,214,214,215,216,217,220,222,225,227,229,232,236,239,242,245,247,250,252,253,0,0,2,4,6,7,9,10,12,13,15,17,18,19,19,20,21,23,24,30,32,30,32,34,35,34,31,30,29,28,27,27,26,
            26,25,26,26,25,26,25,23,21,21,20,19,18,16,15,12,9,6,4,2,0,0,0,255,253,253,252,251,249,249,247,248,248,247,246,249,251,249,249,247,245,240,237,235,235,232,229,228,226,223,218,210,206,204,203,202,205,204,203,202,199,194,191,187,183,179,175,172,169,166,164,162,161,160,158,157,157,160,158,157,160,163,161,162,164,169,172,179,184,190,195,198,199,198,208,214,221,225,230,234,
            237,240,242,246,250,254,0,4,8,12,16,21,25,29,34,36,38,46,50,51,55,56,60,68,70,73,70,56,60,64,64,64,64,64,65,63,63,64,59,64,63,62,62,62,60,60,60,59,59,58,53,50,54,49,48,49,46,44,42,41,39,38,35,33,32,30,29,27,28,28,25,26,23,23,22,20,19,18,16,16,16,15,15,15,14,12,11,11,11,11,11,12,12,13,14,14,14,14,15,15,
            15,14,13,13,12,11,10,9,8,7,6,5,4,3,1,0,0,254,252,251,249,247,246,244,243,241,239,237,236,235,233,232,230,229,228,227,226,225,224,224,224,223,224,223,224,224,225,226,227,228,230,231,232,234,235,237,239,240,242,244,245,246,248,249,250,251,252,254,255,0,0,1,3,5,6,9,11,11,12,13,12,12,14,16,21,14,3,12,4,251,251,249,248,246,245,243,240,237,233,231,
            229,228,227,227,225,224,224,223,218,217,217,217,218,220,222,225,227,230,232,235,238,241,244,248,250,253,0,1,3,4,6,7,9,10,11,13,14,16,17,17,19,19,19,20,21,22,23,27,31,31,31,32,31,31,30,28,28,27,28,26,26,26,26,25,24,23,23,22,22,22,20,20,19,17,15,13,9,6,3,0,255,255,254,253,252,250,250,249,249,248,247,246,246,245,246,247,248,247,246,247,
            245,238,233,231,230,228,226,223,221,218,214,206,203,203,201,202,204,203,201,200,198,195,190,187,183,179,175,171,168,166,163,161,160,160,157,157,156,156,154,154,161,164,161,161,163,167,172,178,183,190,196,201,199,198,207,215,222,226,232,236,240,243,246,249,252,0,3,7,11,15,18,22,26,31,33,36,44,49,52,54,55,59,67,65,69,72,68,52,61,66,67,66,66,67,67,63,64,62,64,64,
            64,63,63,63,62,61,60,60,58,58,54,53,55,52,50,50,49,46,49,44,41,39,37,35,34,32,30,28,27,27,26,26,26,23,22,21,19,18,17,16,15,16,16,16,15,13,13,12,12,12,12,12,13,13,14,14,15,15,16,17,16,16,15,14,13,13,12,11,10,8,7,6,5,4,3,1,0,0,254,252,251,249,247,246,244,242,241,240,238,237,235,234,233,231,230,229,228,227,226,225,
            225,225,225,225,225,226,226,227,228,229,231,232,233,234,236,237,239,240,242,243,245,246,247,248,249,251,252,253,254,255,0,1,3,4,6,7,9,10,13,13,14,15,12,17,24,19,13,22,7,254,253,251,248,249,248,246,243,242,241,238,233,229,230,229,225,224,225,225,224,221,218,219,219,221,223,225,228,230,233,235,238,241,244,247,250,253,0,2,3,5,7,8,9,10,11,12,13,15,16,17,
            18,18,18,19,20,21,22,22,24,28,30,30,31,29,29,28,27,27,27,27,25,24,21,18,19,19,19,20,21,21,22,20,18,15,13,11,8,6,2,254,252,251,252,251,250,248,246,246,248,247,246,245,245,244,244,245,244,245,245,243,238,235,231,229,227,226,223,221,217,215,209,205,204,202,203,204,203,201,201,198,197,194,191,187,183,179,175,172,169,166,163,161,160,161,157,156,155,155,152,152,
            157,159,157,158,162,167,172,178,184,190,197,202,201,202,205,217,223,227,233,238,242,246,250,252,254,1,6,9,14,17,21,24,29,31,35,42,47,53,53,57,60,66,65,65,67,70,63,46,59,67,68,68,67,69,66,66,67,67,65,65,64,64,64,64,63,62,61,60,60,58,57,54,53,52,52,50,50,50,49,45,43,41,38,37,35,33,31,29,29,28,28,25,27,24,23,21,21,18,18,17,
            16,16,16,16,16,15,13,13,13,13,12,13,13,13,14,15,15,16,16,17,16,17,16,15,15,14,13,12,11,10,9,8,6,5,4,3,1,0,0,254,252,251,249,247,245,244,242,241,239,238,237,236,234,233,232,231,229,229,228,227,227,226,226,226,226,227,228,228,229,230,231,233,234,235,236,237,239,240,242,243,244,246,247,248,249,250,251,252,253,254,0,0,2,4,5,6,7,9,10,12,
            13,14,12,16,27,21,22,15,3,0,0,253,251,252,248,249,247,243,244,243,239,233,232,230,224,223,223,222,223,223,221,222,222,223,224,226,228,231,233,236,239,241,244,247,250,253,0,2,4,5,7,8,9,10,11,12,13,14,15,17,17,17,18,18,19,20,20,21,22,23,27,30,30,29,27,26,24,25,24,23,20,19,17,16,18,21,19,19,20,20,20,18,16,14,13,10,6,5,0,252,
            250,249,250,249,248,247,244,244,246,247,246,245,244,244,243,243,242,242,242,240,238,233,228,227,226,223,221,218,216,211,208,204,203,203,204,203,202,201,200,198,195,193,191,187,183,179,176,173,169,166,163,161,159,161,157,156,154,154,152,150,151,153,154,158,162,167,171,177,183,190,196,202,204,207,208,216,229,232,234,239,244,249,253,0,1,4,8,12,16,20,23,27,31,35,40,44,48,52,56,60,
            62,62,64,66,66,64,64,52,55,67,70,70,70,71,69,68,68,67,67,66,66,65,66,65,64,63,62,61,61,59,55,54,53,53,53,52,51,51,49,47,45,43,40,40,36,34,33,30,30,29,28,26,25,25,23,22,21,18,18,17,17,17,16,16,17,16,15,14,14,14,13,13,14,13,14,14,15,16,17,16,17,17,17,16,15,15,14,13,12,11,10,9,7,6,5,4,3,2,0,0,
            254,252,251,249,247,245,244,242,241,239,238,237,236,235,234,232,231,230,229,228,228,228,228,228,228,228,229,230,230,231,232,233,234,235,237,238,239,240,241,243,244,245,246,247,248,249,250,251,253,254,255,0,1,3,3,5,6,8,10,13,14,13,10,17,33,20,23,10,7,4,3,0,254,251,250,252,250,246,243,240,237,234,233,230,226,226,224,224,224,223,225,227,227,226,225,227,229,231,234,236,
            239,242,244,247,250,253,0,1,4,5,6,8,9,9,11,12,12,13,14,15,16,16,16,17,18,19,19,20,21,22,22,24,25,24,23,22,20,20,20,19,18,18,16,15,16,19,21,20,18,20,16,15,14,12,11,9,5,3,254,251,249,247,247,246,247,248,245,244,244,245,246,245,244,245,244,243,240,240,240,239,236,232,227,226,226,223,220,218,215,210,208,204,203,204,204,203,202,200,199,196,
            194,192,190,187,184,180,177,173,170,166,164,161,159,161,158,156,154,153,153,151,151,152,154,157,162,167,171,177,184,190,197,202,206,207,213,214,224,235,238,240,246,250,255,1,3,6,10,14,19,22,26,30,34,37,42,47,51,54,57,58,58,61,64,66,68,70,70,63,64,66,71,71,72,73,71,70,69,68,68,67,67,67,67,66,65,64,63,62,62,59,57,55,53,53,54,54,52,53,50,48,
            47,45,41,41,38,36,34,33,31,30,28,28,25,27,24,23,21,20,19,18,19,18,18,17,17,16,16,15,14,14,14,14,14,14,14,15,15,16,16,17,17,18,17,16,16,15,14,13,12,11,10,10,8,8,6,5,4,3,1,0,255,254,252,250,248,247,245,244,243,241,240,239,237,236,235,234,232,232,231,230,229,229,229,229,229,230,231,231,232,233,233,234,235,236,238,238,239,240,241,242,
            243,244,246,247,248,249,250,251,253,254,255,0,0,1,2,4,6,7,10,12,12,11,15,24,29,22,19,16,11,9,6,3,1,254,250,250,252,248,244,242,239,236,234,232,231,229,226,226,227,226,226,228,228,226,226,228,230,232,234,236,239,242,245,247,250,253,255,1,4,5,7,7,8,9,10,11,11,12,13,13,15,14,15,16,16,18,18,19,20,20,20,21,21,20,19,19,18,18,18,17,
            17,17,16,15,14,16,18,17,16,16,15,13,12,9,6,4,1,0,252,248,245,244,244,244,244,245,245,246,246,246,246,245,243,244,244,242,240,239,237,235,233,230,228,227,226,222,219,216,210,208,206,205,206,205,204,203,201,200,198,196,194,191,189,186,184,181,178,175,171,167,165,162,159,161,158,156,154,153,153,153,153,153,155,158,162,166,172,177,183,190,197,202,206,208,213,220,226,228,239,244,
            247,251,0,3,6,9,13,17,21,24,28,32,36,40,45,50,53,55,56,55,57,59,62,64,68,71,67,55,63,67,72,73,74,73,72,71,70,69,69,68,68,68,68,67,66,65,64,64,62,61,58,56,55,55,55,55,54,53,50,50,47,46,43,41,39,38,36,34,32,30,30,29,27,27,25,24,22,21,20,19,19,19,19,18,18,17,16,15,15,14,14,14,14,14,14,15,15,15,16,16,
            17,17,18,18,16,15,14,14,14,13,11,11,10,9,7,7,5,3,2,0,0,255,253,251,250,248,246,245,244,243,241,240,239,237,236,235,234,233,232,231,231,230,230,230,231,231,232,232,233,234,234,235,236,237,238,239,239,240,241,242,243,244,245,246,248,249,250,251,252,253,254,255,0,0,1,3,4,6,9,11,10,12,20,29,26,21,19,18,16,12,9,6,4,0,253,252,251,248,247,244,
            241,238,235,234,233,232,229,228,229,228,228,228,228,227,225,228,230,232,235,237,240,242,244,247,249,252,255,0,2,5,6,7,8,9,10,10,11,11,11,12,12,13,13,14,14,15,16,18,20,19,19,19,18,18,18,19,19,18,17,17,17,17,16,16,16,16,15,16,15,14,13,13,10,7,4,1,0,252,249,244,242,241,240,241,240,241,243,244,245,247,248,243,243,243,242,242,240,241,236,233,
            232,229,227,227,224,220,217,211,207,205,207,207,207,205,205,203,202,201,199,196,193,191,189,186,183,181,178,176,172,168,165,162,160,160,159,157,154,153,154,153,153,154,155,158,161,167,172,177,184,190,196,201,206,210,213,218,225,230,236,246,249,252,0,4,8,12,15,19,23,26,30,34,38,43,47,52,55,57,58,55,54,58,58,60,64,70,61,56,71,69,72,74,74,74,73,72,71,70,70,70,
            69,70,69,68,67,66,66,65,63,62,60,57,56,56,57,56,55,54,52,50,47,46,44,42,40,39,38,36,34,32,31,30,27,28,26,25,23,21,21,20,19,20,19,19,18,17,17,16,15,15,15,14,14,14,15,15,15,15,16,16,16,17,17,18,16,16,15,15,14,13,12,11,11,10,8,7,6,4,3,1,0,255,254,252,250,249,248,246,245,244,243,241,240,239,238,237,235,235,233,233,
            232,232,232,232,232,232,233,234,234,235,236,236,237,238,238,239,240,240,241,242,243,244,245,245,246,247,248,249,251,252,254,254,255,0,0,2,3,6,8,9,12,19,29,25,19,18,21,19,18,16,12,9,6,3,0,254,251,250,249,246,242,240,238,235,233,232,231,230,231,231,230,229,230,231,230,229,230,232,235,237,239,242,244,246,249,251,253,0,1,3,5,6,7,8,9,10,10,11,12,13,
            12,12,13,14,16,17,17,17,18,19,19,20,20,19,19,18,18,18,17,17,17,17,16,16,16,16,15,16,14,13,11,12,10,7,4,0,254,249,246,244,241,240,239,239,238,239,241,243,245,246,245,243,243,243,242,245,242,242,237,233,230,227,225,224,221,215,213,209,207,206,207,207,207,206,205,204,203,201,199,196,194,191,189,186,184,181,179,176,173,169,166,163,161,161,160,157,155,154,153,154,
            154,155,157,161,162,166,171,177,183,189,196,199,205,210,217,222,225,231,238,244,250,253,0,5,9,13,16,20,24,28,32,36,40,44,49,52,56,56,58,57,57,66,66,65,68,68,57,65,75,74,73,74,75,75,73,70,71,71,71,71,71,71,71,70,69,68,67,66,65,62,63,59,58,57,57,58,57,55,53,50,49,47,45,43,42,40,39,37,35,33,31,31,28,29,26,26,24,22,21,20,
            21,20,20,19,18,18,17,16,15,15,15,15,15,15,14,15,16,16,16,15,16,16,17,17,17,16,15,14,14,14,13,12,11,10,9,8,6,5,3,2,1,0,255,253,251,250,249,248,247,245,244,243,241,240,239,238,236,236,235,234,234,234,233,233,233,234,234,235,236,236,237,238,238,238,239,239,240,241,241,242,243,244,244,245,246,246,247,248,249,252,252,253,254,255,0,1,3,6,9,9,
            15,23,28,18,19,19,20,18,18,17,14,10,8,4,1,0,253,251,249,247,243,240,236,234,233,233,231,231,232,233,231,230,230,231,232,234,232,232,234,236,238,240,242,244,246,248,251,253,255,0,2,4,6,8,9,10,10,10,11,11,13,13,15,16,19,19,19,19,19,20,19,19,20,19,19,19,18,18,17,17,17,17,17,17,17,16,17,16,13,12,9,10,9,7,3,0,253,248,245,242,
            240,239,238,237,236,238,240,243,245,249,247,245,244,243,240,242,243,245,238,234,229,226,224,221,216,213,212,209,208,208,208,208,208,207,206,205,204,201,199,197,194,192,189,186,184,182,179,177,174,171,167,165,162,162,161,157,156,155,154,154,154,155,159,162,164,168,173,178,183,188,194,199,204,210,217,223,227,228,238,244,250,255,2,6,11,14,17,22,25,29,33,37,41,45,49,51,53,54,54,54,
            59,61,61,56,54,50,46,67,74,73,76,76,73,73,72,72,68,68,70,71,72,72,72,72,70,69,68,66,66,62,63,61,59,58,59,59,59,56,54,52,49,48,45,44,42,41,39,38,36,34,32,31,29,28,28,25,25,23,23,22,21,20,20,19,19,17,17,17,16,16,16,15,15,16,15,14,15,15,15,15,15,16,16,16,16,16,15,14,14,13,12,12,11,10,9,8,7,5,4,2,
            1,0,255,254,252,251,250,248,247,246,245,244,242,241,240,239,238,237,236,236,235,235,235,235,235,235,236,236,237,237,238,238,239,239,240,240,240,241,242,242,243,243,244,245,245,246,246,247,248,251,253,253,254,255,0,1,3,6,9,11,17,24,24,16,18,19,20,20,19,17,15,12,9,5,3,0,254,251,249,246,244,240,238,235,234,233,233,233,232,233,232,231,231,232,233,235,235,234,235,237,
            239,241,243,245,247,249,251,253,255,0,1,3,4,6,6,7,7,8,8,9,11,13,15,17,18,18,18,20,20,20,20,20,20,19,19,18,18,18,18,18,18,17,18,17,17,16,16,14,15,12,9,9,9,7,4,0,253,249,244,242,239,237,237,236,236,237,239,242,245,249,248,245,242,239,240,242,243,242,237,233,229,225,223,218,215,214,212,209,208,209,209,209,208,208,207,207,204,203,201,198,
            195,193,190,187,184,183,180,179,176,172,169,166,163,163,162,158,158,157,156,155,156,157,160,162,165,169,173,178,183,189,195,198,204,210,216,223,228,230,235,241,246,1,3,7,11,15,18,21,25,29,33,38,41,44,47,49,52,53,54,55,62,61,64,60,50,44,44,54,69,71,72,74,77,80,76,75,75,72,70,70,72,73,73,73,73,73,73,72,71,69,65,62,60,60,59,60,59,58,56,53,
            50,48,46,44,42,41,40,39,37,35,34,32,31,28,28,28,26,24,24,22,22,21,20,19,19,19,18,18,17,16,16,16,16,15,15,15,15,15,14,15,15,15,16,15,15,15,15,14,14,13,12,12,10,10,9,8,7,5,4,3,1,0,0,254,253,251,250,249,248,247,245,245,243,242,241,240,239,239,238,237,237,236,236,236,236,236,237,237,238,238,239,239,240,240,241,241,241,241,242,242,
            243,243,244,244,245,245,246,246,247,248,249,250,252,253,255,0,3,5,6,11,17,24,20,14,15,18,20,20,19,17,16,13,9,6,4,1,255,252,249,247,245,241,240,239,238,234,234,234,233,233,233,232,231,233,231,230,234,236,238,240,241,242,244,244,246,247,249,250,251,253,254,255,0,0,2,4,5,6,7,8,10,12,13,14,15,17,18,20,20,20,20,20,19,19,18,18,18,17,18,19,
            18,18,18,17,16,15,14,13,11,11,7,6,8,8,5,2,254,250,247,243,239,238,237,236,236,237,238,242,246,250,249,245,241,240,239,241,239,241,237,232,228,226,220,216,216,213,211,210,210,209,210,211,210,209,209,208,206,204,202,200,197,194,191,188,187,185,182,180,178,175,171,168,166,165,164,160,160,158,157,156,157,159,162,165,167,170,174,179,184,189,193,198,203,210,215,221,227,231,233,240,
            242,255,5,7,11,15,18,22,25,29,32,36,39,43,45,48,52,53,52,55,64,64,60,60,61,63,64,61,59,63,67,69,71,70,70,72,72,74,74,74,72,72,73,77,78,78,77,77,76,74,74,70,66,63,62,61,61,59,57,54,51,49,48,46,44,42,41,40,38,37,35,33,32,30,27,28,28,25,24,23,23,22,20,20,19,18,18,18,18,17,16,16,16,16,16,16,15,15,15,15,
            14,14,15,15,14,14,14,14,14,13,12,11,10,9,8,7,6,5,4,3,1,0,0,255,253,252,250,249,249,247,246,245,244,243,242,241,240,240,240,239,238,238,237,237,238,238,238,239,239,240,240,240,241,241,241,241,242,242,242,243,243,243,243,244,244,244,245,246,246,247,249,250,251,253,254,0,1,4,3,4,9,22,14,10,14,17,19,20,19,19,17,13,9,7,5,1,255,252,249,247,
            245,242,242,242,239,239,237,234,233,234,234,232,231,230,230,229,233,234,236,241,244,245,246,246,247,248,249,250,252,252,253,254,255,0,0,1,2,4,5,6,8,10,11,13,15,16,17,19,20,19,19,19,19,18,18,18,18,18,18,19,18,18,17,16,14,13,11,10,8,8,7,5,4,5,4,2,255,250,247,244,242,241,238,237,237,237,239,241,245,249,248,244,241,239,239,240,238,241,237,233,
            227,225,219,217,216,214,212,212,211,211,211,212,212,211,211,210,207,206,204,202,199,196,193,191,188,186,184,182,180,177,173,170,168,167,165,162,161,160,160,159,159,161,164,169,168,170,175,180,184,189,194,198,203,209,214,220,226,230,235,234,242,248,5,8,12,15,18,21,24,28,31,34,37,40,43,46,51,53,53,57,62,61,61,57,57,59,61,60,57,59,59,59,63,69,69,69,70,77,74,73,
            74,78,78,78,78,79,79,80,79,78,77,75,72,66,62,64,62,61,59,56,54,52,50,48,45,44,42,40,40,39,37,34,33,32,30,27,26,26,25,24,24,22,21,20,19,19,19,19,18,17,16,17,17,16,17,17,17,15,15,14,14,14,13,14,14,14,13,13,13,12,12,11,10,9,8,7,6,4,3,2,1,0,0,254,253,252,251,250,249,248,246,246,245,244,243,242,242,241,241,240,
            240,239,239,239,239,239,239,240,240,241,241,241,242,242,242,242,243,243,243,243,243,243,243,243,243,244,244,245,246,246,248,249,250,252,253,0,1,2,0,7,15,18,11,9,11,15,19,20,20,20,18,16,12,9,7,4,255,251,251,250,249,247,244,240,237,236,235,233,233,234,233,232,232,230,229,229,232,235,237,242,243,247,249,248,249,249,250,251,251,251,253,253,254,255,0,0,1,3,5,6,
            8,10,11,13,15,16,17,18,19,19,19,19,19,18,18,18,18,18,18,18,18,17,15,14,12,11,9,8,7,4,5,5,3,3,3,1,0,252,249,247,244,243,242,240,239,238,239,241,244,246,246,243,241,239,236,238,239,238,236,233,226,224,220,217,216,214,213,213,213,212,213,213,213,213,214,215,213,208,206,203,201,198,196,193,190,188,186,184,182,180,176,173,171,170,167,165,164,163,163,162,
            163,163,166,170,170,172,177,181,184,191,194,197,202,208,214,218,224,229,235,238,239,247,254,9,12,15,18,21,24,27,29,33,36,39,42,45,48,50,48,51,56,57,59,59,56,58,59,60,59,58,58,56,54,65,67,71,78,82,84,85,80,76,77,76,75,75,78,79,81,81,80,78,74,66,63,64,65,63,61,59,56,54,52,50,48,46,44,43,41,40,38,36,34,32,31,29,28,27,26,25,
            24,23,21,21,20,19,18,18,19,17,17,17,17,17,17,17,17,16,15,14,14,13,13,13,13,12,12,12,11,11,10,9,8,8,7,6,5,4,3,2,1,0,0,255,253,252,251,250,249,248,247,246,246,245,244,243,243,242,242,241,241,240,240,240,241,241,241,241,242,242,243,243,243,243,244,243,243,243,243,243,243,243,243,243,243,243,244,244,245,246,247,248,249,251,253,255,0,1,255,10,
            17,17,11,9,10,13,17,20,21,21,20,18,15,12,10,6,0,253,252,250,249,246,243,241,239,237,235,235,235,236,236,232,233,231,230,231,233,235,238,242,245,248,249,250,251,250,249,249,250,251,252,252,253,254,255,0,0,2,3,5,7,9,11,12,14,15,16,17,18,19,18,18,18,18,17,17,17,17,17,17,17,16,14,12,10,9,9,8,7,7,6,6,4,4,3,2,2,0,252,250,
            247,245,244,242,241,241,240,241,242,243,242,244,242,238,237,238,238,238,235,230,226,223,219,218,216,215,215,214,214,214,214,215,215,215,214,216,216,213,208,206,203,201,198,195,193,190,188,186,185,182,179,176,175,174,169,167,166,166,165,164,164,165,169,170,171,174,178,181,185,190,193,197,201,207,211,216,222,227,233,238,238,242,248,254,8,14,17,20,23,25,28,31,34,37,40,43,45,47,47,51,
            56,53,57,56,55,56,57,58,58,56,55,44,48,59,58,67,67,69,70,73,79,81,82,81,78,71,68,73,78,81,81,81,73,57,61,64,66,66,63,61,59,57,54,52,50,47,45,44,42,41,40,37,35,34,33,31,30,28,27,25,26,24,22,21,20,19,19,18,19,18,17,17,17,18,17,16,16,15,15,14,13,12,12,12,12,11,11,11,10,10,9,8,7,6,5,5,4,4,2,2,
            1,0,0,255,253,252,251,250,249,248,247,247,246,245,244,244,243,243,242,242,242,242,242,242,242,242,242,242,243,244,244,244,244,245,245,244,244,244,243,243,243,243,243,243,243,244,244,244,245,246,247,248,249,251,252,254,0,0,254,8,10,17,19,14,11,12,17,21,23,23,23,22,18,14,11,7,3,0,0,253,251,249,247,244,241,238,237,235,236,238,237,233,233,231,231,233,234,236,238,243,
            245,247,249,251,252,252,249,249,249,249,250,250,251,252,254,255,0,1,2,4,6,8,10,12,13,14,15,16,17,18,17,17,16,16,16,16,16,17,17,17,16,15,12,11,9,9,8,7,7,7,8,9,10,6,4,5,5,2,0,255,251,249,247,244,243,243,243,241,242,244,243,244,243,239,239,237,238,238,234,229,226,222,220,219,218,217,216,215,216,215,216,217,217,217,216,215,213,213,212,208,
            206,203,201,199,195,193,191,189,188,185,181,180,181,176,172,170,169,168,168,167,167,168,171,171,173,176,180,182,186,191,194,196,201,206,209,214,220,225,230,236,241,240,245,246,253,9,15,18,21,24,26,29,31,34,37,40,42,44,46,47,49,49,51,52,54,54,54,55,54,53,50,35,44,53,59,67,68,69,70,71,73,74,76,82,83,81,73,73,72,75,80,74,61,57,57,71,65,62,60,60,
            61,58,56,54,52,49,47,46,44,43,40,39,37,36,34,33,32,31,29,27,27,25,24,22,21,21,20,19,19,19,18,16,17,17,17,16,15,14,14,13,13,12,11,11,11,10,10,9,9,8,7,6,5,4,4,3,3,3,2,1,0,0,0,255,253,252,251,250,249,248,247,247,246,245,245,244,244,243,243,243,242,242,242,243,243,243,243,243,245,245,245,246,245,246,245,245,245,244,244,244,
            244,244,244,243,243,244,244,244,245,245,246,247,249,250,252,254,255,0,255,3,9,18,22,14,11,14,20,22,24,23,23,22,19,16,12,9,7,4,2,0,254,252,250,246,243,240,239,238,238,238,237,233,234,233,233,234,235,236,239,244,246,247,248,251,253,252,248,247,247,248,249,249,250,251,252,253,255,0,1,3,5,7,9,11,12,13,14,15,16,16,16,16,16,15,15,16,16,16,16,16,
            15,13,11,10,9,9,8,8,7,7,8,11,13,10,7,8,8,4,3,1,254,251,248,247,246,245,245,242,242,246,242,243,243,243,241,237,237,236,233,230,226,223,221,220,219,218,216,215,214,218,218,219,219,218,217,216,215,214,214,212,209,206,204,201,199,196,195,192,191,188,184,184,183,179,175,173,172,171,171,171,171,173,175,176,176,179,181,184,187,192,193,196,200,205,209,213,217,223,228,235,
            239,241,242,245,247,252,9,21,22,22,25,26,28,31,33,37,39,41,43,44,44,49,48,48,48,50,51,50,50,49,46,42,46,54,64,67,67,69,70,71,73,73,74,76,77,77,78,75,60,58,64,66,70,69,59,64,70,68,62,59,56,59,59,55,53,52,50,48,46,44,42,40,39,37,37,35,32,32,30,29,29,27,26,25,23,22,21,22,20,20,19,18,17,17,17,16,15,14,14,13,
            12,10,10,9,10,9,9,9,9,7,6,5,4,3,2,2,2,1,0,2,1,0,0,254,253,252,251,249,249,248,247,247,246,245,245,245,244,244,244,244,244,243,244,244,244,244,245,246,246,246,247,247,247,247,246,246,246,245,244,244,244,244,244,244,244,244,244,245,245,246,247,248,249,250,251,253,255,0,0,255,9,16,24,20,13,14,20,23,24,25,24,23,20,18,14,12,11,9,5,3,
            0,254,252,249,247,243,240,240,241,240,236,233,235,234,234,235,236,239,241,245,246,247,249,251,251,252,248,246,246,247,248,249,249,250,251,252,253,255,0,2,4,7,8,10,11,12,14,15,15,15,15,15,15,15,15,15,15,15,15,15,13,12,10,10,9,9,8,9,8,8,9,10,11,13,10,10,11,7,4,3,0,254,253,252,251,249,245,244,246,246,244,242,243,241,241,240,239,235,232,231,
            227,223,222,221,220,220,218,222,217,220,221,220,220,220,219,219,218,217,215,214,213,210,207,204,202,199,197,196,194,191,188,188,185,181,178,177,176,175,174,173,174,175,176,177,178,180,183,185,188,191,192,196,199,204,208,212,215,222,227,231,235,238,243,244,245,245,250,2,13,19,24,27,28,31,34,34,34,36,38,38,39,40,43,45,47,47,48,46,42,42,45,47,51,59,63,66,66,68,69,70,
            72,72,74,74,74,74,75,84,74,68,72,73,69,70,70,69,68,70,66,63,62,57,58,59,56,53,52,50,49,47,44,42,41,39,39,38,35,34,33,32,32,29,29,27,26,24,24,24,23,22,20,19,17,17,16,15,15,15,13,12,11,10,12,9,8,8,8,8,7,7,5,5,3,2,1,0,0,0,0,0,2,0,254,254,253,252,250,249,248,248,247,247,247,246,246,245,246,245,245,245,
            245,245,245,245,245,246,246,247,247,247,248,248,248,248,247,247,246,245,245,245,245,244,245,244,245,245,245,245,246,246,247,248,249,250,252,253,255,0,1,255,6,14,22,26,18,17,19,25,25,26,26,25,23,20,17,14,13,11,8,5,3,0,253,250,248,245,242,241,241,241,237,236,237,236,236,237,240,242,243,243,245,246,247,250,251,249,246,246,246,247,247,249,249,250,251,252,253,255,0,2,
            4,6,8,9,11,12,13,14,14,14,14,14,14,14,14,14,14,14,14,13,12,10,10,9,9,9,9,9,10,10,11,11,13,16,15,14,14,9,6,7,4,0,0,254,253,250,247,249,249,245,244,244,246,245,244,243,235,235,233,232,226,224,224,223,222,222,220,223,219,222,222,223,223,221,222,221,220,219,217,216,216,213,211,208,205,203,201,199,197,194,193,191,189,185,182,181,179,178,177,177,
            178,179,180,180,182,183,185,187,189,192,192,197,199,203,207,212,215,220,225,228,232,237,240,244,247,248,249,251,254,2,8,12,16,21,24,28,32,35,34,38,42,41,36,33,39,43,41,37,34,41,46,52,56,60,62,64,66,66,67,69,71,72,73,74,74,74,71,77,83,79,77,78,77,74,73,72,70,69,67,63,64,61,55,60,59,57,55,54,52,49,48,45,43,42,41,39,38,36,35,34,
            35,33,31,30,28,28,27,26,25,22,21,20,18,17,16,15,16,15,14,12,11,9,8,8,7,7,7,6,6,6,5,4,2,2,0,0,0,255,255,254,254,0,255,253,252,251,250,249,249,248,248,247,247,247,247,247,246,247,246,246,246,246,246,246,247,248,248,249,249,249,249,249,249,249,248,248,247,246,246,245,245,245,245,245,245,246,246,246,246,247,248,249,250,251,252,253,255,0,1,0,
            0,9,21,30,28,21,24,26,27,28,28,27,26,24,22,19,17,15,11,8,4,0,254,252,249,246,244,243,243,242,238,238,239,237,239,241,242,240,241,244,245,245,246,247,248,246,246,246,246,247,247,248,249,250,250,251,252,254,0,2,4,5,7,8,9,10,12,13,13,13,13,13,13,13,14,13,13,13,13,12,12,11,10,10,10,10,10,11,11,12,13,14,15,17,17,17,17,11,8,8,
            6,3,1,255,253,251,252,250,250,247,245,246,245,245,241,241,238,235,234,233,230,226,227,225,225,224,224,223,224,223,225,227,224,225,224,223,222,221,219,218,219,218,216,211,209,206,205,203,201,198,196,195,192,189,186,184,183,182,181,181,181,182,182,183,184,186,187,188,189,192,193,197,199,203,205,210,214,218,221,225,230,234,236,241,246,252,252,249,251,254,255,1,4,10,11,11,14,16,20,28,
            38,34,27,27,35,34,36,42,47,50,52,55,58,60,61,63,65,64,66,67,69,70,72,74,74,71,69,69,76,80,78,76,74,74,73,71,70,68,66,63,63,63,59,59,60,58,58,57,56,54,51,50,47,44,43,41,40,38,36,35,39,37,34,32,31,29,28,27,26,24,22,21,19,17,17,15,15,15,15,13,11,10,8,8,7,6,6,5,5,4,4,3,2,1,0,255,255,254,254,253,
            253,253,253,252,252,250,250,249,249,249,248,248,248,247,247,247,247,247,247,247,247,247,247,248,248,249,250,250,250,250,250,250,250,250,249,249,248,247,247,247,246,246,246,246,246,247,247,247,247,248,248,249,250,251,252,253,255,0,1,3,0,2,16,28,33,28,29,28,27,28,29,29,28,28,26,23,20,17,14,10,4,1,255,251,246,246,246,245,244,240,238,239,241,240,242,240,240,241,242,244,
            245,243,245,247,247,245,246,247,247,247,248,248,249,249,250,251,252,254,255,1,2,4,6,7,8,10,11,12,11,12,11,11,12,13,13,13,13,12,12,12,11,11,10,11,10,11,11,12,13,13,14,15,17,18,19,20,21,15,12,11,8,5,3,1,0,0,255,253,251,248,248,247,245,242,242,239,238,235,233,233,234,230,229,229,228,227,225,226,225,227,227,226,227,226,225,225,224,223,221,220,
            219,221,219,215,212,210,209,208,205,202,200,199,195,192,190,188,187,186,185,185,185,185,185,185,187,188,188,189,190,194,193,197,199,202,205,209,214,218,220,224,228,231,235,238,243,249,249,250,252,254,0,2,5,5,5,7,10,13,16,17,22,27,29,29,31,37,42,46,48,49,52,55,58,59,59,60,62,62,64,66,67,67,69,72,72,70,71,69,68,69,72,74,74,74,72,70,66,69,69,65,
            64,58,62,56,55,52,54,59,59,58,56,52,51,49,47,45,43,41,40,39,42,41,39,35,34,31,30,28,27,25,23,20,18,17,16,15,14,14,15,13,10,9,8,7,7,6,5,4,3,3,2,1,0,0,255,255,253,253,252,252,252,252,252,251,250,249,249,249,248,248,248,248,248,248,248,248,248,248,248,248,248,248,249,249,250,250,251,251,251,251,251,251,251,251,250,249,249,248,248,248,
            247,247,247,247,247,248,248,248,248,249,249,250,251,252,253,254,255,0,1,3,4,2,10,25,35,36,35,32,33,29,30,30,30,30,29,26,23,20,18,15,12,8,4,1,252,249,248,247,245,243,239,242,241,239,241,241,241,241,244,245,245,245,246,245,245,245,247,248,249,248,248,249,249,250,250,251,252,253,255,0,2,3,5,6,7,9,10,10,10,10,10,11,12,12,12,13,13,12,12,11,
            11,11,11,11,11,12,12,13,14,15,16,17,17,20,21,23,22,20,15,12,10,7,5,3,1,0,0,254,251,250,249,247,243,243,240,238,237,235,234,234,234,231,230,231,233,230,228,229,232,232,231,229,228,227,227,227,226,225,224,222,221,223,221,218,216,215,213,211,209,206,204,202,199,196,194,193,191,189,189,189,189,189,188,189,190,188,189,190,192,195,194,198,199,201,205,209,213,218,219,221,
            224,229,233,236,240,243,244,248,250,254,0,3,6,7,8,10,13,14,17,20,23,25,29,34,37,38,41,43,45,46,49,53,54,57,57,59,60,61,62,63,63,64,66,69,68,67,68,67,67,66,66,66,66,67,67,65,67,69,67,64,66,65,61,60,60,55,47,59,61,61,60,58,56,53,52,50,49,46,46,42,42,44,41,37,36,34,32,29,28,25,24,22,20,17,16,14,13,14,13,12,
            10,9,8,7,7,6,4,4,3,2,1,0,0,0,254,254,253,253,252,252,251,251,250,249,249,248,248,248,248,248,248,248,248,248,248,248,248,249,249,249,249,249,250,250,251,251,252,252,252,252,252,252,252,252,251,250,250,249,249,248,248,248,249,248,248,249,248,249,249,250,250,251,252,252,253,253,255,0,1,4,6,6,6,17,33,39,39,42,46,35,30,29,29,29,29,27,24,21,18,16,
            13,10,6,3,255,253,250,249,248,245,244,244,241,239,243,244,243,241,244,245,246,246,246,245,245,245,247,248,249,249,249,249,249,250,250,251,252,253,254,0,1,2,4,6,7,9,9,10,10,10,11,11,12,12,14,13,13,13,13,12,12,12,12,12,12,12,13,14,14,15,16,17,18,22,21,23,23,20,17,14,12,10,6,3,2,1,0,254,253,252,251,247,242,245,242,241,240,237,237,236,
            236,232,230,229,230,234,231,231,236,238,236,232,231,230,229,230,229,228,227,226,223,226,223,221,219,219,218,215,213,211,208,206,203,200,198,197,195,194,193,193,193,192,192,192,191,190,191,191,194,197,196,198,200,201,204,209,214,215,217,219,221,225,231,233,235,238,240,244,247,251,253,254,1,3,5,7,10,12,14,18,23,25,29,32,35,37,37,40,42,44,46,50,52,54,55,56,57,58,59,59,
            61,61,64,67,66,64,66,65,65,65,65,65,65,64,65,67,69,68,66,65,64,65,64,62,62,60,49,58,62,62,62,60,59,57,56,54,52,50,48,46,47,46,44,43,39,36,32,31,36,30,29,24,19,17,16,14,13,13,12,10,9,8,7,7,6,6,5,4,3,2,2,1,0,255,255,254,253,252,251,251,250,250,249,248,248,247,247,247,247,248,248,248,248,248,248,248,249,249,249,249,
            250,250,251,252,252,253,253,253,253,253,253,253,252,253,252,251,251,250,249,250,249,249,250,249,249,250,249,249,250,251,251,252,252,253,253,253,255,0,1,4,6,9,8,12,26,39,40,44,46,51,48,39,29,25,27,26,25,22,20,17,14,13,8,4,0,253,251,249,247,247,246,245,242,240,244,245,244,244,243,245,247,247,246,244,245,246,247,249,249,250,250,250,250,250,251,251,252,253,254,0,
            0,2,4,5,6,8,9,9,10,10,11,12,12,13,13,13,13,13,13,13,13,13,13,12,13,13,13,14,14,15,16,17,19,21,22,23,21,21,17,14,13,10,6,5,4,3,1,0,255,253,250,246,245,245,246,242,241,240,238,237,236,233,234,232,231,231,231,233,239,243,241,235,234,233,232,232,232,231,230,228,228,230,227,225,223,222,221,220,217,215,212,209,206,204,203,201,199,198,198,197,
            196,196,195,195,193,193,194,196,198,197,197,198,200,202,204,207,211,215,215,217,219,223,227,229,230,232,236,239,243,247,247,249,253,0,1,3,6,8,10,14,19,22,27,29,32,34,36,38,39,41,42,45,48,51,51,52,54,55,56,56,58,59,61,64,66,65,64,65,64,62,62,63,63,63,64,65,66,65,65,65,64,64,64,62,61,63,53,56,64,64,64,63,61,59,58,56,54,53,51,49,
            49,48,46,45,44,42,38,27,26,29,27,23,20,17,15,14,13,12,11,10,9,8,7,7,6,6,6,5,3,3,1,1,2,1,255,254,253,252,252,252,250,249,248,248,247,247,247,247,247,248,248,247,248,248,248,248,249,249,250,250,251,251,251,252,253,253,253,254,254,254,254,254,254,253,252,252,252,251,251,250,251,250,250,250,250,250,250,250,250,251,252,252,252,253,253,254,0,0,2,4,
            7,10,12,11,16,30,41,42,46,47,49,48,32,24,23,23,24,24,22,19,16,13,9,3,0,255,253,252,251,250,247,244,243,242,244,245,244,244,246,247,247,248,244,244,245,247,248,249,249,250,250,250,250,250,250,251,252,253,254,255,0,2,3,5,6,7,9,9,10,10,11,12,12,12,13,13,13,13,14,13,13,14,13,13,14,14,14,14,15,15,16,18,21,22,25,22,20,19,17,14,
            12,10,8,7,5,5,3,1,0,253,249,249,247,245,247,245,243,242,240,238,238,235,235,234,233,231,234,237,239,244,242,238,235,235,234,235,235,234,232,230,233,234,229,228,227,226,225,224,221,219,216,214,210,208,206,205,204,203,202,201,200,199,198,197,196,196,199,201,201,198,199,200,201,203,204,208,209,213,214,215,218,220,223,225,226,229,232,235,239,242,243,245,249,252,254,0,1,3,6,10,
            15,21,25,28,30,32,35,37,37,37,39,41,43,45,47,49,51,53,53,53,54,56,58,61,64,65,63,63,65,64,64,62,62,62,62,63,64,63,64,63,63,62,61,62,62,59,55,62,63,65,65,64,62,61,59,58,56,55,53,51,51,49,48,46,45,44,42,34,22,29,26,23,20,17,16,14,12,12,11,10,9,8,7,7,6,6,6,5,5,4,2,1,0,0,0,255,253,252,252,251,
            251,249,248,247,247,246,246,246,247,247,247,247,248,248,248,249,249,249,250,251,251,252,252,253,253,254,254,254,254,254,254,254,254,254,253,253,252,252,252,252,251,251,251,251,251,251,250,251,251,251,252,253,253,254,254,255,0,0,2,5,7,10,13,15,13,15,30,37,41,46,47,48,42,32,23,21,23,24,24,22,18,14,10,5,4,1,254,253,252,251,249,246,245,244,244,244,244,244,246,249,
            247,249,247,243,246,248,249,249,250,250,250,250,250,249,250,250,251,253,254,255,0,2,4,5,6,7,8,9,10,11,11,11,12,12,14,13,13,13,14,14,14,14,14,14,14,14,15,15,15,15,16,20,22,22,24,21,19,18,17,17,14,10,9,8,7,7,5,3,2,255,252,248,248,247,246,247,245,241,239,238,240,236,237,236,238,239,239,241,244,246,243,240,238,237,237,238,237,236,235,236,
            238,235,233,231,231,230,229,227,225,223,220,217,215,212,211,209,208,207,206,205,203,202,200,199,198,198,199,200,203,200,201,201,203,204,206,207,208,211,212,214,216,217,220,222,224,226,229,231,235,237,239,242,245,248,249,252,254,0,2,8,14,20,22,25,27,29,33,34,34,34,35,37,39,41,43,45,48,49,50,52,53,54,56,59,62,64,62,61,63,63,64,64,63,61,61,61,62,63,62,62,
            62,60,61,60,59,61,59,62,65,66,66,65,64,62,61,59,58,56,54,53,51,50,49,48,47,46,45,36,24,29,28,24,20,17,16,14,12,12,11,10,9,8,7,7,6,5,5,4,6,5,3,2,0,254,254,254,254,253,251,250,249,249,248,247,246,246,246,246,246,246,247,247,247,248,248,249,249,250,250,251,252,252,252,253,254,254,254,255,255,255,255,254,254,254,254,253,253,253,252,252,
            251,251,251,251,251,251,251,251,252,252,253,253,254,254,255,0,0,1,3,5,7,10,13,15,16,14,11,18,30,40,47,46,48,47,39,24,21,23,23,21,19,16,12,9,5,2,0,254,253,252,249,246,245,244,243,244,245,245,247,248,248,248,246,247,248,248,249,250,250,251,251,250,250,250,249,250,251,253,255,0,0,2,4,5,6,7,9,9,10,11,11,12,12,13,13,13,13,13,14,15,
            15,15,15,15,15,15,16,16,16,17,18,21,21,24,24,23,21,20,19,18,15,12,11,10,10,8,8,7,0,0,2,1,254,250,249,247,245,243,243,243,243,238,240,241,243,245,244,246,249,248,244,240,240,240,239,240,239,238,237,241,240,237,236,235,234,233,232,231,229,227,224,221,219,217,215,213,212,211,210,208,207,205,203,202,201,200,201,202,206,203,203,203,204,205,208,206,208,209,211,213,
            215,215,217,219,222,224,226,229,232,234,237,240,242,247,249,251,251,253,0,7,12,16,19,22,24,26,29,32,32,31,33,35,37,38,39,43,44,46,47,50,51,52,54,57,59,61,62,60,60,61,63,63,63,61,61,61,61,61,60,60,59,58,60,59,60,60,60,58,62,67,66,66,65,63,62,60,59,57,56,54,50,51,50,49,49,48,47,33,26,29,27,25,21,18,16,14,13,13,11,10,
            9,8,8,6,6,5,4,3,3,2,4,3,0,254,253,252,251,251,250,250,249,248,248,247,247,246,246,246,246,246,246,246,247,247,247,248,249,250,250,251,251,252,253,253,254,254,255,255,255,255,255,254,254,254,254,254,253,253,252,252,252,252,252,252,252,252,252,253,253,253,254,254,255,255,0,0,1,2,4,5,8,10,12,15,16,17,17,14,17,27,39,47,47,47,46,39,31,22,21,20,
            20,17,14,11,7,3,0,254,253,251,249,245,245,246,246,246,247,247,248,247,248,250,251,247,250,249,249,250,251,251,251,251,250,250,250,250,251,252,254,0,0,2,3,5,6,7,8,10,10,11,12,13,13,13,13,13,13,14,15,15,16,16,16,16,16,16,17,18,18,18,18,20,23,25,26,24,22,20,20,19,17,14,14,12,10,10,8,5,4,3,3,4,2,254,251,249,247,248,247,243,
            243,242,243,244,246,248,250,252,252,250,244,242,241,241,241,241,241,240,240,241,241,240,240,239,238,237,236,235,233,231,228,226,223,221,219,217,216,214,213,211,210,209,207,205,204,204,204,205,209,204,204,205,205,206,207,206,208,209,211,212,213,214,216,218,221,223,225,227,229,232,235,237,240,243,245,247,249,252,0,6,9,11,14,17,20,22,24,27,28,29,32,32,33,35,37,39,41,43,45,47,
            49,50,51,54,57,60,60,59,58,59,60,60,60,59,59,60,60,59,58,58,58,58,58,61,60,60,60,57,57,66,66,66,65,64,63,61,59,57,57,52,50,50,51,50,51,50,46,26,29,28,29,25,21,18,16,14,12,12,11,10,10,9,8,6,5,4,3,2,2,2,0,0,255,253,252,251,251,250,249,249,248,247,247,247,247,247,246,246,246,246,246,246,247,247,248,248,249,249,250,251,
            252,252,253,253,254,254,255,255,255,255,255,255,254,254,254,254,253,253,253,253,253,252,252,252,253,253,253,254,254,254,255,255,0,0,0,1,2,3,4,6,8,10,12,13,16,17,19,20,14,20,36,46,45,46,46,41,31,22,22,21,19,17,15,12,9,4,0,253,251,249,249,247,247,249,248,247,248,247,246,248,249,251,250,246,248,249,250,251,252,252,252,251,251,250,250,250,251,252,254,255,
            0,2,3,5,6,7,8,10,11,11,12,12,12,13,13,13,13,14,15,16,16,16,16,17,17,18,18,19,19,19,20,22,25,25,26,25,23,23,22,22,20,16,14,13,12,11,10,8,7,6,6,6,2,255,253,251,250,250,246,245,244,245,246,247,247,249,254,0,255,250,244,244,243,243,244,244,242,244,248,244,244,243,242,242,242,241,240,239,237,235,233,230,228,226,223,222,220,218,216,215,
            213,212,210,208,208,207,206,209,210,206,207,206,207,208,207,208,208,209,210,210,212,214,215,217,220,222,224,226,228,230,233,235,236,240,242,243,250,253,0,4,8,10,12,14,16,18,20,22,24,27,28,28,29,31,34,35,37,40,42,44,47,47,49,53,56,58,58,58,58,58,58,58,59,58,57,57,58,57,57,59,57,57,57,59,60,60,60,57,53,64,66,66,65,64,63,61,59,57,55,51,
            50,51,52,51,52,52,43,24,29,29,26,23,21,18,16,15,13,12,11,10,9,9,10,7,5,4,3,2,1,0,0,255,253,253,251,251,250,249,248,248,248,248,247,246,246,246,246,245,245,245,245,246,246,247,247,248,249,249,250,251,252,252,253,253,254,254,254,255,255,255,255,255,254,254,254,254,254,253,253,253,253,253,253,253,253,254,254,255,255,255,0,0,0,0,1,1,2,3,5,7,
            8,9,11,13,14,16,18,21,18,16,34,43,45,45,45,43,30,23,23,22,19,16,15,13,8,4,0,252,251,249,249,249,248,250,249,250,251,248,250,250,250,247,250,248,250,249,250,251,252,251,251,252,250,251,250,251,251,252,253,255,0,1,3,4,6,7,8,10,12,12,12,12,13,13,13,13,14,15,16,16,16,17,17,18,18,19,20,21,22,23,23,24,25,25,26,26,27,26,25,24,
            22,19,17,16,14,13,11,10,10,10,7,5,1,1,0,253,253,248,246,247,247,248,248,249,250,251,255,1,0,250,246,246,246,246,246,246,245,246,250,244,247,246,246,246,245,245,244,242,240,239,236,234,232,230,227,225,223,222,220,218,216,215,213,212,211,210,211,212,212,209,209,209,210,209,208,209,209,210,209,210,211,213,215,217,220,222,223,225,227,229,231,233,235,238,240,244,249,251,254,1,
            4,8,10,10,12,14,16,18,20,23,24,24,24,27,31,33,34,37,39,43,46,45,45,48,52,55,56,56,57,57,57,57,58,56,54,55,55,55,56,58,56,58,56,56,60,59,60,59,50,54,64,65,65,64,63,61,59,56,52,50,50,52,52,53,52,53,35,26,30,28,26,23,20,17,16,14,14,12,12,11,11,9,7,6,5,4,2,1,1,0,255,254,253,252,251,250,249,249,248,248,
            247,247,247,246,246,246,245,245,245,245,245,245,246,246,247,247,248,249,250,251,251,252,253,253,253,254,254,254,255,254,254,254,254,254,254,254,254,254,254,254,254,254,254,254,254,255,255,0,255,0,0,0,0,1,2,2,3,4,6,8,8,9,11,12,14,16,18,19,20,16,32,39,45,44,43,41,28,23,23,22,21,18,16,14,8,3,0,253,251,250,250,250,250,251,252,253,254,251,251,250,
            249,246,248,247,249,251,252,251,252,251,251,252,250,251,251,251,252,253,254,255,0,1,3,4,6,7,8,10,12,12,13,13,13,13,13,13,14,15,16,16,17,17,18,19,20,22,22,23,23,23,22,24,25,26,26,28,29,29,29,28,24,21,18,18,17,15,14,14,12,10,8,5,4,3,1,255,253,251,252,251,250,251,251,252,253,255,0,2,0,252,248,248,248,248,249,0,255,247,248,249,
            249,249,249,248,248,247,246,245,244,242,240,238,235,234,231,229,227,225,223,222,220,218,217,215,215,215,214,214,214,211,211,211,210,209,210,210,211,210,209,210,211,213,215,217,220,221,222,224,226,227,228,230,233,235,239,245,248,249,251,254,1,4,7,8,8,9,11,14,16,18,20,22,24,26,28,30,32,34,36,40,44,43,43,44,47,50,53,54,55,55,55,56,56,55,53,52,53,54,54,56,
            54,57,56,54,54,61,61,60,57,50,61,64,63,62,61,60,58,54,53,51,50,52,52,53,53,43,26,30,30,28,25,23,20,18,15,13,13,12,11,11,10,9,7,5,4,3,2,1,0,0,254,253,252,251,250,250,249,248,248,247,247,247,246,246,246,246,246,246,246,246,246,246,246,246,247,247,248,249,250,250,251,252,252,253,253,253,254,254,254,254,254,254,254,254,254,254,254,254,254,254,
            254,254,254,255,255,255,0,0,0,0,0,1,1,2,2,3,4,5,7,7,8,9,10,12,14,16,17,19,19,15,33,38,45,45,45,40,27,23,24,22,19,18,16,14,9,4,0,254,253,251,251,250,250,252,254,254,254,252,250,251,250,246,247,247,248,248,249,250,251,251,251,250,251,251,251,252,252,253,255,0,0,2,3,5,6,8,9,11,12,12,12,13,13,13,13,14,15,15,16,17,
            17,18,19,20,22,23,24,25,23,22,22,23,25,27,28,30,30,31,29,27,25,22,19,20,18,17,16,15,13,11,10,10,8,4,3,2,0,255,254,255,255,254,254,255,1,1,4,3,0,252,250,250,251,251,252,253,253,251,251,252,251,251,251,250,250,250,249,248,247,245,243,241,239,237,235,233,231,229,227,225,224,222,220,219,218,218,217,217,217,214,213,212,211,211,210,211,211,210,210,211,
            212,214,215,218,222,223,221,224,226,226,226,228,230,235,241,245,248,249,250,251,254,0,3,5,4,5,7,10,12,14,15,16,18,21,25,27,30,32,34,37,40,41,41,42,43,46,49,51,52,53,53,53,54,53,52,50,51,52,51,53,53,53,54,54,52,53,59,60,59,54,52,60,61,60,58,56,54,53,51,50,50,52,52,52,51,30,27,32,30,28,25,23,20,18,16,14,13,12,11,10,
            9,7,6,4,4,3,2,1,0,0,254,253,252,250,250,249,249,248,248,247,247,246,246,246,245,246,245,245,246,246,246,246,246,247,247,247,248,249,249,250,251,251,252,252,253,253,253,253,253,253,253,253,253,253,253,254,254,254,254,254,255,255,255,255,0,0,0,0,0,0,1,1,2,2,3,4,4,6,7,7,8,9,10,12,14,16,18,20,18,16,34,36,41,46,45,38,24,24,23,22,
            19,17,16,13,9,4,0,0,254,253,252,252,251,253,255,253,252,251,251,251,247,246,247,247,248,249,249,250,250,251,251,251,251,251,252,253,254,255,0,0,2,3,4,5,7,8,10,11,12,12,13,13,13,13,14,14,15,16,17,18,18,19,20,22,23,25,26,25,24,22,22,23,25,28,29,30,31,33,30,27,25,24,22,21,20,18,18,16,15,15,14,11,9,6,6,6,3,1,0,1,
            0,0,0,0,3,4,6,4,1,254,253,252,253,254,254,254,254,254,253,253,253,253,253,253,253,252,251,250,249,248,246,245,243,240,238,236,234,232,230,228,227,225,224,222,221,220,219,219,219,216,215,214,213,212,212,212,212,211,211,212,213,214,216,218,219,220,220,223,225,225,225,226,231,236,241,244,247,248,249,251,252,255,0,1,2,3,3,6,8,9,11,13,16,19,22,24,26,28,30,33,
            36,37,37,37,39,43,46,49,50,50,51,51,51,50,50,48,49,49,48,50,50,52,52,52,52,52,53,57,58,57,51,49,50,50,51,53,54,52,51,50,50,52,52,51,43,25,32,32,31,28,25,22,20,17,16,14,12,11,11,10,8,7,6,5,3,2,1,0,0,255,254,253,253,252,251,249,249,248,248,247,246,246,246,245,245,245,245,245,245,245,246,246,247,247,247,247,248,248,249,250,
            250,251,251,252,252,252,252,253,253,253,253,253,253,253,253,254,254,254,254,255,255,255,255,0,0,0,0,1,1,1,1,2,2,3,4,4,5,6,6,7,8,9,11,13,14,16,18,19,16,18,33,35,42,44,44,33,27,24,24,23,20,18,16,12,8,5,2,0,0,0,0,0,254,253,252,252,251,249,249,247,245,247,248,248,248,249,250,250,251,251,251,251,252,252,253,254,255,0,0,2,
            2,4,5,7,8,9,11,12,12,13,13,13,13,14,14,15,16,16,17,18,19,20,21,23,24,26,27,25,23,23,23,24,25,28,29,30,33,33,29,27,26,26,24,23,23,21,19,17,19,19,15,11,13,9,9,9,6,4,4,3,3,2,2,0,5,7,8,6,3,0,255,255,0,0,0,0,0,0,255,255,255,0,255,255,255,254,253,253,252,251,249,248,245,244,242,240,238,235,234,232,
            230,228,227,226,224,223,222,222,220,219,216,215,215,214,214,213,213,213,212,213,214,215,216,217,219,220,220,222,225,225,224,227,232,236,238,241,244,246,247,250,252,253,253,253,254,0,0,2,3,4,3,4,7,10,13,17,22,24,26,28,32,32,32,33,35,38,42,46,47,47,49,49,49,48,45,45,46,47,47,47,48,50,50,50,50,50,52,53,56,55,55,53,53,54,54,53,52,51,50,50,
            50,51,51,50,39,28,34,34,31,28,25,22,20,17,15,14,12,10,10,9,8,7,5,4,3,2,1,0,0,255,254,253,253,252,252,250,249,248,248,247,246,246,245,245,245,244,244,244,244,245,245,246,246,246,247,247,247,248,249,249,250,250,251,251,251,252,252,252,252,252,252,253,253,253,253,254,254,254,255,255,255,0,0,0,0,0,0,1,1,2,2,2,3,3,4,4,5,6,7,8,
            8,9,11,12,14,16,18,19,16,19,37,40,44,44,41,30,27,25,25,23,21,19,17,13,10,6,4,2,2,3,4,2,255,255,253,251,247,246,246,245,246,246,248,248,249,250,250,251,251,251,252,253,253,254,255,0,0,1,2,3,5,5,7,8,9,10,12,13,13,13,13,14,14,14,15,15,17,17,18,19,20,21,22,23,24,26,28,26,24,24,24,24,25,28,30,32,34,33,30,28,
            28,27,26,25,25,24,23,21,22,20,21,15,15,13,11,11,8,7,5,5,3,3,3,2,5,8,9,7,3,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,255,254,253,252,251,249,247,245,243,241,239,237,235,233,232,230,229,227,225,224,225,224,221,219,218,216,215,215,214,214,214,214,214,215,216,217,218,219,220,220,220,222,223,224,227,232,235,236,239,241,241,242,245,
            248,250,251,250,251,253,254,255,0,3,3,3,5,6,10,13,17,20,22,24,26,27,28,29,32,35,38,42,45,45,46,48,47,47,43,43,42,44,44,45,48,47,49,48,48,48,49,51,53,53,53,53,53,53,53,52,51,50,49,49,49,50,50,48,34,31,35,34,31,28,24,22,20,17,15,13,12,10,9,8,7,6,5,3,2,1,1,0,0,255,255,254,253,254,253,251,250,250,248,247,
            246,246,245,245,244,244,244,244,244,244,245,245,246,246,246,247,247,248,248,249,249,250,250,251,251,251,251,251,252,252,252,252,253,253,253,254,254,254,255,255,255,255,0,0,0,0,0,1,1,1,2,2,2,3,4,4,5,6,7,7,9,9,11,13,15,16,17,19,15,20,35,42,44,45,38,30,27,26,25,23,21,19,17,15,11,7,5,4,5,5,4,2,0,255,251,248,246,247,247,246,
            246,246,247,248,250,251,252,253,253,253,253,254,255,0,0,1,2,3,4,5,6,7,8,9,10,11,12,13,13,14,14,14,15,15,16,17,17,18,19,19,20,21,22,23,25,27,28,25,25,25,25,25,26,28,31,32,34,34,30,29,29,28,27,27,26,26,27,26,26,27,26,19,19,16,13,12,10,10,6,5,4,5,5,5,6,8,9,7,5,4,3,2,2,2,2,3,3,2,2,2,
            2,1,2,2,2,1,1,1,0,0,255,253,252,250,248,246,244,242,240,238,236,235,233,232,230,228,227,228,226,223,221,219,219,217,216,216,215,215,215,216,218,217,217,218,219,219,220,220,219,220,222,226,230,233,235,237,240,241,240,243,245,246,246,247,249,250,251,255,0,3,4,1,2,5,8,10,13,15,17,19,21,23,24,27,28,31,35,38,39,40,42,45,45,45,42,39,40,40,41,42,
            43,45,46,47,46,46,47,49,51,51,52,51,52,52,51,51,50,49,49,49,48,50,49,45,29,30,34,32,31,28,25,21,19,17,15,13,12,10,9,8,6,5,4,2,2,0,0,0,0,0,255,254,253,253,253,253,252,251,250,248,247,246,245,245,244,244,244,244,244,244,244,245,245,245,246,246,247,247,247,248,248,249,249,249,250,250,250,251,251,252,252,252,252,252,253,253,254,254,254,254,
            255,255,255,0,0,0,0,1,1,1,2,2,3,3,3,4,5,6,7,8,9,10,12,13,14,16,18,19,13,22,35,43,43,40,36,27,25,24,23,22,20,19,17,15,12,8,6,5,7,6,4,1,0,254,250,249,247,247,247,246,246,248,248,249,250,252,253,254,255,255,255,0,0,0,1,3,3,4,5,6,7,8,9,10,11,12,13,14,14,14,14,15,15,15,16,17,17,18,19,20,
            21,22,23,24,26,26,25,25,26,25,25,25,27,28,31,32,34,34,31,32,30,29,29,29,29,29,29,29,29,30,29,24,22,18,16,14,13,11,7,6,6,7,7,7,6,9,10,9,8,6,5,4,4,4,4,5,5,5,4,4,4,4,5,6,6,6,4,3,2,1,0,0,255,253,251,249,247,245,243,241,239,238,236,234,233,231,230,230,228,225,223,221,220,220,218,218,217,217,217,217,
            217,218,218,219,219,219,219,219,219,219,220,222,227,231,234,237,239,239,239,240,242,244,244,245,246,247,247,249,250,252,255,0,0,1,5,7,9,12,14,16,18,20,22,24,26,29,32,34,35,36,38,42,43,43,39,37,37,38,38,40,41,42,43,44,44,43,45,46,48,49,50,50,50,50,50,50,49,48,47,47,48,49,47,41,26,30,34,32,30,28,24,22,19,17,15,13,12,10,9,8,
            6,5,4,2,1,0,0,0,0,0,255,254,253,253,252,252,252,252,251,249,248,247,246,245,245,244,244,243,244,244,244,244,245,245,246,246,246,247,247,247,247,248,248,248,249,249,250,250,250,251,251,251,252,251,252,252,253,253,253,254,254,254,254,255,0,0,0,0,1,1,2,2,3,3,4,4,5,5,6,7,8,10,11,12,14,15,16,17,13,26,35,42,36,29,30,27,24,24,23,21,
            20,18,17,14,11,9,8,8,8,6,6,2,1,255,251,250,247,247,246,246,246,247,248,250,252,253,254,255,0,0,0,1,1,2,2,3,5,5,6,7,8,9,10,11,12,12,13,14,14,14,15,15,15,16,16,17,17,18,19,20,21,22,23,25,24,24,25,25,25,25,26,26,27,28,31,32,33,32,32,32,30,31,31,33,31,30,30,30,32,32,28,26,24,19,17,16,15,12,10,8,
            9,10,10,9,9,12,12,12,12,11,8,7,7,7,8,8,8,7,7,7,7,8,9,8,8,7,7,6,4,3,2,1,0,255,254,252,250,248,246,244,242,240,238,237,235,234,233,231,230,227,226,223,223,222,221,220,219,219,220,220,218,218,219,219,219,219,218,218,218,218,219,220,223,228,233,236,236,237,237,238,239,241,242,243,243,243,244,245,245,245,247,248,251,0,4,5,8,9,11,13,
            15,17,20,22,25,27,29,30,32,33,35,38,40,40,38,36,36,35,36,37,38,39,41,41,41,40,42,44,46,47,48,48,48,49,49,48,48,47,47,47,48,48,45,34,24,30,32,31,28,26,23,21,20,17,15,13,12,10,9,7,6,5,3,2,1,1,0,0,0,255,255,254,253,252,252,252,251,251,250,250,249,247,246,245,245,244,244,244,243,244,244,244,245,245,245,246,246,246,247,247,
            247,247,248,248,248,248,249,249,249,250,250,250,251,251,251,251,252,252,252,253,253,253,254,254,255,255,0,0,0,1,1,2,2,2,3,3,4,5,6,7,8,9,11,12,14,16,17,15,15,31,38,36,33,29,27,26,24,23,22,20,19,18,16,14,11,10,9,9,7,7,4,2,1,254,251,249,248,248,248,248,248,249,249,251,253,254,0,0,1,2,2,2,2,3,3,5,5,7,7,8,
            9,10,11,12,12,14,14,15,15,15,15,15,15,16,16,17,18,18,19,21,21,22,24,24,23,24,24,25,25,26,26,26,27,28,30,32,31,32,32,32,31,31,32,32,31,31,31,31,35,36,29,27,25,19,18,18,17,13,11,11,11,12,12,12,13,15,15,15,15,14,11,11,11,11,11,11,10,10,10,11,12,11,11,11,10,9,8,7,7,5,4,3,2,0,0,255,253,251,249,247,
            245,243,241,240,238,237,235,234,233,230,228,227,225,224,224,223,222,220,221,220,219,218,219,219,219,218,218,218,218,217,218,219,221,223,229,231,233,234,236,237,237,237,239,240,240,241,241,241,241,242,245,248,253,0,1,4,5,8,10,12,13,16,19,20,23,24,27,28,30,31,33,35,37,37,34,31,33,32,34,35,35,37,38,39,38,38,40,42,46,45,46,47,47,47,47,47,47,47,46,46,
            47,46,40,26,25,31,31,30,26,25,22,21,19,16,15,13,12,10,9,7,5,4,3,2,1,1,0,0,255,255,0,255,253,252,251,251,250,250,249,249,248,248,247,246,246,245,245,244,244,244,244,244,245,245,245,246,246,246,246,246,247,247,247,247,248,248,248,248,249,249,249,250,250,250,250,251,251,252,252,252,252,253,253,254,254,254,255,0,0,1,1,1,1,2,2,3,4,4,5,7,
            8,9,10,11,14,15,16,13,15,31,38,32,28,26,23,24,25,22,22,19,18,17,15,14,13,11,11,9,7,5,3,3,1,253,251,250,249,249,248,247,248,250,251,253,0,0,1,1,1,2,4,4,4,5,5,6,7,8,9,9,10,11,12,13,13,14,15,15,15,15,15,15,15,15,16,17,18,19,19,20,22,23,23,25,23,23,23,24,25,26,26,26,27,28,30,32,31,31,34,35,
            33,33,33,33,33,33,34,33,37,35,31,29,24,21,21,21,20,16,14,13,14,14,15,16,18,19,19,18,17,15,14,14,14,15,15,15,14,14,14,15,15,14,13,12,11,11,10,9,8,7,6,5,4,2,1,0,0,254,252,250,248,246,245,242,241,239,238,237,236,234,232,230,228,227,226,225,223,222,221,221,220,219,219,218,218,218,218,218,217,217,218,219,220,221,225,227,229,233,233,235,
            235,235,236,236,237,239,239,240,239,240,243,247,251,254,0,1,3,5,8,11,12,15,17,19,21,22,24,26,28,28,32,35,36,34,30,28,30,30,32,33,34,35,36,36,36,36,38,39,42,43,44,45,45,46,45,45,45,45,46,46,46,45,33,20,27,31,29,28,26,23,22,19,17,16,14,12,12,10,8,7,6,4,3,2,1,1,0,0,0,255,255,254,254,252,251,250,250,249,249,248,
            248,248,247,246,246,245,245,244,244,244,244,244,245,245,246,246,246,246,246,246,246,247,247,247,247,247,248,248,248,248,249,249,249,250,250,250,250,251,251,251,252,252,252,253,253,254,255,255,0,0,0,0,1,1,1,2,3,4,5,6,8,8,10,11,13,15,16,13,16,32,35,29,26,27,25,26,25,21,19,18,17,17,16,15,15,14,13,11,8,6,4,1,254,252,251,249,249,249,249,248,
            249,250,252,253,0,2,3,3,2,3,4,5,5,6,6,8,8,9,10,11,12,13,13,14,15,15,16,16,15,16,15,15,15,16,16,17,17,18,19,20,21,21,23,24,22,23,23,24,24,25,26,26,27,28,29,29,31,31,32,33,35,35,35,35,35,35,35,36,36,33,30,26,22,22,23,22,22,17,16,16,16,17,17,20,23,23,22,21,19,18,17,17,17,17,18,18,18,18,19,18,
            17,15,14,14,13,13,12,11,10,9,8,7,6,4,4,3,1,0,255,253,251,249,247,246,244,242,240,239,239,236,234,232,230,229,229,227,225,223,222,221,220,220,219,218,217,218,217,217,217,217,217,218,219,220,222,225,227,231,231,233,233,234,234,233,236,237,237,238,237,239,241,244,248,250,252,255,1,3,7,10,10,13,16,16,16,19,22,24,26,27,30,33,33,30,26,26,28,28,28,31,
            32,33,33,34,34,34,35,37,39,40,41,42,43,44,43,43,43,44,45,45,44,41,23,20,29,30,29,26,24,21,20,19,17,15,13,12,11,10,8,7,6,5,3,2,1,1,0,0,255,255,254,254,253,252,251,250,248,248,248,247,247,247,247,247,246,245,245,245,245,245,245,245,245,246,246,246,246,246,246,246,246,246,246,246,247,247,247,247,248,248,248,248,249,249,249,250,250,250,251,251,
            251,251,252,252,253,253,254,255,0,0,0,0,0,0,1,1,3,4,5,6,7,9,10,12,13,15,16,12,15,30,34,27,22,23,24,26,25,21,19,18,17,17,17,16,15,15,13,11,8,6,4,0,254,253,252,251,249,250,250,249,250,250,251,253,255,0,0,1,2,3,4,6,6,7,8,9,10,11,12,12,13,14,15,15,16,16,17,16,16,16,15,15,16,15,16,17,18,18,19,20,
            21,20,23,22,23,22,23,23,24,24,25,26,26,27,28,29,31,31,32,33,35,37,36,35,35,34,34,34,31,31,27,24,23,23,24,25,24,20,18,18,19,20,21,24,26,26,25,23,23,22,21,21,20,20,21,21,22,21,21,20,19,17,16,15,15,15,14,13,12,11,10,9,8,6,5,5,3,2,0,255,253,251,250,248,246,245,243,242,241,238,236,234,232,230,228,228,228,226,225,223,
            222,221,220,217,216,216,217,217,217,217,218,218,218,219,221,222,226,230,230,231,232,232,231,233,233,235,237,238,237,238,239,242,244,245,248,253,0,2,4,8,9,12,13,12,13,16,19,22,24,26,28,29,30,27,22,23,23,25,26,28,30,30,31,32,31,31,33,36,37,37,38,40,41,42,42,42,42,43,43,44,43,35,19,23,28,29,27,24,23,21,18,18,16,14,12,12,10,9,8,7,
            5,5,3,2,1,1,0,0,0,255,254,253,253,253,251,250,249,248,248,247,247,247,247,246,246,245,245,245,245,245,246,245,246,246,246,246,246,246,246,246,246,246,246,246,246,246,247,247,247,247,248,248,248,249,249,249,250,250,250,251,251,251,251,252,252,253,253,254,255,255,0,0,0,0,1,2,3,3,5,6,7,8,9,11,12,13,15,10,15,27,34,25,20,23,26,25,23,20,19,17,
            17,17,17,17,16,15,13,11,8,8,5,0,254,253,252,251,250,249,249,249,249,250,251,252,254,255,0,1,2,3,5,6,7,7,8,10,11,12,13,14,15,15,16,17,17,17,17,17,16,16,16,15,16,16,17,17,18,19,19,19,20,20,22,21,22,22,22,23,23,24,24,25,26,27,27,27,28,31,31,33,34,34,32,31,29,28,29,30,30,28,25,24,25,25,28,28,26,22,20,21,
            21,22,23,26,31,30,28,27,25,25,24,24,23,23,24,25,25,24,23,21,19,19,18,18,17,17,15,15,14,13,12,11,9,8,7,6,5,4,3,1,0,254,252,250,249,247,246,245,243,240,238,236,234,232,231,229,227,225,224,223,223,221,219,218,217,216,217,217,217,217,219,219,219,219,222,223,226,230,230,229,229,229,229,230,231,234,235,236,236,236,238,241,243,242,246,250,255,1,3,7,
            7,8,8,9,11,14,16,19,22,25,26,25,25,20,19,20,23,23,23,24,26,28,29,30,29,30,30,34,34,35,36,37,39,40,40,40,41,42,42,43,37,24,18,25,27,27,26,23,21,20,18,16,15,14,12,11,9,8,7,6,5,4,3,2,1,0,0,0,255,254,254,253,252,252,251,250,249,248,248,247,247,246,246,246,246,245,245,245,245,246,246,246,246,246,246,246,246,245,245,245,
            245,246,246,246,246,246,246,247,247,247,247,248,248,248,249,249,250,250,250,251,251,251,251,252,252,253,253,254,254,255,255,255,0,0,0,1,2,4,5,7,8,10,10,11,12,14,13,9,19,25,32,26,22,22,23,22,21,20,18,17,17,17,16,16,16,15,13,9,9,6,2,0,254,252,251,251,250,250,250,250,250,250,251,252,253,255,0,1,3,4,5,6,7,9,10,11,12,13,14,15,
            16,16,17,18,18,18,18,18,17,17,16,16,16,16,16,17,18,17,18,19,19,19,20,20,21,21,21,22,23,23,24,25,25,26,26,26,26,29,31,32,33,33,32,31,30,28,28,29,28,26,26,26,27,29,30,29,26,23,23,23,23,24,25,28,32,33,32,29,28,28,28,27,27,27,28,28,27,25,24,22,21,20,20,20,20,18,18,17,16,14,14,12,11,10,9,8,7,6,5,3,
            2,0,255,253,251,249,248,247,244,242,240,238,236,235,233,231,229,228,226,225,224,222,220,219,217,216,216,217,217,218,221,221,220,219,222,224,226,229,229,227,226,227,227,228,230,231,230,229,229,230,233,235,239,243,246,249,253,255,1,4,4,4,6,8,10,12,14,17,19,22,24,22,18,15,17,18,19,19,20,20,23,24,26,27,27,28,28,30,32,32,34,36,39,39,39,39,40,40,41,41,
            29,18,20,25,26,25,23,21,20,19,18,15,15,13,12,10,9,8,7,5,5,4,2,1,0,0,0,255,254,254,253,253,251,251,250,249,249,248,248,247,247,247,247,246,246,246,246,246,246,246,246,246,246,246,246,246,246,245,245,245,245,245,245,246,246,246,246,246,247,247,247,247,248,248,248,249,249,250,251,251,251,251,251,252,253,253,254,254,254,254,255,255,255,0,0,1,2,4,5,6,
            7,8,10,11,13,13,10,13,21,25,30,26,23,21,21,20,19,18,18,17,17,17,16,15,14,13,10,9,8,3,0,254,252,251,251,251,250,250,249,250,250,250,251,252,253,254,0,0,2,5,6,7,8,10,11,12,14,15,16,17,17,18,18,18,19,19,18,18,18,17,17,17,17,17,17,18,17,17,17,18,18,18,19,20,20,20,21,21,22,23,24,25,25,26,26,26,26,28,30,31,
            32,31,30,29,28,27,27,27,26,26,26,27,28,30,30,29,27,25,25,25,25,26,27,30,33,33,33,32,31,31,31,31,31,31,31,30,29,26,25,23,22,22,22,22,22,21,20,19,17,16,15,14,13,12,11,10,9,8,7,6,4,2,0,255,253,251,250,248,246,244,242,240,239,237,235,233,231,230,228,227,225,224,222,220,219,217,217,217,217,217,218,219,218,217,219,222,224,225,225,223,
            223,223,223,224,224,224,224,224,226,226,229,232,235,238,242,247,250,253,255,0,1,2,4,6,8,10,12,14,17,20,21,17,11,14,15,16,16,17,17,17,19,21,23,24,25,26,26,27,29,31,32,32,35,38,39,38,37,38,38,37,22,17,22,24,24,23,21,19,18,17,16,14,14,12,11,10,8,7,6,5,4,3,2,1,0,0,255,255,254,253,253,252,251,250,250,249,248,248,248,247,
            247,247,247,246,246,246,246,246,246,245,246,245,245,246,246,246,245,246,245,245,245,245,245,245,245,246,246,246,247,247,247,248,247,248,248,249,249,250,250,251,251,251,252,252,253,253,254,254,254,255,254,255,255,0,0,1,2,4,5,6,7,8,10,11,12,11,10,19,21,26,28,26,23,20,20,19,18,18,18,17,17,17,17,15,13,12,9,7,4,1,255,254,253,252,251,251,251,250,250,250,
            250,250,250,252,253,254,0,1,2,3,5,8,9,10,12,13,15,16,17,18,18,19,20,20,20,19,19,19,19,18,18,18,18,17,17,18,17,17,17,17,17,18,18,18,19,20,21,22,23,23,24,25,25,25,26,26,27,28,28,29,31,32,31,28,27,27,28,28,28,28,28,28,29,30,30,29,27,26,27,27,28,29,30,32,34,34,35,34,34,34,34,35,34,33,33,31,29,27,25,24,
            24,24,23,24,23,23,21,20,19,18,17,16,14,13,12,12,11,10,9,8,6,4,1,0,255,254,252,250,248,246,245,243,241,239,237,235,233,232,230,229,227,226,225,223,222,220,219,218,217,217,217,217,217,216,217,218,219,220,219,219,218,219,220,221,222,222,223,224,226,227,228,229,231,233,235,239,246,251,252,254,0,1,3,5,6,8,10,12,16,19,16,10,9,11,12,13,14,14,15,15,
            16,18,19,20,22,25,25,25,26,28,29,29,31,33,36,37,36,35,32,29,20,21,23,23,22,20,18,17,16,15,15,13,11,11,10,8,7,6,5,5,4,3,2,1,0,0,255,254,253,252,252,251,250,250,249,248,248,247,247,247,247,247,246,246,246,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,246,246,247,247,247,247,247,247,248,249,249,249,250,250,
            251,251,252,253,253,254,254,254,254,254,255,255,255,0,0,1,2,3,5,6,7,8,9,10,11,9,13,20,21,26,26,24,20,19,18,18,18,17,16,17,17,16,15,14,12,10,9,4,2,0,255,254,253,252,252,251,251,250,250,250,250,250,251,252,253,255,0,1,2,4,6,7,9,11,12,14,15,16,17,18,19,20,20,21,21,21,20,21,20,20,20,19,19,19,19,18,18,17,16,16,
            17,17,18,18,19,20,21,22,23,24,24,25,25,26,26,26,26,27,28,28,29,29,29,29,28,29,30,30,29,29,29,30,30,30,31,30,28,28,29,30,31,31,32,34,36,37,36,37,37,39,38,38,37,35,33,31,30,27,26,25,25,24,24,24,24,24,22,22,21,20,18,17,16,15,14,13,12,12,12,10,8,5,4,2,0,0,254,253,250,249,247,245,243,242,239,238,236,234,232,231,
            229,228,226,225,224,223,222,222,221,220,220,219,218,218,218,219,219,218,218,219,219,220,221,221,222,223,224,225,226,228,229,230,231,232,234,236,239,244,248,251,253,0,2,4,5,7,9,13,14,14,11,6,6,8,9,10,12,12,12,12,13,14,16,17,19,21,22,24,25,26,27,27,28,29,32,34,33,30,23,21,21,23,23,22,20,18,16,14,14,13,11,12,12,9,8,7,6,5,5,4,
            4,3,2,1,0,0,255,254,253,252,251,250,250,249,249,248,247,247,246,246,246,246,246,246,245,245,245,245,245,245,245,245,245,245,245,245,245,245,244,244,244,244,244,245,245,245,246,246,246,247,247,247,247,248,248,248,248,249,249,250,250,251,252,253,253,254,254,255,255,255,255,0,0,0,0,1,2,3,4,5,6,8,9,10,11,9,14,19,21,25,23,21,19,18,17,16,16,16,16,14,
            14,15,15,13,11,9,5,2,1,0,254,254,253,253,252,251,251,250,250,250,250,251,251,252,253,254,0,1,2,4,6,8,10,11,13,14,15,16,18,19,20,20,22,22,22,22,22,22,22,21,21,21,20,20,20,19,18,17,16,16,17,17,18,19,20,20,21,22,22,23,24,24,25,26,26,27,27,28,27,27,28,29,29,28,29,31,32,32,31,31,31,31,31,32,32,31,30,30,31,32,
            33,35,36,37,38,39,39,40,41,41,40,40,37,35,34,32,31,29,27,26,25,25,25,24,25,24,24,23,22,21,20,19,17,16,15,14,14,14,14,12,9,7,6,5,3,1,0,255,253,251,249,248,246,244,242,240,238,237,235,233,232,230,228,227,226,224,224,223,223,223,222,221,221,221,220,220,221,220,221,221,222,222,222,223,224,224,225,226,227,228,229,230,231,233,235,236,238,240,242,245,
            249,253,0,1,3,4,6,7,7,8,6,3,4,6,7,8,8,9,9,9,10,11,12,14,17,18,18,21,24,25,25,24,25,26,28,30,30,27,23,21,21,22,21,19,17,16,13,12,11,10,9,8,10,9,6,6,5,4,3,3,3,2,2,1,0,0,255,254,253,251,250,250,249,248,248,247,247,246,246,246,245,245,245,245,245,245,244,244,244,244,245,244,245,244,245,245,245,244,245,244,
            244,244,244,245,245,245,245,246,246,247,247,247,247,247,247,248,249,249,249,250,250,251,251,252,253,254,254,255,255,0,0,0,0,0,1,1,2,3,4,6,7,8,9,11,10,10,13,17,21,25,22,20,18,17,16,15,14,14,14,14,12,12,13,12,10,8,4,1,0,255,254,253,253,252,251,251,250,250,250,250,250,251,251,252,253,255,0,1,2,4,6,8,10,11,13,14,16,17,18,19,
            20,21,22,23,23,23,23,23,23,22,22,22,22,21,20,19,19,18,17,17,17,17,18,19,20,20,20,21,22,23,24,25,26,27,28,27,27,27,27,29,29,29,29,29,30,32,34,33,33,32,32,32,32,33,32,32,32,32,33,34,37,37,39,39,40,41,41,42,42,41,40,39,38,36,35,33,31,30,29,27,27,26,26,25,26,25,25,24,24,23,21,20,19,18,17,16,16,15,15,13,
            11,10,8,7,5,4,2,1,255,254,252,250,248,246,244,243,241,239,237,235,234,232,231,229,228,227,226,225,224,223,223,223,222,222,222,221,222,222,222,222,223,223,223,224,225,225,226,226,228,228,229,230,232,233,234,235,237,239,241,242,244,247,251,254,0,1,2,2,3,1,0,0,2,3,4,5,5,6,7,7,7,8,9,11,13,15,16,18,21,22,22,22,22,23,23,25,25,21,21,20,
            20,20,18,17,15,13,10,9,8,7,6,6,5,5,6,3,3,3,2,1,1,1,0,0,0,255,255,254,253,251,250,249,248,247,247,247,247,246,246,245,245,245,244,245,244,244,244,244,244,244,244,244,244,245,245,245,245,244,244,244,244,244,244,245,245,245,246,246,246,247,247,247,247,247,248,248,249,249,249,250,250,251,251,252,253,254,254,255,0,0,0,0,0,0,0,1,2,3,4,6,
            7,8,9,11,11,9,13,17,21,23,22,22,20,17,16,15,14,13,13,14,13,13,13,12,10,6,2,0,0,255,254,254,253,252,251,251,250,250,249,250,250,251,252,253,254,255,0,0,2,4,6,8,10,11,13,14,16,17,18,20,21,22,22,23,24,24,24,23,23,23,23,22,22,22,22,20,19,18,18,18,18,18,18,19,19,20,20,21,22,24,25,26,27,27,26,28,28,28,29,29,
            29,29,29,30,32,34,35,35,35,33,33,33,34,34,33,33,34,34,36,37,39,40,41,42,43,43,43,42,42,41,41,40,38,38,36,34,33,32,30,29,29,27,27,27,27,26,26,26,25,24,23,22,21,20,18,18,17,17,16,15,13,12,10,9,7,6,4,2,1,0,254,253,251,249,247,245,243,242,239,238,236,235,232,232,230,229,228,227,226,225,224,224,223,223,223,223,223,223,223,223,
            223,224,224,225,225,226,227,227,228,229,230,231,232,233,235,236,238,239,240,241,242,243,245,250,255,255,0,0,1,1,255,255,0,1,2,2,3,3,4,4,5,6,7,8,10,11,12,15,17,19,19,19,19,18,15,12,16,18,19,19,19,18,17,15,13,12,10,8,6,5,4,4,3,2,2,4,2,0,0,0,0,0,0,255,255,254,254,253,252,251,250,249,248,247,246,246,246,246,245,245,
            245,244,244,244,243,244,244,243,244,244,244,244,244,245,245,245,245,245,245,244,244,244,245,245,245,246,246,246,247,247,247,247,247,247,248,248,249,249,249,249,250,250,252,253,253,254,254,255,255,0,0,0,0,0,1,2,3,4,5,6,8,9,10,11,11,11,16,19,22,23,22,21,20,19,16,14,13,13,13,13,13,12,11,10,6,3,1,0,0,255,254,254,254,253,252,251,250,250,249,249,
            250,251,252,253,254,255,0,1,2,4,6,8,10,11,13,14,16,17,19,20,21,22,23,24,24,24,24,24,24,24,23,23,23,22,22,20,20,20,19,17,17,18,19,19,20,20,21,22,23,25,26,27,27,27,29,29,29,29,29,29,30,30,30,31,33,35,36,35,35,34,33,33,33,33,34,35,36,37,39,40,40,42,43,43,44,44,43,43,42,42,41,41,40,39,37,36,35,33,32,31,
            30,29,28,28,28,27,27,27,27,26,25,24,22,21,20,19,19,19,18,17,15,14,12,11,10,8,7,5,4,2,0,255,254,251,250,248,246,244,242,240,238,237,235,234,232,231,230,229,228,227,226,225,225,224,224,224,224,224,224,224,225,225,225,226,226,227,227,228,228,229,230,231,232,234,235,236,237,239,240,241,241,242,243,245,251,254,255,0,0,255,252,253,254,255,0,0,1,1,1,2,
            3,4,5,6,7,8,10,12,15,15,15,16,18,17,13,13,17,16,15,17,15,15,13,13,11,10,9,7,5,4,3,2,1,1,0,0,0,0,255,255,254,254,254,253,253,252,252,251,250,250,249,248,247,246,246,245,245,245,245,244,244,244,243,243,243,243,243,243,243,243,244,244,244,244,245,245,245,245,245,245,245,245,245,245,245,246,246,246,247,247,247,247,247,248,248,248,248,249,249,249,
            250,250,251,252,252,253,254,254,255,0,0,0,1,2,2,3,4,5,6,7,8,9,10,11,11,12,16,18,21,22,22,22,21,18,15,13,13,12,12,12,12,12,10,6,3,1,0,0,0,255,255,254,254,254,253,252,251,250,249,250,250,251,252,253,254,255,0,1,3,5,6,8,9,11,13,14,16,18,19,20,22,23,23,25,25,25,25,24,24,24,24,24,23,23,22,22,21,20,20,20,
            22,22,21,20,20,21,22,23,24,25,26,26,27,28,29,29,29,29,29,30,30,31,32,33,34,35,36,35,35,34,33,33,34,34,36,37,39,40,41,42,43,44,44,44,44,43,43,43,43,43,43,42,41,41,39,38,37,35,34,33,32,31,29,29,29,29,28,28,27,27,26,25,24,23,22,22,21,20,19,18,17,16,15,13,12,10,9,7,6,4,3,0,0,254,252,250,248,246,244,242,
            241,239,238,236,235,233,232,231,230,228,228,227,226,226,226,225,226,226,226,226,226,226,226,227,227,228,228,229,229,230,231,232,233,234,235,236,237,239,239,240,241,242,242,243,246,252,255,0,255,252,250,251,251,252,253,254,255,255,0,0,0,1,2,3,4,6,7,8,10,11,12,14,16,17,11,9,9,11,14,16,14,13,13,12,10,9,6,5,3,2,0,0,255,254,254,254,253,254,254,253,
            253,253,252,252,251,251,250,250,249,248,248,247,246,246,245,245,245,244,244,243,243,243,243,243,243,243,242,242,243,243,243,243,244,244,244,245,245,245,245,245,245,245,246,246,246,246,246,247,247,247,247,247,247,248,248,249,249,249,249,249,250,251,252,252,252,253,253,254,255,0,0,0,1,2,2,3,4,5,6,7,8,9,10,11,10,12,15,18,21,21,21,21,19,17,14,13,13,12,11,11,
            11,9,6,4,2,1,0,0,255,255,255,255,254,254,253,253,252,251,250,250,250,251,252,253,254,255,0,1,3,5,7,8,10,11,13,14,16,17,19,20,22,23,24,25,26,25,25,25,25,24,24,23,23,23,22,22,22,21,21,20,19,20,21,21,21,22,23,24,24,25,26,28,28,28,28,28,29,30,30,30,31,31,33,33,34,34,34,34,34,34,33,33,34,36,38,40,41,43,44,44,
            45,46,44,44,44,44,44,44,44,44,44,43,43,42,41,39,38,37,36,35,33,32,31,30,30,29,30,29,28,28,27,26,26,25,24,24,23,21,21,20,19,17,17,15,13,12,11,9,8,6,4,3,1,0,254,253,250,248,247,245,243,241,240,238,237,235,234,233,232,231,229,229,228,227,227,227,227,226,227,227,227,227,227,228,228,229,229,230,230,231,231,233,233,234,235,236,237,239,239,240,
            241,242,242,242,243,248,252,253,252,249,248,249,250,250,251,252,253,253,253,254,254,255,0,1,2,3,4,5,7,8,10,14,15,12,10,11,13,14,15,14,10,10,9,10,9,7,3,1,0,255,253,253,252,252,251,251,252,251,252,253,252,251,250,250,250,250,249,249,248,247,247,246,246,245,245,245,244,244,243,243,243,242,242,243,242,243,242,243,243,243,243,243,244,244,244,245,245,245,246,246,
            246,246,246,246,246,246,247,247,247,247,247,247,248,248,248,248,249,249,250,250,251,251,252,252,253,253,253,254,254,255,0,0,1,2,2,3,4,5,6,7,8,9,10,10,11,12,15,18,20,21,20,19,17,16,14,13,12,12,11,11,10,7,6,3,1,1,0,0,255,255,255,255,254,254,253,253,252,251,251,251,251,251,252,253,254,255,0,1,3,5,7,9,10,12,13,14,16,18,19,20,
            22,23,24,25,26,26,25,26,25,25,25,25,24,24,23,23,22,22,22,22,21,21,21,21,21,22,23,24,24,25,27,27,27,28,28,28,29,30,31,31,32,32,33,33,33,34,34,33,33,33,34,34,35,38,40,42,44,46,46,46,46,46,45,45,45,45,44,45,45,45,44,44,43,43,42,41,40,39,37,36,34,33,32,31,31,30,30,30,30,29,29,28,28,27,26,25,24,23,22,21,
            20,19,18,16,15,14,12,11,9,8,6,4,3,1,0,254,252,251,249,247,245,243,242,240,239,237,236,235,234,232,231,230,230,229,229,228,228,228,228,228,228,228,228,229,229,230,230,230,230,231,232,233,234,235,235,237,237,238,239,240,240,241,241,242,242,244,247,249,248,246,248,248,248,248,249,250,250,251,251,251,252,253,254,0,0,0,1,0,3,8,11,11,10,10,10,11,11,11,10,10,
            3,3,2,2,2,0,0,254,253,252,251,250,249,249,249,248,248,249,249,249,249,249,250,249,248,248,248,248,247,246,246,245,245,245,244,244,243,242,242,242,242,242,241,241,241,242,242,242,243,242,243,244,244,244,244,245,245,246,246,246,246,246,246,247,247,247,247,247,247,247,248,247,248,248,248,249,249,249,250,250,251,251,251,252,253,253,254,254,255,255,0,0,1,2,2,3,4,5,6,6,
            7,9,11,11,11,13,16,18,20,19,18,18,17,15,13,12,12,12,11,11,9,5,4,2,1,0,0,0,255,254,254,254,253,253,253,253,253,252,252,252,252,252,253,253,254,255,0,1,3,5,7,9,10,12,13,15,16,18,19,21,22,23,24,25,26,26,27,26,26,26,26,25,25,25,25,24,23,23,22,21,21,21,21,21,22,22,23,23,25,26,26,27,27,27,28,28,29,30,31,32,
            32,33,33,33,33,33,33,33,33,33,34,35,38,40,42,44,46,47,47,46,46,46,46,45,45,46,46,46,45,44,45,45,44,44,43,42,41,40,39,37,36,34,33,32,32,32,32,31,31,31,31,30,29,28,27,27,26,25,24,23,22,20,19,18,16,15,13,12,11,9,8,6,4,3,1,0,254,252,251,249,247,246,244,243,241,239,238,237,236,234,233,233,232,231,230,230,230,230,230,230,
            229,229,229,230,230,230,231,231,231,232,233,233,234,234,236,237,238,239,239,240,240,240,241,241,242,242,243,244,244,245,245,245,246,246,247,248,248,249,249,249,251,251,253,253,255,254,252,0,7,8,9,7,5,5,5,6,6,5,5,4,255,254,254,253,253,252,251,250,249,249,248,248,247,247,246,247,246,246,247,247,247,247,247,248,248,247,247,246,245,245,245,245,244,244,244,243,242,242,241,241,
            241,241,241,241,241,241,242,242,242,243,243,243,244,244,244,245,245,246,246,246,246,247,246,247,247,247,248,247,248,247,248,248,247,248,248,248,248,249,249,250,251,252,251,252,252,253,253,254,254,255,0,0,0,1,2,2,4,4,5,6,8,8,10,11,12,14,17,18,19,18,17,16,15,13,12,12,11,11,11,10,9,6,5,3,1,0,0,0,254,254,253,253,253,253,253,253,253,252,252,252,
            253,253,253,254,255,0,0,2,3,5,7,9,10,12,13,15,16,17,19,20,22,23,24,25,26,27,27,27,27,27,26,25,25,25,24,24,23,23,23,23,23,22,21,21,22,22,23,24,24,25,25,26,27,27,28,28,29,30,31,32,32,32,33,33,33,33,33,33,33,34,35,38,40,42,44,46,46,46,46,45,45,45,46,46,44,46,47,46,45,48,51,50,47,45,43,43,43,41,40,39,
            37,35,35,34,33,33,33,34,34,34,33,32,31,30,29,29,27,26,25,24,23,22,21,19,18,16,15,14,12,10,9,7,6,4,3,1,0,255,253,251,249,248,246,245,243,242,240,239,238,236,235,234,233,233,232,231,231,231,231,231,231,231,230,231,231,231,231,232,232,232,233,234,235,235,236,237,238,239,239,240,239,240,241,241,241,242,243,243,244,244,244,244,245,245,246,246,246,247,247,248,
            249,249,251,252,253,251,255,5,5,5,6,3,0,0,1,1,1,0,0,0,252,251,249,249,248,247,247,247,247,246,246,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,245,244,244,243,243,243,243,242,242,242,241,241,240,240,240,241,241,241,241,242,242,242,242,243,243,244,245,245,245,246,246,246,246,247,247,247,247,247,247,248,247,248,247,247,248,247,248,248,248,249,249,250,
            250,250,251,251,251,252,252,253,254,255,0,0,0,0,1,2,3,4,5,6,7,8,9,9,11,14,17,18,18,17,17,16,14,12,12,11,11,11,11,11,9,7,6,4,2,1,0,255,255,254,253,253,253,253,253,253,254,254,254,253,254,254,254,255,0,0,1,3,4,6,7,9,11,12,13,15,16,18,19,20,22,22,24,25,26,26,27,28,28,28,28,27,27,26,25,25,24,24,23,23,
            23,22,22,22,22,22,24,25,26,25,26,26,26,27,27,28,29,30,31,31,32,32,33,33,33,33,33,34,34,35,37,40,41,43,45,45,45,45,45,44,45,44,43,43,44,45,46,46,44,45,46,47,48,45,44,44,42,42,40,39,38,37,36,35,35,34,37,38,38,37,36,35,34,32,31,30,28,28,27,25,24,23,22,20,19,18,16,15,13,12,10,8,7,5,4,2,1,0,255,253,
            251,250,248,247,245,244,242,241,240,239,237,236,235,234,234,233,232,232,232,232,232,231,231,232,231,232,232,232,233,233,234,234,235,236,237,237,238,239,239,240,239,240,240,240,241,241,242,244,244,244,243,243,243,244,244,244,244,245,245,246,247,248,250,251,250,249,253,0,1,0,0,255,255,254,255,255,254,254,254,253,252,249,246,245,244,244,244,243,243,243,243,243,243,243,243,243,243,243,243,243,
            242,243,243,243,244,244,244,244,243,243,243,243,243,242,242,241,241,241,241,240,240,240,240,239,240,240,241,241,242,242,242,243,244,244,245,245,245,245,246,246,247,247,247,247,247,248,247,248,247,247,247,247,247,247,248,247,248,248,248,249,249,250,250,250,250,251,252,253,254,255,0,0,0,0,1,1,2,3,4,5,6,7,7,7,11,15,15,17,17,16,16,16,13,12,11,11,11,11,11,11,
            10,8,7,5,4,3,1,0,0,255,255,255,254,254,254,255,255,0,0,0,0,0,0,0,1,2,3,5,6,7,9,10,11,13,14,15,17,18,19,20,21,22,23,24,25,26,26,27,27,28,28,27,27,26,26,25,25,24,23,23,23,23,23,22,23,23,24,24,25,25,26,26,26,27,28,29,30,30,31,32,32,32,32,33,33,33,34,34,35,37,39,40,41,43,43,44,44,43,43,43,
            44,44,44,43,44,44,45,46,45,45,45,45,44,44,44,43,42,42,40,40,38,38,37,36,36,37,39,40,40,40,39,37,35,34,32,31,30,29,28,27,26,24,23,22,20,19,17,16,14,13,11,10,8,7,5,4,3,1,0,254,253,251,250,249,247,246,244,243,241,240,239,238,237,236,235,234,234,233,233,233,232,233,232,232,232,232,233,233,233,234,234,235,235,236,237,237,238,239,239,240,
            239,239,240,240,241,241,241,242,243,243,243,242,242,242,242,242,243,243,244,244,245,247,248,249,247,247,251,254,253,252,252,251,251,252,252,252,252,252,252,253,248,246,243,242,241,240,240,240,240,240,241,241,240,241,241,241,241,241,241,241,241,241,240,241,241,242,242,242,243,242,242,241,241,241,241,240,240,240,240,240,240,240,240,240,239,239,240,240,241,241,242,242,243,244,245,245,246,246,246,247,
            246,247,247,247,247,248,247,248,247,247,247,247,247,247,247,247,247,248,248,248,249,250,250,250,250,251,251,252,253,254,254,255,0,0,0,1,2,3,3,5,5,6,6,5,11,15,15,16,16,15,13,13,12,11,11,10,11,11,11,11,11,10,10,9,7,6,3,1,0,0,0,0,0,0,0,0,1,2,2,2,2,1,2,3,4,4,5,5,6,7,7,9,11,13,14,15,16,18,19,20,
            21,22,22,23,24,25,26,27,27,27,28,28,27,27,26,26,25,25,25,24,24,23,23,24,24,25,25,25,25,24,25,26,27,28,28,29,29,30,31,32,33,33,33,34,34,34,35,36,37,38,38,40,41,42,42,42,43,43,43,43,44,44,44,44,44,44,45,45,45,45,45,45,44,44,43,43,43,41,41,40,38,38,38,38,37,37,38,39,41,41,40,38,36,34,33,33,32,30,29,28,
            27,25,24,22,21,20,18,17,15,14,12,11,9,8,6,5,3,1,0,255,254,253,251,250,248,247,245,244,243,242,240,239,239,238,237,236,235,234,234,234,234,233,233,233,233,233,233,233,234,234,235,235,236,236,237,237,238,239,239,240,239,239,239,240,240,240,240,240,240,241,243,242,241,241,241,241,241,241,242,243,244,245,246,246,247,248,249,250,250,249,249,249,250,250,250,250,249,248,248,248,
            241,240,240,242,240,238,237,238,238,238,238,238,238,238,238,239,239,239,239,239,238,239,239,239,239,239,240,240,241,241,241,241,240,240,240,240,240,239,239,239,240,240,240,239,239,239,239,239,240,241,241,242,243,243,244,245,245,245,246,246,247,247,247,247,247,247,247,247,247,247,246,246,246,246,247,246,247,247,248,248,248,249,250,250,250,250,251,251,252,253,254,254,255,0,0,0,1,3,3,4,
            5,6,5,6,10,15,15,15,14,13,12,11,11,10,11,11,12,13,13,13,13,12,12,10,9,8,6,5,3,3,3,3,2,2,3,4,5,6,7,5,4,3,3,4,5,6,7,7,8,10,11,11,12,14,15,16,17,18,19,20,21,21,22,23,23,25,26,26,26,27,27,27,27,27,27,27,27,26,26,25,25,25,25,25,25,25,25,25,25,24,26,27,26,27,28,30,31,31,32,33,
            33,34,34,35,35,35,36,37,38,38,38,40,41,40,41,41,42,43,43,44,45,45,45,45,44,45,46,45,45,45,45,44,44,44,43,43,42,41,40,39,38,37,37,36,39,40,39,37,39,41,40,37,37,36,35,33,32,31,30,28,28,26,25,23,22,20,19,17,15,14,13,11,10,8,7,5,4,2,1,0,255,253,252,251,249,248,247,245,244,243,242,241,240,239,238,237,236,236,235,235,
            235,234,234,234,233,233,234,233,234,235,235,235,236,236,237,237,238,238,239,239,238,239,239,239,240,240,239,239,240,240,240,240,240,240,240,240,240,240,241,242,243,244,244,246,247,245,247,248,247,247,247,247,247,247,246,245,244,244,243,242,236,236,236,236,235,235,234,235,235,236,236,236,236,236,236,236,236,237,237,237,237,237,237,237,238,237,238,238,238,239,239,239,240,240,239,239,239,239,239,238,
            238,239,239,238,239,239,239,239,239,240,241,241,242,243,243,244,245,245,246,246,247,247,247,247,247,247,247,246,246,246,246,246,246,246,246,246,246,247,247,247,248,249,249,250,249,249,250,250,251,252,253,254,254,255,0,0,1,2,2,4,5,5,5,5,9,14,14,14,13,11,10,10,10,10,11,12,13,13,12,12,13,13,13,12,11,10,10,9,7,6,5,5,6,6,5,6,8,9,8,7,
            7,6,7,8,8,8,10,11,11,12,12,13,14,15,17,17,17,18,19,19,20,21,22,23,24,24,25,26,26,26,27,27,28,28,28,28,27,27,27,27,26,26,25,25,25,25,25,25,26,26,26,27,27,29,31,31,32,32,33,34,34,34,34,35,36,36,37,38,37,36,38,40,39,40,40,41,42,43,44,46,46,46,47,47,47,47,46,46,45,44,44,44,44,44,43,42,41,41,40,39,
            38,38,38,36,36,36,37,37,36,35,37,38,38,38,37,36,34,31,30,29,28,26,25,24,22,21,19,18,16,15,13,12,10,9,8,6,5,3,2,0,0,254,253,252,250,249,248,246,245,244,243,242,241,240,239,238,237,236,236,236,235,235,234,234,233,233,233,233,234,235,235,235,236,236,236,237,237,237,239,238,238,239,239,239,240,240,239,238,239,239,239,239,239,239,239,239,239,239,240,241,
            242,244,245,245,243,242,245,246,245,245,244,244,244,243,242,241,239,238,237,236,233,232,232,231,231,231,231,232,232,233,233,233,233,233,233,234,234,234,235,235,235,236,236,235,235,236,236,237,237,237,237,238,238,238,239,239,239,238,239,239,239,238,238,237,238,238,238,238,239,239,240,241,241,242,243,244,244,245,245,246,246,246,247,247,246,246,246,246,246,245,246,245,245,246,246,246,246,246,247,247,
            247,248,248,249,249,249,250,250,250,251,252,252,253,254,255,0,0,1,2,3,4,5,5,4,8,13,13,12,12,10,9,10,10,11,11,12,13,14,14,14,12,12,12,12,11,10,9,8,7,6,5,5,4,4,4,5,5,5,6,5,6,7,9,11,11,11,13,15,15,15,16,15,15,16,17,18,18,19,19,20,21,21,22,22,23,24,24,25,25,26,26,27,27,28,28,28,28,28,27,27,
            27,26,26,26,26,26,26,26,26,26,27,28,30,31,32,32,33,34,35,35,36,36,37,37,37,37,37,38,37,36,39,39,38,39,40,41,43,44,45,46,47,49,49,48,48,47,47,47,46,45,44,44,43,43,43,43,42,40,40,39,37,37,37,36,36,36,35,35,35,34,35,37,38,38,38,37,36,33,31,29,28,27,25,24,22,21,19,18,16,15,14,12,11,9,8,6,5,4,2,1,
            0,255,254,252,251,250,249,247,246,245,244,243,242,240,240,238,238,237,237,236,236,235,234,234,234,233,233,233,234,234,235,235,235,235,236,236,237,237,237,238,238,238,238,239,239,239,238,238,238,238,238,238,238,238,238,238,238,239,238,239,242,241,240,240,239,240,241,241,241,242,241,241,240,239,238,236,234,233,233,233,229,229,228,228,228,228,229,229,229,230,230,230,230,231,231,231,232,232,233,233,
            234,234,234,235,235,235,234,235,236,236,236,237,237,237,237,238,238,238,239,239,239,239,239,238,237,236,237,238,239,239,240,241,241,242,243,243,244,244,245,245,245,246,246,246,246,246,246,245,245,245,245,245,245,245,245,245,245,245,246,247,247,248,248,249,249,249,249,249,250,251,251,252,252,253,254,255,0,0,1,3,3,4,5,4,5,9,13,13,12,10,9,11,12,12,12,13,13,12,12,11,
            10,10,10,9,8,6,5,5,6,6,5,4,5,8,6,5,4,6,7,4,3,3,6,10,12,12,14,15,15,16,16,16,16,16,16,18,19,20,20,20,21,21,22,22,23,23,24,24,25,25,26,27,27,27,27,28,28,28,28,27,27,27,27,27,27,27,26,27,27,27,27,28,29,30,31,32,32,33,34,35,36,36,37,37,38,38,38,37,36,37,37,37,38,38,40,41,42,44,45,46,
            47,49,48,48,51,50,46,46,47,45,44,44,43,42,42,41,41,41,39,38,37,37,37,35,35,35,35,35,35,35,34,35,37,39,39,37,35,34,32,30,28,26,24,23,22,20,19,17,16,15,14,12,11,9,8,7,5,4,3,1,0,0,254,253,252,250,249,248,247,246,244,243,242,241,240,239,238,238,236,236,236,235,235,234,233,233,233,233,234,234,234,234,235,234,235,235,235,236,236,236,
            237,237,238,238,238,239,238,237,237,237,237,237,237,237,237,237,237,237,237,238,239,238,238,237,236,236,238,238,238,239,238,237,237,236,234,231,231,230,230,229,226,225,225,225,225,225,226,226,226,226,227,228,228,228,229,229,230,230,230,231,232,232,233,233,233,234,234,234,234,234,235,235,236,236,236,236,237,237,238,238,239,239,239,240,240,240,239,238,238,239,239,240,241,241,242,243,243,244,244,245,
            245,245,245,245,245,245,245,244,245,245,244,245,244,244,245,245,245,245,245,246,247,247,248,248,248,248,248,249,250,250,251,252,252,253,253,254,255,0,0,2,3,4,5,5,4,4,9,13,12,11,11,12,12,12,11,11,9,9,10,11,11,10,10,9,7,6,8,12,13,12,11,11,12,15,14,14,13,14,16,16,15,16,18,19,16,13,12,13,13,13,14,14,14,14,15,17,19,20,21,21,
            21,21,22,22,22,23,23,24,24,25,25,25,26,26,27,27,27,28,28,27,27,27,27,27,28,28,27,27,26,26,27,27,28,28,28,29,30,31,32,32,33,35,35,36,36,36,36,35,35,36,36,36,37,38,39,40,41,43,44,45,46,47,48,46,46,46,45,45,45,44,43,44,45,43,41,40,40,40,38,38,38,37,36,35,35,34,34,35,35,35,34,35,35,35,35,35,34,32,30,27,
            25,24,24,22,21,20,18,17,16,15,13,12,11,9,8,7,6,5,3,2,0,0,0,253,252,251,250,248,247,246,245,244,243,241,240,239,238,237,237,236,235,235,234,234,233,233,233,232,233,233,233,233,234,234,234,234,234,234,235,234,236,237,237,238,238,238,238,237,236,236,236,236,236,236,236,235,235,235,236,237,235,235,235,233,233,234,235,234,235,234,233,233,231,230,228,227,227,227,226,226,
            223,223,222,222,222,223,223,224,224,224,225,225,225,226,226,227,227,227,228,229,230,230,231,231,232,232,233,233,234,234,234,234,234,234,235,235,236,236,236,237,237,238,238,239,239,239,239,239,239,239,239,240,240,241,242,242,242,243,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,245,245,245,246,247,247,247,247,246,246,247,249,250,251,251,252,252,253,254,254,255,0,0,
            2,3,4,5,5,3,4,7,10,12,13,14,14,14,13,11,11,12,13,15,17,17,18,18,16,15,13,14,14,14,15,15,16,16,15,16,16,15,16,16,16,17,18,21,22,21,16,12,11,11,9,7,5,4,7,14,19,20,21,21,21,21,21,21,22,22,23,23,23,24,24,25,25,26,26,26,27,27,27,27,27,26,25,26,27,26,26,26,25,25,25,24,25,25,26,27,28,29,30,31,
            32,32,32,33,33,34,33,33,33,34,34,36,36,37,38,39,40,41,42,44,44,45,44,45,44,44,44,44,43,42,41,41,41,40,40,39,39,38,38,37,36,36,36,35,35,34,34,34,34,34,34,35,34,34,34,33,33,31,29,28,26,23,21,21,19,19,18,17,15,14,13,12,11,9,8,7,5,4,3,1,1,0,255,254,252,251,250,249,247,246,245,244,242,241,240,239,238,237,236,236,
            235,235,234,234,232,233,232,232,233,232,232,233,232,232,233,232,233,233,233,234,234,235,236,236,237,237,237,236,235,235,235,235,235,235,235,234,233,234,235,234,231,231,231,230,229,230,231,231,230,229,227,225,224,224,223,224,224,224,223,223,220,219,219,220,220,220,220,221,221,222,222,222,223,224,224,225,225,226,227,227,227,228,229,230,230,230,231,231,232,232,233,233,234,234,234,234,234,234,235,235,
            236,236,237,237,237,237,237,238,238,238,238,238,239,239,239,239,240,241,241,242,243,243,243,243,243,243,243,243,243,243,242,243,243,243,243,243,243,244,245,245,245,245,246,246,246,246,246,247,248,248,249,250,250,251,252,253,254,254,255,0,1,2,3,4,4,5,5,4,4,7,11,14,15,15,14,13,14,15,16,17,17,18,18,18,18,18,18,17,17,17,17,18,18,18,17,17,17,17,17,18,
            19,19,19,19,20,20,21,21,22,22,21,21,21,15,5,6,14,19,20,20,21,20,20,21,21,22,22,22,22,22,23,23,24,25,25,25,25,25,25,25,25,24,24,24,24,24,23,22,22,22,22,22,23,23,24,25,26,27,28,29,29,30,31,31,31,31,31,31,32,32,33,35,35,36,37,37,38,39,40,41,41,42,42,42,41,42,42,41,41,40,39,39,39,39,38,38,38,37,37,36,
            36,36,35,35,35,35,36,35,34,34,33,33,33,33,32,31,30,30,29,27,25,22,20,20,19,18,17,16,15,14,13,12,11,9,8,7,5,4,3,1,0,0,255,253,252,251,249,248,247,246,244,243,242,241,240,239,238,238,236,236,235,234,234,233,232,232,232,231,231,231,231,231,231,231,231,231,231,231,231,232,233,233,234,234,234,235,235,235,235,234,234,234,234,234,233,233,233,231,231,231,
            230,229,228,226,225,225,226,225,224,223,222,221,220,220,220,220,220,220,220,220,217,217,217,217,217,217,217,218,218,219,220,220,221,221,222,222,223,224,225,225,226,226,227,227,228,229,229,230,230,230,231,231,232,232,232,233,232,232,233,233,233,234,235,235,236,235,235,236,236,236,237,237,237,237,238,238,238,239,239,238,239,239,239,240,240,240,240,240,240,241,241,241,241,241,241,242,243,243,244,244,
            244,245,245,245,246,246,246,247,248,248,248,249,250,250,251,252,253,254,255,0,0,1,1,2,3,4,5,7,7,7,8,9,12,13,13,14,15,16,17,18,19,19,20,20,20,19,19,19,19,18,18,19,19,19,19,19,19,18,18,19,19,19,20,20,21,21,22,22,22,23,23,22,22,21,16,8,9,16,19,19,20,20,20,20,21,21,21,21,22,22,22,23,24,24,25,25,24,24,23,23,
            23,22,22,22,22,21,20,20,20,20,20,21,21,21,22,23,25,26,26,27,28,28,29,29,29,29,29,30,31,31,32,33,34,34,35,36,36,37,38,39,39,39,39,39,39,40,39,39,39,38,38,37,37,36,36,36,36,36,36,35,35,35,34,35,35,35,35,35,34,34,34,34,33,32,31,31,31,29,29,28,26,24,21,19,18,17,17,16,15,14,13,11,10,9,8,6,5,4,2,1,
            0,255,254,253,252,250,249,249,247,246,245,243,242,241,240,239,238,237,236,235,235,234,233,233,232,232,231,230,231,230,230,230,230,229,230,230,230,230,230,230,230,231,232,232,233,233,233,234,233,233,233,233,232,232,232,232,231,227,228,230,228,227,227,225,223,222,221,221,220,219,218,218,218,217,217,217,217,217,217,217,214,214,214,215,215,215,215,216,216,217,217,218,219,219,219,220,221,222,223,223,
            224,224,225,226,226,227,227,228,229,229,229,230,230,230,230,230,230,231,231,231,231,231,231,232,232,233,233,234,234,235,235,235,236,236,236,236,236,236,237,238,238,238,238,238,237,237,238,238,238,238,238,239,239,240,240,240,241,242,242,243,243,243,244,244,245,246,246,246,247,248,249,249,249,250,251,251,252,254,254,255,0,0,0,1,3,4,5,6,8,10,11,12,13,14,15,16,16,17,18,19,
            20,20,21,20,20,20,20,20,20,19,19,19,20,20,20,20,20,20,20,19,19,20,20,20,21,21,22,22,23,23,24,24,23,22,20,15,10,14,19,19,20,20,19,19,20,19,19,19,19,19,19,20,21,21,21,21,21,21,21,21,21,21,20,20,19,19,19,19,19,19,19,20,20,21,22,22,23,24,25,26,26,27,28,27,28,29,29,29,30,31,32,32,32,33,34,34,35,35,36,36,
            37,38,37,37,38,38,38,38,37,37,37,36,35,35,35,35,35,35,35,35,34,34,34,34,33,32,31,31,31,31,30,30,30,30,30,30,30,29,28,27,26,25,24,21,18,17,16,15,14,12,11,11,9,8,7,6,5,3,1,0,0,254,254,252,251,250,249,248,247,245,244,243,242,241,240,238,238,237,236,235,234,233,233,232,231,231,230,229,229,229,228,229,228,228,228,228,228,228,228,229,
            228,229,230,230,231,231,230,232,232,231,232,231,231,230,230,230,229,226,226,228,227,225,224,222,221,220,219,218,217,216,215,215,215,214,214,214,215,214,214,214,212,212,213,213,213,214,214,214,215,215,216,216,216,217,218,218,219,219,220,221,222,223,223,224,224,225,226,226,226,227,227,227,228,228,228,228,229,229,229,229,229,229,230,230,230,230,231,231,231,232,232,233,233,234,234,234,235,235,235,235,
            236,236,236,236,236,236,235,235,235,236,237,238,238,238,238,239,240,240,241,241,241,242,243,243,244,245,245,245,246,246,247,248,248,249,250,251,251,252,253,254,255,255,0,1,2,3,4,6,7,9,11,12,14,15,16,17,17,18,19,20,20,19,20,21,21,21,21,21,21,21,21,20,20,21,21,21,21,20,19,19,20,21,20,21,21,22,22,23,23,24,24,24,23,23,20,14,11,14,19,20,
            20,21,21,21,21,21,20,20,20,20,20,19,19,18,18,18,18,18,18,18,18,18,17,18,18,17,18,18,18,19,19,20,21,22,22,23,24,24,25,25,25,26,27,27,27,27,28,29,29,30,30,30,31,32,32,33,33,34,34,35,35,36,36,36,36,36,36,36,35,35,34,34,34,34,34,34,34,34,33,34,34,33,32,32,31,31,32,32,32,31,30,29,29,29,30,30,30,29,29,27,
            26,25,24,23,20,17,14,13,12,11,11,9,8,7,6,5,3,2,1,0,255,254,253,251,250,250,249,247,246,245,244,243,242,240,239,238,237,236,235,235,233,232,232,231,230,230,229,228,228,227,227,227,226,226,227,226,226,226,226,226,227,227,227,228,228,228,229,229,229,230,230,230,230,229,228,228,228,227,224,223,223,223,222,220,219,218,217,216,216,214,213,213,212,212,212,212,212,212,212,212,
            211,211,211,211,212,212,213,213,213,214,214,214,215,215,216,216,217,218,218,219,220,220,221,222,222,222,223,224,224,224,225,225,225,226,226,226,226,226,227,227,227,227,227,227,228,228,228,229,230,230,230,230,231,231,231,232,233,233,233,233,233,234,234,234,234,234,234,235,235,234,234,235,235,237,237,237,237,238,238,239,240,241,241,242,243,243,244,244,245,245,246,247,247,248,249,250,251,251,251,253,
            253,254,0,0,1,2,4,6,7,8,9,12,13,15,16,17,17,18,18,19,21,21,21,20,20,21,21,22,22,20,20,20,19,19,18,17,17,18,19,20,21,21,20,21,22,23,23,23,24,24,24,23,22,18,13,11,14,18,20,20,21,21,21,20,20,19,19,18,17,17,17,16,16,16,16,16,15,15,16,15,15,16,16,16,16,16,17,18,18,19,20,21,22,23,23,23,24,25,25,25,
            26,26,26,26,26,27,28,28,29,29,29,29,30,30,31,32,32,32,33,33,34,35,35,35,35,35,35,34,34,33,33,33,33,33,33,32,32,33,33,33,33,32,32,31,31,31,31,31,30,30,29,28,28,27,27,27,28,27,26,26,25,25,24,23,20,18,14,12,11,10,9,8,7,5,4,3,2,0,0,255,254,253,252,250,250,249,248,247,246,244,243,242,240,240,239,237,236,235,234,233,
            232,231,231,230,229,229,228,227,227,226,226,225,224,224,224,224,224,224,224,224,224,224,224,225,225,225,226,226,226,227,228,228,228,228,227,226,226,227,225,224,223,221,220,219,218,217,216,215,214,213,212,212,211,211,210,210,210,210,210,210,209,210,210,210,211,211,211,211,212,212,213,214,214,214,214,215,216,216,217,217,218,218,219,219,220,221,221,222,222,222,222,223,223,223,223,223,223,224,224,224,
            224,225,225,225,225,226,226,227,227,227,228,229,229,229,229,229,229,230,230,231,232,232,232,232,233,233,233,233,233,233,234,234,235,235,236,236,236,237,238,238,239,239,240,240,241,242,242,243,244,244,245,245,246,247,248,248,249,250,251,252,253,254,255,0,0,2,3,5,7,8,9,11,12,14,16,17,18,19,19,17,16,16,16,17,20,23,24,24,23,21,21,21,21,24,25,25,25,23,21,19,
            19,20,21,21,22,22,22,22,22,22,22,22,21,18,17,16,17,17,17,18,18,18,17,17,17,16,16,16,16,15,15,15,15,15,14,14,14,14,14,14,14,14,15,15,16,17,17,18,19,20,20,22,23,23,24,24,24,24,25,25,25,25,25,25,26,26,26,27,28,28,28,28,28,29,29,30,31,31,32,32,33,33,33,33,33,33,33,32,32,32,32,32,32,32,32,32,32,31,32,32,
            32,32,31,31,30,30,30,29,29,29,28,27,27,26,26,25,25,25,24,24,23,23,22,21,19,17,15,12,11,10,8,6,5,3,2,1,0,0,255,253,252,251,250,249,249,248,247,245,244,243,242,241,240,238,237,236,235,233,233,232,231,230,229,228,227,227,226,226,225,224,223,223,223,222,222,221,222,222,221,222,222,222,222,222,223,223,223,224,224,223,224,225,226,226,225,224,225,224,224,223,
            222,221,220,219,218,217,216,214,212,211,211,211,210,209,209,209,209,209,209,209,208,209,209,208,209,209,210,211,211,211,212,212,212,213,214,214,215,215,215,216,216,217,217,218,218,218,219,219,219,220,220,220,220,220,221,221,221,221,221,221,222,222,222,223,223,223,224,224,225,225,225,226,226,226,227,227,227,227,228,228,229,230,230,231,231,231,231,231,232,232,233,233,233,234,234,235,235,236,236,237,
            237,238,238,239,240,241,241,242,242,243,244,245,245,246,247,248,248,249,250,251,252,254,255,0,1,2,3,5,6,7,8,10,12,13,14,15,15,14,14,15,18,20,21,22,23,23,23,22,21,21,21,20,20,21,22,22,22,22,21,20,19,19,20,20,20,20,20,20,20,20,19,19,19,18,18,18,17,17,17,17,16,15,14,14,14,13,13,13,13,13,13,13,13,13,13,13,13,13,13,13,
            13,14,15,15,15,17,18,19,20,20,21,22,23,23,24,24,24,24,24,24,24,24,24,24,24,24,24,25,25,26,26,26,26,27,27,28,29,30,31,31,32,32,32,32,32,32,32,32,31,31,31,31,31,31,31,31,31,31,31,31,31,30,30,30,29,28,28,28,28,28,27,26,26,25,25,24,24,22,21,21,21,21,20,18,17,15,13,11,10,9,7,6,4,2,1,0,0,255,253,252,
            251,249,249,248,248,247,245,244,243,242,241,239,238,236,235,233,233,232,231,230,228,228,227,226,225,225,224,223,223,222,221,221,221,220,220,220,219,219,218,218,218,219,220,220,220,220,220,220,221,221,221,221,222,223,223,222,222,222,222,221,221,220,219,219,218,217,216,216,214,212,210,210,209,208,208,208,208,208,208,208,207,207,208,207,208,208,208,209,210,210,210,211,211,212,212,212,213,213,214,214,
            214,214,215,215,215,216,216,217,217,217,217,218,218,218,218,218,219,219,219,219,220,220,220,220,220,221,221,221,222,222,222,223,223,224,224,225,225,226,227,227,228,228,228,229,229,229,230,230,231,231,231,232,232,233,233,234,234,235,235,236,236,237,237,238,239,240,241,241,242,243,243,244,245,245,246,246,247,249,250,251,252,253,254,0,0,2,3,4,5,7,8,9,10,12,14,15,15,16,18,20,
            21,21,22,23,23,23,23,23,21,20,20,20,19,19,19,19,19,18,18,18,18,18,18,18,17,18,18,17,17,17,17,16,16,16,15,15,15,14,13,13,13,13,12,11,11,11,11,11,11,10,11,11,11,11,12,12,12,12,12,13,14,14,14,15,16,17,17,18,18,19,20,21,21,22,22,22,22,22,22,22,22,22,22,23,22,22,23,23,24,24,24,25,25,25,26,27,28,28,29,30,
            30,31,31,31,31,31,31,31,30,30,30,29,29,29,29,30,30,30,31,31,30,30,30,29,29,28,27,27,27,26,25,25,25,24,24,23,23,22,21,20,18,18,18,17,15,14,13,11,10,8,7,6,5,4,2,1,0,255,254,253,251,250,249,248,247,245,244,244,243,241,239,238,236,235,235,234,233,232,230,229,228,227,226,225,224,224,224,223,222,221,220,219,218,218,217,217,217,217,216,216,
            216,216,217,217,217,217,218,218,217,218,219,218,218,219,220,219,219,219,220,218,218,218,217,216,216,215,214,214,213,213,212,212,210,208,208,208,207,207,207,207,206,206,207,207,207,207,207,208,208,208,209,209,209,209,210,210,210,210,210,211,211,212,212,212,212,213,213,213,214,214,214,215,215,215,216,216,216,216,216,217,217,217,218,218,218,218,219,219,220,220,221,221,222,223,223,223,224,225,225,225,
            226,227,227,227,228,228,228,229,229,230,230,231,231,232,232,233,233,234,234,235,235,236,236,237,238,239,240,240,241,242,243,244,244,245,245,246,247,248,249,250,252,253,254,0,0,1,2,3,5,6,7,9,10,12,14,16,17,18,19,20,22,23,22,21,20,20,19,17,16,16,16,16,17,17,17,17,17,17,17,16,16,16,16,16,15,15,15,15,15,14,14,14,13,12,12,13,12,12,12,11,
            11,10,10,10,10,10,10,10,10,10,10,10,10,10,11,11,11,11,12,13,13,14,16,17,17,18,18,18,18,18,18,19,19,20,20,20,21,20,20,20,20,20,21,21,21,21,22,22,22,23,23,23,23,23,24,25,26,27,27,28,29,29,29,29,29,29,30,30,29,29,29,29,29,29,29,29,29,30,30,30,29,29,29,28,27,27,27,26,26,26,25,24,23,22,22,22,22,21,20,19,
            18,16,16,15,14,13,12,11,10,9,7,5,4,4,3,3,2,1,0,0,255,253,250,248,247,246,245,243,242,239,238,237,236,235,235,235,234,232,231,229,227,226,225,224,224,222,221,221,220,219,218,217,217,217,216,216,216,217,216,216,216,215,214,213,213,214,214,215,215,215,215,215,216,216,216,216,216,216,216,216,216,215,214,214,213,212,212,211,211,210,210,210,209,209,209,208,207,206,207,207,
            206,206,206,205,206,206,206,206,206,206,206,207,207,207,207,207,208,208,208,208,208,208,209,209,209,210,210,210,210,211,211,211,212,213,213,214,214,215,215,215,215,215,216,216,216,216,217,217,218,218,219,220,220,221,222,222,223,223,224,224,225,225,226,226,227,227,227,228,228,229,230,230,231,231,232,232,233,233,233,234,234,235,235,236,236,238,239,239,240,242,242,242,243,244,245,246,246,247,249,250,
            251,252,253,254,0,0,1,2,4,5,6,9,11,13,14,15,17,19,19,19,18,18,17,18,18,18,16,14,12,12,13,13,14,15,15,15,14,14,14,14,15,14,14,14,13,13,13,12,12,12,11,11,11,11,11,10,10,10,10,10,9,9,9,9,9,9,9,9,9,9,9,9,9,10,10,10,11,11,12,12,14,15,16,17,17,17,17,17,17,17,17,18,18,19,20,20,19,19,18,19,
            19,19,19,20,20,20,20,20,20,21,21,21,21,21,23,24,25,25,26,26,27,27,28,28,28,29,29,30,31,31,31,31,31,31,31,31,31,30,29,29,28,27,27,27,27,27,26,25,25,25,24,23,22,21,20,20,20,19,18,17,16,15,15,14,14,13,12,11,10,8,7,6,5,4,4,3,3,1,0,0,0,255,253,250,249,248,247,245,244,242,240,239,238,238,238,237,236,235,233,231,
            229,227,226,225,225,225,224,223,223,222,222,221,221,220,219,217,216,215,214,213,213,213,213,212,212,211,211,210,210,211,211,211,212,213,213,214,213,213,213,212,213,212,212,211,211,210,209,209,208,207,207,207,206,205,205,205,205,205,205,205,202,203,204,204,204,204,203,203,204,204,204,204,204,205,205,205,205,205,206,206,205,206,206,207,207,207,208,208,208,208,209,209,209,210,211,211,212,212,213,213,
            213,213,214,214,214,214,215,215,216,217,218,219,219,220,221,221,222,222,223,223,224,224,224,225,225,226,226,227,227,228,229,229,230,230,231,231,232,232,232,233,233,234,234,235,236,237,237,238,238,240,241,242,242,243,244,245,245,246,247,248,249,251,252,253,254,255,0,1,3,5,7,9,10,11,13,15,17,17,15,15,15,14,14,15,16,16,14,13,12,11,11,11,12,12,12,12,13,12,12,12,
            12,12,11,11,11,11,11,10,10,10,9,9,9,9,9,8,8,8,8,8,7,7,7,7,7,7,8,8,8,8,8,8,9,9,9,10,10,11,11,12,13,14,15,15,15,15,15,15,16,16,17,18,19,19,19,18,17,17,17,17,17,18,18,18,17,18,18,18,19,20,20,19,20,21,23,24,23,23,24,24,25,25,26,27,28,28,28,30,31,31,31,32,33,33,33,33,33,32,31,30,
            29,27,27,26,25,25,24,24,24,24,23,22,22,21,20,19,17,16,16,16,16,16,16,15,14,14,13,12,11,9,8,5,3,2,1,0,0,255,255,255,255,255,254,252,250,248,246,245,243,242,241,240,239,237,236,235,234,234,233,232,231,230,229,228,227,226,225,224,222,221,221,220,220,219,218,217,217,216,215,215,215,214,213,212,212,211,211,211,211,211,212,211,210,209,209,210,210,210,210,210,
            209,209,209,209,209,208,208,207,206,206,205,204,203,203,203,202,202,202,202,202,199,199,198,199,199,200,200,201,201,201,201,201,202,202,202,203,203,203,203,203,203,204,204,204,205,205,205,205,205,206,206,207,207,208,208,209,209,210,210,210,211,211,211,212,213,213,213,214,215,216,217,218,219,220,220,221,221,221,221,222,222,223,223,224,224,224,225,226,227,227,228,228,229,229,229,229,230,230,231,231,
            232,233,233,234,235,236,236,237,238,239,239,241,242,243,243,244,245,246,247,248,249,250,251,252,254,255,0,2,3,5,6,8,9,11,14,14,13,12,12,12,12,12,12,12,13,11,9,8,8,8,9,9,9,9,9,9,10,10,10,10,9,9,9,9,9,9,9,9,9,8,8,8,7,7,7,7,7,7,7,7,7,6,6,7,7,7,7,7,7,7,7,7,8,8,9,9,10,10,11,11,
            11,11,12,13,13,13,14,14,15,15,15,16,16,16,16,16,16,16,16,16,16,16,15,15,16,16,16,17,18,18,18,18,20,22,22,22,22,23,24,24,25,26,26,26,26,27,29,31,31,31,32,32,33,35,35,34,32,31,31,31,30,29,29,28,27,26,25,24,23,24,25,25,24,22,20,19,18,18,18,18,18,17,16,15,14,14,12,11,10,9,8,7,6,5,3,2,2,2,0,255,
            253,253,253,253,252,249,246,244,242,240,239,239,239,238,237,235,233,232,231,230,229,229,229,229,229,227,225,222,220,219,219,219,220,220,219,219,218,217,216,216,216,215,215,214,212,211,209,208,208,208,208,208,208,208,208,209,209,208,207,207,206,206,207,207,207,207,206,205,204,204,203,203,202,201,201,201,201,200,200,200,198,198,197,196,195,195,196,196,197,198,199,199,199,199,199,200,200,201,201,201,
            201,201,201,201,202,202,202,202,203,203,204,204,205,205,205,206,206,207,208,208,209,209,209,210,211,212,212,213,214,215,216,217,218,218,219,220,220,220,221,221,222,222,222,223,224,224,225,225,226,226,227,227,228,228,228,229,229,229,230,230,231,232,232,233,234,234,235,236,237,238,239,240,241,242,242,243,244,245,246,247,247,248,249,251,253,255,0,2,3,4,5,6,8,10,12,11,9,9,9,9,
            9,9,10,11,10,8,7,6,6,6,6,7,7,7,7,7,6,7,7,7,8,8,8,7,7,7,7,7,6,6,6,6,6,5,6,6,6,6,5,6,6,6,6,6,6,6,6,6,6,6,6,7,7,8,8,9,9,10,10,10,10,10,11,11,12,12,13,13,14,14,15,16,16,15,15,15,15,15,15,15,15,15,15,15,16,16,16,17,17,17,18,20,23,24,24,24,25,25,25,25,
            25,25,25,25,26,27,27,28,28,29,31,33,34,35,35,34,33,33,32,31,30,30,30,29,28,27,26,26,25,24,23,22,21,19,18,17,17,17,17,17,17,16,15,15,15,15,13,12,11,10,10,9,8,7,7,6,6,5,2,254,250,250,253,254,253,250,247,245,243,242,241,240,239,238,237,236,235,233,233,232,232,231,231,230,229,228,226,224,221,220,219,219,220,221,221,220,218,217,217,217,
            216,215,214,213,211,210,209,207,206,206,205,205,204,204,205,206,207,207,206,205,204,204,203,203,203,203,203,203,203,202,202,201,201,200,199,199,199,198,198,198,196,195,195,194,194,194,194,194,194,195,195,196,197,197,197,197,198,198,198,198,199,199,199,199,199,200,200,201,201,201,201,202,203,203,203,204,204,205,206,206,206,207,208,209,209,210,211,212,213,214,214,216,216,217,217,218,219,219,220,221,
            221,221,221,222,223,223,223,224,224,225,225,226,226,227,227,228,228,229,229,230,231,231,231,232,232,232,234,236,237,239,240,240,241,241,242,243,243,245,247,248,249,250,251,253,254,0,0,1,3,4,5,5,6,8,10,10,10,8,7,7,6,6,7,7,7,5,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,4,4,5,5,4,4,4,3,3,3,3,3,3,3,3,4,4,4,4,
            4,4,4,5,5,5,5,6,6,7,7,8,8,9,9,9,9,9,9,9,10,10,10,10,11,12,13,13,13,14,15,16,16,16,16,17,17,16,15,15,15,15,16,16,16,16,17,18,19,20,22,23,24,23,23,22,22,21,21,21,23,24,25,26,28,30,30,30,29,29,30,32,34,35,35,34,34,34,34,33,31,30,29,29,28,28,27,26,24,23,21,19,18,17,16,16,16,17,18,18,
            18,17,17,16,15,14,13,12,12,12,12,12,11,10,9,8,6,3,255,251,249,249,250,250,248,245,242,241,240,240,239,238,237,236,236,235,235,234,233,232,231,230,229,228,228,227,225,223,221,219,218,219,220,220,220,218,216,215,215,216,217,217,216,215,213,211,208,207,206,205,203,202,201,201,202,203,204,203,203,203,203,203,203,202,201,201,200,200,200,200,200,200,200,199,198,198,197,197,196,196,
            195,194,193,193,193,193,193,193,193,194,194,193,193,194,195,196,196,196,196,197,197,197,197,197,197,198,198,198,199,200,200,200,201,201,202,202,202,203,204,204,205,206,206,207,208,209,210,211,211,212,213,214,215,216,217,217,217,217,218,219,219,220,220,221,221,222,222,223,223,224,224,225,225,225,226,226,227,228,228,229,230,230,230,230,230,232,234,236,236,235,235,237,239,241,243,245,246,246,247,248,
            249,250,251,252,253,254,255,0,0,2,3,4,5,7,9,10,10,8,6,5,4,4,5,4,3,1,0,0,0,0,0,0,1,1,1,1,1,1,1,2,2,1,2,2,1,1,1,1,1,1,1,1,2,2,2,3,3,2,3,3,3,4,4,4,4,4,5,6,7,8,9,10,11,12,13,13,14,14,14,14,14,14,15,15,15,16,16,17,17,17,18,18,18,18,18,19,19,18,18,18,
            18,18,19,19,20,20,20,20,20,20,20,19,19,20,21,22,22,21,21,21,22,24,26,27,28,29,30,29,29,29,29,30,30,31,31,31,30,30,31,31,31,31,30,30,28,27,25,23,21,19,17,16,16,17,17,17,17,17,17,18,18,18,18,18,17,15,14,13,13,12,11,11,10,9,7,4,1,254,252,251,250,249,247,246,245,244,243,240,238,236,236,236,236,235,234,234,234,233,232,232,
            231,230,229,228,228,227,226,223,221,219,218,218,219,219,218,216,214,213,214,215,215,214,213,213,211,209,207,206,205,204,202,201,201,201,201,202,202,202,202,202,201,201,201,202,202,201,201,200,200,199,199,199,199,199,198,197,197,196,196,195,196,196,195,193,192,192,192,192,192,192,192,192,193,193,194,194,194,195,195,195,195,196,196,196,196,196,197,197,197,198,198,198,198,199,199,200,201,201,202,203,
            203,204,205,206,207,207,208,209,210,211,212,213,213,214,215,216,216,217,217,217,217,217,218,219,220,221,222,222,223,224,225,226,227,229,230,231,231,232,232,232,232,233,233,234,234,235,237,238,239,239,240,241,242,242,242,242,243,244,245,246,247,248,249,250,251,252,253,254,255,0,0,1,2,3,5,7,7,6,5,4,3,3,3,3,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
            0,0,0,0,0,255,255,0,0,0,0,0,0,1,1,1,1,1,2,2,2,2,2,3,4,6,8,10,12,13,14,14,14,13,12,12,12,12,13,13,13,14,14,14,14,13,12,11,11,12,12,13,13,13,13,14,14,14,15,14,14,14,14,15,15,15,15,15,15,16,18,19,19,20,21,22,23,23,23,23,23,23,24,25,26,26,27,27,27,28,29,29,29,28,27,27,27,27,27,27,
            26,25,25,25,25,24,23,21,19,16,14,13,14,15,16,17,17,18,18,18,18,18,18,17,16,15,14,13,12,10,9,8,7,6,4,2,0,0,255,255,253,250,247,245,243,242,239,236,233,232,231,232,233,233,234,234,233,232,231,231,230,230,229,228,227,226,225,224,222,220,219,218,218,217,217,215,214,213,212,210,209,208,208,208,208,207,206,205,204,203,202,202,201,201,200,199,199,201,203,203,
            201,198,197,197,198,200,200,201,201,201,200,199,198,198,198,197,196,196,196,196,195,195,196,195,193,192,192,192,192,192,192,192,192,192,192,192,193,193,194,194,194,194,195,195,195,195,195,196,196,196,196,197,197,198,198,199,199,200,201,202,202,203,204,205,205,206,208,209,210,210,211,211,212,213,214,215,216,216,217,219,220,222,223,224,224,224,225,225,226,226,227,228,229,230,230,231,231,232,232,233,
            234,235,236,236,236,236,237,237,238,238,239,239,240,240,241,242,243,243,244,245,246,247,248,248,249,251,252,253,254,255,255,255,255,0,1,3,4,3,2,1,0,0,0,0,1,1,2,2,2,1,1,0,0,0,0,0,255,255,255,254,254,254,254,254,254,254,254,254,254,254,254,254,255,255,255,0,0,0,0,0,0,2,3,6,8,10,11,11,9,8,6,6,6,7,8,10,11,10,10,11,
            12,14,16,17,18,19,19,19,19,19,20,21,21,21,20,19,18,18,18,17,16,16,16,18,19,19,19,18,18,17,17,18,20,21,23,25,26,27,26,26,26,26,26,25,25,24,24,24,25,25,26,26,25,25,24,24,24,24,24,24,23,22,21,20,20,19,17,15,12,11,11,11,12,12,12,13,14,15,16,16,16,16,15,14,13,13,12,11,9,7,6,6,6,6,5,3,2,1,0,255,
            253,250,247,245,242,239,235,233,231,230,229,229,230,231,232,231,231,230,230,229,228,227,226,225,224,223,221,220,219,219,219,218,217,217,217,217,216,213,209,207,207,207,208,208,207,206,205,203,202,201,201,200,200,199,199,198,198,199,200,201,201,200,199,199,201,203,204,204,204,203,202,201,200,199,199,198,197,196,195,195,196,195,195,194,193,192,192,192,192,191,191,191,191,191,191,191,192,192,192,193,
            193,193,194,194,194,194,195,195,195,195,196,196,197,197,198,198,199,200,201,201,202,203,204,205,205,206,208,210,211,213,213,214,215,216,216,217,217,218,219,220,222,222,222,222,222,222,222,223,223,224,225,226,227,228,229,230,231,232,233,233,234,235,236,236,236,236,236,237,237,238,238,239,240,240,241,241,242,242,243,245,246,247,248,248,249,249,249,250,250,251,252,252,253,254,0,1,1,1,0,0,
            254,253,253,254,254,255,0,0,1,2,2,2,2,2,1,0,0,255,254,253,252,251,251,251,251,251,251,252,252,252,253,254,254,255,255,255,255,0,0,2,4,6,7,8,8,8,6,5,3,3,4,6,9,11,13,14,14,14,14,14,15,16,17,19,20,21,21,21,21,21,21,21,22,22,22,22,22,21,21,22,22,22,22,23,24,25,24,23,21,20,19,20,21,22,23,24,24,24,24,24,
            24,24,24,24,24,24,23,23,23,23,22,22,21,21,21,21,21,21,21,20,20,19,18,16,14,13,11,11,11,11,11,11,11,11,12,13,14,15,14,14,13,12,12,11,10,9,9,8,7,7,6,6,5,4,3,2,1,0,255,253,251,250,248,245,242,239,236,234,232,231,230,228,227,227,228,229,229,229,229,227,226,224,223,221,220,218,216,216,217,218,218,216,214,214,214,215,215,213,210,209,
            208,209,209,208,206,204,203,202,201,201,200,199,199,199,199,198,198,198,199,200,201,201,202,203,204,204,204,204,203,202,201,200,199,199,199,198,197,196,196,196,195,195,194,193,193,192,192,192,192,191,191,191,191,191,191,192,192,192,193,193,193,194,194,194,194,194,195,195,195,195,196,197,197,197,198,199,200,201,202,203,204,205,207,208,209,210,211,212,213,214,214,214,215,215,216,217,218,218,219,219,
            219,220,221,222,222,223,224,225,225,226,226,227,227,227,228,229,230,231,232,233,233,234,235,236,236,237,237,238,239,239,239,240,240,241,241,241,242,242,243,244,245,245,245,246,247,248,249,250,250,251,252,253,254,255,0,0,0,255,254,252,251,250,250,250,251,252,253,254,254,255,255,255,0,0,0,0,0,0,255,255,255,254,254,253,254,255,0,0,1,1,0,0,255,255,255,0,1,3,4,5,
            5,5,5,4,4,3,2,2,2,3,4,6,9,11,13,15,16,17,17,16,16,15,15,16,16,17,17,16,17,17,17,17,17,17,17,18,18,18,18,19,20,20,20,20,21,22,22,22,22,21,20,20,20,20,20,21,22,22,23,23,23,22,22,22,21,21,21,20,20,19,19,19,19,19,18,18,17,16,15,14,14,13,13,12,11,10,9,8,8,9,9,9,9,9,10,11,12,13,12,11,
            11,10,10,10,10,9,8,7,6,5,5,4,3,2,0,0,255,254,253,251,250,249,248,246,244,241,239,237,235,232,230,228,227,225,224,224,223,222,222,221,220,219,218,218,217,216,215,215,216,216,215,214,212,211,211,212,212,211,210,208,208,208,207,206,204,203,201,201,200,200,199,199,199,199,199,198,198,198,197,198,199,201,203,204,203,203,202,201,201,200,200,199,198,198,198,198,198,197,196,195,
            196,195,195,195,194,194,194,194,194,194,194,194,194,194,193,193,193,193,194,194,194,194,194,195,195,196,196,196,196,196,197,198,199,200,201,201,202,203,204,205,206,207,208,209,210,211,212,213,214,214,215,216,217,217,218,218,218,219,219,220,221,222,223,223,224,224,224,224,225,226,226,227,227,227,227,228,228,229,229,230,231,232,233,233,234,234,234,235,236,237,237,238,239,239,240,240,241,241,242,243,
            243,244,244,244,245,246,248,248,249,250,251,251,252,252,252,251,250,249,248,247,246,246,246,247,247,248,248,249,249,249,250,250,251,251,252,252,253,254,254,255,255,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,1,1,2,2,2,3,3,4,5,7,9,10,11,11,11,11,10,10,11,12,12,12,12,12,12,12,12,12,12,13,13,13,13,13,13,13,14,14,14,15,15,
            16,16,17,17,17,18,18,19,19,20,20,19,18,18,18,18,19,19,19,19,19,19,19,19,19,19,18,18,17,17,17,18,18,18,18,17,16,15,14,14,13,12,12,11,11,10,9,9,8,8,7,7,7,8,8,10,10,11,10,9,8,8,8,8,8,8,7,6,5,5,4,3,1,0,255,254,253,253,252,251,250,250,249,247,246,244,243,241,239,236,233,231,228,226,225,223,222,221,220,220,
            220,220,220,220,219,218,218,218,218,218,217,215,212,209,208,208,209,209,209,209,208,207,205,204,203,202,202,201,200,200,200,200,200,200,199,199,199,198,197,196,196,196,197,198,199,199,200,200,200,199,199,199,198,198,199,199,198,198,197,196,197,197,197,197,197,197,197,198,198,197,197,198,198,198,198,197,197,196,196,195,196,196,196,197,197,198,199,200,200,200,200,200,201,201,202,203,204,205,207,208,
            210,211,212,213,213,214,215,216,216,217,217,218,219,219,220,221,221,221,221,221,222,222,223,223,224,224,225,225,226,227,227,226,226,227,228,228,229,229,230,230,230,231,231,231,232,233,233,234,234,235,235,236,237,238,238,239,239,239,240,240,241,241,240,240,241,242,243,244,245,246,246,247,246,246,245,245,244,244,244,243,243,243,243,243,243,243,244,244,245,245,246,246,247,248,248,249,250,250,251,251,
            251,252,252,252,251,250,250,249,249,249,249,250,251,251,252,253,255,255,0,0,0,255,255,255,0,1,3,5,5,5,5,5,5,5,5,5,6,6,7,7,7,7,7,8,9,9,10,10,10,10,10,10,10,10,10,11,11,12,12,12,12,12,13,13,14,14,14,14,15,15,15,16,16,16,16,16,16,16,16,16,16,16,16,16,16,17,17,17,17,17,17,17,16,16,16,16,16,16,16,15,
            15,14,12,10,9,8,7,7,7,7,6,5,5,4,4,4,5,5,5,6,6,7,7,7,6,5,5,4,4,3,2,1,0,0,255,254,253,252,251,250,248,247,246,245,244,243,243,242,241,239,237,234,232,230,228,226,224,222,221,221,220,220,219,219,218,218,218,219,219,218,217,215,213,211,209,208,207,206,206,207,207,207,206,205,204,202,201,200,200,200,200,201,201,202,202,202,202,201,201,201,
            200,200,199,199,199,200,200,200,200,200,200,200,200,200,200,200,199,198,198,197,199,198,198,198,198,198,198,199,198,198,198,198,198,198,198,197,197,197,198,198,199,199,200,200,200,200,201,201,201,201,201,201,202,202,203,205,206,208,209,210,211,212,212,213,214,215,215,216,216,216,217,218,218,219,220,220,220,220,221,221,222,222,223,223,223,223,223,224,224,224,224,225,225,225,226,227,227,228,228,229,
            229,230,231,231,232,232,233,233,234,234,235,235,235,235,235,236,236,238,239,241,241,241,241,240,239,239,239,239,239,239,239,239,240,240,241,241,241,241,241,240,240,240,240,240,240,241,241,241,242,242,243,243,244,245,245,245,246,246,246,246,246,245,245,245,245,245,245,246,247,248,249,250,251,252,253,254,255,255,255,255,254,254,255,0,0,1,2,3,2,2,1,0,0,0,1,1,1,1,1,1,
            1,2,3,4,6,6,7,7,7,7,7,7,7,7,7,7,7,7,7,8,8,8,9,9,10,10,11,12,12,13,13,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,14,13,13,13,13,12,11,11,10,9,8,8,8,8,9,9,9,9,9,9,8,7,5,4,3,2,2,3,4,5,5,5,5,5,5,4,4,3,2,0,0,254,253,252,251,250,250,249,248,
            247,245,243,241,240,239,240,240,241,240,239,237,235,233,231,229,227,225,223,222,221,220,218,217,217,216,216,217,217,217,217,216,215,214,212,210,209,207,206,206,206,205,204,203,202,201,201,200,200,201,201,201,201,202,202,203,204,205,205,205,204,203,202,202,202,202,202,202,202,202,201,201,201,201,202,202,201,201,200,199,201,200,200,200,200,200,200,200,200,200,200,200,200,200,200,200,200,200,200,201,
            201,201,202,202,202,202,203,203,204,204,204,204,205,205,206,206,207,207,208,208,209,210,211,212,213,214,214,214,214,215,215,216,217,217,218,218,218,219,219,220,220,221,221,222,222,223,223,223,223,223,224,224,224,225,225,226,227,227,227,228,228,228,229,229,230,230,231,232,233,234,236,237,238,239,238,238,237,236,236,236,236,236,236,236,237,237,237,237,237,237,237,237,237,237,237,237,237,237,237,237,
            237,237,237,237,237,238,238,239,239,240,240,241,242,242,243,243,243,242,242,242,242,242,243,243,244,245,246,246,247,246,246,246,246,246,247,248,248,249,249,249,250,250,250,250,250,251,251,252,252,253,253,253,253,254,254,254,254,254,254,254,255,255,0,0,0,0,1,2,3,3,4,4,4,5,5,5,6,6,6,6,6,6,7,7,7,7,7,8,8,9,9,9,10,10,10,11,11,12,12,12,
            12,11,11,11,11,10,10,10,10,9,9,9,9,9,9,9,9,9,8,8,8,8,8,8,9,9,8,8,8,8,7,7,7,6,6,5,4,4,3,3,3,3,4,4,4,4,3,3,2,1,1,0,0,255,253,251,249,247,246,246,246,245,244,242,240,239,238,238,239,239,239,237,236,234,232,230,228,227,225,224,222,221,220,219,218,217,216,216,215,215,215,214,213,212,211,209,208,207,206,206,
            205,205,204,203,202,201,201,201,201,201,201,202,202,202,203,203,204,205,206,206,207,207,206,206,206,206,205,205,205,204,203,203,203,203,203,203,203,202,202,201,202,202,202,202,202,202,202,202,202,202,202,202,202,202,202,202,202,202,202,203,203,204,204,204,204,204,204,204,204,205,205,206,206,207,207,207,208,208,208,209,209,210,210,210,211,211,212,212,213,214,214,215,216,217,217,217,217,218,218,219,
            219,220,221,221,222,222,222,223,223,223,224,224,225,225,225,226,226,227,227,228,228,229,229,229,230,230,231,232,233,234,235,236,237,237,236,235,234,233,232,232,232,233,233,234,234,234,234,234,234,234,235,235,235,235,235,235,235,235,235,235,235,235,235,236,236,236,237,237,238,238,239,240,241,241,242,242,242,242,242,242,242,242,242,242,243,243,243,242,242,242,242,243,244,246,247,248,248,248,248,248,
            248,248,248,248,249,250,251,252,253,253,254,254,254,253,253,252,252,252,252,252,252,252,253,253,253,253,254,254,255,0,0,1,2,2,3,3,3,4,4,4,4,4,4,5,5,5,6,6,6,7,7,7,7,7,7,7,8,8,8,8,8,8,8,8,8,8,8,8,8,8,8,8,7,7,7,6,6,6,6,7,7,7,7,7,7,8,8,8,8,8,8,9,9,9,9,9,9,9,9,9,
            8,8,7,6,5,4,2,1,0,0,0,0,255,254,253,252,250,249,247,246,245,244,243,242,241,239,239,238,237,237,236,235,234,233,232,231,230,228,227,226,225,223,222,220,219,218,217,217,216,216,216,215,214,213,212,210,209,208,207,206,206,205,205,204,204,204,204,204,204,204,204,205,205,206,206,206,206,206,205,205,205,205,205,205,205,205,205,205,205,205,205,205,205,205,205,205,204,204,203,203,
            205,205,205,205,204,204,204,204,204,204,204,204,204,204,204,204,204,204,204,204,204,204,204,204,204,204,204,205,205,205,205,206,206,206,207,207,208,208,209,209,209,210,210,210,210,211,211,212,213,213,214,215,215,216,216,217,217,218,218,218,219,219,220,220,220,220,220,220,221,221,222,223,223,224,225,226,226,227,227,228,228,228,229,230,230,231,232,233,234,234,234,234,234,234,234,234,234,234,234,234,
            233,233,233,233,233,233,232,232,232,232,232,232,232,233,233,234,234,234,234,234,234,234,235,235,235,235,236,237,237,238,239,239,240,240,240,240,240,240,240,240,240,240,240,239,239,239,239,240,240,240,241,242,243,243,243,243,244,244,244,245,246,247,247,248,249,249,249,250,250,251,251,251,252,252,251,251,251,251,251,251,252,252,253,254,254,255,255,255,255,0,0,0,0,0,0,1,1,1,2,2,
            2,3,3,3,3,4,4,4,5,5,5,6,6,6,6,6,6,6,6,7,7,7,7,7,7,7,7,7,6,6,6,5,5,5,5,5,5,6,6,6,6,7,7,7,7,7,7,7,7,7,7,8,8,8,9,9,9,9,8,8,7,7,6,5,4,3,3,2,1,0,0,255,253,252,251,250,249,248,247,246,245,244,244,243,241,240,239,237,236,234,233,233,232,231,230,230,229,228,226,225,
            224,222,221,220,219,218,217,216,216,216,216,216,215,215,214,212,211,210,209,208,207,207,207,207,206,206,206,206,206,206,206,207,207,207,208,208,209,209,208,208,207,207,207,206,206,206,207,207,207,207,207,206,206,206,206,205,205,205,205,205,207,207,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,206,207,207,207,207,207,207,207,208,208,208,209,209,209,
            210,210,211,211,211,212,212,213,213,214,214,214,215,215,216,216,216,217,217,217,218,218,219,220,220,221,221,222,222,223,223,223,224,224,224,225,226,226,227,228,229,230,230,231,231,231,232,232,232,232,232,232,232,232,232,232,232,231,231,231,230,230,230,230,230,230,230,231,231,231,231,231,232,232,232,232,232,232,232,232,232,232,232,232,233,233,233,234,234,234,235,235,235,236,236,236,237,237,237,238,
            238,238,238,238,238,238,239,239,239,240,240,241,241,241,241,241,241,241,242,242,243,243,244,244,245,245,245,246,246,246,246,246,246,247,247,248,248,249,250,251,252,252,253,253,254,254,254,254,253,254,254,254,255,255,0,0,0,1,2,2,2,2,3,3,3,4,4,4,5,5,5,5,6,6,6,6,6,5,5,5,5,5,5,5,4,4,4,4,5,5,5,5,5,5,5,5,5,5,5,5,
            5,6,6,6,6,5,5,5,5,5,5,6,6,6,6,6,6,5,5,4,3,3,2,2,1,1,1,0,0,0,255,254,253,252,250,249,248,247,246,245,245,244,243,242,241,240,239,237,236,234,233,232,231,230,229,229,228,227,227,226,225,224,223,222,221,220,219,218,218,217,217,217,216,216,215,214,214,213,212,211,211,210,210,209,209,209,208,208,208,207,207,207,207,207,207,208,209,209,210,210,
            210,209,209,209,208,208,208,208,208,208,208,208,208,207,207,208,208,208,207,207,209,209,209,208,208,208,208,208,208,208,208,208,208,208,208,208,208,207,207,207,207,207,208,208,208,208,208,209,209,209,209,210,210,210,210,210,210,210,210,211,211,211,212,212,212,213,213,214,214,214,215,215,215,216,216,216,217,217,218,218,218,219,220,220,221,221,222,222,222,223,223,224,224,225,225,226,227,227,228,228,
            228,229,229,229,229,229,230,230,230,231,231,231,231,231,231,231,231,231,230,230,230,230,229,229,229,229,229,229,229,230,230,230,230,230,231,231,231,231,231,231,231,231,231,231,231,232,232,233,234,235,235,236,236,236,236,237,237,237,238,238,238,238,239,239,239,239,240,240,241,241,242,242,243,243,243,244,244,244,244,244,245,245,245,245,246,245,245,245,245,244,244,244,243,243,243,243,243,243,244,244,
            245,245,246,247,247,247,248,248,248,248,249,249,249,250,250,251,251,251,252,253,253,254,255,255,0,0,0,1,1,2,2,2,2,2,2,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,2,2,2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,2,2,1,1,0,0,0,0,255,255,254,253,252,251,251,250,249,248,247,246,245,244,243,
            242,241,240,239,239,238,237,236,235,235,234,233,232,232,231,230,229,228,227,226,225,224,223,222,221,221,220,219,218,218,218,218,217,217,217,217,217,216,215,215,214,214,214,213,213,213,213,213,214,214,214,214,214,214,215,215,215,214,214,214,213,212,212,211,211,210,210,210,210,210,210,210,210,210,209,209,209,209,209,209,211,211,211,211,211,211,211,211,210,210,210,210,210,211,211,211,211,211,211,210,
            210,210,210,210,210,210,210,210,210,210,211,211,211,211,212,212,212,213,213,213,213,214,214,214,214,215,215,215,215,215,216,216,216,217,217,217,218,218,219,219,219,219,220,220,220,221,221,222,223,223,224,225,226,226,227,227,228,228,228,228,228,228,228,228,228,228,228,228,228,229,229,229,229,229,229,229,229,229,229,230,230,230,230,230,230,230,230,230,230,230,231,231,231,231,231,231,231,230,230,230,
            230,231,232,233,234,235,236,236,237,237,237,237,236,236,236,236,236,237,237,238,238,238,239,239,239,239,240,240,241,241,242,242,243,243,243,243,244,243,243,243,243,243,243,243,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,245,245,245,245,246,246,246,246,246,246,246,246,246,247,247,248,249,250,251,252,253,253,254,255,255,255,0,0,0,0,255,255,255,255,
            254,254,254,253,253,253,253,253,253,253,253,253,254,254,254,254,255,255,255,255,255,255,255,255,0,0,0,0,0,0,0,255,255,255,255,254,254,254,253,252,252,251,250,250,249,249,248,248,248,247,247,246,246,245,244,244,243,242,241,241,240,239,239,238,237,237,236,235,234,234,233,232,231,231,230,229,228,227,227,226,225,225,224,223,223,222,222,222,222,222,222,222,222,222,222,222,221,221,220,220,
            219,219,218,218,218,218,218,218,217,217,217,217,217,217,217,216,216,216,216,216,216,216,216,216,216,216,215,215,214,213,213,212,212,212,211,211,211,211,211,211,215,214,214,214,214,213,213,213,213,213,213,213,213,213,213,213,213,213,213,213,213,213,213,213,213,213,213,214,214,214,214,214,214,214,214,215,215,215,215,215,215,215,216,216,216,216,216,216,217,217,217,218,218,218,219,219,220,220,220,221,
            221,221,222,222,222,223,223,224,224,224,225,225,226,226,227,227,227,227,228,228,228,228,229,229,229,229,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,231,231,231,231,231,231,231,231,231,230,230,230,229,229,229,229,229,229,230,231,231,232,232,233,234,234,234,235,235,236,236,237,238,238,239,240,240,241,241,241,242,242,241,241,241,241,241,241,241,241,242,242,242,242,243,243,
            243,243,243,243,243,243,243,243,243,243,243,243,243,243,243,243,243,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,245,245,246,246,247,248,248,249,250,250,251,251,251,251,252,252,251,251,251,251,250,250,249,249,249,249,249,249,249,249,249,250,250,250,251,251,251,251,252,252,252,252,252,252,252,252,252,252,252,251,251,251,251,250,250,249,249,248,248,
            247,246,246,245,245,245,244,244,244,244,243,243,242,242,242,241,241,240,240,239,239,238,238,237,237,236,236,235,235,234,234,233,233,232,232,231,230,230,229,228,227,227,226,225,225,225,224,224,224,223,223,223,223,222,222,221,221,221,220,220,220,220,219,219,219,219,219,219,219,219,219,219,219,219,219,219,220,220,220,221,221,221,221,221,220,220,219,219,218,218,217,217,216,216,216,216,216,215,215,215,
            222,222,222,222,222,221,221,221,220,220,220,219,219,219,218,218,218,218,217,217,217,217,216,216,216,216,216,216,216,216,216,216,217,217,217,217,218,218,218,218,219,219,219,219,220,220,220,220,220,221,221,221,221,221,222,222,222,223,223,223,224,224,225,225,226,226,226,227,227,228,228,228,228,229,229,229,229,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,229,229,229,229,229,228,228,
            228,228,228,229,229,229,229,230,230,230,231,231,231,232,232,232,232,232,232,232,232,232,232,232,232,232,232,232,232,232,232,233,233,233,234,235,235,236,237,237,238,239,239,240,240,241,241,241,241,241,241,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,242,243,243,243,243,243,243,243,243,243,243,243,243,243,242,242,242,242,242,242,242,242,243,243,
            243,243,244,244,245,245,246,246,247,247,247,248,248,248,248,248,248,248,248,248,248,247,247,247,246,246,246,246,246,246,246,246,246,246,246,246,246,247,247,247,247,247,247,247,247,247,247,247,246,246,246,246,245,245,245,245,244,244,244,243,243,243,243,242,242,242,241,241,241,240,240,240,239,239,238,238,237,237,236,236,235,235,235,234,234,233,233,233,232,232,231,231,231,230,230,230,229,229,228,228,
            228,227,227,226,226,226,225,225,225,224,224,223,223,223,223,222,222,222,222,221,221,221,221,221,221,221,221,221,221,221,222,222,222,222,222,222,222,222,222,222,222,222,222,222,222,222,222,222,222,222,222,222,222,223,223,223,223,223,223,223,226,226,226,226,226,226,226,226,226,226,225,225,225,225,225,225,224,224,224,224,224,223,223,223,223,223,223,223,223,223,223,223,223,223,223,224,224,224,224,224,
            225,225,225,225,225,225,226,226,226,226,226,226,226,226,226,226,227,227,227,227,227,227,227,228,228,228,228,228,229,229,229,229,229,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,229,229,229,229,230,230,230,230,230,230,230,231,231,231,232,232,232,233,233,233,234,234,234,234,235,235,235,235,235,234,234,234,234,233,233,233,232,232,232,231,231,231,231,231,231,231,231,231,231,
            231,232,232,232,233,233,233,234,234,235,235,235,236,236,237,237,237,238,238,238,238,239,239,239,239,239,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,241,241,241,241,241,241,241,241,241,241,241,241,241,242,242,242,242,242,242,242,243,243,243,243,243,243,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,244,
            244,244,244,244,244,244,244,244,244,244,243,243,243,243,242,242,242,241,241,241,240,240,240,239,239,238,238,238,237,237,236,236,236,235,235,234,234,234,233,233,233,232,232,231,231,231,230,230,230,229,229,229,228,228,228,227,227,227,226,226,226,226,225,225,225,225,224,224,224,224,224,223,223,223,223,223,223,223,223,223,223,223,223,223,223,223,223,223,223,223,223,223,223,224,224,224,224,224,224,224,
            224,224,224,224,224,224,224,224,224,224,225,225,225,225,225,225,225,225,226,226,227,227,227,227,227,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,230,230,230,230,230,
            230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,231,231,231,231,231,232,232,232,232,232,232,233,233,233,233,233,233,234,234,234,234,234,234,234,234,233,233,233,233,233,233,233,232,232,232,232,231,231,231,231,231,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,231,231,231,231,231,231,232,232,232,232,232,233,233,233,233,233,234,234,234,234,234,235,235,235,
            235,235,236,236,236,236,236,236,237,237,237,237,237,238,238,238,238,238,238,238,239,239,239,239,239,239,239,239,239,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,240,239,239,239,239,239,239,238,238,238,238,237,237,237,236,236,236,236,235,235,235,234,234,234,233,233,233,233,232,232,232,232,231,231,231,231,
            230,230,230,230,229,229,229,228,228,228,228,227,227,227,227,226,226,226,226,226,225,225,225,225,225,225,224,224,224,224,224,224,224,224,224,224,224,224,224,224,224,224,224,224,225,225,225,225,225,225,225,225,225,225,225,225,225,226,226,226,226,226,226,226,226,226,226,226,226,226,226,226,226,227,227,227,227,227,227,227,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,
            229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,231,231,231,231,231,231,231,231,231,231,231,231,231,231,231,231,231,231,
            231,231,231,231,231,231,231,231,231,231,231,230,230,230,230,230,230,230,230,230,230,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,230,230,230,230,230,230,230,230,230,230,231,231,231,231,231,231,231,231,232,232,232,232,232,232,232,232,233,233,233,233,233,233,233,233,233,234,234,234,234,234,234,234,
            234,234,234,234,234,234,234,234,235,235,235,235,235,235,235,235,235,235,234,234,234,234,234,234,234,234,234,234,234,234,234,234,233,233,233,233,233,233,233,233,232,232,232,232,232,232,232,232,231,231,231,231,231,231,231,230,230,230,230,230,230,229,229,229,229,229,229,229,228,228,228,228,228,228,228,227,227,227,227,227,227,227,227,227,226,226,226,226,226,226,226,226,226,226,226,226,226,226,226,226,
            226,226,226,226,226,226,226,227,227,227,227,227,227,227,227,227,227,227,227,227,228,228,228,228,228,228,228,228,228,228,228,228,228,228,229,229,229,229,229,229,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,
            228,228,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,
            228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,229,230,230,230,230,230,230,230,230,230,230,230,230,230,230,230,229,229,229,229,229,229,229,229,229,229,229,229,229,
            229,229,229,229,229,229,229,229,229,229,229,229,229,229,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,227,228,228,228,228,228,228,228,228,228,228,
            228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,
            228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,
            228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,
            228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228,228];

        if (lngDeg > 179) lngDeg = -180;
        if (lngDeg < -180) lngDeg = -180;
        if (latDeg > 90) latDeg = 90;
        if (latDeg < -90) latDeg = -90;
        let index = (90 - latDeg) * 360 + 180 + lngDeg;
        let geoHeight = GEOID_HEIGHT_DATA[index];
        if (geoHeight > 127) geoHeight -= 256.0;
        return geoHeight;        
    }
}