<template>
    <div class="container">
        <div class="row pt-2 pb-4 mt-3" v-if="!showLoader">
            <div class="col-lg-5">
                <label for="orbitSource" class="">Your Filters 
                    <small class="px-2" v-if="numberOfPersonalFilters > 0 && numberOfPersonalFilters != 3">(<span class="text-muted mx-1">You can define <strong class="text-light">{{3 - numberOfPersonalFilters}}</strong> more filters</span>)</small>
                    <small class="px-2" v-if="numberOfPersonalFilters == 0">(<span class="text-muted mx-1">You can define <strong class="text-light">3</strong> filters in total</span>)</small>
                    <small class="px-2" v-if="numberOfPersonalFilters == 3">(<span class="text-muted mx-1">All <strong class="text-light">3</strong> filters have been defined</span>)</small>
                </label>
                <b-form-select 
                    class="form-control"
                    v-model="currFilter" 
                    data-vv-name="orbitSource"
                    id="orbitSource"
                    @change="onCurrFilterChanged"
                    :options="currentUserFilterOptions">
                </b-form-select>

                <span v-if="validated" class="text-danger">
                    {{errors.first('orbitSource')}}
                </span>  
            </div>
            <div class="col-lg-3 filterbtn">
                <button type="button" class="btn btn-secondary mr-2 mr-4" @click="addNewFilter" v-if="canAddMoreFilters">Add</button>
            </div>
        </div>
        <div class="row pt-2 border" v-if="currFilter != null">                
            <div class="col-12 pb-4">
                <div class="row mx-1 my-3">
                    <div class="col-lg-6">
                        <label for="filterName" class="">Filter Name <span v-if="currFilter.isShared">(readonly)</span></label>
                        <b-form-input 
                            id="filterName" 
                            v-model="currFilter.name"
                            :readonly="currFilter.isShared"
                            :class="currFilter.isShared ? 'cb-grayout' : ''">
                        </b-form-input>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-2" v-if="!currFilter.isShared">
                        <label for="isEnabled" class="">Status</label>
                        <b-form-checkbox 
                            id="isEnabled"
                            v-model="currFilter.isEnabled"
                            class="cb-mid-align"
                            > Enabled
                        </b-form-checkbox>
                    </div>
                    <div class="col-2" v-if="currFilter.isShared">
                        <label for="isShared" class="">Filter Type</label> <br/>
                        <span class="cb-mid-align"><strong>Regional Events Filter</strong></span>
                    </div>
                    <div class="col-2" v-if="currFilter.isShared">
                        <b-form-checkbox 
                            id="isEditingEnabled"
                            v-model="currFilter.isReadOnly"
                            :value="false"
                            :unchecked-value="true"
                            class="cb-btm-align"
                            > Enable Editing
                        </b-form-checkbox>
                    </div>                    
                </div>
                <div class="row mx-1 mt-3">
                    <div class="col-lg-6 mt-3 pt-3 border-left border-top border-right border-info">
                        <span class="text-light">General Observability Criteria <small class="pl-2"><a href="#" @click="showObservabilityExplanation">(show explanation)</a></small></span>
                        <hr style="height:1px; border-width:0; color:gray; background-color:gray;">
                    </div>
                    <div class="col-lg-2 mt-3"></div>
                    <div class="col-lg-4 mt-3 pt-3 border-left border-right border-top border-secondary"  v-if="!currFilter.isShared">
                        <span class="text-light">Site &amp; Distance from Shadow</span>
                        <hr style="height:1px; border-width:0; color:gray; background-color:gray;">
                    </div>
                </div>
                <div class="row mx-1">
                    <div class="col-2 pb-3 pt-1 border-left border-info">
                        <label for="starMag" class="">Max Star Mag</label>
                        <b-form-input 
                            id="starMag" 
                            v-model="currFilter.starMag" 
                            v-validate="'between:8,16'"
                            data-vv-name="starMag"                            
                            type="number"
                            step="0.5" min="8" max="16"
                            :readonly="currFilter.isReadOnly">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('starMag')}}
                        </span>                           
                        <b-tooltip target="starMag" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }">
                            Maximum star magnitude
                        </b-tooltip>                           
                    </div>
                    <div class="col-2 pb-3 pt-1 border-info">
                        <label for="minDur" class="">Min Durarion (sec)</label>
                        <b-form-input 
                            id="minDur" 
                            v-model="currFilter.minDur" 
                            v-validate="'between:0.1,60'"
                            data-vv-name="minDur"
                            type="number"
                            step="0.1" min="0.1" max="60"
                            :readonly="currFilter.isReadOnly">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('minDur')}}
                        </span>                            
                        <b-tooltip target="minDur" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }">
                            Minimum event duration in seconds
                        </b-tooltip>                          
                    </div>
                    <div class="col-2 pb-3 pt-1 border-right border-info">
                        <label for="magDrop" class="">Min Mag Drop</label>
                        <b-form-input 
                            id="magDrop" 
                            v-model="currFilter.magDrop" 
                            v-validate="'between:0.1,10'"
                            data-vv-name="magDrop"                                
                            type="number"
                            step="0.1" min="0.1" max="10"                            
                            :readonly="currFilter.isReadOnly">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('magDrop')}}
                        </span>    
                        <b-tooltip target="magDrop" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }">
                            Minimum event magnitude drop in Red
                        </b-tooltip>                        
                    </div>
                    <div class="col-2"></div>
                    <div class="col-4 pb-3 pt-1 border-left border-right border-secondary"  v-if="!currFilter.isShared">
                        <label for="location" class=""><router-link :to="{ name: 'UserProfile', hash: '#saved-sites'}">Location</router-link></label>
                        <b-form-select 
                            class="form-control" 
                            v-model="currFilter.selectedSite" 
                            v-validate="{required: true}"
                            data-vv-name="location"                                        
                            id="location"
                            :options="locations">
                        </b-form-select>

                        <span v-if="validated" class="text-danger">
                            {{errors.first('location')}}
                        </span>                                       
                    </div>                   
                </div>
                <div class="row mx-1">
                    <div class="col-2 pb-3 border-left border-bottom border-info">
                        <label for="aperture" class="">Aperture (cm)</label>
                        <b-form-input 
                            id="aperture" 
                            v-model="currFilter.aperture" 
                            v-validate="'between:3,65'"
                            data-vv-name="aperture"                                
                            type="number"
                            step="0.5" min="3" max="65"
                            :readonly="currFilter.isReadOnly">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('aperture')}}
                        </span>                           
                        <b-tooltip target="aperture" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }">
                            Telescope aperture
                        </b-tooltip>                           
                    </div>
                    <div class="col-2 pb-3 border-bottom border-info">
                        <label for="detectionFrames" class="">Detection (frames)</label>
                        <b-form-input 
                            id="detectionFrames" 
                            v-model="currFilter.detectionFrames" 
                            v-validate="'between:1,10'"
                            data-vv-name="detectionFrames"                               
                            type="number"
                            step="1" min="1" max="10"
                            :readonly="currFilter.isReadOnly">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('detectionFrames')}}
                        </span>                               
                        <b-tooltip target="detectionFrames" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }">
                            Minimum number of desired video frames to be recorded for a central positive event
                        </b-tooltip>                          
                    </div>
                    <div class="col-2 pb-3 border-right border-bottom border-info">
                        <label for="magAdjust" class="">MagAdjust</label>
                        <b-form-input 
                            id="magAdjust" 
                            v-model="currFilter.magAdjust" 
                            v-validate="'between:-5,5'"
                            data-vv-name="magAdjust"                               
                            type="number"
                            step="0.5" min="-5" max="5"
                            :readonly="currFilter.isReadOnly">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('magAdjust')}}
                        </span>                          
                        <b-tooltip target="magAdjust" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }">
                            A factor in stellar magnitudes to adjust for darker or light-poluted skies and/or for more or less sensitive camera
                        </b-tooltip>                              
                    </div>   
                    <div class="col-2"></div>
                    <div class="col-2 pt-2 pb-3 border-left border-bottom border-secondary" v-if="!currFilter.isShared">
                        <label for="location" class="">Max Distance (km)</label>
                        <b-form-input 
                            id="distance" 
                            v-model="currFilter.distance" 
                            v-validate="'between:1,1000'"
                            data-vv-name="distance"                               
                            type="number"
                            step="1" min="1" max="1000">
                        </b-form-input>

                        <span v-if="validated" class="text-danger">
                            {{errors.first('distance')}}
                        </span>                           
                    </div>
                    <div class="col-2 pt-2 pb-3 border-right border-bottom border-secondary" v-if="!currFilter.isShared">
                        <label for="distFrom" class="">From</label>
                        <b-form-select 
                            class="form-control" 
                            v-model="currFilter.distFrom" 
                            v-validate="{required: true}"
                            data-vv-name="distFrom"                                        
                            id="distFrom"
                            :options="distanceReferences">
                        </b-form-select>

                        <span v-if="validated" class="text-danger">
                            {{errors.first('distFrom')}}
                        </span>                                       
                    </div>                                       
                </div>
                <div class="row mx-1 mt-3" v-if="!currFilter.isShared">
                    <div class="col-lg-6 mt-3 pt-3 border-left border-top border-right border-success">
                        <span class="text-light">Altitude &amp; Twilight/Daytime Events</span>
                        <hr style="height:1px; border-width:0; color:gray; background-color:gray;">
                    </div>
                    <div class="col-lg-2 mt-3"></div>
                    <div class="col-lg-4 mt-3 pt-3 border-left border-right border-top border-danger"  v-if="!currFilter.isShared">
                        <span class="text-light">Probability &amp; Issue Flags</span>
                        <hr style="height:1px; border-width:0; color:gray; background-color:gray;">
                    </div>
                </div>                
                <div class="row mx-1" v-if="!currFilter.isShared">
                    <div class="col-2 pb-3 pt-1 border-left border-success">
                        <label for="altitude" class="">Min Altitude (deg)</label>
                        <b-form-input 
                            id="altitude" 
                            v-model="currFilter.minAltitude" 
                            v-validate="'between:0,80'"
                            data-vv-name="distance"                                 
                            type="number"
                            step="1" min="0" max="80">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('distance')}}
                        </span>                            
                        <b-tooltip target="altitude" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }">
                            Minimum altitude of the star above a zero horizon 
                        </b-tooltip>
                    </div>                       
                    <div class="col-4 pb-3 pt-1 border-right border-success">
                        <label for="altitude" class="">Use Extinction Coeff. (for lower alt events)</label>
                        <b-form-select 
                            class="form-control" 
                            v-model="currFilter.extK" 
                            id="extK"
                            :options="extinctionReferences">
                        </b-form-select>
                        <b-tooltip target="extK" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }">
                            Atmospheric extinction coefficient to be used when determining the <b>General Observability</b> of the event at lower altitudes
                        </b-tooltip>                        
                    </div>                                        
                    <div class="col-2"></div>
                    <div class="col-2 pb-3 pt-1 border-left border-danger">
                        <b-form-checkbox 
                            id="maxEPWEnabled"
                            v-model="currFilter.maxEPWEnabled"
                            class="cb-pad-btm"
                            > Max SigPW
                        </b-form-checkbox>                          
                        <b-form-input 
                            id="maxEPW" 
                            v-model="currFilter.maxEPW" 
                            v-validate="'between:0.05,100'"
                            data-vv-name="maxEPW"                                  
                            type="number"
                            step="0.01" min="0.05" max="100" 
                            v-if="currFilter.maxEPWEnabled">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('maxEPW')}}
                        </span>                            
                        <b-form-input 
                            id="maxEPWEOff" 
                            value="N/A"
                            :disabled="true"
                            :readonly="true"
                            v-if="!currFilter.maxEPWEnabled"
                            class="cb-grayout">
                        </b-form-input>
                        <b-tooltip target="maxEPW" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }" v-if="currFilter.maxEPWEnabled">
                            Maximum 1-sigma prediction error in path widths
                        </b-tooltip>                        
                    </div>                   
                    <div class="col-2 pb-3 pt-1 border-right border-danger">
                        <b-form-checkbox 
                            id="maxRUWEEnabled"
                            v-model="currFilter.maxRUWEEnabled"
                            class="cb-pad-btm"
                            > Max RUWE
                        </b-form-checkbox>                        
                        <b-form-input 
                            id="maxRUWE" 
                            v-model="currFilter.maxRUWE" 
                            v-validate="'between:0.1,10'"
                            data-vv-name="maxRUWE"                               
                            type="number"
                            step="0.1" min="0.1" max="10"
                            v-if="currFilter.maxRUWEEnabled">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('maxRUWE')}}
                        </span>                            
                        <b-form-input 
                            id="maxRUWEOff" 
                            value="N/A"
                            :disabled="true"
                            :readonly="true"
                            v-if="!currFilter.maxRUWEEnabled"
                            class="cb-grayout">
                        </b-form-input>
                        <b-tooltip target="maxRUWE" triggers="hover" interactive="false" placement="bottom" variant="primary" :delay="{ 'show': 1500 }" v-if="currFilter.maxRUWEEnabled">
                            Maximum <a href="https://gea.esac.esa.int/archive/documentation/GDR2/Gaia_archive/chap_datamodel/sec_dm_main_tables/ssec_dm_ruwe.html" target="_blank">RUWE</a> value for the star from the Gaia catalogue
                        </b-tooltip>                                              
                    </div>                         
                </div>        
                <div class="row mx-1" v-if="!currFilter.isShared">
                    <div class="col-2 pb-3 border-left border-bottom border-success">
                        <label for="maxSunAlt" class="">Max Sun Alt (deg)</label>
                        <b-form-input 
                            id="maxSunAlt" 
                            v-model="currFilter.maxSunAlt" 
                            v-validate="'between:-18,0'"
                            data-vv-name="maxSunAlt"                             
                            type="number"
                            step="1" min="-18" max="0"
                            v-if="!currFilter.inclDTO">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('maxSunAlt')}}
                        </span>                           
                        <b-form-input 
                            id="maxSunAltOff" 
                            value="N/A"
                            :disabled="true"
                            :readonly="true"
                            v-if="currFilter.inclDTO"
                            class="cb-grayout">
                        </b-form-input>           
                        <b-tooltip target="maxSunAlt" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }" v-if="!currFilter.inclDTO">
                            Maximum altitude of the Sun below the horizon for the inclusion of twilight events
                        </b-tooltip>                                       
                    </div>                        
                    <div class="col-4 pb-3 border-right border-bottom border-success">
                        <label for="inclDTO" class="">Daytime Events</label>
                        <b-form-checkbox 
                            id="inclDTO"
                            v-model="currFilter.inclDTO"
                            class="cb-mid-align"
                            > <span id="inclDTOText">Include observable daytime events</span>
                        </b-form-checkbox>
                        <b-tooltip target="inclDTOText" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }">
                            When selected the Occult's criteria for inclusion of daytime events will be applied
                        </b-tooltip>                             
                    </div>                                      
                    <div class="col-2"></div>  
                    <div class="col-2 pb-3 border-left border-bottom border-danger">
                        <label for="minProb" class="">Min Probability (%)</label>
                        <b-form-input 
                            id="minProb" 
                            v-model="currFilter.minProb" 
                            v-validate="'between:0,100'"
                            data-vv-name="minProb"                                 
                            type="number"
                            step="5" min="0" max="100"
                            v-if="!currFilter.maxEPWEnabled">
                        </b-form-input>
                        <span v-if="validated" class="text-danger">
                            {{errors.first('minProb')}}
                        </span>                            
                        <b-form-input 
                            id="minProbOff" 
                            value="N/A"
                            :disabled="true"
                            :readonly="true"
                            v-if="currFilter.maxEPWEnabled"
                            class="cb-grayout">
                        </b-form-input>
                        <b-tooltip target="minProb" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }" v-if="!currFilter.maxEPWEnabled">
                            Minimum probability for a positive detection at the selected site
                        </b-tooltip>                                                 
                    </div>                     
                    <div class="col-2 pb-3 border-right border-bottom border-danger">
                        <label for="excBadStars" class="">Stars with Issues</label>
                        <b-form-checkbox 
                            id="excBadStars"
                            v-model="currFilter.excBadStars"
                            class="cb-mid-align"
                            > <span id="excBadStarsText">Exclude events</span>
                        </b-form-checkbox>
                        <b-tooltip target="excBadStarsText" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 1500 }">
                            When selected events involving stars that have any issue flags, will not be included
                        </b-tooltip>                          
                    </div>                                           
                </div>                            

                <div class="row pt-3" v-if="false">
                    <div class="col-12">
                        <span style="color:gray" @click="showSpecializedFilters = !showSpecializedFilters">Specialized Criteria 
                            <i class="fa pr-1 fa-chevron-up" v-if="showSpecializedFilters"></i>
                            <i class="fa pr-1 fa-chevron-down" v-if="!showSpecializedFilters"></i>
                        </span>
                    </div>                    
                    <div class="col-12">
                        <div :class="showSpecializedFilters ? '' : 'collapse'" id="SpecializedFilters">
                            <hr style="height:1px; width:100%; border-width:0; color: rgb(65, 65, 65); background-color: rgb(65, 65, 65)">
                            <div class="row pt-2"  style="display: flex;">
                                <div class="col-12">
                                    <div class="bs-component">
                                        <form>
                                            <fieldset>
                                                <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <label for="asteroidNames" class="">Asteroid Numbers (semicolon separted)</label>
                                                        <input class="form-control" id="asteroidNames">
                                                    </div>                                                 
                                                </div>                                                     
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-2"  style="display: flex;">
                                <div class="col-lg-3">
                                    <label for="minSemMagAxis" class="">Semi-major axis greater than (au)</label>
                                    <b-form-input 
                                        id="minSemMagAxis" 
                                        v-model="currFilter.minSemMagAxis" 
                                        type="number"
                                        step="1" min="0" max="100">
                                    </b-form-input>                                                                                                         
                                </div>
                                <div class="col-lg-3">
                                    <label for="maxSemMagAxis" class="">Semi-major axis less than (au)</label>
                                    <b-form-input 
                                        id="maxSemMagAxis" 
                                        v-model="currFilter.maxSemMagAxis" 
                                        type="number"
                                        step="1" min="0" max="100">
                                    </b-form-input>                                 
                                </div>     
                                <div class="col-lg-6">
                                    <div class="bs-component">
                                        <form>
                                            <fieldset>
                                                <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <label for="asteroidClasses" class="">Asteroid Classes</label>
                                                        <input class="form-control" id="asteroidClasses">
                                                    </div>                                                 
                                                </div>                                                     
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>                                                           
                            </div>                            
                            <div class="row pt-2"  style="display: flex;">
                                <div class="col-12">
                                    <input type="checkbox" class="mr-2">with Moons
                                    <input type="checkbox" class="mr-2 ml-4">with Rings
                                    <input type="checkbox" class="mr-2 ml-4">with Shape Models
                                    <input type="checkbox" class="mr-2 ml-4">with No Previous Observations
                                </div>
                            </div>
                        </div>     
                    </div>                                 
                </div>                
            </div>
            <div class="col-12" v-if="!currFilter.isReadOnly">
                <div v-if="currFilter.isShared && !currFilter.isReadOnly">
                    <i class="fa fa-warning warn-strong"></i>
                    <small class="px-2"><span class="text-danger mx-1"><strong>If you modify this Regional Events Filter the changes will apply for everyone who is using your filter</strong></span></small>
                </div>
                <div class="row pb-3">
                    <hr style="height:1px; width:97%; border-width:0; color:gray; background-color:gray">
                    <div class="col-5"></div>
                    <div class="col-2">
                        <button type="button" class="btn btn-primary align-self-end btn-block mt-2" @click="handleSave()"><b-spinner v-if="isSubmitting" class="justify-content-center mr-2" varient="secondary" style="width: 1rem; height: 1rem;"></b-spinner>Save</button>
                    </div>     
                    <div class="col-2">
                        <button type="button" class="btn btn-secondary align-self-end btn-block mt-2" @click="unselectUnsavedNewFilter()"  v-if="currFilter.isNew">Cancel</button>
                    </div>                         
                    <div class="col-2"></div>           
                    <div class="col-1" v-if="!currFilter.isShared && !currFilter.isNew">
                        <b-dropdown variant="secondary" size="sm" class="align-self-end mt-2">
                            <template #button-content>
                                <i class="fa fa-bars"></i>
                            </template>
                            <b-dropdown-item-button @click="handleDelete()">
                                <i class="fa fa-trash pr-3 text-danger"></i>Delete this filter
                            </b-dropdown-item-button>
                        </b-dropdown>                        
                    </div>                         
                </div>
            </div>
        </div>
        <div class="text-center">
            <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
        </div>        
        <b-modal 
            size="lg"
            v-model="showExplanation"
            title="General Observability Criteria" 
            @ok="showExplanation = false">
                <div class="col-12">
                    <div class="row pt-2"  style="display: flex;">
                        <div class="col-lg-12">
                            <div class="bs-component">
                                    The General Observability Criteria in OWC has been designed to easily exclude events that are not going to be observable because 
                                    they are too short for the given star magnitude to be reliably recorded. The user can also provide a hard limit on the minimum
                                    duration or the magnitude drop or a limit on the star magnitude for exclusion.<br/><br/>
                                    The filtering assumes that video recording will be used for the detection and is based on the aperture of the telescope to be used 
                                    and the minimum desired number of positive detection video frames for the maximum predicted event duration.<br/><br/>
                                    An event will be included if:<br/><br/>
                                    <span style="color: aquamarine;"><b>StarMag</b> &lt; 5 * Log<sub>10</sub>(<b>Aperture</b>) + 2.5 * Log<sub>10</sub>(<b>MaxDuration</b>/<b>DetectionFrames</b>) + 8.5 + <b>MagAdjust</b></span><br/><br/>
                                    The formula allows for a user provided magnitude adjustment factor so it can be tuned to particular personal conditions. 
                                    Proving positive values for MagAdjust will adjust for having better conditions (e.g. darker skies or more sensitive equipment) while negative values
                                    will adjust for having worse conditions (e.g. light polluted skies or less sensitive equipment).<br/><br/>
                                    The formula does not use the star magnitude, the maximum predicted event duration or the magnitude drop directly however the user can configure 
                                    preferred limits for these parameters and events that do not meet these limits will be excluded upfront without applying the formula. These extra 
                                    parameters are included in the criteria as some observers prefer to set them directly as their hard limits of what they are comfortable 
                                    to attempt to observe.<br/><br/>
                                    During the development and testing of the formula it was determined that the predicted magnitude drop does not have significant effect and is not
                                    part of the formula. <br/><br/>
                                    When enabled the formula will also adjust for magnitudes lost due to atmospheric extinction for lower altitude events.
                            </div>                        
                        </div>
                    </div> 
                </div>
                <template #modal-footer>
                    <button v-b-modal.modal-close_visit class="btn btn-primary btn-sm m-1" @click="showExplanation = false">Close</button>
                </template>                  
        </b-modal>        
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmDialogue from '../components/ConfirmDialogue.vue'

export default {
    data() {
        return {
            currFilter: null,
            distanceReferences: [
                {"value": "center", "text": "Center"},
                {"value": "shadow", "text": "Shadow Edge"},
                {"value": "sigma", "text": "1-Sigma Edge"}
            ],
            extinctionReferences: [
                {"value": 0,    "text": "0.00: This feature will be disabled"},
                {"value": 0.15, "text": "0.15: Good mountain night"},
                {"value": 0.20, "text": "0.20: Average mountain night or Good dry night"},
                {"value": 0.25, "text": "0.25: Average dry night or Good humid night"},
                {"value": 0.30, "text": "0.30: Poor mountain night or Average humid night"},
                {"value": 0.35, "text": "0.35: Poor dry night"},
                {"value": 0.40, "text": "0.40: Poor humid night"},
            ],
            newFilterTemplate: { 
                id: null,
                starMag: 15,
                minDur: 0.4,
                magDrop: 0.2,
                distance: 20,
                distFrom: "shadow",
                aperture: 25,
                detectionFrames: 4,
                magAdjust: 0,
                minProb: 0,
                maxEPWEnabled: false,
                maxEPW: 1.00,
                maxRUWEEnabled: true,
                maxRUWE: 1.4,
                excBadStars: true,
                extK: 0.25,
                minAltitude: 5,
                inclDTO: false,
                maxSunAlt: -8,
                selectedSite: null,
                name: "Filter 1",
                isEnabled: false,
                isShared: false,
                isSpecialised: false,
                isReadOnly: false,
                isEditingEnabled: false,
                isNew: true
            },
            showLoader: false,
            isSubmitting: false,
            showSpecializedFilters: false,
            validated: false,
            submitted: true,
            showExplanation: false
        }
    }, 
    components: {
        ConfirmDialogue
    },    
    methods: {
        ...mapActions(['loadUserSites', 'loadUserFilters', 'addUserFilter', 'updateUserFilter', 'deleteUserFilter']),
        selectDefaultFilter(){
            if (this.currentUserFilters != null && this.currentUserFilters.length > 0) 
            {
                var selectedFilter = this.currentUserFilters[0];
                var prevSelectedFilterName = localStorage.getItem('owc-selected-user-filter-name');
                if (prevSelectedFilterName)
                {
                    var prevSelectedFilter = this.currentUserFilters.filter(x => x.name == prevSelectedFilterName);
                    if (prevSelectedFilter && prevSelectedFilter.length == 1) selectedFilter = prevSelectedFilter[0];
                }
                this.currFilter = selectedFilter;
            }
        },
        addNewFilter() {
            this.currFilter = this.newFilterTemplate;
            if (this.currentUserSites.length > 0) 
            {
                this.currFilter.selectedSite = this.currentUserSites[0];
                this.currFilter.siteName = this.currentUserSites[0].name;
            }

            this.showSpecializedFilters = false;
        },
        unselectUnsavedNewFilter(){
            this.currFilter = null;
            this.selectDefaultFilter();
        },
        onCurrFilterChanged(){
            // Remember the current manually selected filter as default selection next time we land here
            localStorage.setItem('owc-selected-user-filter-name', this.currFilter.name);
        },
        showObservabilityExplanation(){
            this.showExplanation = true;
        },
        async handleSave() {
            this.validated = true;
            var valid = await this.$validator.validate();
            if (valid)
            {   
                if (this.currFilter.isNew)
                {
                    let existing = this.currentUserFilters.find(s => s.name.toUpperCase() === this.currFilter.name.toUpperCase());
                    if (existing)
                    {
                        this.showToast(`Filter called ${existing.Name} already exists.`, 'ERROR');
                    }
                    else
                    {
                        this.isSubmitting = true;
                        try
                        { 
                            let form = this.mapFromDisplayObject(this.currFilter);
                            
                            await this.addUserFilter(form)

                            this.showToast('Filter added successfully.', 'SUCCESS');
                            localStorage.setItem('owc-selected-user-filter-name', this.currFilter.name);
                            
                            setTimeout(() => {
                                // Refresh page
                                this.$router.go(0);
                                }, 2000);                              
                        }
                        catch(error)
                        {
                            this.showToast(error.message, 'ERROR');
                        }
                        finally
                        {
                            this.isSubmitting = false;
                        }
                    }                    
                }
                else
                {
                    this.isSubmitting = true;
                    try
                    { 
                        let form = this.mapFromDisplayObject(this.currFilter);
                        
                        await this.updateUserFilter(form);

                        this.showToast('Filter updated successfully.', 'SUCCESS');
                        localStorage.setItem('owc-selected-user-filter-name', this.currFilter.name);

                        setTimeout(() => {
                            // Refresh page
                            this.$router.go(0);
                            }, 2000);                               
                    }
                    catch(error)
                    {
                        this.showToast(error.message, 'ERROR');
                    }
                    finally
                    {
                        this.isSubmitting = false;
                    }                    
                }
            }              
        },
        async handleDelete() {
            if (this.currFilter)
            {
                const ok = await this.$refs.confirmDialogue.show({
                    title: 'Delete Filter',
                    message: `Are you sure you want to delete '${this.currFilter.name}'?`,
                    okButton: 'Delete'
                });
                if (ok)
                {
                    if (!this.currFilter.isNew && this.currFilter.id)
                    {
                        this.isSubmitting = true;
                        try
                        { 
                            await this.deleteUserFilter(this.currFilter.id)

                            this.showToast(`Filter '${this.currFilter.name}' deleted successfully.`, 'SUCCESS');
                            localStorage.setItem('owc-selected-user-filter-name', null);

                            setTimeout(() => {
                                    // Refresh page
                                    this.$router.go(0);
                                    }, 2000);                        
                        }
                        catch(error)
                        {
                            this.showToast(error.message, 'ERROR');
                        }
                        finally
                        {
                            this.isSubmitting = false;
                        }
                    }
                    else
                    {
                        this.unselectUnsavedNewFilter();
                    }
                }
            }
        },
        getSiteByName(name){
            return this.currentUserSites.find(x => x.Name == name);
        },
        mapToDisplayObject(x) {
            var disp = {...x};
            disp.isEnabled = x.status & 1 == 1 ? true : false;
            disp.isShared = x.flags & 1 == 1 ? true : false;
            disp.isSpecialised = x.flags & 2 == 2 ? true : false;
            disp.isReadOnly = disp.isShared;
            if (!disp.isShared)
            {
                var selectedSite = this.getSiteByName(x.siteName);
                if (selectedSite) disp.selectedSite = selectedSite;
                else console.log(`Could not find '${x.siteName}' among current sites`);
            }            
            return disp;
        },
        mapFromDisplayObject(x) {
            
            if (x.isShared)
            {
               return {
                    id: x.id,
                    name: x.name,
                    flags: 1,
                    status: 0,
                    starMag: x.starMag,
                    minDur: x.minDur,
                    magDrop: x.magDrop,
                    aperture: x.aperture,
                    detectionFrames:x.detectionFrames,
                    magAdjust: x.magAdjust
                };
            }
            
            let form = {
                id: x.id,
                name: x.name,
                flags: 0,
                status: 0,
                starMag: x.starMag,
                minDur: x.minDur,
                magDrop: x.magDrop,
                aperture: x.aperture,
                detectionFrames:x.detectionFrames,
                magAdjust: x.magAdjust,
                minAltitude: x.minAltitude,
                extK: x.extK,
                maxSunAlt: x.maxSunAlt,
                inclDTO: x.inclDTO,
                siteName: x.selectedSite.Name,
                distance: x.distance,
                distFrom: x.distFrom,
                maxEPW: x.maxEPW,
                maxEPWEnabled: x.maxEPWEnabled,
                maxRUWE: x.maxRUWE,
                maxRUWEEnabled: x.maxRUWEEnabled,
                minProb: x.minProb,
                excBadStars: x.excBadStars
            };

            if (x.isEnabled) form.status += 1;
            if (x.isShared) form.flags += 1;
            if (x.isSpecialised) form.flags += 2;

            return form;
        }
    },
    async mounted() {
        this.showLoader = true;

        try 
        {
            await this.loadUserSites();
            await this.loadUserFilters();

            this.selectDefaultFilter();

            this.showLoader = false;
        }
        catch(error)
        {
            this.showLoader = false;

            this.showToast(error, 'ERROR');     
        }    
    },
    computed: {
        currentUserSites() {
            return this.$store.getters.currentUserSites;
        },
        currentUserFilters(){
            return this.$store.getters.currentUserFilters.map(x => this.mapToDisplayObject(x));
        },
        numberOfPersonalFilters(){
            return this.currentUserFilters.filter(x => !x.isShared).length;
        },
        canAddMoreFilters(){
            return this.numberOfPersonalFilters < 3;
        },
        currentUserFilterOptions(){
            return this.currentUserFilters.map(x => ({ "value": x, "text": x.name}));
        },
        locations() {
            return this.currentUserSites.map(x => ({ "value": x, "text": x.Name}));
        }
    }     
}
</script>

<style scoped>
 .filterbtn
 {
    padding-top: 30px;
 }

 .filtermsg
 {
    padding-top: 40px;
    text-align: right;
 }

 .cb-mid-align {
    padding-top: 6px;
 }

 .cb-btm-align {
    padding-top: 30px;
 }

 .cb-pad-btm {
    padding-bottom: 6px;
 }
 .cb-grayout {
    background-color: rgb(191, 191, 191);
 } 
</style>