<template>
    <span>
         <span v-if="tag" class="border border-info pull-right tag align-middle pb-1">
             <small class="px-2"> {{tag.agreement}}</small>
         </span>
    </span>
</template>

<script>
export default {
    props: ['tags'],
    data() {
        return {
            tag: null
        }
    },
    mounted() {
        if (this.$props.tags)
        {
            var tagsWithAgreement = this.$props.tags.filter(x => x.agreement);
            if (tagsWithAgreement.length > 0)
            {
                this.tag = tagsWithAgreement[0];
            }  
        }
    }
}
</script>

<style scoped>
span.tag {
    background-color: yellow
}
span.tag small {
    color: black
}
</style>