<template>
    <div>
        <div class="row pt-2 mt-3" v-bind:class="{ 'grayout': showLoader }">
            <div class="col-xl-3 col-lg-4 col-md-4">
                <label for="asteroidNumber">Star Identifier <span class="text-muted"><small><strong class="text-light">(&nbsp;</strong> UCAC4, TYC, HIP, G<strong class="text-light">&nbsp;)</strong></small></span></label>
                <input 
                    v-validate="{required: true}" 
                    v-model="form.starIdentifier" 
                    class="form-control mb-3" 
                    :disabled="showLoader"
                    id="starDesignation" name="starIdentifier">
                <div v-if="validated" class="text-danger">
                        {{errors.first('starIdentifier')}}
                </div>                     
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3">
                <label for="eventDate" class="">&nbsp;</label>
                <button 
                    type="button" 
                    @click="onResolveStar" 
                    :disabled="showLoader"
                    class="btn btn-primary align-self-end btn-block mt-2">Resolve Star</button>    
            </div>                                             
        </div>
        <div class="row mb-4">
            <div class="col-12">
                <b-spinner v-if="!totalEventsInfo" class="justify-content-center" type="grow" varient="secondary" style="width: 1rem; height: 1rem;" ></b-spinner>                    
                <span class="text-muted" v-if="totalEventsInfo"><small>Searching <strong class="text-light">{{totalEventsInfo.totTE | numberWithGroupSeparators}}</strong> events from <strong class="text-light">{{totalEventsInfo.fromDate | formatDayMonth}}</strong> to <strong class="text-light">{{totalEventsInfo.toDate | formatDayMonth}}</strong>, last updated on <strong class="text-light">{{totalEventsInfo.updated | formatDate}}</strong> at <strong class="text-light">{{totalEventsInfo.updated | formatHourMinute}} UT</strong></small></span>
            </div>    
        </div>      
        <div class="row" v-if="resolvedStar">
            <div class="col-md-6 col-12">
                <div>
                    <span class="pr-2">Resolved Star:</span>
                    <a :href="`http://vizier.u-strasbg.fr/viz-bin/VizieR-S?${resolvedStar.name}`" title="Open in VizierR" target=_blank>{{resolvedStar.name}}</a>
                    <span class="pl-2">|</span><StarLink class="pl-2" :gaiaStar="resolvedStar"/>
                    <span class="pl-2">|</span><a :href="`/aladinViewer.html?starId=${resolvedStar.name}&amp;fov=0.02&amp;catalogue=1&amp;ra=${resolvedStar.ra}&amp;de=${resolvedStar.de}`" 
                        target="_blank" 
                        class="pl-2"
                        title='AladinLite image with GaiaEDR3 overlay and marked 4" and 8" apertures'>
                        Aladin DSS
                    </a>                     
                </div>
                <div>
                    <small>
                        <span class="text-muted">RA [ICRS]:</span> <RaValue :val="resolvedStar.ra / 15" />
                        <span class="pl-2">|</span><span class="text-muted pl-2">Dec [ICRS]:</span> <DecValue :val="resolvedStar.de" />    
                        <span v-if="this.resolvedStarSearchId != resolvedStar.name">
                            <span class="pl-2">|</span><span class="pl-2">{{this.resolvedStarSearchId}}</span>
                        </span>
                    </small>
                </div>       
            </div>
            <div class="col-xl-2 col-lg-2 col-md-3 col-3 pull-left">
                <button 
                    type="button" 
                    @click="onSearchEvents" 
                    :disabled="showLoader"
                    class="btn btn-primary btn-block mt-2 pull-left">Search Events</button>            
            </div>                
        </div>              
        <div class="row mt-3">
            <div class="col-12 justify-content-center">
                <div class="table-responsive-md" v-if="(submitted || (foundEventsList && foundEventsList.length > 0)) && !showLoader">
                    <table class="table table-dark table-bordered table-hover">
                        <thead class="thead-dark text-center">
                            <tr>
                                <td>Closest Approach, UT</td>
                                <td>Asteroid</td>
                                <td>Star</td>
                                <td>Mag</td>
                                <td>Mag Drop</td>
                                <td>Max Dur.</td>
                                <td>Rank</td>
                            </tr>    
                        </thead>
                        <tbody>
                            <tr style="cursor: pointer;" v-for="evt in foundEventsList" :key="evt.id" @click="openEvent(evt)">
                                <td>{{evt.time}} <TagList v-if="evt.tags" :tags="evt.tags" /></td>
                                <td>{{evt.asteroid}}</td>
                                <td>{{evt.star}}</td>
                                <td class="text-center">{{evt.mag | formatMag}}</td>
                                <td class="text-center">{{evt.magDrop | formatMag}}</td>
                                <td class="text-center">{{evt.maxDur | formatMag}}</td>
                                <td class="text-center">{{evt.rank}}</td>
                            </tr>    
                        </tbody> 
                    </table>
                    <div class="text-muted pt-2" v-if="submitted && foundEventsList"><small>Found <strong class="text-light">{{foundEventsList.length}}</strong> event{{foundEventsList.length > 1 ? 's' : ''}} in <strong class="text-light">{{msElapsed / 1000}}</strong> sec</small></div>
                </div>
            </div>
        </div>     
        <div class="row mt-4">
            <div class="col-12 justify-content-center">
                <div class="text-center">
                    <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
                    <div v-if="showLoader && resolvedStar" class="text-muted pt-4">This search will take a couple of minutes...</div>
                    <div v-if="showLoader && resolvedStar" class="text-muted pt-4"><strong class="text-light">{{elapsedTime | formatMsToMinuteSecond}}</strong></div>
                    <h5 class="text-danger" v-if="foundEventsList && foundEventsList.length < 1 && submitted && !showLoader">No events found</h5>
                    <h5 class="text-danger" v-if="!resolvedStar && resolveSubmitted && !showLoader">{{resolvedStarSearchId}} could not be resolved</h5>
                </div>
            </div>
        </div>                
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import RaValue from '@/components/RaValue.vue'
import DecValue from '@/components/DecValue.vue'
import TagList from '@/components/TagList.vue'
import StarLink from '@/components/StarLink.vue'

export default {
    data() {
        return {
            form: {
                starIdentifier: '',
                resolvedId: '',
                ra: null,
                dec: null
            },
            showLoader: false,
            validated: false,
            submitted: false,
            resolveSubmitted: false,
            startTime: 0,
            msElapsed: 0,
            elapsedTime: 0,
            updateElapsedTimeTimer: 0,
            resolvedStar: null,
            resolvedStarSearchId: null,
            foundEventsList: null
        }
    },
    components: {
        RaValue,
        DecValue,
        TagList,
        StarLink
    },       
    methods: {
        ...mapActions(['resolveStar', 'searchEventsByStar']),
        openEvent(evt) {
            let routeData = this.$router.resolve({ name: 'Event', params: { eventId: evt.id } });
            window.open(routeData.href, '_blank');
        },
        updateElapsedTime() {
            this.elapsedTime = Date.now() - this.startTime;
            this.updateElapsedTimeTimer = setTimeout(this.updateElapsedTime, 1000);
        },
        async onResolveStar()
        {
            this.validated = true;
            var result = await this.$validator.validate();

            if (result)
            {
                this.showLoader = true;
                this.resolveSubmitted = false;
                this.resolvedStar = null;
                this.resolvedStarSearchId = null;
                try 
                {
                    this.startTime = Date.now();
                    this.updateElapsedTime();

                    this.resolvedStarSearchId = this.form.starIdentifier;
                    await this.resolveStar(this.form.starIdentifier);

                    this.resolvedStar = this.$store.state.resolvedStar;
                    this.foundEventsList = null;
                    localStorage.removeItem('owc-star-search-starid');
                    localStorage.removeItem('owc-star-search-list');
                    localStorage.removeItem('owc-star-search-star');
                    this.showLoader = false;
                    this.resolveSubmitted = true;
                }
                catch(error)
                {
                    this.showLoader = false;
                    this.showToast(error.message, 'ERROR');                    
                }
                finally
                {
                    clearTimeout(this.updateElapsedTimeTimer);
                }

                this.validated = false;
            }
        },
        async onSearchEvents()
        {
                this.showLoader = true;

                try 
                {
                    this.startTime = Date.now();
                    this.updateElapsedTime();

                    await this.searchEventsByStar(
                            { 
                                ra: this.resolvedStar.ra, 
                                de: this.resolvedStar.de,
                                id: this.form.starIdentifier
                            });
                            
                    this.msElapsed = Date.now() - this.startTime;

                    this.foundEventsList = this.$store.getters.eventSearchResult;

                    localStorage.setItem('owc-star-search-starid', this.form.starIdentifier);
                    localStorage.setItem('owc-star-search-list', JSON.stringify(this.foundEventsList));
                    localStorage.setItem('owc-star-search-star', JSON.stringify(this.resolvedStar));

                    this.showLoader = false;
                    this.submitted = true;
                }
                catch(error)
                {
                    this.showLoader = false;
                    this.showToast(error.message, 'ERROR');                    
                }
                finally
                {
                    clearTimeout(this.updateElapsedTimeTimer);
                }
        }
    },
    computed: {
        totalEventsInfo(){
            return this.$store.getters.totalEventsInfoResult ?? null;
        }
    },
    async mounted() {
        try
        {
            if (this.$store.state.eventList && this.$store.state.eventList.form && this.$store.state.eventList.form.type === 'star')
            {
                this.form.starIdentifier = this.$store.state.eventList.form.starIdentifier;
                this.foundEventsList = this.$store.getters.eventSearchResult;
            }
            else if (localStorage.getItem('owc-star-search-starid'))
            {
                this.form.starIdentifier = localStorage.getItem('owc-star-search-starid');
                var cachedListStr = localStorage.getItem('owc-star-search-list');
                if (cachedListStr)
                {
                    this.foundEventsList = JSON.parse(cachedListStr);
                }
                var cachedStarStr = localStorage.getItem('owc-star-search-star');
                if (cachedStarStr)
                {
                    this.resolvedStar = JSON.parse(cachedStarStr);
                }
            }
        }
        catch(error)
        {
            this.showToast(error.message, 'ERROR');                    
        }
    }    
}
</script>

<style scoped>

</style>