import axios from 'axios';
import * as owcMixin from '@/mixins/owcMixin.js';

export default {
    async loadTagPairs ({commit}) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/tag-info`;
            let result = await axios.get(getUrl, { withCredentials: false });

            if (result && result.status === 200)
            {
                commit('SET_TAG_PAIRS', result.data);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async loadPolygonPairs ({commit}) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/geo-poly-info`;
            let result = await axios.get(getUrl, { withCredentials: false });

            if (result && result.status === 200)
            {
                commit('SET_GEOPOLY_PAIRS', result.data);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async loadCommunityTagsInfo ({commit}) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/community-tags`;
            let result = await axios.get(getUrl, { withCredentials: false });

            if (result && result.status === 200)
            {
                commit('SET_COMMUNITY_TAGS', result.data);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async loadCampaignTagsInfo ({commit}) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/campaign-tags`;
            let result = await axios.get(getUrl, { withCredentials: false });

            if (result && result.status === 200)
            {
                commit('SET_CAMPAIGN_TAGS', result.data);
            }
        }
        catch(error){
            throw new Error(error);
        }
    }, 
    async loadTags ({commit}, allTags) {
        try
        {
            await owcMixin.sleep(250);

            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/tags?allTags=${allTags}`;
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                let combinedRes = { 'data': result.data, 'form' : { selectedTagGroup: allTags ? 'a' : 'm' } };

                commit('SET_TAGS', combinedRes);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async loadCommunityTags({commit})
    {
        try
        {
            await owcMixin.sleep(250);

            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/tags/community`;
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                let combinedRes = { 'data': result.data, 'form' : { selectedTagGroup: 'c' } };

                commit('SET_TAGS', combinedRes);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async loadSingleEventUserTags({commit})
    {
        try
        {
            await owcMixin.sleep(250);

            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/tags/single-event-tags`;
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                let combinedRes = { 'data': result.data, 'form' : { selectedTagGroup: 'c' } };

                commit('SET_TAGS', combinedRes);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },    
    async updateTag ({commit}, form) {
        try
        {
            let patchUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/tag/${form.id}`;
            let data =  { desciption: form.descr, url: form.url};

            await axios.patch(patchUrl, data, { withCredentials: true });

            commit('UPDATE_TAG', form);
        }
        catch(error){
            throw new Error(error);
        }
    }, 
    async toggleSelectedTag ({commit}, tagId) {
        commit('TOGGLE_SELECTED_TAG', tagId);
    },  
    async clearSelectedTags ({commit}) {
        commit('CLEAR_SELECTED_TAGS');
    },
    async loadTagUsage({commit})
    {
        try
        {
            await owcMixin.sleep(250);

            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/tag-usage`;
            let result = await axios.get(getUrl, { withCredentials: false });

            if (result && result.status === 200)
            {
                commit('SET_TAG_USAGE', result.data);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },    
}