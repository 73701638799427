import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import axios from 'axios'
import store from '../store'

Vue.use(VueRouter)

const indexRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: "OWC", public: true}
  }, 
  {
    path: '/about',
    name: 'About',
    meta: { title: "About OWC", public: true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

import authRoutes from '@/router/authRoutes.js'
import eventRoutes from '@/router/eventRoutes.js'
import extRoutes from '@/router/extRoutes.js'
import taggingRoutes from '@/router/taggingRoutes.js'

const routes = [
  ...indexRoutes,
  ...authRoutes,
  ...eventRoutes,
  ...extRoutes,
  ...taggingRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const debug = process.env.NODE_ENV != 'production';
const publicRouteNames = routes.filter(x => x.meta && x.meta.public).map(x => x.name);

router.beforeEach(async (to, from , next) => {
  var authRequired = to.name && !publicRouteNames.includes(to.name);
  let loggedIn = localStorage.getItem('owc-user');
  let loggedInUser = loggedIn != null ? JSON.parse(loggedIn) : null;
  let loggedInUserId = loggedInUser != null ? loggedInUser.login : null;

  if (debug)
  {
    console.log(`Router path: ${to.path} (authRequired: ${authRequired}, loggedInUserId: ${loggedInUserId})`);
  }

  if (authRequired && !loggedInUserId) {
    console.log('Login required for ', to.path);
    return next('/login');
  }

  if (loggedInUserId !== null && store.state.user === null)
  {
     if (loggedInUser)
     {
        let sessDurMin = localStorage.getItem('owc-user-session-duration');
        let sessLastReq = localStorage.getItem('owc-user-last-request-time');
        var expired = false;
        if (sessDurMin && sessLastReq)
        {
            if (debug)
            {
              console.log(`Found existing session with duration: ${sessDurMin} min, expiring in ${((parseInt(sessLastReq) + parseInt(sessDurMin) * 60000 - new Date().getTime()) / 1000).toFixed()} sec`);
            }

            if (parseInt(sessLastReq) + parseInt(sessDurMin) * 60000 < new Date().getTime())
            {
               localStorage.removeItem('owc-user');
               expired = true;
            }
        }

        if (debug)
        {
           if (expired)
             console.log(`Found expired session in localStorage`);
           else 
             console.log('Restoring logged in user from localStorage');
        }

        if (!expired)
        {
          store.commit('SET_LOGGED_IN_USER', loggedInUser);
          authRequired = false;  
        }
        else
        {
          console.log(`New authentication will be requried for this request as the previous session has expired.`);
          authRequired = true;  
        }
     }

     if (authRequired)
     {
        if (debug)
        {
            console.log(`ensureCurrentUser; loggedInUserId:${loggedInUserId}, store.state.user:${store.state.user}`);
        }
        if (to.name != 'Login' && to.name != 'RestoreSession' && localStorage.getItem('owc-user-remember') === 't')
        {
            // Attempt to restore session from saved credentials
            console.log(`Restoring previous session as authentication is requried`);
            return next(`/restore-session?redirectTo=${encodeURIComponent(to.path)}`);
        }
        else
        {
            return next('/login');
        } 
     }
  }

  next();
})

router.afterEach((to) => {
  Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title : 'OccultWatcher Cloud';
    
    var isLightTheme = localStorage.getItem('theme') === 'light';
   
    document.getElementById('mainTheme').href = isLightTheme ? process.env.VUE_APP_BS_FLATLY_URL : process.env.VUE_APP_BS_DARKLY_URL;
    document.getElementById('owcStyle').href = isLightTheme ? process.env.VUE_APP_OWC_LIGHT_URL : process.env.VUE_APP_OWC_DARK_URL;
  });
})

/*
if (debug)
{
  axios.interceptors.request.use(request => {
    console.log('Starting Request', JSON.stringify(request, null, 2))
    return request
  })  
}
*/

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(
  function (response) {
    if (response.config.withCredentials === true)
    {
       if (debug)
       {
          console.log('Successful request with auth. Updating session last request time.');
       }
       localStorage.setItem('owc-user-last-request-time', new Date().getTime());
    }
    return response
  }, 
  function (error) {
    try
    {
      if (error.response && error.response.status === 401) {
        if (!error.response.config.url.endsWith('/login') && !error.response.config.url.endsWith('/restore-session'))
        {
          console.log(`Got unauthorised response for ${error.response.config.url}`, error.response.status, error.response);
          if (localStorage.getItem('owc-user-remember') === 't')
          {
              // Attempt to restore session from saved credentials
              console.log(`Restoring previous session as last request returned 401 and there are saved user credentials.`);
              let suffix = error.response.config.method == 'post' ? '&failedPost=1' : '';
              router.push(`/restore-session?redirectTo=${encodeURIComponent(router.currentRoute.fullPath)}${suffix}`);
          }
          else
          {
              store.dispatch('logoutUser');
              router.push({ name: 'Login' });
          }
        }
        else
        {
          if (error.response.config.headers["OW-Session"])
          {
              console.log('Got unauthorised response while attempting to log in with owcSessionId. Redirecting for manual login...');
              var redirectPath = router.currentRoute.fullPath;
              var lastChunkPos = redirectPath.indexOf('&owcSessionId=');
              if (lastChunkPos > -1) redirectPath = redirectPath.substring(0, lastChunkPos);

              if (localStorage.getItem('owc-user-remember') === 't')
              {
                  // Attempt to restore session from saved credentials
                  console.log(`Restoring previous session as last request returned 401 and there are saved user credentials.`);
                  router.push(`/restore-session?redirectTo=${encodeURIComponent(redirectPath)}`);
              }
              else
              {
                router.push(`/login?redirectTo=${encodeURIComponent(redirectPath)}`);
              }
          }
          else
          {
              console.log('Got unauthorised response while attempting to log in');
          }          
        }
      }
      else if (error.response && error.response.status === 400) {
         return error.response.data ? Promise.reject(error.response.data) : Promise.reject(error);
      }
    }
    catch(err)
    {
      console.log('Error processing error response', err);
    }

    return Promise.reject(error);
  }
);

export default router
