<template>
    <div>
        <nav class="nav navbar-expand-sm" id="top-nav">
            <router-link  class="navbar-brand mx-4 pb-1" to="/">OW Cloud</router-link>
            <div class="collapse navbar-collapse pb-1 mx-3" id="mynav">
                <div class="pt-2 yellow-highlight mr-auto" v-if="!showLoader && foundEventsList && !errored"  style="margin-left: 35px">{{campaignId}} {{titleDescr}}</div>
                <ThemeSelector />
                <LoggedInUserActions 
                    :viewPortVisible="false" 
                    :delayedShow="true" 
                    :redirectToCurrent="true" 
                    ref="logginComponent"/>
            </div>             
        </nav>
        <div class="container-flow px-4">
            <div class="row">
                <div class="col-md-8 col-12 pt-2 mt-3">   
                    <b-tabs content-class="mt-3" v-model="searchTab" v-on:activate-tab="activeTabChanged">
                        <b-tab title="Observed Events" :active="!this.taggedMode"> 
                        </b-tab>
                        <b-tab title="Future Events" :active="this.taggedMode">
                        </b-tab>
                    </b-tabs>      
                    <div class="row pt-2 mt-3" v-bind:class="{ 'grayout': showLoader }">
                        <div class="col-xl-3 col-lg-3 col-md-3">
                            <label for="eventDate" class="">{{dateHeading}}</label>
                            <b-input-group class="mb-3">
                                <input 
                                    class="form-control" 
                                    v-model="form.dateFormatted" type="text"
                                    :disabled="showLoader"
                                    autocomplete="off" id="date" name="date"/>
                                <b-input-group-append>
                                    <b-form-datepicker 
                                        v-model="form.date" 
                                        v-validate="{required: true}" 
                                        data-vv-name="datePicker"
                                        button-only right locale="en-AU" 
                                        aria-controls="example-input"
                                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                        @context="onDatePick">
                                    </b-form-datepicker>
                                </b-input-group-append>
                            </b-input-group>  
                            <div v-if="validated" class="text-danger">
                                    {{errors.first('datePicker')}}
                            </div>                                       
                        </div>    
                        <div class="col-xl-2 col-lg-3 col-md-3">
                            <label for="limit" class="">Max Results</label>
                            <b-button-toolbar key-nav>
                                <b-button-group>
                                    <b-button style="margin-right: 1px" ref="d0" @click="setMaxResults(10)" :selected="this.form.limit === 10">10</b-button>
                                    <b-button style="margin-right: 1px" ref="d1" @click="setMaxResults(20)" :selected="this.form.limit === 20">20</b-button>
                                    <b-button style="margin-right: 1px" ref="d2" @click="setMaxResults(50)" :selected="this.form.limit === 50">50</b-button>
                                    <b-button ref="d3" @click="setMaxResults(100)" :selected="this.form.limit === 100">100</b-button>
                                </b-button-group>
                            </b-button-toolbar>
                        </div>                 
                        <div class="col-xl-2 col-lg-3 col-md-3 mx-4">
                            <label for="button" class="">&nbsp;</label>
                            <button 
                                type="button" 
                                @click="onSearchEvents" 
                                :disabled="showLoader"
                                class="btn btn-primary align-self-end btn-block mt-2">Search</button>    
                        </div>                            
                    </div>                                                  
                    <div class="row">
                        <div class="col-12 justify-content-center">
                            <div class="table-responsive-md" v-if="(submitted || foundEventsList) && !showLoader && this.searchResultTab === this.searchTab">
                                <table class="table table-dark table-bordered table-hover">
                                    <thead class="thead-dark text-center">
                                        <tr>
                                            <td>Closest Approach, UT</td>
                                            <td>Asteroid</td>
                                            <td>Star</td>
                                            <td>Mag</td>
                                            <td>Stations</td>
                                            <td v-if="searchTab === 0">Reports</td>
                                        </tr>    
                                    </thead>
                                    <tbody>
                                        <tr v-for="evt in foundEventsList" :key="evt.id" @click="selectEvent(evt)" :class="selectedEvent && evt.id === selectedEvent.id ? 'highlight' : ''">
                                            <td>{{evt.time}} <TagList v-if="evt.tags" :tags="evt.tags" /></td>
                                            <td><router-link :to="{ name: 'Event', params: {eventId: evt.id}}" target='_blank'>{{evt.asteroid}}</router-link></td>
                                            <td>{{evt.star}}</td>
                                            <td class="text-center">{{evt.mag | formatMag}}</td>
                                            <td class="text-center" v-if="evt.stSmry">{{(evt.stSmry.neg + evt.stSmry.pos + evt.stSmry.norep + evt.stSmry.noobs)}}</td>
                                            <td class="text-center" v-if="!evt.stSmry"></td>
                                            <td class="text-center" v-if="searchTab === 0 && evt.stSmry" style="white-space: nowrap">
                                                <span v-if="evt.stSmry.pos > 0">
                                                    <canvas width="10" height="10" class="mr-1 hit" title="Positive Detections"></canvas>{{evt.stSmry.pos}}
                                                </span>
                                                <span v-if="evt.stSmry.neg > 0">
                                                    <canvas width="10" height="10" class="ml-2 mr-1 miss" title="Negative"></canvas>{{evt.stSmry.neg}}
                                                </span>
                                                <span v-if="evt.stSmry.noobs + evt.stSmry.norep > 0">
                                                    <canvas width="10" height="10" class="ml-2 mr-1 norep" title="Not Reported/Not Observed"></canvas>{{evt.stSmry.noobs + evt.stSmry.norep}}
                                                </span>
                                            </td>
                                            <td class="text-center" v-if="searchTab === 0 && !evt.stSmry"></td>
                                        </tr>    
                                    </tbody> 
                                </table>
                                <div class="text-muted pt-2" v-if="submitted"><small>Found <strong class="text-light">{{foundEventsList.length}}</strong> event{{foundEventsList.length > 1 ? 's' : ''}} in <strong class="text-light">{{msElapsed / 1000}}</strong> sec</small></div>
                            </div>                   
                            <div class="text-center">
                                <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
                                <div v-if="showLoader" class="text-muted pt-4">This search may take several seconds...</div>
                                <h5 class="text-danger" v-if="foundEventsList && foundEventsList.length < 1 && submitted && !showLoader">No events found</h5>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="col-md-4 col-12 mt-4">
                    <div class="pt-4 mt-3">
                        <TagInfo :tag="tagInfo"/>
                    </div>
                    <div class="sticky" >
                        <EarthPlot ref="earthPlot"/>
                        <div class="row" v-if="selectedEvent">
                            <div class="col-lg-12 text-center pt-2" style="color:rgb(0, 188, 140);">
                                <h6><a href="#" @click="openSelectedEvent()">{{selectedEvent.asteroid}} occults {{selectedEvent.star}} on {{selectedEvent.time}}</a></h6>

                                <div v-if="this.selectedEventStations && !showSubmitLoader">
                                    <OccultWatcherPlot ref="owPlot" :occel="selectedEvent.data" :owstations="selectedEventStations"/> 
                                    <StationSort ref="owStations" :owstations="selectedEventStations"/>
                                </div>
                                <b-spinner v-if="showSubmitLoader" class="justify-content-center mt-2" varient="secondary" style="width: 2rem; height: 2rem;" ></b-spinner>
                            </div>                     
                        </div>                    
                    </div>
                </div>
            </div>              
        </div>                
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoggedInUserActions from '@/components/LoggedInUserActions.vue'
import TagList from '@/components/TagList.vue'
import EarthPlot from '@/components/EarthPlot.vue'
import OccultWatcherPlot from '@/components/OccultWatcherPlot.vue'
import StationSort from '@/components/StationSort.vue'
import ThemeSelector from '@/components/ThemeSelector.vue'
import TagInfo from '@/components/TagInfo.vue'

export default {
    data() {
        return {
            campaignId: null,
            tagInfo: null,
            taggedMode: false,
            showLoader: false,
            showSubmitLoader: false,
            titleDescr: 'Campaign',
            errored: false,
            submitted: false,
            foundEventsList: [],
            msElapsed: 0,
            selectedEvent: null,
            selectedEventStations: null,
            validated: false,
            searchTab: 0,
            searchResultTab: 0,
            form: {
                date: '',
                dateFormatted: null,
                limit: 20
            }
        }
    }, 
    components: {
        LoggedInUserActions,
        TagList,
        EarthPlot,
        OccultWatcherPlot,
        StationSort,
        ThemeSelector,
        TagInfo
    },  
    methods: {
        ...mapActions(['searchEventsCampaignIdWithAuth', 'getEventStations']),
        onDatePick(ctx) {
            this.form.dateFormatted = ctx.selectedFormatted;
        },
        async selectEvent(evt) {
            this.selectedEventStations = null;
            this.selectedEvent = evt;
            this.$refs.earthPlot.plotEvent(this.selectedEvent.data);

            if (this.searchTab === 0)
            {
                this.showSubmitLoader = true;
                try
                {
                    await this.getEventStations(evt.id);
                }
                finally
                {
                    this.showSubmitLoader = false;
                }
                
                this.selectedEventStations = this.$store.state.currentEventStations;
                this.addOwStationClasses(this.selectedEventStations);
                this.$refs.owPlot?.plot(this.selectedEvent.data, this.selectedEventStations);
                this.$refs.owStations?.update(this.selectedEventStations);
            }
            else
            {
                this.$refs.owPlot?.plot(this.selectedEvent.data);
            }
        },
        openSelectedEvent() {
            this.openEvent(this.selectedEvent);
        },
        openEvent(evt) {
            if (evt)
            {
                let routeData = this.$router.resolve({ name: 'Event', params: { eventId: this.selectedEvent.id } });
                window.open(routeData.href, '_blank');
            }
        },
        setMaxResults(days){
            this.form.limit = days;
        },
        async activeTabChanged(newTabIndex){
            if (this.searchResultTab != newTabIndex)
            {
                await this.onSearchEvents();
            }
        },
        async onSearchEvents(){
            this.validated = true;
            var result = await this.$validator.validate();

            if (result)
            {
                this.selectedEventStations = null;
                this.selectedEvent = null;
                this.$refs.earthPlot.clear();
                this.showLoader = true;

                try 
                {
                    const startTime = Date.now();

                    await this.searchEventsCampaignIdWithAuth(
                        {
                            observed: this.searchTab == 0,
                            campaignId: this.campaignId,
                            date: this.form.date,
                            limit: this.form.limit
                        });

                    this.msElapsed = Date.now() - startTime;

                    this.submitted = true;
                    this.showLoader = false;

                    this.foundEventsList = this.$store.getters.eventSearchResult;
                    if (this.foundEventsList && this.foundEventsList.length > 0)
                    {
                        this.selectEvent(this.foundEventsList[0]);
                        this.tagInfo = this.foundEventsList[0].tags.filter(x => x.name == this.campaignId)[0];
                    }
                    this.searchResultTab = this.searchTab;
                }
                catch(error)
                {
                    this.showLoader = false;
                    this.errored = true;
                    this.showToast(error.message, 'ERROR');                    
                }
            }
        },
        onKeyDown(event){
            if (this.selectedEvent && this.foundEventsList)
            {
                if (event.key == 'ArrowDown' || event.key == 'ArrowUp')
                {
                    var idx = this.foundEventsList.indexOf(this.selectedEvent);

                    if (event.key == 'ArrowDown' && idx < this.foundEventsList.length - 1)
                        this.selectEvent(this.foundEventsList[idx + 1]);
                    else if (event.key == 'ArrowUp' && idx > 0)
                        this.selectEvent(this.foundEventsList[idx - 1]);
                    
                    event.stopPropagation();
                    event.preventDefault();
                }
            }
        }
    },
    computed:
    {
        dateHeading(){
            return this.searchTab == 0 ? "Older Than" : "Start Date";
        }
    },
    async mounted() {
        this.campaignId = this.$route.params.campaignId;
        this.taggedMode = this.$route.meta.tagged === true;
        if (this.taggedMode) {
            this.searchTab = 1;
            this.titleDescr = 'Events';
        }
        const now = new Date();
        const mnth = `${now.getMonth()+1}`;
        const day = `${now.getDate()}`;
        this.form.date = `${now.getFullYear()}-${mnth.padStart(2, '0')}-${day.padStart(2, '0')}`;

        document.addEventListener('keydown', this.onKeyDown);

        if (!this.taggedMode && this.campaignId !== undefined)
        {
            await this.onSearchEvents();
        }
    },
    destroyed(){
        document.removeEventListener("keydown", this.onKeyDown); 
    }
}
</script>

<style scoped>
    div.sticky {
        position: sticky;
        top: 0;      
    }

 .btn-group button[selected='selected']
 {
    border-color: #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
 }

.btn-group button:focus {
    border-color: #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
} 

  .miss {
      background: red;
  }

  .hit {
      background:lime;
  } 
  
  .norep {
      background:gray;
  } 
</style>