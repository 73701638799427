<template>
    <div class="mb-1 row">
        <div class="col-md-12 col-12">
            <small class="pr-2">Change prediction: </small>
            <select class="small" @change="predictionChanged($event)">
                <option v-for="pred in preds" :key="pred.id" :value="pred" :selected="pred.sel">
                    {{pred.default ? '[default] ' : ''}}{{!pred.default & pred.preferred ? '[preferred] ' : ''}}{{pred.orbSrc}}{{pred.orbSrc ? '/' : ''}}{{pred.starSrc}}{{pred.orbSrc || pred.starSrc ? ', ' : ''}}last upd: {{pred.updated | formatDayHourMinuteOrDayOnly}} by {{pred.user}}<span v-if="pred.orbDate">, orbit date: {{pred.orbDate | formatDate}}</span>
                    <OrbitSolutionInfo :orbSlnId="pred.orbSlnId"/>
                </option>
            </select>
        </div>
    </div>
</template>

<script>

import OrbitSolutionInfo from '@/components/OrbitSolutionInfo.vue'

export default {
    props: ['eventId', 'preds'], 
    components: {
        OrbitSolutionInfo
    },
    methods: {
        predictionChanged(event) {
            let selectedPrediction = this.preds[event.target.options.selectedIndex];
            if (selectedPrediction.sel !== true)
            {
                let predSrc = `${selectedPrediction.orbSrc};${selectedPrediction.starSrc};${selectedPrediction.id}`;
                console.log(`Navigating away to: ${predSrc}`);
                let routeData = this.$router.resolve({ name: 'Prediction', params: { eventId: this.eventId, predSrc: predSrc} });
                window.location.href = routeData.href;
            }
        }
    }
}
</script>

<style scoped>
.small {
    font-size: 12px
}
</style>