<template>
    <div>
       <div class="container-fluid">
            <div class="row pt-2 mt-3" :class="showLogLoader ? 'disabled-control' : ''">
                <div class="col-12 justify-content-center">
                    <div class="table-responsive-md" v-if="(submitted || foundFileList) && !showLoader">
                        <table class="table table-dark table-bordered table-hover">
                            <thead class="thead-dark text-center">
                                <tr>
                                    <td>Uploaded</td>
                                    <td>File</td>
                                    <td>Period</td>
                                    <td>Events</td>
                                    <td v-if="isSystemAdmin">User</td>
                                    <td>Status</td>
                                    <td>Prediction Source</td>
                                    <td>Newly Tagged</td>
                                    <td>Notes</td>
                                </tr>    
                            </thead>
                            <tbody>
                                <tr v-for="file in foundFileList" :key="file.id" :class="{ 'table-secondary': file.id === selectedId }" @click="showLog(file)">
                                    <td class="text-center">{{file.created | formatDate}}</td>
                                    <td><span class="badge badge-owc mx-1">{{file.tag.name}}</span> {{file.file}}</td>
                                    <td class="text-center"><Period :from='file.from' :to='file.to' /></td>
                                    <td class="text-center">{{file.total}}</td>
                                    <td v-if="isSystemAdmin">{{file.user}}</td>
                                    <td class="text-center">
                                        <i class="fa fa-check pr-1" style="color: limegreen" v-if="file.status=='Completed'"></i>
                                        <i class="fa fa-hourglass-o pr-2" style="color: gray" v-if="file.status=='Pending'"></i>
                                        <i class="fa fa-hourglass-half pr-2" style="color: yellow" v-if="file.status=='Processing'"></i>
                                        <i class="fa fa-close pr-1" style="color: red" v-if="file.status=='Errored'"></i>
                                        {{file.status}}
                                        <span class="text-muted pt-2" v-if="file.status=='Completed'"><small>(in <strong class="text-light">{{(new Date(file.completed) - new Date(file.created)) / 1000 | formatFixed1}}</strong> sec)</small></span>
                                        <span>
                                        <a href="#" @click.stop="reRunFile(file.id)" v-if="isSystemAdmin && (file.canrerun || file.errored > 0)"
                                                class="pull-right"><i class="fa fa-refresh pr-1" style="color: limegreen"></i>Retry</a> 
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <span v-if="file.useowc === true">OWC</span>
                                        <span v-if="file.useowc === false">{{file.orbit}} / {{file.star}}</span>
                                    </td>
                                    <td class="text-center">{{file.newTags}}</td>
                                    <td class="text-center">
                                        <span v-if="file.sharedTags && file.sharedTags > 0" class="badge badge-info mx-1" :title="`${file.sharedTags} (${(100 * file.sharedTags/file.total).toFixed()}%) events have been included in other feeds.`">{{file.sharedTags}} Shared</span>
                                        <span v-if="isSystemAdmin && file.notInOwc" class="badge badge-owc-err mx-1">{{file.notInOwc}} Not in OWC</span>
                                        <span v-if="isSystemAdmin && file.ambigious" class="badge badge-owc-warn mx-1" :title="`${file.ambigious} events could not be matched to a single event.`">{{file.ambigious}} Ambigious</span>
                                        <span v-if="file.errored" class="badge badge-danger mx-1">{{(file.errored)}} Errored</span>
                                    </td>
                                </tr>    
                            </tbody> 
                        </table>
                    </div>
                    <div> 
                        <div class="row">
                            <div class="col-8">
                                <b-pagination
                                    v-if="(submitted || foundFileList) && !showLoader"
                                    v-model="form.pageNo"
                                    :total-rows="form.totalRows"
                                    :per-page="form.limit"
                                    @change="pageChange"
                                ></b-pagination>
                            </div>
                            <div class="col-4">
                                <button 
                                    type="button" 
                                    @click="loadFileHistory" 
                                    v-if="!showLoader"
                                    class="btn btn-primary pull-right">Refresh History</button> 

                                <span class="text-muted pt-2 pr-4 pull-right" v-if="autoRefreshCountdown > 0"><small>Auto-refresh in <strong class="text-light">{{autoRefreshCountdown}}</strong> sec</small></span>                                
                            </div>     
                        </div>
                    </div>   
                    <div class="text-center">
                        <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
                        <h5 class="text-danger" v-if="foundFileList.length < 1 && submitted && !showLoader">No previous files found</h5>
                    </div>
                </div>
            </div> 
            <div class="row pt-2 mt-3">
                <div class="col-12 justify-content-center text-center">
                    <b-spinner v-if="showLogLoader" class="center-text" varient="secondary" style="width: 2rem; height: 2rem;" ></b-spinner>
                </div>
                <div class="col-12 justify-content-center" v-if="selectedLog && !showLoader && !showLogLoader">
                    <pre class="info-bg px-2 py-2">{{selectedLog}}</pre>
                </div>
            </div>                     
       </div> 
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Period from '@/components/Period.vue'

export default {
    data() {
        return {
            form: {
                limit: 10,
                pageNo: 1,
                totalRows: 0
            },
            selectedLog: '',
            selectedId: -1,
            submitted: false,
            showLoader: false,
            showLogLoader: false,
            autoRefreshTimer: 0,
            autoRefreshCountdown: 0
        }
    },
    components: {
        Period
    },
    methods: {
        ...mapActions(['loadUploadEventsInfo', 'loadUploadEventLog', 'reProcessFile']),
        async pageChange(page){
            this.form.pageNo = page;
            await this.loadFileHistory();
        },
        async loadFileHistory() {
            this.selectedLog = '';
            this.selectedId = -1;
            this.showLoader = true;

            try 
            {
                clearTimeout(this.autoRefreshTimer);

                await this.loadUploadEventsInfo({pageNo: this.form.pageNo, pageSize: this.form.limit});

                this.form.totalRows = this.$store.getters.uploadEventsTotalRows;

                this.showLoader = false;
                this.submitted = true;

                let hasPendingOrRunningItems = this.foundFileList.some(x => x.status=='Pending' || x.status=='Processing');
                if (hasPendingOrRunningItems)
                {
                    this.autoRefreshCountdown = 5;
                    this.autoRefreshTimer = setTimeout(this.autoRefreshTick, 1000);
                }
            }
            catch(error)
            {
                this.showLoader = false;
                this.showToast(error.message, 'ERROR');                    
            }
        },
        autoRefreshTick() {
            this.autoRefreshCountdown--;
            if (this.autoRefreshCountdown === 0)
            {
                this.loadFileHistory();
            }
            else
            {
                this.autoRefreshTimer = setTimeout(this.autoRefreshTick, 1000);
            }
        },
        async showLog(entry) {
            this.showLogLoader = true;
            this.selectedId = entry.id;

            try 
            {
                clearTimeout(this.autoRefreshTimer);

                await this.loadUploadEventLog(entry.id);

                this.selectedLog = this.$store.getters.uploadEventLog;

                this.showLogLoader = false;
            }
            catch(error)
            {
                this.showLogLoader = false;
                this.showToast(error.message, 'ERROR');                    
            }
        },
        async reRunFile(fileId){
            this.showLogLoader = true;

            try 
            {
                clearTimeout(this.autoRefreshTimer);

                await this.reProcessFile(fileId);
                
                this.showLogLoader = false;
            }
            catch(error)
            {
                this.showLogLoader = false;
                this.showToast(error.message, 'ERROR');
                return;       
            }

            await this.loadFileHistory();
        }
    },
    computed: {
        foundFileList(){
            return this.$store.getters.uploadEventsList;
        },
        isSystemAdmin(){
            return this.$store.getters.isSystemAdmin;
        }      
    },
    async mounted() {
        if (this.isSystemAdmin) this.form.limit = 20;
        await this.loadFileHistory();
    }
}
</script>

<style scoped>
 .btn-group button[selected='selected']
 {
    border-color: #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
 }

.btn-group button:focus {
    border-color: #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
}
</style>