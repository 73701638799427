<template>
    <div class="container">
        <div class="row pt-4"  style="display: flex;">
            <div class="col-lg-8 border mx-auto" v-if="!showLoader">
                <div class="bs-component">
                    <form>
                        <fieldset>                           
                            <div class="form-row">
                                <div class="form-group col-md-12 pt-4">
                                    <label for="occelmntFile" class="">.occlemnt File to Upload</label> <span class="text-muted" v-if="totalEventsInfo"><small> (Accepting events from <strong class="text-light">{{totalEventsInfo.fromDate | formatDate}}</strong> to <strong class="text-light">{{totalEventsInfo.toDate | formatDate}}</strong>)</small></span>
                                    <div class="custom-file">
                                        <input 
                                            type="file" 
                                            class="custom-file-input" 
                                            id="customFileInput" 
                                            @change="onFileChange"
                                            aria-describedby="customFileInput">
                                        <label class="custom-file-label" for="customFileInput">{{selectedFile}}</label>
                                    </div>
                                    <input 
                                        type="hidden" 
                                        v-model="form.occfile"
                                        v-validate="{required: true}"
                                        data-vv-name="uploadedFile"/>
                                    <b-progress v-if="totalBytes" :value="uploadedBytes" :max="totalBytes" show-progress animated></b-progress>
                                    <div v-if="form.occfile">
                                        <small class="form-text" v-if="form.occfile.error"><i class="fa fa-frown-o pr-1" style="color: red"></i> {{form.occfile.error}}</small>
                                        <small class="form-text" v-if="form.occfile.warn"><i class="fa fa-warning pr-1" style="color: orange"></i> {{form.occfile.warn}}</small>
                                        <small class="form-text" v-if="form.occfile.info"><i class="fa fa-check-square-o pr-1" style="color: limegreen"></i> {{form.occfile.info}}</small>
                                        <small class="form-text" v-if="form.occfile.info2"><i class="fa fa-check-square-o pr-1" style="color: limegreen"></i> {{form.occfile.info2}}</small>
                                        <small class="form-text" v-if="form.occfile.info3"><i class="fa fa-check-square-o pr-1" style="color: limegreen"></i> {{form.occfile.info3}}</small>
                                    </div>
                                    <span v-if="validated" class="text-danger">
                                    {{errors.first('uploadedFile')}}
                                    </span>                                    
                                </div>                                                 
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12 remove-badges">
                                    <div class="float-left pr-2">
                                        Tag to apply to uploaded events:
                                    </div>
                                    <AppliedUserTags :validated="this.validated"/>
                                </div>   
                            </div>
                            <div class="form-row pl-2">
                                <div class="form-group col-md-6 border border-secondary" style="height:80px; overflow-y: scroll;">
                                    <AvailableUserTags :singleSelection="true"/>
                                </div>
                                <div class="col-md-8"></div>                                 
                            </div>
                            <div class="form-row pt-4">
                                <div class="form-group col-md-12">
                                    <input type="radio" v-model="form.useCloudEvents" value="true" class="form-radio-input" name="predUsage" id="tagDescr" checked>
                                    <label for="tagDescr" class="pl-1">Use occultation prediction elements computed by OW Cloud</label>
                                </div>                                                 
                            </div>
                            <div class="form-row">
                                <div class="col-md-1 text-right">
                                    <i class="fa fa-info-circle"></i>
                                </div>
                                <div class="col-md-11">
                                    <small class="form-text text-muted">If the event has not been computed by OW Cloud then the occultation prediction elements from the uploaded file will be used.</small>
                                </div>
                            </div>                            
                            <div class="form-row pt-4">
                                <div class="form-group col-md-12">
                                    <input type="radio" v-model="form.useCloudEvents" value="false" class="form-radio-input" name="predUsage" id="tagDescr">
                                    <label for="tagDescr" class="pl-1">Use occultation prediction elements from the uploaded file</label>
                                </div>                                                 
                            </div>           
                            <div class="form-row">
                                <div class="col-md-1 text-right">
                                    <i class="fa fa-info-circle"></i>
                                </div>
                                <div class="col-md-11">
                                    <small class="form-text text-muted">OW Cloud automatically computes and keeps up-to-date multiple predictions using the latest updates from all of the following orbits sources: <strong class="text-light">Horizons</strong>, <strong class="text-light">AstOrb</strong>, <strong class="text-light">MPC</strong> and <strong class="text-light">AstDyS</strong> and using the star positions from the latest <strong class="text-light">Gaia</strong> data release. </small>
                                    <small class="form-text text-muted">Only use the predictions from the uploaded file if they have been generated using a different orbit source and/or star coordinates source. This option is typically reserved for orbits produced with a manual orbit fitting using custom object astrometry and/or using custom star astrometry.</small>
                                </div>
                            </div>
                            <div class="form-row pt-4">
                                <div class="col-md-1"></div>
                                <div class="form-group col-md-5">
                                    <label for="orbitSource" class="">Orbit Source</label>
                                    <b-form-select 
                                        class="form-control" 
                                        :class="{ 'disabled-control': form.useCloudEvents == 'true' }"
                                        v-model="form.orbitSource" 
                                        v-validate="{required: form.useCloudEvents === false}"
                                        data-vv-name="orbitSource"                                        
                                        id="orbitSource"
                                        :options="orbitSources">
                                    </b-form-select>

                                    <span v-if="validated" class="text-danger">
                                        {{errors.first('orbitSource')}}
                                    </span>                                       
                                </div>
                                <div class="form-group col-md-5">
                                    <label for="starSource" class="">Star Coordinates Source</label>
                                    <b-form-select 
                                        class="form-control" 
                                        :class="{ 'disabled-control': form.useCloudEvents == 'true' }"
                                        v-validate="{required: form.useCloudEvents === false}"
                                        data-vv-name="starSource"
                                        v-model="form.starSource" 
                                        id="starSource" 
                                        :options="starSources">
                                    </b-form-select>
                                    <span v-if="validated" class="text-danger">
                                        {{errors.first('starSource')}}
                                    </span>                                          
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                            <div class="form-row pt-2">
                                <div class="col-12">
                                    <hr style="height:1px; width:100%; border-width:0; color:gray; background-color:gray">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <button 
                                        type="button" 
                                        @click="onUploadEvents" 
                                        :disabled="showLoader"
                                        class="btn btn-primary">Upload</button>

                                    <button 
                                        type="button" 
                                        @click="onShowHistory" 
                                        :disabled="showLoader"
                                        class="btn btn-secondary pull-right">Show History</button>                                        
                                </div>
                            </div>                                     
                        </fieldset>
                    </form>
                </div>                        
            </div>
        </div>      
        <div class="row">
            <div class="col-12 text-center" v-if="showLoader">
                <b-spinner v-if="showLoader" class="justify-content-center mt-5" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
            </div>                     
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import AppliedUserTags from '@/components/AppliedUserTags.vue'
import AvailableUserTags from '@/components/AvailableUserTags.vue'
import { FileUploadService } from 'v-file-upload'
import axios from 'axios'

export default {
    data() {
        return {
            form: {
                useCloudEvents: false,
                orbitSource : '',
                starSource: '',
                occfile: null
            },
            showLoader: false,
            validated : false,
            submitted : true,
            selectedFile: 'Select file',
            uploadedBytes : null,
            totalBytes: null,
            fileUploaded: false,
            totalEventsInfo: null
        }
    },
    components: {
        AppliedUserTags,
        AvailableUserTags
    },
    methods: {
        ...mapActions(['loadTags', 'loadPredictionSources', 'clearSelectedTags', 'getTotalEventsInfo']),
        async onUploadEvents() {
            this.validated = true;
            var result = await this.$validator.validate();

            if (result)
            {
                if (this.form.occfile.valid !== true)
                {
                    this.showToast('Cannot upload events. ' + this.form.occfile.error, 'ERROR');
                    return;
                }

                this.showLoader = true;

                try 
                {
                    var data = {
                        useCloudEvents: this.form.useCloudEvents,
                        orbitSource: this.form.orbitSource,
                        starSource: this.form.starSource,
                        tagId: this.$store.getters.selectedTag.id,
                        fileId: this.form.occfile.id
                    }

                    let url = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/upload-events`;
                    await axios.post(url, data, { withCredentials: true });

                    this.showToast(`File '${this.selectedFile}' submitted for processing.`, 'SUCCESS'); 

                    setTimeout(() => this.$router.push({ name: 'UploadEventsHistory'}), 2000);

                    this.showLoader = false;
                    this.submitted = true;
                }
                catch(error)
                {
                    this.showLoader = false;
                    this.showToast(error, 'ERROR');                    
                }

                this.validated = false;
            }
        },
        async onShowHistory() {
            this.$router.push({ name: 'UploadEventsHistory'});
        },
        onFileChange(evt) {
            if(evt.target.files.length == 1)
            {
                let occElFile = evt.target.files[0];
                this.uploadOccelFile(occElFile);
            }
        },
        uploadOccelFile(file) {
            let uploadUrl = process.env.VUE_APP_OWC_WEB_API_BASE_URL + `/upload-file?uid=${this.$store.getters.currentUser.id}&type=1`;
            this.form.occfile = null;
            let fileUpload = new FileUploadService(uploadUrl, { }, this.onProgress);
            fileUpload
                .upload(file, { doc_id: 1 })
                .then(e => {
                    // Handle success
                    this.fileUploaded = true;
                    this.totalBytes = null;
                    this.selectedFile = file.name;
                    this.form.occfile = e.target.response;

                    if (!this.form.occfile.valid)
                    {
                        this.showToast(this.form.occfile.error, 'ERROR');
                    }
                })
                .catch(e => {
                    // Handle error
                    if (e.target.status == 413)
                    {
                        this.showToast('The selected file is too large.', 'ERROR');
                    }
                    else 
                    {
                        console.log(e);
                        this.showToast(e.target.response.Message ?? e.target.response, 'ERROR'); 
                    }
                    
                    this.totalBytes = null;
                })
        },
        onProgress(event) {
            this.uploadedBytes = event.loaded;
            this.totalBytes = event.total;
        }
    },
    async mounted() {
        this.showLoader = true;

        try 
        {
            this.clearSelectedTags();
            await this.loadTags(this.isSystemAdmin);
            await this.loadPredictionSources();

            this.showLoader = false;
        }
        catch(error)
        {
            this.showLoader = false;

            this.showToast(error, 'ERROR');     
        }
        
        if (this.$store.getters.totalEventsInfoResult == null || !this.$store.getters.totalEventsInfoResult.cached || (Date.now() - this.$store.getters.totalEventsInfoResult.cached) > 1000 * 3600)
        {
            await this.getTotalEventsInfo();
        }
        this.totalEventsInfo = this.$store.getters.totalEventsInfoResult;        
    },
    computed: {
        foundTags(){
            return this.$store.getters.loadedTags;
        },
        orbitSources(){
            return this.$store.getters.orbitSources;
        },
        starSources(){
            return this.$store.getters.starSources;
        },
        isSystemAdmin(){
            return this.$store.getters.isSystemAdmin;
        }
    }    
}
</script>

<style scoped>

</style>