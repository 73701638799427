<template>
    <div class="ow">
        <svg xmlns="http://www.w3.org/2000/svg" :viewBox="`${-1 * (panelWidth / 2)} 0 ${panelWidth} ${panelHeight}`" v-if="plt">

            <rect :x="-1 * (panelWidth / 2)" :width="panelWidth" :height="panelHeight" v-bind:class="themeBackground" /> 
            <rect :x="-1 * plt.hshw" :width="2 * plt.hshw" height="70" class="shadow" />
            <rect :x="-1 * (plt.hsgw + plt.hshw)" :width="plt.hsgw" height="70" class="sigma" />
            <rect :x="plt.hshw" :width="plt.hsgw" height="70" class="sigma" />

            <line x1="0" y1="0" x2="0" y2="70" class="center" />
            <line :x1="-1 * (plt.hshw + 2 * plt.hsgw)" y1="0" :x2="-1 * (plt.hshw + 2 * plt.hsgw)" y2="70" class="sigma" />
            <line :x1="plt.hshw + 2 * plt.hsgw" y1="0" :x2="plt.hshw + 2 * plt.hsgw" y2="70" class="sigma" />
            <line :x1="-1 * (plt.hshw + 3 * plt.hsgw)" y1="0" :x2="-1 * (plt.hshw + 3 * plt.hsgw)" y2="70" class="sigma" />
            <line :x1="plt.hshw + 3 * plt.hsgw" y1="0" :x2="plt.hshw + 3 * plt.hsgw" y2="70" class="sigma" />
            
            <template v-for="station in stations">
                <rect v-bind:key="station.secr" :x="station.cord" y="0" width="5" :height="siteHeight - 1" :class="station.cls" style="stroke-width:1;stroke:rgb(0,0,0)"><title>{{station.descr}}</title></rect>
            </template>
            
            <rect v-if="bid && announcementMode" :x="bid.cord" y="0" width="4" height="30" style="fill:rgb(0,0,0);stroke-width:1;stroke:rgb(255,255,0)"><title>{{bid.descr}}</title></rect>
            
            <line x1="-500" :y1="siteHeight" x2="500" :y2="siteHeight" class="gray" />

            <template v-if="!announcementMode">
                <line x1="-500" y1="71" x2="500" y2="71" class="gray" />
                
                <line x1="0" y1="70" x2="0" y2="78" class="gray" />

                <template v-for="kmTick in kmTicks">
                    <line v-bind:key="kmTick" :x1="kmTick" y1="70" :x2="kmTick" y2="76" class="gray" />
                </template>         

                <line x1="-490" y1="107" x2="-490" y2="133" class="center" />
                <text x="-478" y="127" fill="gray" font-size="x-large">center</text>

                <rect x="-389" y="109" width="22" height="22" style="fill:rgb(0,0,0)" />
                <rect x="-388" y="110" width="20" height="20" class="shadow" />
                <text x="-358" y="127" fill="gray" font-size="x-large">shadow</text>

                <rect x="-261" y="109" width="22" height="22" style="fill:rgb(0,0,0)" />
                <rect x="-260" y="110" width="20" height="20" class="sigma" />
                <text x="-230" y="127" fill="gray" font-size="x-large">1-sigma</text>

                <line x1="-120" y1="107" x2="-120" y2="133" class="sigma"  />
                <text x="-105" y="127" fill="gray" font-size="x-large">2 &amp; 3-sigma</text>

                <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                <path d="M-1,1 l2,-2
                        M0,4 l4,-4
                        M3,5 l2,-2" 
                        style="stroke:rgb(180,180,180); stroke-width:2" />
                </pattern>

                <template v-if="plotScale">
                    <rect :x="panelWidth/2 - plotScale.width - 100" y="118" :width="plotScale.width" height="8" fill="url(#diagonalHatch)"/>
                    <text :x="panelWidth/2 - 80" y="127" fill="gray" font-size="large">{{plotScale.km}} km</text>
                </template>

            </template>
        </svg>         
    </div>
</template>

<script>
export default {
    name: "OccultWatcherPlot",
    props: ['occel', 'owstations', 'legend', 'preview'],
    data(){
        return {
            panelWidth: 1000,
            panelHeight: 140,
            siteHeight: 12,
            hshw: null,
            hsgw: null,
            scale: 1,
            plt: {
                hshw: 0,
                hsgw: 0
            },
            kmTicks: [],
            stations: [],
            plotScale: null,
            announcementMode: false,
            bid: null
        }
    },
    methods: {
        plot(oe, s){
            var oed = oe ?? this.occel;
            var stations = s ?? this.owstations;
            this.announcementMode = this.legend === 'off';
            const previewMode = this.preview === 'on';
            if (!this.announcementMode) this.panelHeight = 140; else this.panelHeight = 30;
            if (oed)
            {
                this.hshw = oed.shadowWidth / 2;
                this.hsgw = (oed.shdwIncrPathWidths - 1) * 2 * this.hshw;
                var maxDist = 2.2 * (this.hshw + 4 * this.hsgw);
                if (this.announcementMode)
                    maxDist = 2.1 * (this.hshw + 3 * this.hsgw);
                if (previewMode)
                {
                    if (stations && stations.length == 1)
                    {
                        var maxStDist = Math.abs(Math.max(...stations.map(x => x.dist)));
                        if (maxStDist < this.hshw + this.hsgw)
                            maxDist = 2.5 * (this.hshw + this.hsgw);
                        else
                        maxDist = 2.5 * maxStDist;
                    }
                }

                this.scale = this.panelWidth / maxDist;
                this.plt = {
                    hshw: this.scale * this.hshw,
                    hsgw: this.scale * this.hsgw,
                }

                var probe = [{d:200, m: 100}, {d:100, m: 50}, {d:40, m:20}, {d:20, m:10}, {d:10, m:5}, {d:4, m:2}, {d:2, m:1}];
                for(var pb of probe)
                {
                    var numTicks = Math.floor(maxDist / pb.d);
                    
                    if (numTicks < 4) continue;
                    
                    this.kmTicks = [];
                    for (var i = -1 * numTicks; i <= numTicks; i++)
                    {
                        if (i !== 0) this.kmTicks.push(i * pb.m * this.scale);
                    }

                    this.plotScale = {
                        km: pb.m,
                        width: pb.m * this.scale
                    }

                    break;
                }

                this.stations = [];
                if (stations)
                {
                    for(var st of stations)
                    {
                        this.stations.push({ cord: st.dist * this.scale, descr: st.descr ?? st.name, cls: st.cls});
                    }
                }
            }
        },
        setBid(bid){
            this.bid = bid;
            this.bid.cord = this.bid.dist * this.scale;
        }
    },
    computed: {
        themeBackground: function() {
            var isLightTheme = localStorage.getItem('theme') === 'light';
            return isLightTheme ? "background-light" : "background-dark";
        }
    },
    mounted() {
        this.plot(this.$props.occel);
    }
}
</script>

<style scoped>
  div.ow {
        border-style: solid;
        border-width: 1px;
        border-color: gray;
  }

  .background-light {
      fill:rgb(90, 90, 90)  /* 255, 255, 240 | 13, 13, 10 */
  }

  .background-dark {
      fill:rgb(50,50,50)  /* 255, 255, 240 | 13, 13, 10 */
  }

  .shadow {
      fill:rgb(175,238,238)  /* 175,238,238 | 15,68,68*/
  }

  rect.sigma {
      fill:rgb(255,228,225);  /* 255,228,225 | 55, 5, 0*/
  }

  line.sigma {
      stroke:rgb(255,228,225);
      stroke-width:1
  }

  .gray {
      stroke:rgb(128,128,128);
      stroke-width:1
  }

  .black {
      stroke:rgb(12,12,12);
      stroke-width:3
  }

  .yellow {
      stroke:rgb(255,255,0);
      stroke-width:3
  }

  .center {
      stroke:rgb(88,215,107); /* 88,215,107 | 23,96,34*/
      stroke-width:2 
  }
  .scale {
      fill:rgb(2,2,2)
  }

  .site {
      fill:rgb(140,140,140);
  }

  .miss {
      fill:red;
  }

  .hit {
      fill:lime;
  }

  .noobs {
      fill:black;
  }  

  .norep {
      fill:rgb(80,80,80);
  }  

  .cloud0 {
      fill:rgb(0, 53, 116);
  } 

  .cloud1 {
      fill:rgb(18, 77, 142);
  }   

  .cloud2 {
      fill:rgb(40, 101, 169);
  } 

  .cloud3 {
      fill:rgb(63, 128, 196);
  }    
  .cloud4 {
      fill:rgb(88, 154, 224);
  }   
  .cloud5 {
      fill:rgb(118, 186, 186);
  }   
  .cloud6 {
      fill:rgb(145, 214, 214);
  } 
  .cloud7 {
      fill:rgb(171, 242, 242);
  }    
  .cloud8 {
      fill:rgb(198, 198, 198);
  }   
  .cloud9 {
      fill:rgb(226, 226, 226);
  }    
  .cloud10 {
      fill:rgb(248, 248, 248);
  }    
</style>