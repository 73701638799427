import axios from 'axios';

const API_KEY_JS = 'AIzaSyDnetEVDJxqD7vayAbzFmb19eysTDPOuTk';
const MAPS_WEB_API_BASE_URL = 'https://maps.googleapis.com/maps/api';
//const OPEN_ELEVATION_WEB_API_BASE_URL = 'http://oe-api.occultwatcher.net:8080/api/v1/lookup';
const OPEN_ELEVATION_WEB_API_BASE_URL = 'https://www.occultwatcher.net/api2/v1/owc/oe';

export default {
    async getElevation(_, frm) {
        try
        {
            let getUrl = `${MAPS_WEB_API_BASE_URL}/elevation/json?locations=${frm.lat},${frm.lng}&key=${API_KEY_JS}`;
            
            let result = await axios.get(getUrl);

            if (result && result.status === 'OK')
            {
                return result.results[0].elevation;
            }

            return null;
        }
        catch(error) {
            throw new Error(error);
        } 
    },
    async getOpenElevation(_, frm) {
        try
        {
            //let getUrl = `${OPEN_ELEVATION_WEB_API_BASE_URL}?locations=${frm.lat},${frm.lng}`;
            let getUrl = `${OPEN_ELEVATION_WEB_API_BASE_URL}?lat=${frm.lat}&lng=${frm.lng}`;

            let result = await axios.get(getUrl);
           
            if (result && result.status == 200 && result.data?.results[0] && result.data?.results[0].elevation != null)
            {
                return result.data.results[0].elevation;
            }

            return null;
        }
        catch(error) {
            throw new Error(error);
        } 
    }
}