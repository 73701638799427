<template>
    <span>{{sign}}{{deg}}&deg; {{min}}<sup>&apos;</sup> {{sec_whole}}<sup>&quot;</sup>{{sec_frac}}</span>
</template>

<script>
export default {
    props: ['val'],
    data() {
        return {
            sign: null,
            deg: null,
            min: null,
            sec_whole: null,
            sec_frac: null
        }
    },
    mounted(){
        this.sign = this.val < 0 ? '-' : '+';
        let value = Math.abs(this.val);
        var deg = Math.floor(value);
        var minP = (value - deg) * 60;
        var min = Math.floor(minP);
        var secP = (minP - min) * 60;
        var sec = Math.floor(secP);

        this.deg = (deg + '').padStart(2, '0');
        this.min = (min + '').padStart(2, '0');
        this.sec_whole = (sec + '').padStart(2, '0');
        this.sec_frac = ((secP - sec).toFixed(3) + '').substr(1);
    }
}
</script>

<style scoped>

</style>