import Event from '../views/Event.vue'
import EventPrediction from '../views/EventPrediction.vue'
import EventSearch from '../views/EventSearch.vue'
import EventSearchAdvanced from '../views/EventSearchAdvanced.vue'
import CampaignEvents from '../views/CampaignEvents.vue'

export default [
    {
        path: '/event/:eventId',
        name: 'Event',
        component: Event,
        meta: { title: "Event", headerless: true, public: true }
      },
      {
        path: '/event/:eventId/:predSrc',
        name: 'Prediction',
        component: EventPrediction,
        meta: { title: "Event Prediction", headerless: true, public: true }
      },  
      {
        path: '/events',
        name: 'EventSearch',
        component: EventSearch,
        meta: { title: "Simple Event Search", public: true}
      },
      {
        path: '/search',
        name: 'EventSearchAdvanced',
        component: EventSearchAdvanced,
        meta: { title: "Search Events"}
      },      
      {
        path: '/events/campaign/:campaignId',
        name: 'CampaignEvents',
        component: CampaignEvents,
        meta: { title: "Campaign Events", headerless: true}
      },
      {
        path: '/events/tagged/:campaignId',
        name: 'TaggedEvents',
        component: CampaignEvents,
        meta: { title: "Tagged Events", headerless: true, tagged: true }
      },       
      {
        path: '/campaigns',
        name: 'Campaigns',
        meta: { title: "Observation Campaigns", public: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/Campaigns.vue')
      },
]