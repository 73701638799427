<template>
    <div style="font-size:12px;border: 1px solid #676767;" 
            class="px-2 py-2 panel-light" v-if="currentStation && isEditing">
        <div class="row">
            <div class="float-left pl-3">
                <span style="font-size:12px;" class="" nowrap>Distance:</span>
                <div style="font-size:12px;">{{this.distance | formatCentralDistance}}</div>
            </div>
            <div v-if="currAltMsl != null" class="float-left pl-2">
                <span style="font-size:12px;" nowrap>Altitude:</span>
                <div class="align-middle">
                    <input id="siteAltitude" 
                        type="text" class="edit" style="line-height: normal" size="4"
                        v-model="currAltMsl"
                        :disabled="showLoader"/> m
                </div>
            </div>
            <div class="float-left pl-2">
                <span style="font-size:12px;" nowrap>Site name:</span>
                <div class="align-middle">
                    <input id="siteName" 
                        type="text" class="edit" style="line-height: normal"
                        v-model="currentStation.name"
                        :disabled="showLoader"/>
                </div>
            </div>
            <div class="float-left pl-2">
                <span style="font-size:12px;" nowrap>Method:</span>
                <div class="align-middle">
                    <select id="obsMethod" class="edit" v-model="currentStation.obsm" :disabled="showLoader">
                        <option value="">Please choose...</option>
                        <option value="visual">Visual</option>
                        <option value="video">Video</option>
                        <option value="drift scan">Drift scan</option>
                        <option value="photoelectric">Photoelectric</option>
                        <option value="other">Other</option>
                    </select>
                </div>
            </div>
            <div class="float-left pl-2">
                <span style="font-size:12px;" nowrap>Timing:</span>
                <div class="align-bottom">
                    <select id="timMethod" class="edit" v-model="currentStation.timm" :disabled="showLoader">
                        <option value="">Please choose...</option>
                        <option value="beeper">Beeper-box</option>
                        <option value="GPS">GPS</option>
                        <option value="NTP">NTP</option>
                        <option value="radio">Radio broadcast</option>
                        <option value="other">Other</option>
                    </select>
                </div>
            </div>
            <div class="float-left pl-2">
                <span style="font-size:12px;" nowrap>Show site to others:</span>
                <div class="align-bottom">
                    <select id="locDisplay" class="edit" v-model="currentStation.loc" :disabled="showLoader">
                        <option value="">Please choose...</option>
                        <option value="1">Exact location</option>
                        <option value="2">Exact chord (no location)</option>
                        <option value="0">Inexact chord (1 km privacy)</option>
                    </select>
                </div>
            </div>
            <div class="float-left pl-2">
                <span style="font-size:12px;" nowrap>Comittment:</span>
                <div class="align-bottom">
                    <select id="obsCommitment" class="edit" v-model="currentStation.cmt" :disabled="showLoader">
                        <option value="">Please choose...</option>
                        <option value="0">High</option>
                        <option value="1">Medium</option>
                        <option value="2">Low or inexperienced</option>
                    </select>
                </div>
            </div>
            <div class="pl-2 align-bottom" style="padding-top: 12px">
                <b-button class="btn btn-primary mr-2" style="line-height: normal" :disabled="showLoader" variant="primary" size="sm" @click="onCancelClicked">Cancel</b-button>
                <b-button class="btn btn-primary mr-2" style="line-height: normal" :disabled="showLoader" variant="primary" size="sm" @click="onSubmitClicked">Submit</b-button>
                <b-spinner v-if="showLoader" 
                                        class="justify-content-center mt-1" 
                                        varient="secondary" 
                                        style="width: 1rem; height: 1rem;" ></b-spinner>
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    props: ['stations'],
    data() {
        return {
            currentStation: null,
            dist: null,
            isEditing: false,
            showLoader: false,
            currAltMsl: null
        }
    },       
    methods: {
        editStation(station, altMsl) {
            this.currentStation = {...station};
            this.isEditing = true;
            if (altMsl != null) this.currAltMsl = altMsl;
            else this.currAltMsl = null;
        },
        setDistance(dist) {
            this.dist = dist;
        },
        setPlanningOperationCompleted() {
            this.showLoader = false;
            this.isEditing = false;
        },
        onCancelClicked() {
            this.isEditing = false;
            this.$emit('cancelStationEditing');
        },
        onSubmitClicked() {            
            this.showLoader = true;
            this.$emit('submitStation', this.currentStation, this.currAltMsl);
        }
    },
    computed: {
        distance() {
            return this.dist ?? this.currentStation.dist;
        }
    }   
}
</script>