<template>
    <div>
        <div id="evGmap" class="col-12 border border-primary"></div>
        <div class="pt-1" v-if='this.currentUser'>
            <ObservingSiteEditor 
                :stations="stations"
                @cancelStationEditing="cancelStationEditing"
                @submitStation="submitStation"
                ref="siteEditor"/>
            <ObservationReportEditor
                :stations="stations" 
                @submitObservationReport="submitObservationReport" 
                @cancelReportObservation="cancelReportObservation" 
                ref="reportEditor"/>
        </div>
        <div class="pt-1">
            <OccultWatcherPlot :occel="occel" :owstations="this.owStations" :legend="'off'" ref="owPlot"/>         
        </div>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue> 
    </div>
</template>

<script type="module" src="@/assets/js/fundPlane.js"></script>
<script type="module" src="@/assets/js/xephem.js"></script>

<script>
import { mapActions } from 'vuex';
import { FundPlane } from '@/assets/js/fundPlane.js'
import gmapsInit from '@/assets/js/gmaps';
import { xephem } from '@/assets/js/xephem.js'
import * as owcMixin from '@/mixins/owcMixin.js';
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import ObservingSiteEditor from '@/components/ObservingSiteEditor.vue'
import ObservationReportEditor from '@/components/ObservationReportEditor.vue'
import OccultWatcherPlot from '@/components/OccultWatcherPlot.vue'

let NEW_STATION_CONFIG_KEY = 'owc-new-station-config';
let MARKER_OTHERS_SUBMITTED_STATION = 'https://www.occultwatcher.net/meade_gray_sm.png';
let MARKER_MY_SUBMITTED_STATION = 'https://www.occultwatcher.net/meade_gray.png';
let MARKER_NEW_STATION = 'https://www.occultwatcher.net/meade.png';
let MY_STATION_SZ_X = 45;
let MY_STATION_SZ_Y = 48;
let MY_STATION_ANC_X = 18;
let MY_STATION_ANC_Y = 48;
let OTHERS_STATION_SZ_X = 48;
let OTHERS_STATION_SZ_Y = 53;
let OTHERS_STATION_ANC_X = 29;
let OTHERS_STATION_ANC_Y = 53;
let NEW_STATION_SZ_X = 58;
let NEW_STATION_SZ_Y = 63;
let NEW_STATION_ANC_X = 23;
let NEW_STATION_ANC_Y = 63;

export default {
    name: 'Map',
    props: ['location', 'fPlane', 'midTime', 'toTime','stations', 'occel', 'eventId', 'tags', 'currentUser', 'defaultStationLocation', 'star'],
    data() {
        return {
            map: null,
            occData : null,
            marker : null,
            currentMarker: null,
            currentBidMarker: null,
            currentLocation: null,
            currentDistance: null,
            currentSite: null,
            currentInfoWindow: null,
            isEditingStation: false,
            eventHasPassed: false,
            reportCanBeUpdated: false,
            otherObserversSiteMarkers: [],
            previousSelectedMapLocation: null,
            gMapRenderAreaChangedThrottledTimer: 0,
            poliLines: null,
            debugMode: false,
            tFrom: 0,
            tTo: 0,
            xephem: null,
            cordCircle: null
        }
    },   
    async mounted() {
        this.debugMode = process.env.NODE_ENV != 'production';
        this.xephem = new xephem();

        this.poliLines = new Map();
        try 
        {
            var prevLocData = localStorage.getItem('owc-selected-map-location');
            if (prevLocData) console.log('Previously used map location found', prevLocData);
            this.previousSelectedMapLocation = prevLocData ? JSON.parse(prevLocData) : null;

            this.setSelectedMapLocation(null);

            const google = await gmapsInit();
            this.occData = new FundPlane(this.fPlane);
            this.map = this.initNightViewMap();           
            this.map.mapTypeId = localStorage.getItem("mapTypeId") ?? "hybrid";

            var gep = this.occData.centrePathByTime(0, 0);
            if (gep.isValidPath)
            {
                var gepLoc = new google.maps.LatLng(gep.latDeg, gep.lngDeg);
                this.map.setCenter(gepLoc);
            
                this.calcLocationCircumstances(gep.latDeg, gep.lngDeg, null);
            }
            
            this.plotTwilightLines();

            this.map.addListener("click", this.onGMapClck);
            this.map.addListener("rightclick", this.onGMapClck);     
            this.map.addListener("maptypeid_changed", this.onGMapTypeIdChanged);
            this.map.addListener("bounds_changed", this.onGMapRenderAreaChanged);

            this.eventHasPassed = owcMixin.hasPassedUtc(this.toTime);
            this.reportCanBeUpdated = owcMixin.hasPassedUtc(this.toTime);

            this.otherObserversSiteMarkers = [];
            this.currentLocation = null;
            this.currentDistance = null;

            let zoomLevel = 3;
            let hasOwnStations = false;

            if (this.stations)
            {
                for(var st of this.stations)
                {
                    if (st.own === true)
                    {
                        hasOwnStations = true;
                        st.mk = this.createSubmittedStationMarker(st);
                        this.currentLocation = new google.maps.LatLng(st.lat, st.lng);                        
                        this.currentDistance = st.dist;

                        zoomLevel = this.getZoomLevelForDistance(this.currentDistance);                   
                    }
                    else 
                    {
                        let mk = this.createOtherObserverMarker(st);
                        this.otherObserversSiteMarkers.push(mk);
                        st.mk = mk;
                    }
                }

                if (!this.currentLocation && this.stations.length > 0)
                {
                    let latAvg = this.stations.map(x => x.lat).reduce((a, x) => a + x, 0) / this.stations.length;
                    let lngAvg = this.stations.map(x => x.lng).reduce((a, x) => a + x, 0) / this.stations.length;
                    this.currentLocation = new google.maps.LatLng(latAvg, lngAvg);
                    zoomLevel = 3;                    
                }
            }

            if (hasOwnStations === false && this.currentUser && this.eventHasPassed === false)
            {
                var defaultLocation = this.defaultStationLocation;
                if (!defaultLocation) 
                {
                    defaultLocation = this.previousSelectedMapLocation;
                }

                if (defaultLocation)
                {
                    this.isEditingStation = true;
                    this.currentSite = {...this.getNewStationConfig(), lat: defaultLocation.lat, lng: defaultLocation.lng};            
                    this.calcLocationCircumstances(defaultLocation.lat, defaultLocation.lng, null);
                    this.$refs.siteEditor.editStation(this.currentSite, 0);
                    zoomLevel = this.getZoomLevelForDistance(this.currentDistance);
                }
            }
            
            if (this.currentLocation)
            {
               this.map.setCenter(this.currentLocation, 3);
               this.map.setZoom(zoomLevel);
            }
        }
        catch (error) 
        {
            console.error(error);
        }
    },
    components: {
        ConfirmDialogue,
        ObservingSiteEditor,
        ObservationReportEditor,
        OccultWatcherPlot
    },
    methods: {
        ...mapActions(['setSelectedMapLocation', 'planningCancelStation', 'planningUpdateStation', 'planningAddStation', 'planningReportObservation', 'getOpenElevation']),
        getInfoWindowContent(lat, lng, elevationMeters, pth, locMidTime) {

            let rdSun = this.xephem.getSunPositionFromMoment(locMidTime);            
            let jd = this.xephem.JD_from_Moment(locMidTime);
            let sidTim = this.xephem.siderealTimeDeg(jd);

            var altAzStar = this.xephem.raDecToAltAz(this.star.ra_2000, this.star.de_2000, lat, lng, sidTim);
            var altAzSun = this.xephem.raDecToAltAz(rdSun.ra / 15, rdSun.dec, lat, lng, sidTim);

            let contentString = 
                        '<div id="content" class="pr-2" style="color:black;"><small>' +
                        'Site Lng: <strong>' + this.formatLatLngString(lng) + '</strong>' +
                        ', Lat: <strong>' + this.formatLatLngString(lat) + '</strong>' +
                        (elevationMeters != null ? `, Alt: <strong>${elevationMeters.toFixed()} m</strong><br/>` : '<br/>') +
                        'Altitude Star: <strong>' + this.formatAltAzString(altAzStar[0], altAzStar[1]) + '</strong>' +
                        ', Sun: <strong>' + this.formatAltAzString(altAzSun[0]) + '</strong><br/>' +
                        'Event Mid-Time: <strong>' + this.formatTimeUtc(locMidTime) + '</strong><br/>' +
                        '</small></div>';
            return contentString;
        },
        updatePositionAndChord(lat, lng, altMsl)
        {
            let minPos = this.occData.getTforMinimumDistance(lng, lat, altMsl);
            if (this.debugMode) console.log(`updatePositionAndChord(${lng}, ${lat}, ${altMsl}): ${minPos.minD.toFixed(2)}km, ${(minPos.minD * 2/this.occData.asteroidDiameterAugmented).toFixed(2)}PW ${altMsl != null ? "(altitude corrected)" : ""}`);
            let pth = this.occData.pathByTime(minPos.ta);
            
            if (altMsl != null) 
            {
                this.$refs.siteEditor.editStation(this.currentSite, altMsl);
            }

            let locMidTime = new Date(Date.parse(this.midTime) + 1000 * 3600 * minPos.ta);
            this.currentDistance = minPos.minD;

            this.setSelectedMapLocation({
                    lng: lng,
                    lat: lat,
                    midTime: locMidTime,
                    sunAlt: pth.sunAlt,
                    starAlt : pth.starAlt,
                    starAz: pth.starAz,
                    minD: minPos.minD
                });

            if (this.debugMode) console.log(`locationSelected({ lng: ${lng}, lat: ${lat}, dist: ${minPos.minD}})`);
            let isElevationCorrected = altMsl != null;
            let bidClr = "#777777";
            if (isElevationCorrected) bidClr = "#FFFFFF";
            this.drawPathLineFromTo(this.tFrom, this.tTo, 'curr-bid', bidClr, this.currentDistance);
            this.cordCircle = { isElevationCorrected, minPos, lat, lng };
            this.drawCordCorrectionCircle();

            this.$refs.siteEditor.setDistance(minPos.minD);
            if (this.currentSite) this.$refs.owPlot.setBid({dist:this.currentDistance, descr: this.currentSite.name});
            return { pth, locMidTime }
        },
        drawCordCorrectionCircle() {
            if (this.cordCircle?.isElevationCorrected)
            {
                // Draw line from (lng, lat) to line
                this.drawCordCorrectionPointer(this.cordCircle.minPos, this.cordCircle.lat, this.cordCircle.lng, "bid");
            }
            else
            {
                this.clearCordCorrectionPointer("bid");
            }
        },
        clearCordCorrectionPointer(chordId) {
            let poliLinesKey = chordId + "-pointToCorr";
            if (this.poliLines.has(poliLinesKey)) this.poliLines.get(poliLinesKey).setMap(null);
            let circleKey = chordId + "-circleCorr";
            if (this.poliLines.has(circleKey)) this.poliLines.get(circleKey).setMap(null);
        },
        drawStationCordCorrectionCircle(st) {
            if (st.mk && st.alt > 0)
            {
                let lat = st.mk.position.lat();
                let lng = st.mk.position.lng();
                let pos = this.occData.getTforMinimumDistance(lng, lat, st.alt);
                this.drawCordCorrectionPointer(pos, lat, lng, "st-" + st.no);
            }
        },
        drawCordCorrectionPointer(pos, lat, lng, chordId) {
            let site = this.occData.chordPathByTime(pos.ta, pos.minD);

            if (site.valid)
            {
                this.drawCorrectionPointer(lat, lng, chordId, site.latDeg, site.lngDeg);
            }
        },
        drawCorrectionPointer(lat, lng, chordId, lat1, lng1) {
            let linePoints = [ ];
            let poliLinesKey = chordId + "-pointToCorr";
            linePoints.push(new google.maps.LatLng(lat, lng));
            let actualPos = new google.maps.LatLng(lat1, lng1);
            linePoints.push(actualPos);

            if (this.poliLines.has(poliLinesKey))
                this.poliLines.get(poliLinesKey).setMap(null);

            let line = new google.maps.Polyline({
                path: linePoints,
                strokeColor: "#DDDDDD",
                strokeWeight: 2,
                clickable: false,
                geodesic: false,
                map: this.map
            });

            this.poliLines.set(poliLinesKey, line);

            let circleKey = chordId + "-circleCorr";
            if (this.poliLines.has(circleKey)) 
                this.poliLines.get(circleKey).setMap(null);

            let mapZoom = this.map.getZoom();
            let radius = 1000 / Math.pow(1.3, mapZoom);

            const corrCircle = new google.maps.Circle({
                strokeColor: "#FFDDDD",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FFDDDD",
                fillOpacity: 0.35,
                map: this.map,
                center: actualPos,
                radius: radius ?? 150,
                });
            
            this.poliLines.set(circleKey, corrCircle);
        },
        async calcLocationCircumstances(lat, lng, altMsl) {
            try
            {
                let posAndChord = this.updatePositionAndChord(lat, lng, altMsl);
                
                this.currentLocation = new google.maps.LatLng(lat, lng);

                if (this.marker) this.marker.setMap(null);
                if (this.isEditingStation && !this.eventHasPassed)
                {                    
                    this.createBidMarker(this.currentLocation, this.currentSite.name);
                    this.marker = this.currentBidMarker;
                }
                else
                {
                    this.marker = new google.maps.Marker({
                        position: this.currentLocation,
                        map: this.map
                    });
                }
                
                if (posAndChord.pth && posAndChord.pth.validPath && this.marker)
                {
                    var elevationMeters = null;
                    var contentString = this.getInfoWindowContent(lat, lng, elevationMeters, posAndChord.pth, posAndChord.locMidTime);

                    var infowindow = new google.maps.InfoWindow({
                            content: contentString,
                            maxWidth: 400
                        });

                    infowindow.open(this.map, this.marker); 

                    try
                    {
                        // THIS COSTS MONEY
                        //const elevator = new google.maps.ElevationService();
                        //let elevation = await elevator.getElevationForLocations({locations: [this.currentLocation]});
                        //elevationMeters = elevation.results[0].elevation;

                        elevationMeters = await this.getOpenElevation({lat, lng});
                        if (this.debugMode) console.log(`Elevation for (${lat}, ${lng}): ${elevationMeters}`);

                        contentString = this.getInfoWindowContent(lat, lng, elevationMeters, posAndChord.pth, posAndChord.locMidTime);

                        infowindow.setContent(contentString);
                                                
                        this.updatePositionAndChord(lat, lng, elevationMeters);
                    }
                    catch
                    {
                        elevationMeters = null;
                    }                    
                }
            }
            catch(error)
            {
                console.log(error);
            }
        },
        formatLatLngString(val) {
            let sign = val < 0 ? '-' : '+';
            let value = Math.abs(val);
            var deg = Math.floor(value);
            var minP = (value - deg) * 60;
            var min = Math.floor(minP);
            var secP = (minP - min) * 60;
            var sec = Math.floor(secP);

            deg = (deg + '').padStart(2, '0');
            min = (min + '').padStart(2, '0');
            let sec_whole = (sec + '').padStart(2, '0');
            return `${sign}${deg}&deg; ${min}<sup>&apos;</sup> ${sec_whole}<sup>&quot;</sup>`;
        },
        formatTimeUtc(dateTime){
            return `${(dateTime.getHours() + '').padStart(2, '0')}:${(dateTime.getMinutes() + '').padStart(2, '0')}:${(dateTime.getSeconds() + '').padStart(2, '0')} UT`;
        },
        formatAltAzString(alt, az) {
            if (!alt)
                return "";

            if (!az)
                return `${alt.toFixed()}&deg;`;
            
            let azDir = '';
            if (az <= 0 + 22.5 || az > 360 - 22.5)
                azDir = 'N';
            else if (az <= 45 * 1 + 22.5 && az > 45 * 1 - 22.5)
                azDir = 'NE';
            else if (az <= 45 * 2 + 22.5 && az > 45 * 2 - 22.5)
                azDir = 'E';
            else if (az <= 45 * 3 + 22.5 && az > 45 * 3 - 22.5)
                azDir = 'SE';
            else if (az <= 45 * 4 + 22.5 && az > 45 * 4 - 22.5)
                azDir = 'S';
            else if (az <= 45 * 5 + 22.5 && az > 45 * 5 - 22.5)
                azDir = 'SW';
            else if (az <= 45 * 6 + 22.5 && az > 45 * 6 - 22.5)
                azDir = 'W';
            else if (az <= 45 * 7 + 22.5 && az > 45 * 7 - 22.5)
                azDir = 'NW';

            return `${alt.toFixed()}&deg; ${azDir}`;         
        },
        async onGMapClck(mapsMouseEvent) {
            var lat = mapsMouseEvent.latLng.lat();
            var lng = mapsMouseEvent.latLng.lng();
            if (this.isTagged === true)
            {
                if (this.currentUser && !this.eventHasPassed && !this.currentBidMarker && !this.currentMarker)
                {
                    this.isEditingStation = true;
                    this.currentSite = {...this.getNewStationConfig(), lat, lng};
                    this.$refs.siteEditor.editStation(this.currentSite, 0);
                }
            }

            await this.calcLocationCircumstances(lat, lng, null);
        },
        onGMapTypeIdChanged(){
            localStorage.setItem("mapTypeId", this.map.mapTypeId);
        },
        onGMapRenderAreaChanged() {
            clearTimeout(this.gMapRenderAreaChangedThrottledTimer);
            this.gMapRenderAreaChangedThrottledTimer = setTimeout(this.onGMapRenderAreaChangedThrottled, 150);
        },
        onGMapRenderAreaChangedThrottled() {
            let t0 = performance.now();
            this.tFrom = 0;
            this.tTo = 0;

            let hasNaNTaEdge = this.occData.tFrom.some(x => x == NaN || x == "NaN") || this.occData.tTo.some(x => x == NaN || x == "NaN");  

            let zoom = this.map.getZoom();
            if (zoom >= 6 || hasNaNTaEdge)
            {                
                var bnds = this.map.getBounds();
                var ne = bnds.getNorthEast();
                var sw = bnds.getSouthWest();            
                let lngFrom = ne.lng();
                let lngTo = sw.lng();
                let latFrom = ne.lat();
                let latTo = sw.lat();
                let tBnds = [0, 0, 0, 0];
                tBnds[0] = this.occData.getTforMinimumDistance(lngFrom, latFrom, null);
                tBnds[1] = this.occData.getTforMinimumDistance(lngFrom,latTo, null);
                tBnds[2] = this.occData.getTforMinimumDistance(lngTo, latFrom, null);
                tBnds[3] = this.occData.getTforMinimumDistance(lngTo, latTo, null);
                this.tFrom = Math.min(...tBnds.map(x => x.ta));
                this.tTo = Math.max(...tBnds.map(x => x.ta));

                this.drawPathLineFromTo(this.tFrom, this.tTo, -2, "#ff0000");
                this.drawPathLineFromTo(this.tFrom, this.tTo, -1, "#0000ff");
                this.drawPathLineFromTo(this.tFrom, this.tTo, 0, "#00ff00");
                this.drawPathLineFromTo(this.tFrom, this.tTo, 1, "#0000ff");
                this.drawPathLineFromTo(this.tFrom, this.tTo, 2, "#ff0000");

                if (this.stations)
                {
                    for(var st of this.stations)
                    {
                        let clr = "#999999";
                        if (st.cmt == 2)
                            clr = "#FFAA00"; //low-commitment
                        else if (st.cmt == 1)
                            clr = "#AA5500"; //medium-commitment
                        this.drawPathLineFromTo(this.tFrom, this.tTo, 'st-' + st.no, clr, st.dist);
                        this.drawStationCordCorrectionCircle(st);
                    }
                }

                if (this.currentDistance)
                {
                    this.drawPathLineFromTo(this.tFrom, this.tTo, 'curr-bid', "#FFFFFF", this.currentDistance);
                } 
                
                this.drawCordCorrectionCircle();                
            }
            else
            {
                if (this.debugMode)
                    console.log('Drawing full path for zoom < 6');

                this.drawPathLine(-2, "#ff0000");
                this.drawPathLine(-1, "#0000ff");
                this.drawPathLine(0, "#00ff00");
                this.drawPathLine(1, "#0000ff");
                this.drawPathLine(2, "#ff0000");        
                
                if (this.stations)
                {
                    for(var st of this.stations)
                    {
                        let poliLinesKey = 'st-' + st.no;
                        if (this.poliLines.has(poliLinesKey)) this.poliLines.get(poliLinesKey).setMap(null);
                    }
                }

                if (this.currentDistance)
                {
                    let poliLinesKey = 'curr-bid';
                    if (this.poliLines.has(poliLinesKey)) this.poliLines.get(poliLinesKey).setMap(null);
                }                 
            }



            let t1 = performance.now();
            if (this.debugMode)
                console.log(`Lines redrawn in:`+ (t1-t0) +' milliseconds');          
        },
        drawPathLine(limitId, colour) {
            let from = this.occData.tFrom[-1 * limitId + 2];
            let to = this.occData.tTo[-1 * limitId + 2];  
            this.drawPathLineFromTo(from, to, limitId, colour);
        },
        drawPathLineFromTo(from, to, limitId, colour, chord) {
            if (this.debugMode)
                console.log(`drawPathLineFromTo(from: ${from}, to: ${to}, limitId: ${limitId}, colour: ${colour}, chord: ${chord})`);   

            const CHUNKS = 1000;
            let poliLinesKey = limitId;

            let tick = (to - from) / (CHUNKS + 1);
            var linePoints = [ ];
            let dp = { };
            for (let i = 0; i <= CHUNKS; i++)
            {
                let ta = from + i * tick;

                if (chord)
                    dp = this.occData.chordPathByTime(ta, chord);
                else
                    dp = this.occData.centrePathByTime(ta, limitId);

                if (dp.valid)
                    linePoints.push(new google.maps.LatLng(dp.latDeg, dp.lngDeg));
            }

            if (this.poliLines.has(poliLinesKey))
                this.poliLines.get(poliLinesKey).setMap(null);

            let line = new google.maps.Polyline({
                path: linePoints,
                strokeColor: colour,
                strokeWeight: 2,
                clickable: false,
                geodesic: false,
                map: this.map
            });

            this.poliLines.set(poliLinesKey, line);
            
        },
        plotTwilightLines() {
            let t0 = performance.now();

            let rdSun = this.xephem.getSunPosition(this.midTime);            
            let jd = this.xephem.JD_from_DateString(this.midTime);
            let sidTim = this.xephem.siderealTimeDeg(jd);
            let lat0 = -rdSun.dec;
            let long0 = 180 + rdSun.ra - sidTim;
            long0 = this.xephem.range(long0, 360);            
            for(var sunAltStep = 0; sunAltStep < 3; sunAltStep++)
            {
                let subAlt = 0 - 6 * sunAltStep;
                var linePoints = new Array(360);
                
                for(var i = -90; i<=90; i++)
                {
                    let step = 0.5;
                    if (Math.abs(i) >= 60) step = 0.25;
                    if (Math.abs(i) >= 70) step = 0.1;
                    if (Math.abs(i) >= 80) step = 0.02;
                    let lat = i;
                    while(lat < i + 1)
                    {
                        let lngRv = this.xephem.longitudeForAltitudeAndLatitude(sidTim, subAlt, lat, rdSun.ra / 15, rdSun.dec);
                        if (!isNaN(lngRv.lng1))
                        {
                            linePoints[i + 90] = new google.maps.LatLng(lat, lngRv.lng1);
                            linePoints[(90-i) + 180] = new google.maps.LatLng(lat, lngRv.lng2);
                        }
                        lat += step;
                    }
                }

                var termColours = ["#ffff00", "#623999", "#2e006c"];

                linePoints = linePoints.filter(x => x);
                linePoints.push(linePoints[0]);

                new google.maps.Polyline({
                    path: linePoints,
                    strokeColor: termColours[sunAltStep],
                    strokeWeight: 2,
                    clickable: false,
                    geodesic: false,
                    map: this.map
                });                
            }
            let t1 = performance.now();
            if (this.debugMode)
                console.log(`Twilight lines drawn in:`+ (t1-t0) +' milliseconds');          
        },
        createOtherObserverMarker(pos)
        {
            var icon = { 
                url: MARKER_OTHERS_SUBMITTED_STATION, 
                scaledSize: new google.maps.Size(OTHERS_STATION_SZ_X, OTHERS_STATION_SZ_Y),
                anchor: new google.maps.Point(OTHERS_STATION_ANC_X, OTHERS_STATION_ANC_Y)
            };

            var title = '(' + pos.no + ') ' + pos.name + '\r\n' + pos.obs;
            var commitment = 'Unknown';
            if (pos.cmt === 0) commitment = 'High';
            else if (pos.cmt === 1) commitment = 'Medium';
            else if (pos.cmt === 2) commitment = 'Low or inexperienced';
            title = `${title}\r\n---------------------------------\r\n`;

            if (!this.eventHasPassed)
            {
                title += `'Count on me' Indicator: ${commitment}`;
                if (pos.loc === 0)
                {
                    title = title + '\r\nInexact chord';
                }

                if (pos.weather) title = title + pos.weather;
            }

            if (this.eventHasPassed && pos.repText) title = title + pos.repText;

            if (pos.alt === 0)
            {
                title = title + '\r\n! This chord is not elevation corrected !';
            }

            var mk = new google.maps.Marker({
                position: new google.maps.LatLng(pos.lat, pos.lng),
                clickable: true,
                title: title,
                icon: icon,
                map: this.map,
                alwaysVisible: pos.showExactPosition
            });

            return mk;
        },
        createBidMarker(location, siteName)
        {
            var icon = { 
                url: MARKER_NEW_STATION, 
                scaledSize: new google.maps.Size(NEW_STATION_SZ_X, NEW_STATION_SZ_Y),
                anchor: new google.maps.Point(NEW_STATION_ANC_X, NEW_STATION_ANC_Y)
            };
                
            if (this.currentBidMarker)
            {
                if (this.debugMode) console.log('Hiding existing bid marker');
                this.currentBidMarker.setMap(null);
            }
            else
                if (this.debugMode) console.log('NOT hiding existing bid marker', this.currentBidMarker);

            this.currentBidMarker = new google.maps.Marker({
                position: location,
                clickable: false,
                title: siteName,
                icon: icon,
                map: this.map
            });
        },
        async DeleteStation(id){                         
            this.currentInfoWindow.close(); 

            let stationName = this.stations.filter(x => x.no == id)[0].name;
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Delete Station',
                message: `Are you sure you want to delete '${stationName}'?`,
                okButton: 'Delete'
            });

            if (ok === true)
            {
                try 
                {
                    let res = await this.planningCancelStation({eventId: this.eventId, stationId: id});

                    if (!res.success)
                    {
                        this.showToast(res.error, 'ERROR'); 
                    }
                    else 
                    {
                        if (this.currentMarker)
                            this.currentMarker.setMap(null);
                            
                        if (this.currentBidMarker)
                            this.currentBidMarker.setMap(null);

                        this.showToast(`Station '${stationName}' deleted successfully.`, 'SUCCESS'); 
                        setTimeout(() => {
                            // Refresh page
                            this.$router.go(0);
                            }, 2000);                        
                    }
                }
                catch(error)
                {
                    this.showToast(error.message, 'ERROR');                    
                }
            }
        },  
        async EditStation(id){
            console.log(`EditStation(${id})`);
            this.currentSite = this.stations.filter(x => x.no == id)[0];
            this.isEditingStation = true;
            this.$refs.siteEditor.editStation(this.currentSite, 0);

            await this.calcLocationCircumstances(this.currentSite.lat, this.currentSite.lng, this.currentSite.alt);

            if (this.currentMarker)
                this.currentMarker.setMap(null);

            this.currentInfoWindow.close(); 
        },
        async ReportObservation(id){
            console.log(`ReportObservation(${id})`);
            this.currentInfoWindow.close(); 
            let station = this.stations.filter(x => x.no == id)[0];

            this.$refs.reportEditor.reportObservation(station);
        },
        async submitObservationReport(rep)
        {
            console.log(rep);

            try 
            {
                let res = await this.planningReportObservation({
                        eventId: this.eventId, 
                        stationId: rep.stationId, 
                        report: rep.report, 
                        duration: rep.duration, 
                        comment: rep.comment
                    });

                if (!res.success)
                {
                    this.showToast(res.error, 'ERROR'); 
                    this.$refs.reportEditor.hide();
                }
                else 
                {
                    this.showToast(`Observation reported successfully.`, 'SUCCESS'); 
                    setTimeout(() => {
                        // Refresh page
                        this.$router.go(0);
                        }, 2000);                        
                }
            }
            catch(error)
            {
                this.showToast(error.message, 'ERROR');
                this.$refs.reportEditor.hide();
            } 
        },        
        cancelReportObservation() {

        },
        cancelStationEditing() {
            if (this.currentBidMarker)
            {
                this.currentBidMarker.setMap(null);
                this.currentBidMarker = null;
            }

            if (this.currentMarker)
                this.currentMarker.setMap(this.map);

            this.clearCordCorrectionPointer("bid");
            if (this.poliLines.has("curr-bid")) this.poliLines.get("curr-bid").setMap(null);
            this.$refs.owPlot.setBid(null);

        },
        async submitStation(st, stMsl) {           
            try
            {
                let stAlt = 0; 
                if (stMsl != null) stAlt = stMsl;
                let stData = {
                    lng: this.currentLocation.lng(),
                    lat: this.currentLocation.lat(),
                    siteName: st.name,
                    obsMethod: st.obsm,
                    timingMethod: st.timm,
                    locationDisplay: st.loc,
                    commitment: st.cmt,
                    eventId: this.eventId,
                    stationId: st.no,
                    alt: stAlt
                }

                if (st.no == -1)
                {
                    localStorage.setItem(NEW_STATION_CONFIG_KEY, JSON.stringify(st));

                    await this.addStation(stData);
                }
                else
                    await this.updateStation(stData);

                st.mk = this.createSubmittedStationMarker({...st, lat: stData.lat, lng: stData.lng, alt: stAlt});
                this.currentLocation = new google.maps.LatLng(stData.lat, stData.lng);
                this.currentDistance = stData.distance;
            }
            finally            
            {
                this.$refs.siteEditor.setPlanningOperationCompleted();
            }
        },
        async updateStation(stData) {
            try 
                {
                    let res = await this.planningUpdateStation(stData);

                    if (!res.success)
                    {
                        this.showToast(res.error, 'ERROR'); 
                    }
                    else 
                    {
                        if (this.currentMarker)
                            this.currentMarker.setMap(null);
                            
                        if (this.currentBidMarker)
                            this.currentBidMarker.setMap(null);

                        this.showToast(`Station '${stData.siteName}' updated successfully.`, 'SUCCESS');
                        setTimeout(() => {
                            // Refresh page
                            this.$router.go(0);
                            }, 2000);
                    }
                }
                catch(error)
                {
                    this.showToast(error.message, 'ERROR');                    
                }                        
        },
        async addStation(stData) {
            try 
                {
                    let res = await this.planningAddStation(stData);

                    if (!res.success)
                    {
                        this.showToast(res.error, 'ERROR'); 
                    }
                    else 
                    {
                        if (this.currentMarker)
                            this.currentMarker.setMap(null);
                            
                        if (this.currentBidMarker)
                            this.currentBidMarker.setMap(null);

                        this.showToast(`Station '${stData.siteName}' added successfully.`, 'SUCCESS');
                        setTimeout(() => {
                            // Refresh page
                            this.$router.go(0);
                            }, 2000);
                    }
                }
                catch(error)
                {
                    this.showToast(error.message, 'ERROR');                    
                }                        
        },
        CenterMapAtPosition(lat, lng){
            console.log(`CenterMapAtPosition(${lat}, ${lng})`);
            this.map.setCenter(new google.maps.LatLng(lat, lng));
            this.currentInfoWindow.close();        
        },      
        createSubmittedStationMarker(pos)
        {
            var icon = { 
                url: MARKER_MY_SUBMITTED_STATION, 
                scaledSize: new google.maps.Size(MY_STATION_SZ_X, MY_STATION_SZ_Y),
                anchor: new google.maps.Point(MY_STATION_ANC_X, MY_STATION_ANC_Y)
            };

            if (this.currentBidMarker)
                this.currentBidMarker.setMap(null);

            let eventHasPassed = this.eventHasPassed === true;

            var submittedMarker = new google.maps.Marker({
                position: new google.maps.LatLng(pos.lat2 ?? pos.lat, pos.lng2 ?? pos.lng),
                clickable: true,
                title: "("+pos.no + ") " + pos.name + "\r\n" + ( eventHasPassed ? "Click to report observation" : "Click to edit or delete"),
                icon: icon,                
                map: this.map
            });

            const vm = this;
            if (eventHasPassed && (pos.rep == null || pos.rep == 6 /* report to follow*/ || this.reportCanBeUpdated))
            {
                let prefix = pos.rep == null ? "To report observation" : "To update your report";
                submittedMarker.addListener("click", 
                    function() 
                    {
                        vm.currentMarker = submittedMarker;
                        let infoHtml = "<b>" + "(" + pos.no + ") " + pos.name + "</b><br>" + 
                        "<font size=2>Long: " + owcMixin.degToStr(pos.lng) + ", Lat: " + owcMixin.degToStr(pos.lat) + (pos.alt ? `, Alt: ${pos.alt.toFixed(0)}m` : "") + "</font><br><br>" + 
                        prefix + " click <a href='#' onclick='ReportObservation(" + pos.no + ");'>here</a></br>";

                        window.ReportObservation = function(id) {
                            vm.ReportObservation(id)
                        };

                        vm.currentInfoWindow = new google.maps.InfoWindow({content: '<div id="content" class="pr-2" style="color:black;">' + infoHtml + '</div>'});
                        vm.currentInfoWindow.setOptions({ position: vm.currentMarker.getPosition() });
                        vm.currentInfoWindow.open(this.map);
                    }
                );               
            }
            else
            {
                submittedMarker.addListener("click", 
                    function() 
                    {
                        vm.currentMarker = submittedMarker;
                        let infoHtml = "<b>" + "(" + pos.no + ") " + pos.name + "</b><br>" + 
                        "<font size=2>Long: " + owcMixin.degToStr(pos.lng) + ", Lat: " + owcMixin.degToStr(pos.lat) + (pos.alt ? `, Alt: ${pos.alt.toFixed(0)}m` : "") + "</font><br><br>" + 
                        "To edit this station info click <a href='#' onclick='EditStation(" + pos.no + ");'>here</a></br>" + 
                        "To delete this station click <a href='#' onclick='DeleteStation(" + pos.no + ");'>here</a></br>" +
                        "To center this station click <a href='#' onclick='CenterMapAtPosition(" + pos.lat + ", " + pos.lng + ");'>here</a></br>";

                        window.EditStation = function(id) {
                            vm.EditStation(id)
                        };
                        window.DeleteStation = function(id) {
                            vm.DeleteStation(id)
                        };
                        window.CenterMapAtPosition = function(lat, lng) {
                            vm.CenterMapAtPosition(lat, lng)
                        };
                        vm.currentInfoWindow = new google.maps.InfoWindow({content: '<div id="content" class="pr-2" style="color:black;">' + infoHtml + '</div>'});
                        vm.currentInfoWindow.setOptions({ position: vm.currentMarker.getPosition() });
                        vm.currentInfoWindow.open(this.map);
                    }
                );
            }

            return submittedMarker;
        },
        getNewStationConfig() {
            var cfgStr = localStorage.getItem(NEW_STATION_CONFIG_KEY);
            var cfg = null;
            try
            {
                cfg = JSON.parse(cfgStr);
            }
            catch {};

            if (!cfg)
                cfg = { name: 'New Station', no: -1, timm: 'GPS', obsm: 'video', cmt: 0, loc: 2 };
            
            return cfg;
        },
        getZoomLevelForDistance(distance) {
            let distanceAbs = Math.abs(distance);
            if (this.debugMode) console.log('distanceAbs', distanceAbs);
            if (distanceAbs < 25)
                return 9;
            else if (distanceAbs < 100)
                return 8;
            else if (distanceAbs < 250)
                return 7;
            else if (distanceAbs < 500)
                return 6;
            else if (distanceAbs < 1000)
                return 5; 
            
            return 4;
        },
        initNightViewMap(){
            var mapStyles = null;
            var isLightTheme = localStorage.getItem('theme') === 'light';
            if (!isLightTheme)
            {
                mapStyles = [
                    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                    {
                        featureType: "administrative.locality",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [{ color: "#263c3f" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#6b9a76" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [{ color: "#38414e" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#212a37" }],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#9ca5b3" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [{ color: "#746855" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#1f2835" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#f3d19c" }],
                    },
                    {
                        featureType: "transit",
                        elementType: "geometry",
                        stylers: [{ color: "#2f3948" }],
                    },
                    {
                        featureType: "transit.station",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{ color: "#17263c" }],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#515c6d" }],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#17263c" }],
                    },
                ];
            }

            return new google.maps.Map(document.getElementById('evGmap'), 
            {
                center: this.location,
                scrollwheel: true,
                mapTypeControl: true,
                streetControl: true,
                scaleControl: true,
                zoom: 3,
                styles: mapStyles
            });
        }
    },
    computed: {
        isTagged() {
            return this.tags.length > 0;
        },
        owStations() {
            // Add CSS classes
            this.addOwStationClasses(this.stations);
            return this.stations;
        }
    }
}
</script>

<style scoped>
#evGmap {
    height:570px;
    width: 100%;
   }

.delete-btn {
    padding: 0.5em 1em;
    background-color: #eccfc9;
    color: #c5391a;
    border: 2px solid #ea3f1b;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
}
</style>