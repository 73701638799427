<template>
    <span>{{hr}}<sup>h</sup> {{min}}<sup>m</sup> {{sec_whole}}<sup>s</sup>{{sec_frac}}</span>
</template>

<script>
export default {
    props: ['val'],
    data() {
        return {
            hr: null,
            min: null,
            sec_whole: null,
            sec_frac: null
        }
    },
    mounted(){
        let value = Math.abs(this.val);
        var hr = Math.floor(value);
        var minP = (value - hr) * 60;
        var min = Math.floor(minP);
        var secP = (minP - min) * 60;
        var sec = Math.floor(secP);

        this.hr = (hr + '').padStart(2, '0');
        this.min = (min + '').padStart(2, '0');
        this.sec_whole = (sec + '').padStart(2, '0');
        this.sec_frac = ((secP - sec).toFixed(4) + '').substr(1);
    }
}
</script>

<style scoped>

</style>