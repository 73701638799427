<template>
    <div class="container">
        <div class="row pt-4"  style="display: flex;">
            <div class="col-3"></div>
            <div class="col-6">
                <div class="row border" :class="showSubmitLoader ? 'disabled-control' : ''">
                    <div class="col-12 text-white text-center mx-auto pb-2 pt-2 font-weight-bold" style="font-family: 'Arial Narrow Bold', sans-serif">Reset Password</div>
                    
                    <div class="col-12 mb-3 pt-4">
                        <ul class="list-group">
                            <li class="list-group-item">
                                <div class="d-flex align-items-center">
                                    <span style="width:15em">Email Address<span class="text-danger pl-2">*</span></span>
                                    <input 
                                        v-model="form.login" 
                                        v-validate="{required: true, email: true}" 
                                        data-vv-name="Email Address"                                
                                        class="form-control" 
                                        type="text"/>                                    
                                </div>
                                <div v-if="validated" class="text-danger pull-right">
                                        {{errors.first('Email Address')}}
                                </div> 
                            </li>   
                        </ul> 
                    </div>
                    <div class="col-12">
                        <small class="text-muted"><strong class="text-light">NOTE: </strong> OWC accounts are shared with OccultWatcher. Resetting your password will do so for both Occult Watcher and OWC.</small>
                    </div>
                    <div class="col-12">
                        <br/>
                        <small class="text-muted">Once your password has been reset, follow these steps to reconfigure your account in OccultWatcher:</small><br/>
                        <small class="text-muted"><strong class="text-light pr-1">1)</strong> Shut down OccultWatcher if running</small><br/>
                        <small class="text-muted"><strong class="text-light pr-1">2)</strong> From command line run: <strong class="text-light">OccultWatcher.exe reset-account</strong></small><br/>
                        <small class="text-muted"><strong class="text-light pr-1">3)</strong> Start OccultWatcher and reconfigure your account</small><br/>
                    </div>             
                    <div class="col-12 mb-3 mt-3">
                        <b-spinner v-if="showSubmitLoader" class="justify-content-center" varient="secondary" style="width: 2rem; height: 2rem;" ></b-spinner>

                        <button 
                            type="button" 
                            @click="onResetPassword" 
                            class="btn btn-primary pull-right">Reset Password</button>
                    </div>     
                </div> 
            </div> 
        </div>       
    </div> 
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data(){
        return {
            form: {
                login: ""
            },
            validated: false,
            showSubmitLoader: false
        }
    },
    methods: {
        ...mapActions(['resetPassword']),
        async onResetPassword(){
            this.validated = true;
            var result = await this.$validator.validate();

            if (result)
            {
                this.showSubmitLoader = true;

                try 
                {
                    await this.resetPassword({email: this.form.login});

                    this.showSubmitLoader = false;
                    this.submitted = true;
                    
                    this.form = {
                        login: null
                    };

                    this.showToast('You will receive an email shortly. Follow the instructions from the email to complete the reset of your password.', 'SUCCESS', 10000);
                }
                catch(error)
                {
                    this.showSubmitLoader = false;
                    this.showToast(error.message, 'ERROR');
                }

                this.validated = false;
            }
        }
    }
}
</script>

<style scoped>

</style>