import { render, staticRenderFns } from "./OrbitSolutionInfo.vue?vue&type=template&id=4b900b6f&scoped=true&"
import script from "./OrbitSolutionInfo.vue?vue&type=script&lang=js&"
export * from "./OrbitSolutionInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b900b6f",
  null
  
)

export default component.exports