<template>
    <span>{{displayString}}</span>
</template>

<script>
import moment from 'moment'

export default {
    props: ['from', 'to'],
    computed: {
        displayString(){
            let fromM = moment(String(this.$props.from));
            let toM = moment(String(this.$props.to));
            
            let fromDD = fromM.format('DD');
            let toDD = toM.format('DD');
            let fromMMM = fromM.format('MMM');
            let toMMM = toM.format('MMM');

            if (!this.$props.from && !this.$props.to)
            {
                return "";
            }
            else if (!this.$props.from)
            {
                return `before ${toDD} ${toMMM}`;
            }
            else if (!this.$props.to)
            {
                return `after ${fromDD} ${fromMMM}`;
            }

            if (fromMMM == toMMM)
            {
                if (fromDD == toDD)
                    return `${fromDD} ${fromMMM}`;
                else
                    return `${fromDD} - ${toDD} ${fromMMM}`;
            }
            else
                return `${fromDD} ${fromMMM} - ${toDD} ${toMMM}`;
        }
    }
}
</script>