<template>
    <div>
        <nav class="nav navbar-expand-sm" id="top-nav">
            <router-link  class="navbar-brand mx-4 pb-1" to="/">OW Cloud</router-link>
        </nav>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 pb-3 pt-4 mx-2" >
                    <div class="text-center">
                        <div v-if="isFailedPost && !showLoader" class="pt-4 text-danger">Could not complete request as you are no longer logged in ...</div>
                        <b-spinner v-if="isFailedPost || showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
                        <div v-if="isFailedPost && !showLoader" class="pt-4"><strong>Restoring previous session in {{redirectCountDown}}...</strong></div>
                        <div v-if="showLoader" class="text-muted pt-4">Restoring previous session, this could take a few seconds...</div>
                    </div>               
                </div>           
            </div>
        </div>  
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            showLoader: false,
            isFailedPost: false,
            redirectTo: null,
            countDownTickTimer: 0,
            redirectCountDown: 0
        }
    },
    methods: {
        ...mapActions(['loginUser', 'setRestoreSessionFlag']),
        countDownTick() {
            this.redirectCountDown--;
            if (this.redirectCountDown > 0)
                this.countDownTickTimer = setTimeout(this.countDownTick, 1000);
            else
                this.restoreSession();
        },        
        async restoreSession(){
            
            clearTimeout(this.countDownTickTimer);
            this.showLoader = true;

            await this.setRestoreSessionFlag(true);
            try 
            {
                let uName = localStorage.getItem('owc-user-name');
                let uPass = localStorage.getItem('owc-user-pass');
                await this.loginUser({user: uName, pass: uPass, save: true});

                console.log(`Session for ${this.$store.state.user.name} restored, redirecting to ${this.redirectTo}`);

                await this.setRestoreSessionFlag(false);

                this.$router.push(this.redirectTo);            
            }
            catch(error)
            {
                await this.setRestoreSessionFlag(false);
                
                console.log('Error restoring session', error);
                this.$router.resolve({ name: 'Login' });                   
            }            
        }
    },
    async mounted() {
        this.isFailedPost = this.$route.query.failedPost == 1;
        this.redirectTo = this.$route.query.redirectTo;

        if (this.isFailedPost)
        {
            this.countDownTick();
        }
        else
        {
            await this.restoreSession();
        }
    }
}
</script>

<style scoped>

</style>