export default {
    SET_EVENTS: (state, payload ) => {
        state.eventList = payload;
    },
    SET_FILTERED_EVENTS: (state, payload ) => {
        if (payload.data && payload.data.perf && payload.data.result)
        {
            state.filteredEventList = payload.data.result;
            state.filteredEventExecInfo = payload.data.perf;
        }
        else
        {
            state.filteredEventList = payload.data;
            state.filteredEventExecInfo = null;
        }
    },
    SET_CURR_EVENT: (state, payload) => {
        state.currentEvent = payload;
    },
    SET_CURR_PREDICTION: (state, payload) => {
        state.currentPrediction = payload;
    },
    SET_CURR_EVENT_STATIONS: (state, payload) => {
        state.currentEventStations = payload;
    },
    SET_TOTALS_INFO: (state, payload) => {
        state.totalEventsInfo = { ...payload, 'cached' : Date.now() };
    },
    SET_BOOTSTRAP_VIEW_PORT: (state, payload) => {
        state.bsViewPortSize = payload;
    },
    SET_LOGGED_IN_USER: (state, payload) => {
        state.user = payload;
        localStorage.setItem('owc-user', payload != null ? JSON.stringify(payload) : null);
    },
    SET_NEW_USER_SESSION: (_, payload) => {
        localStorage.setItem('owc-user-session-duration', payload != null ? payload.sessionDurMin : null);
        localStorage.setItem('owc-user-last-request-time', payload != null ? new Date().getTime() : null);
    },
    SET_TAG_PAIRS: (state, payload) => {
        state.tagPairs = payload;
    },
    SET_GEOPOLY_PAIRS: (state, payload) => {
        state.polygonPairs = payload;
    },
    SET_TAGS: (state, payload) => {
        state.tagList = payload;
    },
    UPDATE_TAG: (state, payload) => {
        if (state.tagList && state.tagList.data)
        {
            state.tagList.data = state.tagList.data.map(t => {
                if (t.id === payload.id)
                    return {...t, url: payload.url, descr: payload.descr};
                else 
                    return t;
            });    
        }
    },
    TOGGLE_SELECTED_TAG: (state, payload) => {
        let index = state.selectedTagIds.indexOf(payload);
        if (index > -1)
        {
            state.selectedTagIds.splice(index, 1);
            state.selectedTagId = null;
        }
        else
        {
            state.selectedTagIds.push(payload);
            state.selectedTagId = payload;
        }
    },
    CLEAR_SELECTED_TAGS: (state) => {
        state.selectedTagIds = [];
        state.selectedTagId = null;
    },
    SET_UPLOAD_EVENTS: (state, payload) => {
        state.uploadEventsList = payload.entries;
        state.uploadEventsTotalPages = payload.pages;
        state.uploadEventsTotalRows = payload.rows;
    },
    SET_UPLOAD_EVENT_LOG: (state, payload) => {
        state.uploadEventLog = payload;
    },
    SET_PREDICTION_SOURCES: (state, payload) => {
        state.predictionSources = payload;
    },
    SET_RESTORE_SESSION_FLAG: (state, payload) => {
        state.restoreSessionFlag = payload;
    },
    SET_SELECTED_MAP_LOCATION: (state, payload) => {
        state.selectedMapLocation = payload;
        localStorage.setItem('owc-selected-map-location', payload != null ? JSON.stringify(payload) : null);
    },
    SET_USER_PROFILE: (state, payload) => {
        state.userProfile = payload;
    },
    SET_USER_SITES: (state, payload) => {
        state.userSites = payload;
    },
    SET_USER_FILTERS: (state, payload) => {
        state.userFilters = payload;
    },
    SET_STAR_INFO: (state, payload) => {
        state.resolvedStar = payload;
    },
    SET_COMMUNITY_TAGS: (state, payload) => {
        state.communityTags = payload;
    },
    SET_CAMPAIGN_TAGS: (state, payload) => {
        state.campaignTags = payload;
    },
    SET_TAG_USAGE: (state, payload) => {
        state.tagUsage = payload;
    },
    SET_SERVER_VERSION_INFO: (state, payload) => {
        state.serverVersionInfo = payload;
    },
    SET_USER_OAUTH_CLIENT: (state, payload) => {
        state.userOAuthClient = payload;
    },
}