<template>
    <div class="row border">
        <div class="col-12 text-center pt-2 pb-2" style="font-family: Arial, Helvetica, sans-serif">OccultWatcher Feeds AVAILABILITY&nbsp;&nbsp;
            <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 1rem; height: 1rem;" ></b-spinner>
        </div>
        <div class="col-12 mb-3" v-if="!showLoader">
            <ul v-for="ti in tagInfo" :key="ti.id" class="list-group">                          
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <router-link :to="{ name: 'TaggedEvents', params: { campaignId: ti.name }}" v-slot="{ href }" custom>
                        <a :href="href" target="_blank" :title="ti.descr">{{ti.name}}</a>
                    </router-link>                    
                    <span class="badge badge-secondary" style="font-size:13px">{{ti.lastEvent | formatDayMonth}}</span>
                </li>
            </ul>
        </div>
    </div> 
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            showLoader: false,
            tagInfo: []
        }
    },
    methods: {
        ...mapActions(['loadTagUsage'])
    },
    async mounted() {
        this.showLoader = true;

            try 
            {
                await this.loadTagUsage();

                this.tagInfo = this.$store.getters.tagUsageFutureEvents;

                this.showLoader = false;
            }
            catch(error)
            {
                this.showLoader = false;
            }
    },    
}
</script>

<style scoped>

</style>

