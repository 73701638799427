<template >
    <div class="row mx-1 mt-2 border earth-plot-container d-flex align-items-center justify-content-center">
        <div class="earth-plot-canvas" id="earthCanvas">
        </div>
        <input type="hidden" name="hidden" id="hidden" v-model="viewPortSize" v-on:change="viewPortChanged" />
    </div>
</template>

<script type="module" src="@/assets/js/three.js"></script>
<script type="module" src="@/assets/js/occelmnt.js"></script>

<script>
import { Occelmnt } from '@/assets/js/occelmnt.js'

export default {
    name: "EarthPlot",
    props: ['occel'], 
    data() {
        return {
            evt: null,
            viewPortUpdateTimer: null
        }
    },
    methods: {
        viewPortChanged(){
            if (this.evt)
            {
                var canvas = document.getElementById( 'earthCanvas' );
                this.evt.resizeCanvas(canvas);       
            }                
        },
        plotEvent(evt){
            var occel = evt ?? this.$props.occel;
            if (occel)
            {
                if (typeof this.evt !== 'undefined' && this.evt !== null)
                {
                    this.evt.clearPlot();
                    this.evt.update(occel);
                }
                else
                {
                    var canvas = document.getElementById( 'earthCanvas' );
                    this.evt = new Occelmnt(occel);
                    this.evt.initPlot(canvas);
                }

                this.evt.plot();                
            }
        },
        clear(){
            if (typeof this.evt !== 'undefined' && this.evt !== null){
                this.evt.clearPlot();
            }
        }
    },
    computed: {
        viewPortSize(){
            var vp = this.$store.getters.viewPortSize;

            if (!this.viewPortUpdateTimer)
            {
                clearTimeout(this.viewPortUpdateTimer);
                this.viewPortUpdateTimer = null;
            }
            this.viewPortUpdateTimer = setTimeout(() => {
                this.viewPortChanged();
            }, 50);
            return vp;
        }
    },
    mounted() {
        this.plotEvent();      
    }
}
</script>

<style scoped>

</style>