<template>
    <div class="container-fluid">
        <div class="row pt-2">
            <div class="col-12">
                <div class="row pt-2">
                    
                    <!-- Event List  -->
                    <div class="col-xl-8 col-lg-8 col-md-12">
                        <ul class="pagination pagination-secondary mx-auto justify-content-center">
                            <li class="page-item active"><a href="#" class="page-link">Month</a></li>
                            <li class="page-item"><a href="#" class="page-link">W1</a></li>
                            <li class="page-item"><a href="#" class="page-link">W2</a></li>
                            <li class="page-item"><a href="#" class="page-link">W3</a></li>
                            <li class="page-item"><a href="#" class="page-link">W4-5</a></li>
                            <li class="page-item"><a href="#" class="page-link">D1-3</a></li>
                            <li class="page-item"><a href="#" class="page-link">D4-6</a></li>
                            <li class="page-item"><a href="#" class="page-link">D7-9</a></li>
                            <li class="page-item"><a href="#" class="page-link">D10-12</a></li>
                            <li class="page-item"><a href="#" class="page-link">D13-15</a></li>
                            <li class="page-item"><a href="#" class="page-link">D16-18</a></li>
                            <li class="page-item"><a href="#" class="page-link">D19-21</a></li>
                            <li class="page-item"><a href="#" class="page-link">D22-24</a></li>
                            <li class="page-item"><a href="#" class="page-link">D25-27</a></li>
                            <li class="page-item"><a href="#" class="page-link">D29-31</a></li>
                        </ul>   
                        <div class="table-responsive-md">
                            <table class="table table-dark table-bordered table-hover">
                                <thead class="thead-dark text-center">
                                    <tr>
                                        <td>Event Time</td>
                                        <td>Asteroid</td>
                                        <td>Star</td>
                                        <td>Mag Drop</td>
                                        <td>Max Dur.</td>
                                        <td>Mag</td>
                                        <td>Rank</td>
                                        <td>Countries</td>
                                        <td>Tags</td>
                                    </tr>    
                                </thead>
                                <tbody>
                                    <tr class="highlight" id="tr1" data-occel="1">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr>
                                    <tr id="tr2" data-occel="2">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr> 
                                    <tr id="tr3" data-occel="3">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr> 
                                    <tr id="tr4" data-occel="4">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr>                                     
                                    <tr id="tr5" data-occel="5">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr>                                     
                                    <tr id="tr6" data-occel="6">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr> 
                                    <tr id="tr7" data-occel="7">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr> 
                                    <tr id="tr8" data-occel="8">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr> 
                                    <tr id="tr9" data-occel="9">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr> 
                                    <tr id="tr10" data-occel="10">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr> 
                                    <tr id="tr11" data-occel="11">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr>      
                                    <tr id="tr12" data-occel="12">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr> 
                                    <tr id="tr13" data-occel="13">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr> 
                                    <tr id="tr14" data-occel="14">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr>     
                                    <tr id="tr15" data-occel="15">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr> 
                                    <tr id="tr16" data-occel="16">
                                        <td>2020-02-01 14:56:23</td>
                                        <td>(6734) Benzenberg</td>
                                        <td>4U 450-001166</td>
                                        <td>3.0</td>
                                        <td>1.0</td>
                                        <td>13.9</td>
                                        <td>24</td>
                                        <td>
                                            <span class="badge">IT</span>
                                            <span class="badge">FR</span>
                                            <span class="badge">SP</span>
                                        </td>
                                        <td>
                                            <span class="badge">ITALOccult</span>
                                            <span class="badge">UKOCL</span>
                                        </td>
                                    </tr>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                                </tbody>
                            </table>                        
                        </div>    
                        <ul class="pagination justify-content-end">
                            <li class="page-item"><a href="#" class="page-link">&laquo;</a></li>
                            <li class="page-item active"><a href="#" class="page-link">1</a></li>
                            <li class="page-item"><a href="#" class="page-link">2</a></li>
                            <li class="page-item"><a href="#" class="page-link">3</a></li>
                            <li class="page-item"><a href="#" class="page-link">4</a></li>
                            <li class="page-item"><a href="#" class="page-link">&raquo;</a></li>
                        </ul>                                            
                    </div>

                   
                    <div class="col-xl-4 col-lg-4 col-md-12">
                        <!-- Seach Bar -->
                        <div class="row pt-2 mx-1 border">
                            <div class="col-lg-12">
                                <div class="bs-component">
                                    <form>
                                        <fieldset>
                                            <div class="form-row">
                                                <div class="form-group col-md-12 col-xl-9">
                                                    <label for="filterId" class="">Filter</label>
                                                    <select class="form-control" id="filterId">
                                                        <option>Mag. 16 Drop 0.3</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>                                                   
                                                <div class="form-group col-md-12 col-xl-3 d-flex">
                                                    <button type="button" class="btn btn-secondary align-self-end btn-block">Edit</button>
                                                </div>
                                                
                                            </div>    
                                            <div class="form-row">
                                                <div class="form-group col-md-12 col-xl-9">
                                                    <label for="period" class="">Period</label>
                                                    <select class="form-control" id="period">
                                                        <option>18 Nov - 15 Dec</option>
                                                        <option>16 Dec - 12 Dec</option>
                                                        <option>13 Jan - 19 Jan</option>
                                                    </select>
                                                </div>
                                                                                      
                                                <div class="form-group col-md-12 col-xl-3 d-flex">
                                                    <button type="submit" class="btn btn-primary align-self-end btn-block">Search</button>
                                                </div>                                                
                                            </div>
                                            <div class="form-row">
                                                <small class="form-text text-muted">Searching <strong class="text-light">94,1234,569</strong> events from <strong class="text-light">12 Dec</strong> to <strong class="text-light">13 Feb</strong>, last updated on 21 Dec at 12:45:12 UT</small>
                                            </div>                                                       
                                        </fieldset>
                                    </form>
                                </div>
                            </div>  
                        </div>
    
                        <!-- Current Event  -->
                        <div class="row">
                            <div class="col-lg-12 text-center pt-5" style="color:rgb(0, 188, 140);">
                                <h6 id="evtTitle">{{selectedEventTitle}}</h6>
                            </div>
                        </div>
                        <div class="row mx-1 mt-2 border earth-plot-container d-flex align-items-center justify-content-center">
                            <div class="earth-plot-canvas" id="earthCanvas">  
                                                                                  
                            </div>
                        </div>
    
                        <div class="row mt-2">
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-12">
                                        <span class="badge remove-badges">UKOCL <a href="#"><i class="fa fa-window-close"></i></a></span>
                                        <span class="badge badge-disabled">ITALOccult <a href="#"><i class="fa fa-window-close"></i></a></span>
                                        <span class="badge">MyTag <a href="#"><i class="fa fa-check"></i></a></span>        
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <small class="form-text text-muted">No notes present</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <button class="btn btn-secondary pull-right" type="button">Google Maps</button>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-12">
                                <button class="btn btn-secondary mr-2" type="button">Add Notes</button>
                                <button class="btn btn-primary" type="button">Save</button>
                            </div>
                        </div>                         
    
                    </div>                    
                </div>

            </div>
        </div>
    </div> 
</template>

<script>

//import '@/assets/js/three.js'
//import '@/assets/js/owm.js'
//import '@/assets/js/occelmnt.js'

export default {
     data() {
        return {
            CANVAS_WIDTH: 0,
            CANVAS_HEIGHT: 0,
            scene: {},
            camera: {},
            light: {},
            evt: {},
            elements: [],
            selectedEventIndex: 0,
            selectedEventTitle: '(4524) Barklajdetolli occults 4U 458-001927 on Sat 13 Feb, 20:18'
        }
     },
     methods: {
/*         SelectEventRow(row){
            //$(row).addClass('highlight').siblings().removeClass('highlight');
            //var idx = $(row).data("occel") % this.elements.length;
            this.PlotEvent(this.selectedEventIndex);
        },
        InitScene() {
            var canvas = document.getElementById( 'earthCanvas' );

            this.CANVAS_WIDTH = canvas.clientWidth,
            this.CANVAS_HEIGHT = canvas.clientHeight;

            this.scene = new THREE.Scene();

            this.scene.add(new THREE.AmbientLight(0x444444));

            this.camera = new THREE.OrthographicCamera(-2, 2, 2, -2, 0, 10);
            this.light = new THREE.DirectionalLight(0xffffff, 1);
            this.scene.add(this.light);    

            const geometry = new THREE.SphereBufferGeometry( 1.5, 32, 32 );

            const loader = new THREE.TextureLoader();
            loader.setCrossOrigin('anonymous');

            const material = new THREE.MeshStandardMaterial();

            material.map = loader.load("@/assets/images/2_no_clouds_4k.jpg");
            material.bumpMap = loader.load('@/assets/images/elev_bump_4k.jpg');
            material.bumpScale = 0.05;            
            material.needsUpdate = true;

            const globeMesh = new THREE.Mesh( geometry, material );            

            var renderer = new THREE.WebGLRenderer( { antialias: true } );
            renderer.setPixelRatio( window.devicePixelRatio );
            renderer.setSize( this.CANVAS_WIDTH, this.CANVAS_HEIGHT );        
            canvas.appendChild( renderer.domElement );

            globeMesh.position.set( 0, 0, 0 );
            this.scene.add( globeMesh );


            function animate() {
                requestAnimationFrame( animate );
                renderer.render( this.scene, this.camera );
            }
            animate();  
        },
        PlotEvent(elIndex)
        {
            if (typeof this.evt !== 'undefined' && this.evt !== null) this.evt.clearPlot();
            this.evt = new Occelmnt(this.elements[elIndex], OccFileType.String540);

            this.selectedEventTitle = this.evt.toString();
            this.evt.plot2(this.camera, this.light);            
        }*/
    },
    mounted() {

    } 
}
</script>

<style scoped>

</style>