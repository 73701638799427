import Tags from '../views/Tags.vue'
import Filters from '../views/Filters.vue'
import TagEvents from '../views/TagEvents.vue'
import UploadEvents from '../views/UploadEvents.vue'
import UploadEventsHistory from '../views/UploadEventsHistory.vue'
import EventSearchAdvanced from '../views/EventSearchAdvanced.vue'

export default [
    {
        path: '/tags',
        name: 'Tags',
        component: Tags
    },
    {
        path: '/filters',
        name: 'Filters',
        component: Filters
    },  
    {
        path: '/search',
        name: 'Search',
        component: EventSearchAdvanced
    },      
    {
        path: '/tag-events',
        name: 'TagEvents',
        component: TagEvents
    },   
    {
        path: '/upload-events',
        name: 'UploadEvents',
        component: UploadEvents,
        meta: { title: "Upload Events"}
    }, 
    {
        path: '/upload-events/history',
        name: 'UploadEventsHistory',
        component: UploadEventsHistory,
        meta: { title: "Upload Events History"}
    }, 
    {
        path: '/community-tags',
        name: 'CommunityTags',
        meta: { title: "Community Tags", headerless: true, public: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/CommunityTags.vue')
    },       
]