import Vue from 'vue'

Vue.filter('formatYesNo', function(value) {
    return value ? 'Yes' : 'No';
  });
  
  Vue.filter('formatUpperCase', function(value) {
    return value ? value.toUpperCase() : '';
  });
  
  Vue.filter('formatCommaList', function(value) {
    return value ? value.join(', ') : '';
  });
  
  Vue.filter("formatTrim", function (value) {
    if (!value)
      return '';
    else
      return value.trim();
  });
  
  Vue.filter("formatFixed0", function (value) {
    if (!value && value !== 0)
      return '';
    else
      return value.toFixed(0);
  });
  
  Vue.filter("formatFixed1", function (value) {
    if (!value && value !== 0)
      return '';
    else
      return value.toFixed(1);
  });
  
  Vue.filter("formatFixed2", function (value) {
    if (!value && value !== 0)
      return '';
    else
      return value.toFixed(2);
  });
  
  Vue.filter("formatFixed3", function (value) {
    if (value === undefined)
      return '';
    else
      return value.toFixed(3);
  });
  
  Vue.filter("formatFixedOrPrecision2", function (value) {
    if (!value && value !== 0)
      return '';
    else
    {
      var fxd = value.toFixed(2) + '';
      var prc = value.toPrecision(2) + '';
      return prc.length > fxd.length ? prc : fxd;
    }
  });
  
  Vue.filter('numberWithGroupSeparators', function(value) {
    let nStr = value + '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  });