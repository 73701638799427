<template>
    <div>
        <span class="badge badge-owc mx-1" v-for="tag in tags" :key="tag.id">{{tag.name}}<a class="pl-1" href="#" @click="toggleTag(tag)">
            <i class="fa fa-check"></i></a>
        </span>      
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: ['singleSelection'], 
    methods: {
        ...mapActions(['toggleSelectedTag']),
        toggleTag(tag) {
            if (this.singleSelection === true && this.$store.getters.selectedTag !== null)
            {
                this.showToast('Only a single tag can be selected.', 'ERROR'); 
            }
            else
            {
                this.toggleSelectedTag(tag.id);
            }
        }
    },
    computed: {
        tags(){
            return this.$store.getters.deselectedTags;
        },
    }
}
</script>

<style scoped>
.selected-tag {
    border: 1px solid #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
}
</style>