export class xephem {
    // Calc 1900 jan 0.5 as 1900 jan 1.5 minus 1 day
    static ZERO_TICKS = Date.parse("1900-01-01T12:00:00Z") - 1000 * 3600 * 24

    degrad(x) {
        return (x) * Math.PI / 180.0;
    }

    raddeg(x) {
        return (x) * 180.0 / Math.PI;
    }

    range(x, r) {
        x -= r * Math.floor(x/r);
        return x;
    }

    // number of days elapsed since 1900 jan 0.5
    getMJD(moment)
    {
        return (new Date(moment - xephem.ZERO_TICKS)) / (1000 * 3600 * 24);
    }

    /* given the mean anomaly, ma, and the eccentricity, s, of elliptical motion,
     * find the true anomaly, *nu, and the eccentric anomaly, *ea.
     * all angles in radians.
     */
    anomaly(ma, s) {
        var rv = {
            nu: NaN, 
            ea : NaN
        }

        let fea = 0;

        if (s < 1.0) 
        {
            /* elliptical */
            let m = ma - Math.PI * 2 * Math.floor(ma / (Math.PI * 2));
            if (m > Math.PI) m -= Math.PI * 2;
            if (m < -Math.PI) m += Math.PI * 2;

            /*  The current method is ok but smoe tempting mods require
            *	0<M<PI. Substitute m1 for m if so.
            *	m1 = fabs(m);
            */
            fea = m;

            for (;;) 
            {
                let dla = fea - (s * Math.sin(fea)) - m;
                if (Math.abs(dla)<1e-6)
                    break;
                /* avoid runnaway corrections for e>.97 and M near 0*/
                let corr = 1 - (s * Math.cos(fea));
                if (corr < 0.1) corr = 0.1;
                dla /= corr;
                fea -= dla;
            }

            rv.nu = 2 * Math.atan(Math.sqrt((1 + s) / (1 - s)) * Math.tan(fea / 2));
        }

        rv.ea = fea;
        
        return rv;
    }

    obliquity (mjd)
    {
        let t = mjd/36525.0;
        return this.degrad(2.345229444E1 - ((((-1.81E-3*t)+5.9E-3)*t+4.6845E1)*t)/3600.0);
    }

    nutation (mjd) {
        var rv = {
            deps: NaN,
            dpsi: NaN
        };
            
        let t = mjd/36525.0;
        let t2 = t*t;

        let a = 100.0021358*t;
        let b = 360.0*(a - Math.floor(a));
        let ls = 279.697+.000303*t2+b;

        a = 1336.855231*t;
        b = 360.0*(a - Math.floor(a));
        let ld = 270.434-.001133*t2+b;

        a = 99.99736056000026*t;
        b = 360.0*(a - Math.floor(a));
        let ms = 358.476-.00015*t2+b;

        a = 13255523.59*t;
        b = 360.0*(a - Math.floor(a));
        let md = 296.105+.009192*t2+b;

        a = 5.372616667*t;
        b = 360.0*(a - Math.floor(a));
        let nm = 259.183+.002078*t2-b;

        /* convert to radian forms for use with trig functions.
         */
        let tls = 2 * this.degrad(ls);
        nm = this.degrad(nm);
        let tnm = 2*nm;
        ms = this.degrad(ms);
        let tld = 2 * this.degrad(ld);
        md = this.degrad(md);

        /* find delta psi and eps, in arcseconds.
         */
        rv.dpsi = (-17.2327 - .01737 * t) * Math.sin(nm) + (-1.2729 - .00013 * t) * Math.sin(tls)
               + .2088 * Math.sin(tnm) - .2037 * Math.sin(tld) + (.1261 - .00031 * t) * Math.sin(ms)
               + .0675 * Math.sin(md) - (.0497 - .00012 * t) * Math.sin(tls + ms)
               - .0342 * Math.sin(tld - nm) - .0261 * Math.sin(tld + md) + .0214 * Math.sin(tls - ms)
               - .0149 * Math.sin(tls - tld + md) + .0124 * Math.sin(tls - nm) + .0114 * Math.sin(tld - md);

        rv.deps = (9.21 + .00091 * t) * Math.cos(nm) + (.5522 - .00029 * t) * Math.cos(tls)
               - .0904 * Math.cos(tnm) + .0884 * Math.cos(tld) + .0216 * Math.cos(tls + ms)
               + .0183 * Math.cos(tld - nm) + .0113 * Math.cos(tld + md) - .0093 * Math.cos(tls - ms)
               - .0066 * Math.cos(tls - nm);

        /* convert to radians.
         */
        rv.dpsi = this.degrad(rv.dpsi/3600);
        rv.deps = this.degrad(rv.deps/3600);

        return rv;
    }    

    /* given the modified JD, mjd, return the true geocentric ecliptic longitude
        *   of the sun for the mean equinox of the date, *lsn, in radians, and the
        *   sun-earth distance, *rsn, in AU. (the true ecliptic latitude is never more
        *   than 1.2 arc seconds and so may be taken to be a constant 0.)
        * if the APPARENT ecliptic longitude is required, correct the longitude for
        *   nutation to the true equinox of date and for aberration (light travel time,
        *   approximately  -9.27e7/186000/(3600*24*365)*2*pi = -9.93e-5 radians).
        */
    sunpos(mjd)
    {
        var rv = {
            lsn: NaN,
            rsn: NaN
        }
       
        let t = mjd/36525.0;
        let t2 = t*t;
        let a = 100.0021359*t;
        let b = 360.0*(a - Math.floor(a));
        let ls = 279.69668+0.0003025*t2+b;
        a = 99.99736042000039*t;
        b = 360*(a - Math.floor(a));
        let ms = 358.47583-(0.00015+0.0000033*t)*t2+b;
        let s = 0.016751-0.0000418*t-1.26e-07*t2;

        var an = this.anomaly(this.degrad(ms), s);

        a = 62.55209472000015*t;
        b = 360*(a - Math.floor(a));
        let a1 = this.degrad(153.23+b);
        a = 125.1041894*t;
        b = 360*(a - Math.floor(a));
        let b1 = this.degrad(216.57+b);
        a = 91.56766028*t;
        b = 360*(a - Math.floor(a));
        let c1 = this.degrad(312.69+b);
        a = 1236.853095*t;
        b = 360*(a - Math.floor(a));
        let d1 = this.degrad(350.74-0.00144*t2+b);
        let e1 = this.degrad(231.19+20.2*t);
        a = 183.1353208*t;
        b = 360*(a - Math.floor(a));
        let h1 = this.degrad(353.4+b);
        let dl = 0.00134 * Math.cos(a1) + 0.00154 * Math.cos(b1) + 0.002 * Math.cos(c1) + .00179 * Math.sin(d1) + 0.00178 * Math.sin(e1);
        let dr = 5.43e-06 * Math.sin(a1) + 1.575e-05 * Math.sin(b1) + 1.627e-05 * Math.sin(c1) + 3.076e-05 * Math.cos(d1) + 9.27e-06 * Math.sin(h1);

        rv.lsn = an.nu + this.degrad(ls-ms+dl);

        while (rv.lsn < 0) { rv.lsn += 2 * Math.PI; }
        while (rv.lsn > 2 * Math.PI) { rv.lsn -= 2 * Math.PI; }

        rv.rsn = 1.0000002*(1-s*Math.cos(an.ea))+dr;

        return rv;
    }

    ecleq_aux (sw, mjd, x, y) {
        /* +1 for eq to ecliptic, -1 for vv. */			
        /* sw==1: x==ra, y==dec.  sw==-1: x==lng, y==lat. */
        /* sw==1: p==lng, q==lat. sw==-1: p==ra, q==dec. */

        var rv = {
            p: NaN, 
            q: NaN
        }

        let eps = this.obliquity (mjd);		/* mean obliquity for date */
        let nut = this.nutation (mjd);
        eps += nut.deps;
        let seps = Math.sin(eps);
        let ceps = Math.cos(eps);

        let sy = Math.sin(y);
        let cy = Math.cos(y);				/* always non-negative */
        if (Math.abs(cy)<1e-20) 
            cy = 1e-20;		/* insure > 0 */
        let ty = sy/cy;
        let cx = Math.cos(x);

        let sx = Math.sin(x);
        let q = Math.asin((sy*ceps)-(cy*seps*sx*sw));
        let p = Math.atan(((sx * ceps) + (ty * seps * sw)) / cx);
        if (cx < 0) p += Math.PI;		/* account for atan quad ambiguity */

        this.range (p, 2* Math.PI);

        rv.p = p;
        rv.q = q;
        return rv;
    }

    JD_from_Moment(moment) {
        
        let year = moment.getFullYear();
        let month = moment.getMonth() + 1;
        let dy = moment.getDate();
        let hr = moment.getHours();
        let min = moment.getMinutes();
        let sec = moment.getSeconds();

        return this.JD_from_Date(year, month, dy, hr, min, sec);
    }

    JD_from_DateString(formatedDate)
    {
        let year = Number(formatedDate.substr(0, 4));
        let month= Number(formatedDate.substr(5, 2));
        let dy = Number(formatedDate.substr(8, 2));
        let hr = Number(formatedDate.substr(11, 2));
        let min = Number(formatedDate.substr(14, 2));
        let sec = Number(formatedDate.substr(17));

        return this.JD_from_Date(year, month, dy, hr, min, sec);
    }

    JD_from_Date(year, month, dy, hr, min, sec)
    {
        let day = dy + (hr + min / 60.0 + sec / 3600.0) / 24.0;
        let num = ((12.0 * (year + 4800.0)) + month) - 3.0;
        let num2 = Math.floor(((((2.0 * (num % 12.0)) + 7.0) + (365.0 * num)) / 12.0)) + day;
        let num3 = (num2 + Math.floor((num / 48.0))) - 32083.5;
        if (((year > 0x62e) | ((year == 0x62e) & (month > 10))) | (((year == 0x62e) & (month == 10)) & (day > 4.0)))
        {
            num3 = ((num3 + Math.floor((num / 4800.0))) - Math.floor((num / 1200.0))) + 38.0;
        }
        return num3;
    }    

    siderealTimeDeg(jd) {
        let num2 = Math.floor(jd) + 0.5;
        let num = (num2 - 2451545.0) / 36525.0;
        let num3 = (100.46061837 + (36000.770053608 * num)) + ((0.00038793 * num) * num);
        num3 += 360.985647 * (jd - num2);
        num3 = num3 % 360.0;
        return num3;
    }

    getSunPosition(formatedDate) {
        var moment = Date.parse(formatedDate + 'Z');
        return this.getSunPositionFromMoment(moment);
    }

    getSunPositionFromMoment(moment) {
        let rv = {
            ra: NaN,
            dec: NaN
        };

        let mjd = this.getMJD(moment);
        var sunPos = this.sunpos(mjd);
        var sunEcl = this.ecleq_aux(-1, mjd, sunPos.lsn, 0);
        if (sunEcl.q > Math.PI) sunEcl.q -= 2 * Math.PI;

        rv.ra = this.raddeg(sunEcl.p);
        rv.dec = this.raddeg(sunEcl.q);

        return rv;
    }
    
    longitudeForAltitudeAndLatitude(siderial, altitude, latitude, raInHours, decInDeg)
    {
        let degToRadian = 57.295779513082323;
        altitude = altitude / degToRadian;
        latitude = latitude / degToRadian;
        decInDeg = decInDeg / degToRadian;
        let lng1 = degToRadian * Math.acos((Math.sin(altitude) - Math.sin(latitude) * Math.sin(decInDeg)) / (Math.cos(latitude) * Math.cos(decInDeg)));
        let lng2 = -1 * lng1;
        lng1 = lng1 + raInHours * 15 - siderial;
        lng2 = lng2 + raInHours * 15 - siderial;
        lng1 = this.range(lng1, 360);
        lng2 = this.range(lng2, 360);
        return { lng1, lng2 };
    }

    getBearing(long1, lat1, long2, lat2)
    {
        return (180 / Math.PI) * this.calcBearing(long1 * Math.PI / 180, lat1 * Math.PI / 180, long2 * Math.PI / 180, lat2 * Math.PI / 180);
    }

    calcBearing(ALongitude, ALatitude, CLongitude, CLatitude)
    {
        let w = CLongitude - ALongitude;
        let v = CLatitude - ALatitude;

        let asinVal = Math.sqrt((Math.sin(v / 2) * Math.sin(v / 2)) + (Math.cos(ALatitude) * Math.cos(CLatitude) * Math.sin(w / 2) * Math.sin(w / 2)));
        let s = 2 * Math.asin(Math.max(-1, Math.min(1, asinVal)));
        let x = 0;
        if (Math.cos(CLatitude) < 0.000000000000001)
        {
            // initial point is pole
            if (CLatitude > 0)
            {
                x = Math.PI;  // start from north pole
            }
            else
            {
                x = 0; // start from south pole
            }
        }
        else
        {
            // initial point isn't on pole
            if (Math.sin(ALongitude - CLongitude) < 0)
            {
                let acosVal = (Math.sin(CLatitude) - Math.sin(ALatitude) * Math.cos(s)) / (Math.sin(s) * Math.cos(ALatitude));
                x = Math.acos(Math.max(-1, Math.min(1, acosVal)));
            }
            else
            {
                let acosVal = (Math.sin(CLatitude) - Math.sin(ALatitude) * Math.cos(s)) / (Math.sin(s) * Math.cos(ALatitude));
                x = 2 * Math.PI - Math.acos(Math.max(-1, Math.min(1, acosVal)));
            }
        }

        return x;
    }

    raDecToAltAz(ra,dec,lat,lon,gmst){
        ra = 15 * ra * Math.PI / 180;
        dec = dec * Math.PI / 180;
        lat = lat * Math.PI / 180;
        lon = lon * Math.PI / 180;
        gmst = gmst * Math.PI / 180;
        
        var localSiderealTime=(gmst+lon)%(2*Math.PI);
            
        let H=(localSiderealTime - ra);
        if(H<0){H+=2*Math.PI;}
        if(H>Math.PI){H=H-2*Math.PI;}
    
        let az = (Math.atan2(Math.sin(H), Math.cos(H)*Math.sin(lat) - Math.tan(dec)*Math.cos(lat)));
        let a = (Math.asin(Math.sin(lat)*Math.sin(dec) + Math.cos(lat)*Math.cos(dec)*Math.cos(H)));
        az-=Math.PI;
    
        if(az<0){az+=2*Math.PI;}
        return [a* 180 / Math.PI, az * 180 / Math.PI];
    }    
}