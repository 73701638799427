import axios from 'axios';
import * as owcMixin from '@/mixins/owcMixin.js';

export default {
    async searchEvents ({commit}, form) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/events/?astNo=${form.astNo}&dt=${form.date}&bf=${form.bufferDays}`;

            let result = await axios.get(getUrl, { withCredentials: false });

            let combinedRes = { 'data': result.data, 'form' : { type: 'ast', astNo: form.astNo, date: form.date, bufferDays: form.bufferDays } };
 
            commit('SET_EVENTS', combinedRes );
        }
        catch(error){
            throw new Error(error);
        }
    },
    async searchEventsByObjectId ({commit}, form) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/events-all/?objectId=${form.objectId}&dt=${form.date}&bf=${form.bufferDays}&t=${form.taggedOnly}`;

            let result = await axios.get(getUrl, { withCredentials: false });

            let combinedRes = { 'data': result.data, 'form' : { type: 'obj', objectId: form.objectId, date: form.date, bufferDays: form.bufferDays, taggedOnly: form.taggedOnly } };
 
            commit('SET_EVENTS', combinedRes );
        }
        catch(error){
            throw new Error(error);
        }
    },
    async searchPastEvents({commit}, form) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/past-events/?tags=${form.tags ? form.tags.join('|') : ''}&dt=${form.date}&objectId=${form.objectId}&observer=${form.observer ?? ''}&limit=${form.limit}`;

            let result = await axios.get(getUrl, { withCredentials: false });

            let combinedRes = { 'data': result.data, 'form' : { type: 'past', tags: form.tags, observer: form.observer, date: form.date, objectId: form.objectId, limit: form.limit, tabIndex: form.tabIndex } };
 
            commit('SET_EVENTS', combinedRes );
        }
        catch(error){
            throw new Error(error);
        }
    },    
    async searchEventsByTag({commit}, form) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/events2/?tags=${form.tags.join('|')}&countries=${form.countries.join('|')}&dt=${form.date}&minR=${form.minRank}&limit=${form.limit}`;

            let result = await axios.get(getUrl, { withCredentials: false });

            let combinedRes = { 'data': result.data, 'form' : { type: 'tag', tags: form.tags, countries: form.countries, date: form.date, minRank: form.minRank, limit: form.limit } };
 
            commit('SET_EVENTS', combinedRes );
        }
        catch(error){
            throw new Error(error);
        }
    },
    async searchEventsByStar({commit}, form) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/events3/?id=${form.id}&ra=${form.ra}&de=${form.de}`;

            let result = await axios.get(getUrl, { withCredentials: false });

            let combinedRes = { 'data': result.data, 'form' : { type: 'star', starIdentifier: form.id } };
 
            commit('SET_EVENTS', combinedRes );
        }
        catch(error){
            throw new Error(error);
        }
    },   
    async searchEventById ({commit}, eventId) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/event/${eventId}`;

            let result = await axios.get(getUrl, { withCredentials: false });

            commit('SET_CURR_EVENT', result.data);
        }
        catch(error){
            throw new Error(error);
        }
    },
    async searchEventPrediction ({commit}, form) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/event/${form.eventId}/${form.predSrc}`;

            let result = await axios.get(getUrl, { withCredentials: false });

            commit('SET_CURR_PREDICTION', result.data);
        }
        catch(error){
            throw new Error(error);
        }
    },
    async searchMyEventPrediction ({commit}, form) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/event/my/${form.eventId}/${form.predSrc}`;

            let result = await axios.get(getUrl, { withCredentials: true });

            commit('SET_CURR_PREDICTION', result.data);
        }
        catch(error){
            throw new Error(error);
        }
    },
    async setPreferredPrediction(_, form) {
        try
        {
            await owcMixin.sleep(200);

            let postUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/event/${form.eventId}/set-preferred-prediction?predictionId=${form.predictionId}`;
            let result = await axios.post(postUrl, null, { withCredentials: true });

            if (result && result.status === 200)
            {
                return true;    
            }
        }
        catch(error) {
            throw new Error(error);
        }  
    },
    async getEventStations ({commit}, eventId) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/event/${eventId}/stations`;

            let result = await axios.get(getUrl, { withCredentials: false });

            commit('SET_CURR_EVENT_STATIONS', result.data);
        }
        catch(error){
            throw new Error(error);
        }
    },    
    async searchEventsCampaignIdWithAuth({commit}, form) {
        try
        {
            var epPart = form.observed ? "campaign-events-observed" : "campaign-events-future";
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/${epPart}/?id=${form.campaignId}&d=${form.date}&l=${form.limit}`;

            let result = await axios.get(getUrl, { withCredentials: true });

            let combinedRes = { 'data': result.data, 'form' : { type: 'campaign', campaignId: form.campaignId, date: form.date, limit: form.limit } };
 
            commit('SET_EVENTS', combinedRes );
        }
        catch(error){
            throw new Error(error);
        }
    },
    async resolveStar({commit}, starId) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/star-id-gaia?q=${encodeURIComponent(starId)}`;
            let result = await axios.get(getUrl, { withCredentials: false });

            commit('SET_STAR_INFO', result.data);
        }
        catch(error) {
            throw new Error(error);
        }
    },       
    async getTotalEventsInfo ({commit}) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/events/info`;
            let result = await axios.get(getUrl, { withCredentials: false });

            commit('SET_TOTALS_INFO', result.data);
        }
        catch(error) {
            throw new Error(error);
        }
    }, 
    async requestEventPredictionUpdate(_, eventId) {
        try
        {
            await owcMixin.sleep(1000);

            let postUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/event/${eventId}/request-prediction-update`;
            let result = await axios.post(postUrl, { withCredentials: false });

            if (result && result.status === 200)
            {
                return true;    
            }
        }
        catch(error) {
            throw new Error(error);
        }        
    },  
    async requestChangeDefaultPrediction(_, frm) {
        try
        {
            await owcMixin.sleep(1000);
            
            let postUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/event/${frm.eventId}/request-default-update/${frm.predId}`;

            let result = await axios.post(postUrl, null, { withCredentials: true });

            if (result && result.status === 200)
            {
                return true;    
            }
        }
        catch(error) {
            throw new Error(error);
        } 
    },
    async locateEvent(_, frm)
    {
        try
        {
            let getUrl = frm.date != null 
                ? `${process.env.VUE_APP_OWC_WEB_API_EXT_URL}/locate?ast=${frm.ast}&tag=${frm.tag}&date=${frm.date}`
                : `${process.env.VUE_APP_OWC_WEB_API_EXT_URL}/locate?ast=${frm.ast}&tag=${frm.tag}&occelmntId=${frm.occelmntid}`;

            let result = await axios.get(getUrl, { withCredentials: false, headers: {
                accept: "application/json",
              } });

            return result.data;
        }
        catch(error) {
            throw new Error(error);
        }       
    }
}