import axios from 'axios';

export default {
    async loadUserFilters ({commit}) {
        try
        {
            let getUrl = `${process.env.VUE_APP_WEB_API_BASE_URL}/filters/list`;
            
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                commit('SET_USER_FILTERS', result.data);                
            } 
        }
        catch(error){
            throw new Error(error);
        }
    },    
    async addUserFilter (_, form) {
        try
        {
            let putUrl = `${process.env.VUE_APP_WEB_API_BASE_URL}/filters/new`;
            
            let result = await axios.put(putUrl, form, { withCredentials: true });

            if (result && result.status === 200)
            {
                // All good
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async updateUserFilter (_, form) {
        try
        {
            let postUrl = `${process.env.VUE_APP_WEB_API_BASE_URL}/filters/${form.id}`;
            
            console.log(`PST ${postUrl}`);
            
            let result = await axios.post(postUrl, form, { withCredentials: true });

            if (result && result.status === 200)
            {
                // All good
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async deleteUserFilter (_, id) {
        try
        {
            let deleteUrl = `${process.env.VUE_APP_WEB_API_BASE_URL}/filters/${id}`;
            
            let result = await axios.delete(deleteUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                // All good
            }
        }
        catch(error){
            throw new Error(error);
        }
    },        
    async filterEvents ({commit}, form) {
        try
        {
            let postUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/search/advanced`;
            
            let result = await axios.post(postUrl, form, { withCredentials: true });

            let combinedRes = { 'data': result.data, 'form' : form };
 
            commit('SET_FILTERED_EVENTS', combinedRes );
        }
        catch(error){
            throw new Error(error);
        }
    },
    async filterEvents2 ({commit}, form) {
        try
        {
            let postUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/search/filter`;
            
            let result = await axios.post(postUrl, form, { withCredentials: true });

            let combinedRes = { 'data': result.data, 'form' : form };
 
            commit('SET_FILTERED_EVENTS', combinedRes );
        }
        catch(error){
            throw new Error(error);
        }
    }
}