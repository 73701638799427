<template>
    <div>
        <b-button @click="modalShow =!modalShow" title="Edit"><i class="fa fa-edit"></i></b-button>
        <b-modal 
            v-model="modalShow"
            title="Update Tag"
            @show="showModal"
            @hidden="resetModal"
            @ok="handleOk">
                <div class="col-12">
                    <div class="row pt-2"  style="display: flex;">
                        <div class="col-lg-12">
                            <div class="bs-component">
                                <form>
                                    <fieldset>                           
                                        <div class="form-row">
                                            <div class="form-group col-md-12 pt-2">
                                                <label for="tagName" class="">Tag Name</label>
                                                <input class="form-control disabled-control" id="tagName" v-model="form.name">
                                            </div>                                                 
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label for="tagUrl" class="">Url</label>
                                                <input class="form-control" id="tagUrl" v-model="form.url">
                                            </div>                                         
                                        </div>                                               
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label for="tagDescr" class="">Description</label>
                                                <textarea class="form-control span6" rows="4" id="tagDescr" v-model="form.descr"></textarea>
                                            </div>                                                 
                                        </div>                                    
                                    </fieldset>
                                </form>
                            </div>                        
                        </div>
                    </div> 
                </div>
        </b-modal>            
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: "UpdateTag",
    props: ['tag'],    
    data() {
        return {
            modalShow: false,
            form : {
                name: '',
                descr: '',
                url: ''
            },
            submitted: false            
        }
    },
    methods: {
        ...mapActions(['updateTag']),
        showModal() {
            this.form = {
                id: this.$props.tag.id,
                name: this.$props.tag.name,
                descr: this.$props.tag.descr,
                url: this.$props.tag.url
            }
        },
        resetModal() {
            this.form = { };
        },    
        async handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();            
            this.submitted = true;
            var valid = await this.$validator.validate();
            if (valid)
            {     
                try
                { 
                    await this.updateTag({ id: this.form.id, url: this.form.url, descr: this.form.descr })

                    this.showToast('Tag updated successfully.', 'SUCCESS');
                }
                catch(error)
                {
                    this.showToast(error.message, 'ERROR');
                }
                this.modalShow = false;
                this.submitted = false;
            }
        }            
    }
}
</script>

<style scoped>

</style>