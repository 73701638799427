import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import owcMixin from '@/mixins/owcMixin';
import '@/filters/dateTimeFilters';
import '@/filters/astronomyFilters';
import '@/filters/filters';

import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Clipboard from 'v-clipboard'
 
Vue.use(BootstrapVue);
Vue.use(Clipboard)

const dictionary = {
  en: {
    custom: {
      eventDatePicker: {
         required: 'Required field'
      },
      objectId: {
        required: 'Required field',
        regex: 'Invalid object id',
      },
      selectedTags : {
        required: 'Select one or more tags'
      },
      orbitSource: {
        required: 'Required field'
      },
      starSource: {
        required: 'Required field'
      },
      uploadedFile: {
        required: 'A file should be upload first'
      },
    }
  }
}

Vue.use(VeeValidate, { inject: true, dictionary });

Vue.mixin(owcMixin);

Vue.config.productionTip = false

export const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')