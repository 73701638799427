<template>
    <div v-if="foundEvent">
        <button class="btn btn-primary mb-2" @click="onTagClicked">Tag Event</button>

        <b-modal 
            ref="loginPromptModal" 
            title="Login Required" 
            centered size="md"
            @ok="onRedirectToLogin">
            <div class="d-block my-2">
                <span class="ml-4">&nbsp;</span>In order to tag an event with a community tag you are required to log in first. Once logged in press the 'Tag Event' button again.
            </div>
        </b-modal>

        <b-modal 
            ref="tagEventModal" 
            title="Tag Event" 
            scrollable 
            centered
            size="lg"
            @shown="onTagsModalShown"
            @ok="comunityTagEvent"
            :ok-disabled="this.selectedTag === -1">
        
            <b-spinner v-if="showLoader || showSubmitLoader" class="justify-content-center my-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>

            <div v-if="!showLoader" v-bind:class="{ 'grayout': showSubmitLoader }">
                <div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div>Please select the tag you want to apply to this event:</div>
                            <span class="text-muted"><small>(If you want to tag an event against a new tag not listed below, please contact <strong class="text-light">OWCloud@groups.io</strong>)</small></span>                            
                            <hr style="height:1px; width:100%; border-width:0; color: rgb(65, 65, 65); background-color: rgb(65, 65, 65)">
                            
                        </div>   
                        <div class="col-12"  v-for="tag in loadedTags" :key="tag.id">
                            <b-radio v-model="selectedTag" :value="tag.id">
                                <span class="badge badge-owc mx-2">{{tag.name}}</span>
                                <span>{{tag.descr}}</span>
                            </b-radio>                        
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: "CommunityTagEvent",
    props: ['eventId'],
    data() {
        return {
            showLoader: false,
            showSubmitLoader: false,
            selectedTag: -1
        }        
    },
    methods: {
         ...mapActions(['loadSingleEventUserTags', 'singleTagEvent']),
        async refreshTagPairs() {
            this.showLoader = true;

            try 
            {
                await this.loadSingleEventUserTags();

                this.showLoader = false;
            }
            catch(error)
            {
                this.showLoader = false;
                this.showToast(error.message, 'ERROR');                    
            }
        },        
        showLoginPrompt() {
            this.$refs['loginPromptModal'].show()
        },
        hideLoginPrompt() {
            this.$refs['loginPromptModal'].hide()
        },
        showTagEventPrompt() {
            this.$refs['tagEventModal'].show()
        },
        hideTagEventPrompt() {
            this.$refs['tagEventModal'].hide()
        },
        async onTagsModalShown(){
            if (!this.loadedTags || this.loadedTags.length == 0)
            {
                await this.refreshTagPairs();
            }
        },
        onTagClicked(){
            if (!this.currentUser)
            {
                this.showLoginPrompt();
            }
            else
            {
                this.showTagEventPrompt();
            }
        },
        onRedirectToLogin(){
            this.hideLoginPrompt();
            this.$router.push(`/login?redirectTo=${encodeURIComponent(this.$router.currentRoute.path)}`);
        },
        async comunityTagEvent(bvModalEvt){

            bvModalEvt.preventDefault();
            this.showSubmitLoader = true;

            try 
            {
                var form = {
                    eventId: this.eventId,
                    tagId: this.selectedTag
                };
                await this.singleTagEvent(form);

                this.showSubmitLoader = false;

                this.hideTagEventPrompt();

                // refresh the current page
                this.$router.go();
            }
            catch(error)
            {
                this.showSubmitLoader = false;
                this.showToast(error.message, 'ERROR');                    
            }
        }
    },
    computed: {
        currentUser(){
            return this.$store.getters.currentUser;
        },
        foundEvent(){            
            return this.$store.getters.currentEventResult;
        },
        loadedTags() {
            return this.$store.getters.loadedTags;
        }
    }
}
</script>

<style scoped>

</style>