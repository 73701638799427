import axios from 'axios';
import * as owcMixin from '@/mixins/owcMixin.js';

import authActions from '@/store/actions/authActions.js'
import eventActions from '@/store/actions/eventActions.js'
import tagEventActions from '@/store/actions/tagEventActions.js'
import tagActions from '@/store/actions/tagActions.js'
import planningActions from '@/store/actions/planningActions.js'
import googleMapsApiActions from '@/store/actions/googleMapsApiActions.js'
import filterEventActions from '@/store/actions/filterEventActions.js'

export default {
    ...authActions,
    ...eventActions,
    ...tagEventActions,
    ...tagActions,
    ...planningActions,
    ...googleMapsApiActions,
    ...filterEventActions,
    async setViewPortSize ({commit}, viewPortSize) {
        commit('SET_BOOTSTRAP_VIEW_PORT', viewPortSize);
    },     
    async setSelectedMapLocation ({commit}, loc) {
        commit('SET_SELECTED_MAP_LOCATION', loc);
    }, 
    async loadServerVersionInfo ({commit}) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/server/version`;
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                commit('SET_SERVER_VERSION_INFO', result.data);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },     
    async dummyMethod(){
        await owcMixin.sleep(2000);
    }
}