<template>
    <div style="font-size:12px;border: 1px solid #676767;background-color: #6A6A48" 
            class="px-2 py-2" v-if="currentStation && isEditing">
        <div class="row">
            <div class="float-left pl-3">
                <span style="font-size:12px;" nowrap>Site name:</span>
                <div class="align-middle">{{currentStation.name}}</div>
            </div>
            <div class="float-left pl-3">
                <span style="font-size:12px;" nowrap>Report:</span>
                <div class="align-middle">
                    <select id="obsReport" class="edit" v-model="this.report" :disabled="showLoader" @change="repChanged($event)">
                        <option value="0">Not Reported</option>
                        <option value="1">Report a miss</option>
                        <option value="2">Clouded out</option>
                        <option value="3">Failed to observe</option>
                        <option value="4">Report a positive</option>
                        <option value="5">No observation</option>
                        <option value="6">Observed - report to follow</option>
                    </select>
                </div>
            </div>
            <div class="float-left pl-2" v-if="this.report == '4'">
                <span style="font-size:12px;" nowrap>Duration (sec):</span>
                <div class="align-bottom">
                    <input id="obsDur" 
                        type="text" class="edit" style="line-height: normal"
                        v-model="duration"
                        @keypress="isNumber($event)"
                        :disabled="showLoader"/>
                </div>
            </div>
            <div class="float-left pl-3" v-if="this.fixedComment != '3'">
                <span style="font-size:12px;" nowrap>Comment (optional):</span>
                <div class="align-middle">
                    <select id="obsReport" class="edit" v-model="this.fixedComment" :disabled="showLoader" @change="fixedCommentChanged($event)">
                        <option value="0"></option>
                        <option value="1">Clouded Out</option>
                        <option value="2">Technical Failure</option>
                        <option value="3">Provide Own Comment</option>
                    </select>
                </div>
            </div>            
            <div class="float-left pl-2" v-if="this.fixedComment == '3'">
                <span style="font-size:12px;" nowrap>Comment (optional):</span>
                <div class="align-bottom">
                    <input id="obsComment" 
                        type="text" class="edit" style="line-height: normal"
                        v-model="comment"
                        :disabled="showLoader"/>
                </div>
            </div>
            <div class="pl-2 align-bottom" style="padding-top: 12px">
                <b-button class="btn btn-primary mr-2" style="line-height: normal" :disabled="showLoader" variant="primary" size="sm" @click="onCancelClicked">Cancel</b-button>
                <b-button class="btn btn-primary mr-2" style="line-height: normal" :disabled="showLoader || !isReported" variant="primary" size="sm" @click="onSubmitClicked">Submit Report</b-button>
                <b-spinner v-if="showLoader" 
                                        class="justify-content-center mt-1" 
                                        varient="secondary" 
                                        style="width: 1rem; height: 1rem;" ></b-spinner>
            </div>
        </div>
    </div>  
</template>

<script>
export default {
    props: ['stations'],
    data() {
        return {
            currentStation: null,
            isEditing: false,
            showLoader: false,
            report: 0,
            duration: 0,
            fixedComment: 0,
            comment: null
        }
    },       
    methods: {
        reportObservation(station) {
            this.currentStation = {...station};
            this.report = this.currentStation.rep != null ? this.currentStation.rep : 0;
            this.duration = this.currentStation.repDur != null ? this.currentStation.repDur : 0;
            this.fixedComment = this.currentStation.repComment != null ? 3 :0;
            this.comment = this.currentStation.repComment;
            this.isEditing = true;
        },
        hide() {
            this.isEditing = false;
            this.showLoader = false;
        },
        onCancelClicked() {
            this.isEditing = false;
            this.$emit('cancelReportObservation');
        },
        onSubmitClicked() {            
            this.showLoader = true;

            if (this.fixedComment == 0)
                this.comment = "";
            else if (this.fixedComment == 1)
                this.comment = "Clouded Out";
            else if (this.fixedComment == 2)
                this.comment = "Technical Failure";

            if (this.comment == null) this.comment = "";

            this.$emit('submitObservationReport', { stationId: this.currentStation.no, report: this.report, duration: this.duration, comment: this.comment});
        },
        repChanged(event) {
            this.report = Number(event.target.value);
            if (this.fixedComment == 3) this.fixedComment = 0;
        },
        fixedCommentChanged(event) {
            this.fixedComment = Number(event.target.value);
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }           
        }
    },
    computed: {
        isReported() {
            return this.report > 0;
        },
        isPositive() {
            return this.report == 4;
        }
    } 
}
</script>