<template>
    <div>
        <div class="row pt-2 mt-3" v-bind:class="{ 'grayout': showLoader }">
            <div class="col-xl-2 col-lg-2 col-md-3 non-tab-top-align">
                <label for="objectId2" class="">Object Id</label>
                <span class="text-muted" style="cursor: pointer;"
                    @click="showObjectIdHint = !showObjectIdHint">
                    <small>
                        <strong class="text-light"> (&nbsp;</strong>
                            <i class="fa pr-1 fa-question-circle"></i>
                            <span :style="showObjectIdHint ? 'color: #cccccc;' : ''" class="pr-1">Help</span>
                            <i class="fa pr-1 fa-chevron-up" v-if="showObjectIdHint"></i>
                            <i class="fa pr-1 fa-chevron-down" v-if="!showObjectIdHint"></i>
                        <strong class="text-light">)</strong>
                    </small>
                </span>
                <b-popover 
                    variant="secondary"
                    target="objectId2" 
                    triggers=""
                    placement="left"
                    :show.sync="showObjectIdHint">
                    <template #title>Supported Object Id Formats <a @click="showObjectIdHint = false" class="pl-2"><i class="fa pr-1 fa-close"></i></a></template>
                    <strong>Asteroid Number</strong>: 17237 <br/>
                    <strong>Unnumbered Asteroid</strong>: 2015 BZ518 <br/>
                    <strong>Periodic Comet</strong>: 252P<br/>
                    <strong>Provisional Comet</strong>: C/2017 Y2<br/>
                    <strong>Interstellar Object</strong>:2I<br/>
                    <strong>Planetary Moon</strong>: P6M14<br/>
                </b-popover>
                <input 
                    v-validate="{required: false, regex: /^(\d+(P|I)?|P\dM\d+|((P|C|A)\/)?\d{4}\s[\w\d]+)$/i }" 
                    v-model="form.objectId" 
                    class="form-control mb-3" 
                    :disabled="showLoader"
                    id="objectId2" 
                    name="objectId2">
                <div v-if="validated" class="text-danger">
                        {{errors.first('objectId2')}}
                </div>                     
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 non-tab-top-align">
                <label for="eventDate" class="">Event Date</label>
                <b-input-group class="mb-3">
                    <input 
                        class="form-control" 
                        v-model="form.eventDateFormatted" type="text"
                        :disabled="showLoader"
                        autocomplete="off" id="eventDate" name="eventDate"/>
                    <b-input-group-append>
                        <b-form-datepicker 
                            v-model="form.eventDate" 
                            v-validate="{required: false}" 
                            data-vv-name="eventDatePicker"
                            button-only right locale="en-AU" 
                            aria-controls="example-input"
                            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                            @context="onDatePick">
                        </b-form-datepicker>
                    </b-input-group-append>
                </b-input-group>  
                <div v-if="validated" class="text-danger">
                        {{errors.first('eventDatePicker')}}
                </div>                                       
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3">
                <b-tabs content-class="mt-1" v-model="tabIndex">
                    <b-tab title="Observer" active>                      
                        <input 
                            v-validate="{required: false }" 
                            v-model="form.eventObserver" 
                            class="form-control mb-3" 
                            :disabled="showLoader"
                            id="eventObserver" 
                            title="Optionally search by Observer or Tag"
                            name="eventObserver">
                        <div v-if="validated" class="text-danger">
                                {{errors.first('eventObserver')}}
                        </div>  
                    </b-tab>
                    <b-tab title="Tags">
                        <TagPicker      
                            v-model="form.eventTags" 
                            v-validate="{required: false}" 
                            data-vv-name="Tags"                      
                            :title="'tags'" 
                            :loadedTags="loadedTags" 
                            :selectedTags="form.eventTags"
                            @pickedTagsChanged="onTagsChanged"/>
                        <div v-if="validated" class="text-danger">
                                {{errors.first('Tags')}}
                        </div>
                    </b-tab>
                </b-tabs>                
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 non-tab-top-align">
                <label for="limit" class="">Max Results</label>
                <b-button-toolbar key-nav>
                    <b-button-group>
                        <b-button style="margin-right: 1px" ref="d0" @click="setMaxResults(10)" :selected="this.form.limit === 10">10</b-button>
                        <b-button style="margin-right: 1px" ref="d1" @click="setMaxResults(15)" :selected="this.form.limit === 15">15</b-button>
                        <b-button style="margin-right: 1px" ref="d2" @click="setMaxResults(20)" :selected="this.form.limit === 20">20</b-button>
                        <b-button ref="d3" @click="setMaxResults(25)" :selected="this.form.limit === 25">25</b-button>
                    </b-button-group>
                </b-button-toolbar>
            </div>                 
            <div class="col-xl-2 col-lg-3 col-md-3 non-tab-top-align">
                <label for="eventDate" class="">&nbsp;</label>
                <button 
                    type="button" 
                    @click="onSearchEvents" 
                    :disabled="showLoader"
                    class="btn btn-primary align-self-end btn-block mt-2">Search</button>    
            </div>                                 
        </div>
        <div class="row mb-4">
            <div class="col-12">
                <b-spinner v-if="!totalEventsInfo" class="justify-content-center" type="grow" varient="secondary" style="width: 1rem; height: 1rem;" ></b-spinner>                    
                <span class="text-muted" v-if="totalEventsInfo"><small>Searching <strong class="text-light">{{totalEventsInfo.totPE | numberWithGroupSeparators}}</strong> past tagged events, of which <strong class="text-light">{{totalEventsInfo.totPPE | numberWithGroupSeparators}}</strong> had planned observations</small></span>
            </div>    
        </div>           
        <div class="row pt-2 mt-3">
            <div class="col-12 justify-content-center">
                <div class="table-responsive-md" v-if="(submitted || foundEventsList) && !showLoader">
                    <table class="table table-dark table-bordered table-hover">
                        <thead class="thead-dark text-center">
                            <tr>
                                <td>Closest Approach, UT</td>
                                <td>Asteroid</td>
                                <td>Star</td>
                                <td>Mag</td>
                                <td>Stations</td>
                            </tr>    
                        </thead>
                        <tbody>
                            <tr style="cursor: pointer;" v-for="evt in foundEventsList" :key="evt.id" @click="openEvent(evt)">
                                <td>{{evt.time}} <TagList v-if="evt.tags" :tags="evt.tags" /></td>
                                <td>{{evt.asteroid}}</td>
                                <td>{{evt.star}}</td>
                                <td class="text-center">{{evt.mag | formatMag}}</td>
                                <td class="text-center" v-if="evt.stSmry">
                                    <span v-if="evt.stSmry.pos > 0">
                                        <canvas width="10" height="10" class="mr-1 hit" title="Positive Detections"></canvas>{{evt.stSmry.pos}}
                                    </span>
                                    <span v-if="evt.stSmry.neg > 0">
                                        <canvas width="10" height="10" class="ml-2 mr-1 miss" title="Negative"></canvas>{{evt.stSmry.neg}}
                                    </span>
                                    <span v-if="evt.stSmry.noobs + evt.stSmry.norep > 0">
                                        <canvas width="10" height="10" class="ml-2 mr-1 norep" title="Not Reported/Not Observed"></canvas>{{evt.stSmry.noobs + evt.stSmry.norep}}
                                    </span>
                                </td>     
                                <td class="text-center" v-if="!evt.stSmry"></td>                       
                            </tr>    
                        </tbody> 
                    </table>
                    <div class="text-muted pt-2" v-if="submitted"><small>Found <strong class="text-light">{{foundEventsList.length}}</strong> event{{foundEventsList.length > 1 ? 's' : ''}} in <strong class="text-light">{{msElapsed / 1000}}</strong> sec</small></div>
                </div>                   
                <div class="text-center">
                    <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
                    <div v-if="showLoader" class="text-muted pt-4">This search may take a few seconds...</div>
                    <h5 class="text-danger" v-if="foundEventsList && foundEventsList.length < 1 && submitted && !showLoader">No events found</h5>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import TagPicker from '@/components/TagPicker.vue'
import TagList from '@/components/TagList.vue'

export default {
    components: {
        TagPicker,
        TagList
    },
    data() {
        return {
            form: {
                objectId: '',
                eventDate: '',
                eventDateFormatted: '',
                limit: 10,
                eventTags: null,
                eventObserver: null
            },
            showLoader: false,
            validated: false,
            submitted: false,
            msElapsed: 0,
            foundEventsList: null,
            showObjectIdHint: false,
            tabIndex: 0
        }
    },
    methods: {
        ...mapActions(['loadTagPairs', 'searchPastEvents']),
        onDatePick(ctx) {
            this.form.eventDateFormatted = ctx.selectedFormatted;
        },
        onTagsChanged(tags) {
            this.form.eventTags = tags;
        },
        onCountriesChanged(tags) {
            this.form.eventCountries = tags;
        },
        openEvent(evt) {
            let routeData = this.$router.resolve({ name: 'Event', params: { eventId: evt.id } });
            window.open(routeData.href, '_blank');
        },      
        async onSearchEvents() 
        {
            this.validated = true;
            var result = await this.$validator.validate();

            if (result)
            {
                var mappedTags = this.loadedTags.filter(x => this.form.eventTags.indexOf(x.name) > -1);

                this.showLoader = true;

                try 
                {
                    const startTime = Date.now();
                    const query = {
                        objectId: this.form.objectId,
                        date: this.form.eventDate,
                        limit: this.form.limit,
                        tabIndex: this.tabIndex,
                        tags: null,
                        observer: null
                    }

                    if (this.tabIndex == 1) 
                        query.tags = mappedTags.map(x => x.id);
                    else
                        query.observer = this.form.eventObserver;

                    await this.searchPastEvents(query);

                    this.msElapsed = Date.now() - startTime;

                    this.showLoader = false;
                    this.submitted = true;

                    this.foundEventsList = this.$store.getters.eventSearchResult;
                }
                catch(error)
                {
                    this.showLoader = false;
                    this.showToast(error.message, 'ERROR');                    
                }

                this.validated = false;
            }
        },
        setMaxResults(days){
            this.form.limit = days;
        },
        async refreshTagPairs() {
            this.showLoader = true;

            try 
            {
                await this.loadTagPairs();

                this.showLoader = false;
            }
            catch(error)
            {
                this.showLoader = false;
                this.showToast(error.message, 'ERROR');                    
            }
        }      
    },
    computed: {
        totalEventsInfo(){
            return this.$store.getters.totalEventsInfoResult ?? null;
        },
        loadedCountries() {
            return this.$store.getters.polygons;
        },
        loadedTags() {
            return this.$store.getters.tags;
        }
    },
    async created() {
        try
        {
            if (!this.$store.state.tagPairs)
            {
                await this.refreshTagPairs();
            }

            if (this.$store.state.eventList && this.$store.state.eventList.form && this.$store.state.eventList.form.type === 'past')
            {
                if (this.loadedTags && this.$store.state.eventList.form.tags)
                    this.form.eventTags = this.loadedTags.filter(x => this.$store.state.eventList.form.tags.indexOf(x.id) > -1).map(x => x.name);
                
                this.form.objectId =  this.$store.state.eventList.form.objectId;
                this.form.eventDate =  this.$store.state.eventList.form.date;
                this.form.observer =  this.$store.state.eventList.form.observer;
                this.form.limit = this.$store.state.eventList.form.limit;
                this.tabIndex = this.$store.state.eventList.form.tabIndex;
                
                this.foundEventsList = this.$store.getters.eventSearchResult;
            }            
        }
        catch(error)
        {
            this.showToast(error.message, 'ERROR');                    
        }
    }
}
</script>

<style scoped>
 .btn-group button[selected='selected']
 {
    border-color: #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
 }

.btn-group button:focus {
    border-color: #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
}

.non-tab-top-align {
    padding-top: 14px;
}

.miss {
    background: red;
}

.hit {
    background:lime;
} 
.norep {
    background:gray;
} 
</style>