export default {
    eventList: null,
    currentEvent: {},
    currentPrediction: null,
    totalEventsInfo: null,
    bsViewPortSize: '',
    user: null,
    userProfile: null,
    userSites: [],
    userFilters: [],
    tagList: null,
    selectedTagIds: [],
    selectedTagId: null,
    uploadEventsList: null,
    uploadEventsTotalPages: 0,
    uploadEventsTotalRows: 0,
    uploadEventLog: null,
    predictionSources: null,
    restoreSessionFlag: false,
    selectedMapLocation: null,
    tagPairs: null,
    polygonPairs: null,
    resolvedStar: null,
    communityTags: null,
    campaignTags: null,
    currentEventStations: null,
    navigating: false,
    tagUsage: [],
    serverVersionInfo: null,
    filteredEventList: null,
    filteredEventExecInfo: null,
    userOAuthClient: null,
}