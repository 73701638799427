<template>
    <span>
        <a v-if="isAster" :href="`https://ssd.jpl.nasa.gov/sbdb.cgi?sstr=${ssdSearchId}`" title="Open in JPL Small Body Database" target=_blank>{{name}}</a>
        <span v-if="!isAster">{{name}}</span>      
    </span>
</template>

<script>
export default {
    props: ['isAster', 'name', 'number'],
    computed: {
        ssdSearchId(){
            if (this.$props.number > 0)
                return this.$props.number;
            return this.$props.name;
        }
    }
}
</script>