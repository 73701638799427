<template>
    <div>
        <nav class="nav navbar-expand-sm" id="top-nav">
            <router-link  class="navbar-brand mx-4 pb-1" to="/">OW Cloud</router-link>
            <div class="collapse navbar-collapse pb-1 mx-3" id="mynav">
                <div class="pt-2 yellow-highlight mr-auto" v-if="!showLoader && locatedEvents && !errored"  style="margin-left: 35px">Locate Event</div>
                <ThemeSelector />
                <LoggedInUserActions 
                    :viewPortVisible="false" 
                    :delayedShow="true" 
                    :redirectToCurrent="true" 
                    ref="logginComponent"/>
            </div>             
        </nav>           
        <div class="container-float mx-4 my-4" v-if="!showLoader && locatedEvents && !errored && locatedEvents.length > 1">
            <h5>Multiple events found</h5>
            <table class="table table-dark table-bordered table-hover">
               <thead class="thead-dark text-center">
                    <tr>
                        <td>Closest Approach, UT</td>
                        <td>Asteroid</td>
                        <td>Star</td>
                        <td>Mag</td>
                    </tr>    
                </thead>
                <tbody>
                    <tr v-for="evt in locatedEvents" :key="evt.id" @click="navigateToEvent(evt)" :class="selectedEvent && evt.id === selectedEvent.id ? 'highlight' : ''">
                        <td>{{evt.time}} <TagList v-if="evt.tags" :tags="evt.tags" /></td>
                        <td><router-link :to="{ name: 'Event', params: {eventId: evt.id}}" target='_blank'>{{evt.asteroid}}</router-link></td>
                        <td>{{evt.star}}</td>
                        <td class="text-center">{{evt.mag | formatMag}}</td>
                    </tr>    
                </tbody> 
            </table>                                         
        </div>
        <div class="text-center">
            <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
            <h5 class="text-danger mt-3" v-if="(!locatedEvents || locatedEvents.length == 0) && !showLoader">No event was found for {{form.ast}} on {{form.date}} tagged as {{form.tag}}</h5>
            <h5 class="text-danger mt-3" v-if="errored && !showLoader">Error getting event for {{form.ast}} on {{form.date}} tagged as {{form.tag}}</h5>
        </div>          
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import TagList from '@/components/TagList.vue'

export default {
    data() {
        return {
            showLoader: false,
            errored: false,
            form: {
                ast: 0,
                tag: null,
                date: null,
                occelmntId: null
            },
            locatedEvents: null
        }
    }, 
    components: {
        TagList
    },      
    methods: {
        ...mapActions(['locateEvent']),
        async navigateToEvent(evt) {
            console.log(`Redirecting to ${evt.id}`);
            this.$router.push({ name: 'Event', params: {eventId: evt.id}});
        }
    },
    async mounted() {

        this.showLoader = true;

        try 
        {
            this.form = {
                ast: this.$route.query.ast,
                tag: this.$route.query.tag,
                date: this.$route.query.date,
                occelmntId: this.$route.query.occelmntId,
            }

            var locatedEvents = await this.locateEvent(this.form);

            this.locatedEvents = locatedEvents;
            if ( this.locatedEvents &&  this.locatedEvents.length == 1)
            {
                var evt = this.locatedEvents[0];
                this.navigateToEvent(evt);
            }

            this.showLoader = false;
        }
        catch(error)
        {
            this.showLoader = false;
            this.errored = true;
            this.showToast(error.message, 'ERROR');                    
        }        
    },
    computed: {

    }
}
</script>

<style scoped>
    .loading-image {
        background: transparent center no-repeat;
        height: 768px;
        width: 1024px;
    }
</style>