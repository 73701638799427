import axios from 'axios';
import * as owcMixin from '@/mixins/owcMixin.js';

export default {
    async communityTagEvent(_, form)
    {
        try
        {
            await owcMixin.sleep(1000);

            let postUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/tag-event/community`;
            let data =  { eventId: form.eventId, tagId: form.tagId };

            let result = await axios.post(postUrl, data, { withCredentials: true });

            if (result && result.status === 200)
            {
                return true;    
            }
        }
        catch(error) {
            throw new Error(error);
        }
    },
    async singleTagEvent(_, form)
    {
        try
        {
            await owcMixin.sleep(1000);

            let postUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/tag-event/single-event-tag`;
            let data =  { eventId: form.eventId, tagId: form.tagId };

            let result = await axios.post(postUrl, data, { withCredentials: true });

            if (result && result.status === 200)
            {
                return true;    
            }
        }
        catch(error) {
            throw new Error(error);
        }
    },    
    async reProcessFile(_, fileId)
    {
        try
        {
            let postUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/reupload-file/${fileId}`;

            let result = await axios.post(postUrl, null, { withCredentials: true });

            if (result && result.status === 200)
            {
                return true;    
            }
        }
        catch(error) {
            throw new Error(error);
        }
    },
    async loadUploadEventsInfo ({commit}, form) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/upload-events-paged?pageNo=${form.pageNo}&pageSize=${form.pageSize}`;
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                commit('SET_UPLOAD_EVENTS', result.data);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async loadUploadEventLog ({commit}, fileId) {
        try
        {
            await owcMixin.sleep(250);

            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/upload-events/${fileId}/log`;
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                commit('SET_UPLOAD_EVENT_LOG', result.data);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async loadPredictionSources({commit}){
        try
        {
            let getUrl = `${process.env.VUE_APP_OWC_WEB_API_BASE_URL}/prediction-sources`;
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                commit('SET_PREDICTION_SOURCES', result.data);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },       
}