<template>
    <div>
        <div v-bind:class="themeBackground">  
            <svg xmlns="http://www.w3.org/2000/svg" :viewBox="`${-1 * (panelWidth / 2)} ${-1 * (panelWidth / 2)} ${panelWidth} ${panelWidth}`" v-if="ell">
                <line :x1="-0.95 * (panelWidth / 2)" y1="0" :x2="0.95 * (panelWidth / 2)" y2="0" class="axis" />
                <line x1="0" :y1="-0.95 * (panelWidth / 2)" x2="0" :y2="0.95 * (panelWidth / 2)" class="axis" />            
                
                <ellipse cx="0" cy="0" :rx="ell.rx" :ry="ell.ry" :class="cls" :transform="`rotate(${-1 * ell.pa.toFixed(2)} 0 0)`" />

                <template v-for="masTick in ell.masTicks">
                    <line v-bind:key="`a${masTick}`" :x1="masTick" y1="-2" :x2="masTick" y2="2" class="axis" v-if="masTick < 0.95 * (panelWidth / 2) - 10"/>
                    <line v-bind:key="`d${masTick}`" :y1="masTick" x1="-2" :y2="masTick" x2="2" class="axis" v-if="masTick > -0.95 * (panelWidth / 2) + 10"/>
                </template>
                
                <polygon :points="`-2.5,${-0.95 * (panelWidth / 2) + 7} 0,${-0.95 * (panelWidth / 2)} 2.5,${-0.95 * (panelWidth / 2) + 7}`" class="pointer" />
                <polygon :points="`${0.95 * (panelWidth / 2) - 7},-2.5 ${0.95 * (panelWidth / 2)},0 ${0.95 * (panelWidth / 2) - 7},2.5`" class="pointer" />

                <text x="10" :y="-0.8 * (panelWidth / 2)" fill="white" font-size="large">&delta;</text>
                <text :x="0.8 * (panelWidth / 2)" y="-10" fill="white" font-size="large">&alpha;</text> 

                <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
                <path d="M-1,1 l2,-2
                        M0,4 l4,-4
                        M3,5 l2,-2" 
                        style="stroke:rgb(180,180,180); stroke-width:2" />
                </pattern>

                <template v-if="ell.plotScale">
                    <rect :x="-panelWidth/2 + 5" :y="panelWidth/2 - 15" :width="ell.plotScale.width" height="8" fill="url(#diagonalHatch)"/>
                    <text :x="-panelWidth/2 + 5" :y="panelWidth/2 - 22" fill="white" font-size="small">{{ell.plotScale.mas}} mas</text>
                </template>            
            </svg>
        </div>
        <small>{{this.$props.title}}</small>
        <div>
        <small>({{this.err.maj | formatFixedOrPrecision2}} x {{this.err.min | formatFixedOrPrecision2}}) mas @ {{this.err.pa | formatAngle0t0180}}&deg;</small>
        </div>
    </div>
</template>

<script>
export default {
    props: ['cls', 'err', 'title'],
    data() {
        return {
            panelWidth: 200
        }
    },
    methods: {
        plot(){
         
        }
    },
    computed: {
        ell() {
            let rat = this.err.dispRat ?? 1;
            let coeff = 0.7 / Math.min(1.15, Math.max(0.3, (1 - 0.7 * Math.log10(rat))));
            let scale = this.panelWidth / (coeff * 4 * this.err.maj * 1.05);
            let masTicks = [];
            let plotScale = null;

            var maxRange = coeff * this.err.maj * 0.4;
            var probe = [{d:100, m: 500}, {d:40, m: 200}, {d:20, m: 100}, {d:10, m: 50}, {d:4, m:20}, {d:2, m:10}, {d:1, m:5}, {d:0.4, m:2}, {d:0.2, m:1}, {d:0.1, m:0.5}, {d:0.04, m:0.2}, {d:0.02, m:0.1}, {d:0.01, m:0.05}, {d:0.004, m:0.02}, {d:0.002, m:0.01}];
            for(var pb of probe)
            {
                var numTicks = Math.floor(maxRange / pb.d);
                
                if (numTicks < 4) continue;
                
                masTicks = [];
                for (var i = -1 * numTicks; i <= numTicks; i++)
                {
                    if (i !== 0) masTicks.push(i * pb.m * scale);
                }

                plotScale = {
                    mas: pb.m,
                    width: pb.m * scale
                }

                break;
            }        

            let el = {
                scale,
                ry: this.err.maj * scale,
                rx: this.err.min * scale,
                pa: this.err.pa,
                plotScale,
                masTicks
            }

            return el;
        },
        themeBackground: function() {
            var isLightTheme = localStorage.getItem('theme') === 'light';
            return "ellipse " + (isLightTheme ? "background-light" : "background-dark");
        }        
    },
    async mounted() {
        this.plot();
    }
}
</script>

<style scoped>
  div.ellipse {
        border-style: solid;
        border-width: 1px;
        border-color: gray;
  }

  .background-light {
      background:rgb(58, 53, 53)
  }

  .background-dark {
      background:rgb(41, 38, 38)
  }

  .axis {
      stroke:rgb(128,128,128);
      stroke-width:1;
  }

  .pointer {
     fill:white;
  }

  .occ {
      fill:rgb(97, 81, 81);
      stroke:rgb(184, 184, 184);
      stroke-width:2;
      fill-opacity:0.8
  }

  .star {
      fill:rgb(105, 109, 77);
      stroke:rgb(184, 184, 184);
      stroke-width:2;
      fill-opacity:0.8
  }

  .ast {
      fill:rgb(77, 104, 109);
      stroke:rgb(184, 184, 184);
      stroke-width:2;
      fill-opacity:0.8
  }
</style>