<template>
    <span>
        <span v-if="orbDate">, orbit: {{orbDate | formatDate}}</span>
        <span v-if="orbSlnId && orbSlnId.startsWith('JPL')" class="text-muted">
            (<span style="color:rgb(0, 188, 140)" :title="orbSlnId">{{orbSlnId | formatJPLNo}}</span>)
        </span>
        <span v-if="orbSlnId && !orbSlnId.startsWith('JPL') && orbSlnId.indexOf('/DE') > 0" class="text-muted">
            (<span style="color:rgb(0, 188, 140)" :title="orbSlnId">{{orbSlnId | formatJPLDE}}</span>)
        </span>
    </span>
</template>

<script>
export default {
    props: ['orbDate', 'orbSlnId']
}
</script>

<style scoped>

</style>