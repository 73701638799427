import axios from 'axios';

export default {
    async planningCancelStation (_, form) {
        try
        {
            let postUrl = `${process.env.VUE_APP_PLANNING_WEB_API_BASE_URL}/${form.eventId}/cancel-station?stationId=${form.stationId}`;

            let result = await axios.post(postUrl, null, { withCredentials: true });

            console.log('planningCancelStation', result);
            return { success: true }
        }
        catch(error){
            return { error: error.message ?? error.Error, success: false };
        }
    },
    async planningUpdateStation (_, form) {
        try
        {
            console.log(`planningUpdateStation(${form.eventId}, ${form.stationId})`, form);

            let postUrl = `${process.env.VUE_APP_PLANNING_WEB_API_BASE_URL}/${form.eventId}/update-station?stationId=${form.stationId}`;

            let result = await axios.post(postUrl, form, { withCredentials: true });

            console.log('planningUpdateStation', result);
            return { success: true }
        }
        catch(error){
            return { error: error.message ?? error.Error, success: false };
        }
    },
    async planningAddStation (_, form) {
        try
        {
            console.log(`planningAddStation(${form.eventId}, ${form.stationId})`, form);

            let postUrl = `${process.env.VUE_APP_PLANNING_WEB_API_BASE_URL}/${form.eventId}/add-station`;

            let result = await axios.post(postUrl, form, { withCredentials: true });

            console.log('planningAddStation', result);
            return { success: true }
        }
        catch(error){
            return { error: error.message ?? error.Error, success: false };
        }
    },
    async planningReportObservation (_, form) {
        try
        {
            let postUrl = `${process.env.VUE_APP_PLANNING_WEB_API_BASE_URL}/${form.eventId}/report-obs?stationId=${form.stationId}&report=${form.report}&duration=${form.duration}&comment=${form.comment}`;

            let result = await axios.post(postUrl, null, { withCredentials: true });

            console.log('planningReportObservation', result);

            return { success: true }
        }
        catch(error){
            return { error: error.message ?? error.Error, success: false };
        }
    },      
}