<template>
    <div v-if="stations">
        <div class="pl-1 py-3">
            Stations ({{this.stations.length}})        
        </div>
        <div>
            <table class="table table-dark table-bordered" id="tblStations">
                <thead class="thead-dark text-center">
                    <tr>
                        <td>Chord</td>
                        <td>Observer</td>
                        <td v-if="!hasReportData">Commitment</td>
                        <td v-if="hasCloudData || hasReportData">Note</td>
                    </tr>    
                </thead>
                <tbody>
                    <tr v-for="st in stations" :key="st.id">
                        <td class="text-center">{{st.dist | formatFixed1}} km</td> 
                        <td>{{st.obs}}</td>
                        <td v-if="hasReportData"><canvas width="10" height="10" class="mr-2" :class="st.cls"></canvas>{{(st.repText ? st.repText : "No report")}}</td>       
                        <td v-if="!hasReportData">{{st.cmtmt}}</td>  
                        <td v-if="!hasReportData && hasCloudData"><pre>{{st.weaText | formatTrim}}</pre></td>                                                        
                    </tr>    
                </tbody> 
            </table>                                        
        </div>
    </div>
</template>

<script>
export default {
    name: "StationSort",
    props: ['owstations'], 
    data(){
        return {
            hasStations: false,
            hasCloudData: false,
            hasReportData: false,
            stations: null
        }
    },
    methods: {
        update(argSt) {
            this.stations = argSt;

            this.hasStations = this.stations && this.stations.length > 0;
            this.hasCloudData = this.hasStations && this.stations.filter(x => x.cloud !== null && x.report === null).length > 0;
            this.hasReportData = this.hasStations && this.stations.filter(x => x.report !== null).length > 0;          
        }   
    },
    mounted() {
        this.update(this.$props.owstations);
    }   
}
</script>

<style scoped>
    #tblStations tr td {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    #tblStations tr td pre {
        margin-bottom: 0px;
    }

  .miss {
      background: red;
  }

  .hit {
      background:lime;
  } 

  .cloud0 {
      background:rgb(0, 53, 116);
  } 

  .cloud1 {
      background:rgb(18, 77, 142);
  }   

  .cloud2 {
      background:rgb(40, 101, 169);
  } 

  .cloud3 {
      background:rgb(63, 128, 196);
  }    
  .cloud4 {
      background:rgb(88, 154, 224);
  }   
  .cloud5 {
      background:rgb(118, 186, 186);
  }   
  .cloud6 {
      background:rgb(145, 214, 214);
  } 
  .cloud7 {
      background:rgb(171, 242, 242);
  }    
  .cloud8 {
      background:rgb(198, 198, 198);
  }   
  .cloud9 {
      background:rgb(226, 226, 226);
  }    
  .cloud10 {
      background:rgb(248, 248, 248);
  }   
</style>