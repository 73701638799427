import moment from 'moment'

export default {
    eventSearchResult: (state) => {
        return state.eventList ? state.eventList.data : null;
    },
    filterEventSearchResult: (state) => {
        return state.filteredEventList;
    },
    filterEventSearchExecInfo: (state) => {
        return state.filteredEventExecInfo;
    },
    currentEventResult: (state) => {
        return state.currentEvent;
    },
    totalEventsInfoResult: (state) => {
        return state.totalEventsInfo;
    },
    viewPortSize: (state) => {
        return state.bsViewPortSize;
    },
    currentUser: (state) => {
        return state.user;
    },
    currentUserProfile: (state) => {
        return state.userProfile;
    },    
    currentUserSites: (state) => {
        return state.userSites;
    },
    currentUserFilters: (state) => {
        return state.userFilters;
    },    
    currentUserMainRole: (state) => {
        if (!state.user) return null;
        if (state.user.owcRoles.indexOf('Admin') > -1)
            return 'Administrator';
        else if (state.user.owcRoles.indexOf('FeedMaintainer') > -1)
            return 'Feed Maintainer';  
            
        return 'User';
    },
    isSystemAdmin: (state) => {
        return state.user !== null && state.user.adminFlag == 2;
    },
    isOwcAdmin: (state) => {
        return state.user && state.user.owcRoles.indexOf('Admin') > -1;
    },    
    isOwcAdminOrFeedMaintaner: (state) => {
        return  state.user && (state.user.owcRoles.indexOf('Admin') > -1 || state.user.owcRoles.indexOf('FeedMaintainer') > -1);
    },
    currentUserOAuthClient: (state) => {
        return state.userOAuthClient;
    },
    loadedTags:  (state) => {
        return state.tagList ? state.tagList.data : [];
    },
    selectedTags:  (state) => {
        return state.tagList 
            ? state.tagList.data.filter(x => state.selectedTagIds.indexOf(x.id) > -1) 
            : [];
    },
    selectedTag:  (state) => {
        if (state.selectedTagId === null)
            return null;

        return state.tagList 
            ? state.tagList.data.filter(x => state.selectedTagId == x.id)[0] 
            : null;
    },
    deselectedTags:  (state) => {
        return state.tagList 
            ? state.tagList.data.filter(x => state.selectedTagIds.indexOf(x.id) == -1) 
            : [];
    },
    uploadEventsList: (state) => {
        return state.uploadEventsList ?? [];
    },
    uploadEventsTotalPages: (state) => {
        return state.uploadEventsTotalPages;
    },
    uploadEventsTotalRows: (state) => {
        return state.uploadEventsTotalRows;
    },
    uploadEventLog: (state) => {
        return state.uploadEventLog;
    },
    orbitSources: (state) => {
        if (!state.predictionSources)
            return [];

        return state.predictionSources.orbits ?? [];
    },
    starSources: (state) => {
        if (!state.predictionSources)
            return [];

        return state.predictionSources.stars ?? [];
    },
    currentPrediction: (state) => {
        return state.currentPrediction;
    },
    selectedMapLocation: (state) => {
        return state.selectedMapLocation;
    },
    tags: (state) => {
        return state.tagPairs ?? [];
    },
    polygons: (state) => {
        return state.polygonPairs ?? [];
    },
    communityTags: (state) => {
        return state.tagList?.data?.filter(x => x.community) ?? [];
    },
    adHocCampaignTags: (state) => {
        return state.campaignTags?.filter(x => x.flags == 1) ?? [];
    },
    luckyStarCampaignTags: (state) => {
        return state.campaignTags?.filter(x => x.flags == 2) ?? [];
    },
    tagUsageFutureEvents: (state) => {
        const rightNow = new moment();
        return state.tagUsage?.filter(x => moment(String(x.lastEvent)).isAfter(rightNow)) ?? [];
    },
    serverVersionInfo: (state) => {
        return state.serverVersionInfo;
    }
}