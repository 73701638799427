import * as THREE from './three.js'

export class Occelmnt {
    constructor(data, debugMode)
    {
        this.debugMode = debugMode || process.env.NODE_ENV != 'production';
        this.update(data);
    }

    shadowLine1 = null;
    shadowLine2 = null;
    sigmaLine1 = null;
    sigmaLine2 = null;
    debugMode = false;

    static fundPlaneGeometry = new THREE.PlaneGeometry( 50, 50, 50 );
    static shadowLineMat = new THREE.LineBasicMaterial( { transparent: true, opacity: 0.5, color: 0x8888FF} );
    static sigmaLineMat = new THREE.LineDashedMaterial( { transparent: true, opacity: 0.3, color: 0xFF8888} );
    static EARTH_PLOT_FACTOR = 2.14;       
    static camera = null;
    static light = null;
    static scene = null;
    static renderer = null;

    update(data){
        if (data.subStlrLng)
        {
            this.SubstellarLongRad = -1 * THREE.Math.degToRad(data.subStlrLng);
            this.SubstellarLatRad = -1 * THREE.Math.degToRad(data.subStlrLat);
            this.SubSolarLongRad = -1 * THREE.Math.degToRad(data.subSolrLng);
            this.SubSolarLatRad =  THREE.Math.degToRad(data.subSolrLat);
    
            this.XatMin = data.xMin;
            this.YatMin = data.yMin;
            this.dX = data.dX;
            this.dY = data.dY;
            this.shadowWidthEarthRadii = data.shadowWidth / 6371.0;
            this.errorAsIncreaseInPathWidths = data.shdwIncrPathWidths;
        }
        else
        {
            this.parse540String(data);
        }
    }

    plotShadow() {
        var n = Math.sqrt(this.dX * this.dX + this.dY * this.dY);
        var D = this.XatMin * this.dX + this.YatMin * this.dY;
        var dH = D / n / n;
        //var H2 = H1 - dH;
        // This correction will return a dH=0 for most events. For satellite predictions it will be non-zero and must be applied for the purpose of this plotting
        // as the original (XatMin, YatMin) will be for the main body, not the satellite
        this.XatMin = this.XatMin - dH * this.dX;
        this.YatMin = this.YatMin - dH * this.dY;

        if (this.debugMode)
        {
            console.log(`Besselian (${this.XatMin}, ${this.YatMin}), errorAsIncreaseInPathWidths: ${this.errorAsIncreaseInPathWidths}, dH: ${dH}`);
        }
        
        var halfShadow = this.shadowWidthEarthRadii * Occelmnt.EARTH_PLOT_FACTOR / 2.0;
        var halfSigma = this.errorAsIncreaseInPathWidths * 2 * halfShadow;
        var centerLineTranslation = Math.sign(this.XatMin) * Occelmnt.EARTH_PLOT_FACTOR * Math.sqrt(this.YatMin * this.YatMin + this.XatMin * this.XatMin);
        var pathRotation = Math.PI / 4 + Math.atan(this.YatMin/this.XatMin);

        if (this.debugMode)
        {
            console.log(`halfShadow: ${halfShadow}, halfSigma: ${halfSigma}, centerLineTranslation: ${centerLineTranslation}, pathRotation: ${pathRotation},`);
        }
        
        this.shadowLine1 = new THREE.Line( Occelmnt.fundPlaneGeometry, Occelmnt.shadowLineMat );
        this.shadowLine1.rotation.set(Occelmnt.camera.rotation.x, Occelmnt.camera.rotation.y, Occelmnt.camera.rotation.z);
        this.shadowLine1.translateZ(1.5);
        this.shadowLine1.rotateZ(pathRotation);
        this.shadowLine1.translateX(centerLineTranslation - halfShadow);
        Occelmnt.scene.add( this.shadowLine1 );

        this.shadowLine2 = new THREE.Line( Occelmnt.fundPlaneGeometry, Occelmnt.shadowLineMat );
        this.shadowLine2.rotation.set(Occelmnt.camera.rotation.x, Occelmnt.camera.rotation.y, Occelmnt.camera.rotation.z);
        this.shadowLine2.translateZ(1.5);
        this.shadowLine2.rotateZ(pathRotation);
        this.shadowLine2.translateX(centerLineTranslation + halfShadow);
        Occelmnt.scene.add( this.shadowLine2 );     

        // Show sigma lines if error is bigger than 50% path widths
        if (this.errorAsIncreaseInPathWidths > 1.5)
        {
            this.sigmaLine1 = new THREE.Line( Occelmnt.fundPlaneGeometry, Occelmnt.sigmaLineMat );
            this.sigmaLine1.rotation.set(Occelmnt.camera.rotation.x, Occelmnt.camera.rotation.y, Occelmnt.camera.rotation.z);
            this.sigmaLine1.translateZ(1.5);
            this.sigmaLine1.rotateZ(pathRotation);
            this.sigmaLine1.translateX(centerLineTranslation - halfSigma);
            Occelmnt.scene.add( this.sigmaLine1 );       
    
            this.sigmaLine2 = new THREE.Line( Occelmnt.fundPlaneGeometry, Occelmnt.sigmaLineMat );
            this.sigmaLine2.rotation.set(Occelmnt.camera.rotation.x, Occelmnt.camera.rotation.y, Occelmnt.camera.rotation.z);
            this.sigmaLine2.translateZ(1.5);
            this.sigmaLine2.rotateZ(pathRotation);
            this.sigmaLine2.translateX(centerLineTranslation + halfSigma);
            Occelmnt.scene.add( this.sigmaLine2 );    
        }
    }

    clearPlot(){
        if (this.shadowLine1 != null) {
            Occelmnt.scene.remove(this.shadowLine1);
        }
        if (this.shadowLine2 != null) {
            Occelmnt.scene.remove(this.shadowLine2);
        }
        if (this.sigmaLine1 != null) {
            Occelmnt.scene.remove(this.sigmaLine1);
        }
        if (this.sigmaLine2 != null) {
            Occelmnt.scene.remove(this.sigmaLine2);
        }
    }

    plot() {
        var distance = 5;
        var y = distance * Math.sin(-this.SubstellarLatRad);
        var x = distance * Math.cos(-this.SubstellarLatRad) * Math.cos(this.SubstellarLongRad);
        var z = distance * Math.cos(-this.SubstellarLatRad) * Math.sin(this.SubstellarLongRad);

        Occelmnt.camera.position.set(x, y, z);
        Occelmnt.camera.lookAt(Occelmnt.scene.position);

        if (this.debugMode)
        {
            console.log(`Camera at (${x}, ${y}, ${z}) ${x*x + y*y + z*z}`);
        }
        
        var lightDistance = 50;
        y = lightDistance * Math.sin(this.SubSolarLatRad);
        x = lightDistance * Math.cos(this.SubSolarLatRad) * Math.cos(this.SubSolarLongRad);
        z = lightDistance * Math.cos(this.SubSolarLatRad) * Math.sin(this.SubSolarLongRad);
        Occelmnt.light.position.set(x, y, z);

        if (this.debugMode)
        {
            console.log(`Light at (${x}, ${y}, ${z}) ${x*x + y*y + z*z}`);
        }

        this.plotShadow(Occelmnt.camera);
    }

    parse540String(data)
    {
        var lines = data.split(/\r?\n/);
        if (lines.length < 10)
        {
            console.error(`Occelmnt: Not a 10 lines but ${lines.length}`);
        }
        var totalChars = lines[0].length + lines[1].length + lines[2].length + lines[3].length + lines[4].length + lines[5].length + lines[6].length + lines[7].length + lines[8].length + lines[9].length;
        if (totalChars != 520)
        {
            console.error('Occelmnt: Not a 540 string. ');
        }

        this.ObjectName = lines[1].substr(7, 15).trim();
        this.ObjectNumber = lines[1].substr(0, 7).trim();
        this.StarName = lines[3].substr(33, 20).trim();
        var eventYear = parseInt(lines[0].substr(1, 4));
        var eventMonth = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(lines[0].substr(6, 3)) / 3 + 1;
        var eventDay = parseInt(lines[0].substr(9, 3));     
        this.EventDate = new Date(eventYear + "-" + eventMonth + "-" + eventDay);
        this.SubstellarLongRad = -1 * THREE.Math.degToRad(parseFloat(lines[5].substr(0, 9).trim()));
        this.SubstellarLatRad = -1 * THREE.Math.degToRad(parseFloat(lines[5].substr(9, 8).trim()));
        this.SubSolarLongRad = -1 * THREE.Math.degToRad(parseFloat(lines[5].substr(17, 9).trim()));
        this.SubSolarLatRad =  THREE.Math.degToRad(parseFloat(lines[5].substr(26, 8).trim()));

        this.XatMin = parseFloat(lines[6].substr(8, 9).trim());
        this.YatMin = parseFloat(lines[6].substr(17, 9).trim());
        this.dX = parseFloat(lines[6].substr(26, 9).trim());
        if (this.dX == 0.0) this.dX = 1E-07;
        this.dY = parseFloat(lines[6].substr(35, 9).trim());
        this.shadowWidthEarthRadii = parseFloat(lines[4].substr(4, 7).trim()) / 6371;    
        this.errorAsIncreaseInPathWidths = parseFloat(lines[9].substr(23, 6).trim());     

        if (this.debugMode)
        {
            console.log(`SubSolarLongRad: ${lines[5].substr(17, 9).trim()}, SubSolarLatRad: ${lines[5].substr(26, 8).trim()}`);
        }        
    }

    initPlot(canvas) {
        var CANVAS_WIDTH = canvas.clientWidth,
            CANVAS_HEIGHT = canvas.clientHeight;

        Occelmnt.scene = new THREE.Scene();

        Occelmnt.scene.add(new THREE.AmbientLight(0x4B4B4B));

        Occelmnt.camera = new THREE.OrthographicCamera(-2, 2, 2, -2, 0, 10);
        Occelmnt.light = new THREE.DirectionalLight(0xffffff, 1);
        Occelmnt.scene.add(Occelmnt.light);    

        const geometry = new THREE.SphereBufferGeometry( 1.5, 32, 32 );

        const loader = new THREE.TextureLoader();
        loader.setCrossOrigin('anonymous');

        const material = new THREE.MeshStandardMaterial();

        material.map = loader.load(require('@/assets/images/2_no_clouds_4k.jpg'));
        material.bumpMap = loader.load(require('@/assets/images/elev_bump_4k.jpg'));
        material.bumpScale = 0.05;            
        material.needsUpdate = true;

        const globeMesh = new THREE.Mesh( geometry, material );            

        Occelmnt.renderer = new THREE.WebGLRenderer( { antialias: true } );
        Occelmnt.renderer.setPixelRatio( window.devicePixelRatio );
        Occelmnt.renderer.setSize( CANVAS_WIDTH, CANVAS_HEIGHT );        
        canvas.appendChild( Occelmnt.renderer.domElement );

        globeMesh.position.set( 0, 0, 0 );
        Occelmnt.scene.add( globeMesh );


        function animate() {
            requestAnimationFrame( animate );
            Occelmnt.renderer.render( Occelmnt.scene, Occelmnt.camera );
        }
        animate();        
    }

    resizeCanvas(canvas) {          
        Occelmnt.renderer.setSize( canvas.clientWidth, canvas.clientHeight );
    }

    getEventTitle() {
        return `Occelmnt: (${this.ObjectNumber}) ${this.ObjectName} occults ${this.StarName} on ${this.EventDate.toDateString()}`;
    }
}
