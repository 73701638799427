<template>
    <div>        
        <div class="container">
            <div class="mt-2">               
                <b-tabs content-class="mt-3">
                    <b-tab title="Search by Object" active>                      
                        <SearchByObject />
                    </b-tab>
                    <b-tab title="Search by Star">
                        <SearchByStar />
                    </b-tab>
                    <b-tab title="Search Past Events">
                        <SearchPastEvents />
                    </b-tab>
                </b-tabs>
            </div>                        
        </div>            
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import SearchByObject from '@/components/SearchByObject.vue'
import SearchPastEvents from '@/components/SearchPastEvents.vue'
import SearchByStar from '@/components/SearchByStar.vue'

export default { 
    components: {
        SearchByObject,
        SearchPastEvents,
        SearchByStar
    },
    methods: {
        ...mapActions(['getTotalEventsInfo'])
    },
    async mounted() {
        try
        {
            if (!this.$store.getters.totalEventsInfoResult || !this.$store.getters.totalEventsInfoResult.cached || (Date.now() - this.$store.getters.totalEventsInfoResult.cached) > 1000 * 3600)
            {
                await this.getTotalEventsInfo();
            }
        }
        catch(error)
        {
            this.showToast(error.message, 'ERROR');                    
        }
    }    
}
</script>

<style scoped>
</style>