<template>
    <div class="table-responsive-md" v-if="predictions && predictions.length > 0">
        <table class="table table-dark table-bordered table-hover">
            <thead class="thead-dark text-center">
                <tr>
                    <td>Data Sources</td>
                    <td>Last Updated (UT)</td>
                    <td>Orbit Date</td>
                    <td>Error (<span title="Path Widths">PW<b> *</b></span>)</td>
                    <td>Error (time)</td>
                    <td v-if="predictions.length > 1">Path Diff<b> **</b></td>
                </tr>    
            </thead>
            <tbody>
                <tr style="cursor: pointer;" v-for="pred in predictions" :key="pred.src" @click="openPrediction(pred)">
                    <td class="text-center">
                        <i class="fa pr-2 fa-hourglass" v-if="pred.id === predIdForUpdate && pendingUpdate" title="Prediction update is pending"></i>
                        {{pred.src | formatPredictionSource}}
                        <span class="badge badge-pill badge-secondary mx-1" v-if="pred.default && !pred.defPredUser">default</span>
                        <span class="badge badge-pill badge-info mx-1" v-if="pred.default && pred.defPredUser" :title="`Set as default by ${pred.defPredUser}`">default</span>
                    </td>
                    <td class="text-center">{{pred.updated | formatDayHourMinuteOrDayOnly}} <small><span class="text-muted">(by {{pred.user}})</span></small></td>
                    <td class="text-center">
                        {{pred.orbDate | formatDate}}
                        <OrbitSolutionInfo :orbSlnId="pred.orbSlnId"/>
                    </td>
                    <td class="text-center">{{pred.errpw - 1 | formatFixed2}}</td>       
                    <td class="text-center">{{pred.errtime / 60.0 | formatMinSecDuration}}</td>
                    <td class="text-center" v-if="predictions.length > 1">
                        <span v-if="pred.shiftInfo">{{pred.shiftInfo}}</span>
                        <span v-if="!pred.shift">-</span>
                    </td>           
                </tr>    
            </tbody> 
        </table>
        <div v-for="err in updateErrors" :key='err'>
            <span class="text-danger"><small>{{err}}</small></span>
        </div>          
        <div v-for="wrn in updateWarnings" :key='wrn'>
            <span class="text-warning"><small>{{wrn}}</small></span>
        </div>                 
        <div>
            <span class="text-muted"><small>* <strong class="text-light">PW</strong> = path widths</small></span>
            <span class="text-muted" v-if='predictions.length > 1'>;<span class="text-muted pl-2" ><small><strong class="text-light">**</strong> Across and along path difference between predictions. Across path shift is computed on the Fundamental Plane, where '-' indicates a left and '+' a right path shift. </small></span></span>
            <br/>
        </div>         
    </div>     
</template>

<script>
import OrbitSolutionInfo from '@/components/OrbitSolutionInfo.vue'

export default {
    components: {
        OrbitSolutionInfo
    },
    props: ['predictions', 'eventId', 'pendingUpdate', 'messages'],
    methods: {
        openPrediction(pred) {
            let src = pred.src; 
            if (!src || src === '') src = pred.id;
            let routeData = this.$router.resolve({ name: 'Prediction', params: { eventId: this.eventId, predSrc: src} });
            window.open(routeData.href, '_blank');
        }
    },
    computed: {
        totalEventsInfo() {
            return this.$store.getters.totalEventsInfoResult;
        },
        predIdForUpdate(){
            var guess1 = this.$props.predictions.filter(x => x.default & x.src.startsWith('Horizons'))[0];
            if (guess1) return guess1.id;

            var guess2 = this.$props.predictions.filter(x => x.user == 'OWC' & x.src.startsWith('Horizons'))[0];
            if (guess2) return guess2.id;

            var guess3 = this.$props.predictions.filter(x => x.src.startsWith('Horizons'))[0];
            if (guess3) return guess3.id;

            return this.$props.predictions[0].id;
        },
        updateWarnings(){
            return this.$props.messages.filter(x => x.startsWith('W: ')).map(x => x.substr(2));
        },
        updateErrors(){
            return this.$props.messages.filter(x => x.startsWith('E: ')).map(x => x.substr(2));
        }
    }
}
</script>

<style scoped>

</style>