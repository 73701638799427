import Vue from 'vue'

Vue.filter("formatPredictionSource", function (value) {
    if (value === undefined || value === null)
      return '';
    else
    {
      var extract = /([^;]+(;[^;]+)?)/g.exec(value);
      if (extract == null) return value;
      return extract[0].replace(';', '/');
    }
  });
  
  Vue.filter("formatMag", function (value) {
    if (value === undefined)
      return '';
    else
      return value.toFixed(2);
  });

  Vue.filter('formatMinSecDuration', function(value) {
    if (value < 1) {
      return `${(value * 60).toFixed(1)} sec`
    }
    else
    {
      var min = Math.floor(value);
      var sec = Math.floor((value - min) * 60);
      return `${min}:${(sec + '').padStart(2, '0')} min`
    }
  });
  
  Vue.filter('formatHourMinSec', function(value) {
    if (value) {
      var hr = Math.floor(value);
      var minP = (value - hr) * 60;
      var min = Math.floor(minP);
      var secP = (minP - min) * 60;
      return `${(hr + '').padStart(2, '0')} ${(min+ '').padStart(2, '0')} ${secP.toFixed(4)}`
    }
    return '';
  });
  
  Vue.filter('formatDegMinSec', function(value) {
    if (value) {
      var sgn = value < 0 ? '-' : '+';
      value = Math.abs(value);
      var deg = Math.floor(value);
      var minP = (value - deg) * 60;
      var min = Math.floor(minP);
      var secP = (minP - min) * 60;
      return `${sgn}${(deg + '').padStart(2, '0')} ${(min+ '').padStart(2, '0')} ${secP.toFixed(3)}`
    }
    return '';
  });

  Vue.filter('formatLng', function(value) {
    if (value) {
      var ew = value < 0 ? 'W' : 'E';
      value = Math.abs(value);
      var deg = Math.floor(value);
      var minP = (value - deg) * 60;
      var min = Math.floor(minP);
      var secP = (minP - min) * 60;
      return `${(deg + '').padStart(2, '0')}° ${(min+ '').padStart(2, '0')}' ${secP.toFixed(0)}" ${ew}`
    }
    return '';
  });

  Vue.filter('formatLat', function(value) {
    if (value) {
      var ns = value < 0 ? 'S' : 'N';
      value = Math.abs(value);
      var deg = Math.floor(value);
      var minP = (value - deg) * 60;
      var min = Math.floor(minP);
      var secP = (minP - min) * 60;
      return `${(deg + '').padStart(2, '0')}° ${(min+ '').padStart(2, '0')}' ${secP.toFixed(0)}" ${ns}`
    }
    return '';
  });   

  Vue.filter('formatAzDir', function(value) {
    if (value) {
      let azDir = '';
      if (value <= 0 + 22.5 || value > 360 - 22.5)
          azDir = 'N';
      else if (value <= 45 * 1 + 22.5 && value > 45 * 1 - 22.5)
          azDir = 'NE';
      else if (value <= 45 * 2 + 22.5 && value > 45 * 2 - 22.5)
          azDir = 'E';
      else if (value <= 45 * 3 + 22.5 && value > 45 * 3 - 22.5)
          azDir = 'SE';
      else if (value <= 45 * 4 + 22.5 && value > 45 * 4 - 22.5)
          azDir = 'S';
      else if (value <= 45 * 5 + 22.5 && value > 45 * 5 - 22.5)
          azDir = 'SW';
      else if (value <= 45 * 6 + 22.5 && value > 45 * 6 - 22.5)
          azDir = 'W';
      else if (value <= 45 * 7 + 22.5 && value > 45 * 7 - 22.5)
          azDir = 'NW';
      return azDir
    }
    return '';
  });
  
  Vue.filter('formatJPLNo', function(value) {
    if (value) {
      var match = /(JPL#\w?\d+[^,]*)/g.exec(value);
      if (match == null) return value;
      return match[0];
    }
    return '';
  });

  Vue.filter('formatJPLDE', function(value) {
    if (value) {
      var match = /([^,]+)/g.exec(value);
      if (match == null) return value;
      return match[0];
    }
    return '';
  });  
  
  Vue.filter("formatAngle180", function (value) {
    if (!value && value !== 0)
      return '';
    else
    {
      while (value > 180) value -= 360;
      while (value < -180) value += 360;
      return value.toFixed(0);
    }
  });
  
  Vue.filter("formatAngle0t0180", function (value) {
    if (!value && value !== 0)
      return '';
    else
    {
      while (value > 180) value -= 180;
      while (value < 0) value += 180;
      return value.toFixed(0);
    }
  });

  Vue.filter("formatCentralDistance", function (value) {
    if (!value && value !== 0)
      return '';
    else
    {
      var leftOrRight = 'right';
      if (value < 0) leftOrRight = 'left';
      
      return `${Math.abs(value).toFixed(2)} km (fp) ${leftOrRight}`;
    }
  });
  