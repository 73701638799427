import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function(value) {
    if (value) {
      return moment(String(value)).format('DD MMM YYYY')
    }
  });
  
  Vue.filter('formatDayMonth', function(value) {
    if (value) {
      return moment(String(value)).format('DD MMM')
    }
  });
  
  Vue.filter('formatHourMinute', function(value) {
    if (value) {
      return moment(String(value)).format('HH:mm')
    }
  });
  
  Vue.filter('formatHourMinuteSec', function(value) {
    if (value) {
      return moment(String(value)).format('HH:mm:ss')
    }
  });
  
  Vue.filter('formatDateHourMinute', function(value) {
    if (value) {
      return moment(String(value)).format('DD/MMM/YY, HH:mm')
    }
  });
  
  Vue.filter('formatDayHourMinute', function(value) {
    if (value) {
      return moment(String(value)).format('DD MMM, HH:mm')
    }
  });

  Vue.filter('formatDayHourMinuteOrDayOnly', function(value) {
    if (value) {
      var ddMMMhhmm = moment(String(value)).format('DD MMM, HH:mm');
      return ddMMMhhmm.replace(/, 00:00$/g, '');
    }
  });  
  
  Vue.filter('formatMsToMinuteSecond', function(value) {
    if (value) {
      const secs = value / 1000.0;
      const min = secs / 60;
      const sec = secs % 60
      return `${Math.floor(min)}:${String(Math.floor(sec)).padStart(2, "0")}`
    }
  });