<template>
    <div>
        <div class="container-fluid bg-image"  :style="{ backgroundImage: `url(${bgImageUrl})`}">
            <div class="login_form_wrapper">
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-6 col-md-offset-2">
                            <div class="login_wrapper" :class="showLoader ? 'disabled-control' : ''">
                                <div class="formsix-pos">
                                    <div class="form-group i-email"> 
                                        <input 
                                            v-model="form.loginName" 
                                            v-validate="{required: true}" 
                                            type="email" 
                                            class="form-control" 
                                            id="email" 
                                            placeholder="Email Address *"
                                            name="login"> 
                                    </div>
                                    <div v-if="validated" class="text-danger pb-3">
                                            {{errors.first('login')}}
                                    </div>
                                </div>
                                <div class="formsix-e">
                                    <div class="form-group i-password"> 
                                        <input 
                                            v-model="form.password" 
                                            v-validate="{required: true}" 
                                            type="password" 
                                            class="form-control" 
                                            id="password" 
                                            placeholder="Password *"
                                            name="password">
                                    </div>
                                    <div v-if="validated" class="text-danger pb-3">
                                            {{errors.first('password')}}
                                    </div>                                    
                                </div>
                                <div class="login_remember_box">
                                    <div class="row align-items-center">
                                        <div class="col-6">
                                            <input type="checkbox" v-model="form.rememberme">
                                            <label class="px-2">Remember me</label> 
                                        </div>
                                        <div class="col-6 pull-right">
                                            <a href="/reset-password" class="forget_password"> Forgot Password </a> 
                                        </div>
                                    </div>
                                </div>
                                <div class="login_btn_wrapper"> <a href="#" class="btn btn-primary login_btn" @click="onLogin"> Login </a> </div>
                                <div class="text-center">
                                     <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
                                </div>
                                <div class="login_line_div"></div>
                                <div class="login_message">
                                    <p>Don’t have an account ? <a href="/create-account"> Sign up </a> </p>
                                </div>                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data()
    {
        return {
            form: {
                loginName: '',
                password: '',
                rememberme: false
            },
            redirectTo: null,
            validated: false,
            submitted: false,
            showLoader: false
        }
    },
    methods: {
        ...mapActions(['loginUser', 'ensureCurrentUser']),
        async onLogin(){
            this.validated = true;
            var result = await this.$validator.validate();

            if (result)
            {
                this.showLoader = true;
                try
                {
                    var loginForm = { user: this.form.loginName, pass: this.form.password, save: this.form.rememberme };
                    await this.loginUser(loginForm).catch(e => {
                        console.log(e);
                    });

                    this.showLoader = false;

                    if (this.$store.state.user)
                    {
                        if (this.redirectTo)
                        {
                            console.log(`Redirecting to ${this.redirectTo}`);
                            this.$router.push(this.redirectTo);
                        }
                        else
                        {
                            this.$router.push({ name: 'Home'});
                        }                        
                    }
                    else
                    {
                        this.showToast(`Error logging in as ${this.form.loginName}. Check that your user name and password are correct.`, 'ERROR'); 
                    }
                }
                catch(error)
                {
                    this.showLoader = false;
                    this.showToast(error.message, 'ERROR'); 
                }                
            }            
        }
    },
    mounted() {
        this.form.rememberme = localStorage.getItem('owc-user-remember') === 't';
        if (this.form.rememberme && 
            localStorage.getItem('owc-user-name') && 
            localStorage.getItem('owc-user-name') != 'null' &&
            localStorage.getItem('owc-user-pass') != 'null')
        {
            this.form.loginName = localStorage.getItem('owc-user-name');
            this.form.password = localStorage.getItem('owc-user-pass');              
        }

        this.redirectTo = this.$route.query.redirectTo;
    },
    computed: {
        bgImageUrl(){
            return require('@/assets/images/oono-s.jpg');
        }
    }
}
</script>

<style scoped>
    body, html {
        height: 100%;
    }

    .bg-image {
        /* Full height */
        height: 100%;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }        
    

    .login_form_wrapper {
        float: left;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 100px;
    }

    .login_wrapper {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border: 1px solid rgb(116, 114, 114);
        border-radius: 5px;
        float: left;
        width: 100%;
        background:rgb(66, 64, 64);
        padding: 50px    
    }       

    .login_wrapper a.btn {
        color: #fff;
        width: 100%;
        height: 50px;
        padding: 6px 25px;
        line-height: 36px;
        margin-bottom: 20px;
        text-align: left;
        border-radius: 5px;
        font-size: 16px;
    }

    .login_wrapper a.btn-center {
        color: #fff;
        width: 100%;
        height: 50px;
        padding: 6px 25px;
        line-height: 36px;
        margin-bottom: 20px;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
    }        

    .login_wrapper a i {
        float: right;
        margin: 0;
        line-height: 35px
    }

    .login_wrapper a.occult-watcher {
        background: #040f70;
        border: 1px solid #040f70
    }

    
    .login_wrapper a.occult-watcher:hover {
        background: #0616a8 !important;
        border-color: #0616a8 !important;
    } 

    .login_wrapper h2 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        color: #fff;
        line-height: 20px;
        text-transform: uppercase;
        text-align: center;
        position: relative
    }

    .login_wrapper .formsix-pos,
    .formsix-e {
        position: relative
    }

    .form-group {
        margin-bottom: 15px
    }

    .login_wrapper .form-control {
        height: 53px;
        padding: 15px 20px;
        font-size: 14px;
        line-height: 24px;
        border-radius: 3px;
        box-shadow: none;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
    }

    .login_wrapper .form-control:focus {
        color: black;
        background-color: fafafa;
        border: 1px solid #4285f4 !important
    }

    .login_remember_box {
        margin-top: 30px;
        margin-bottom: 30px;
        color: white
    }

    .login_remember_box .control {
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        margin: 0
    }

    .login_remember_box .control input {
        position: absolute;
        z-index: -1;
        opacity: 0
    }

    .login_remember_box .control__indicator {
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        background: #eee;
        border: 1px solid white
    }

    .login_remember_box .forget_password {
        float: right;
        color: #db4c3e;
        line-height: 12px;
        text-decoration: underline
    }

    .login_line_div {
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: 1px solid #e4e4e4
    }

    .login_btn_wrapper a.login_btn {
        text-align: center;
        text-transform: uppercase
    }

    .login_message p {
        text-align: center;
        font-size: 16px;
        margin: 0
    }

    p {
        color: white;
        font-size: 14px;
        line-height: 24px
    }

    .login_wrapper a.login_btn:hover {
        background-color: #2c6ad4;
        border-color: #2c6ad4
    }

    .login_wrapper a.btn:hover {
        background-color: #2c6ad4;
        border-color: #2c6ad4
    }


    .login_wrapper a.google-plus {
        background: #bd4033;
        border: 1px solid #bd4033
    }        

    .login_wrapper a.google-plus:hover {
        background: #db4c3e;
        border-color: #db4c3e
    }   
</style>