<template>
    <div class="pull-right">
        <ul class="navbar-nav pull-right pr-3">
            <li v-if="!isLightTheme" class="px-2 mt-1" style="color: #333333; background: #cccccc; cursor: pointer; font-size: 0.8em; border: 1px solid #969696;" @click="onToggleTheme">Light Theme</li>
            <li v-if="isLightTheme" class="px-2 mt-1" style="color: #DDDDDD; background: #333333; cursor: pointer; font-size: 0.8em; border: 1px solid #565656;" @click="onToggleTheme">Dark Theme</li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLightTheme: true
        }
    },
    methods:{
        onToggleTheme() {
            if (localStorage.getItem('theme') === 'light')
                localStorage.setItem('theme', 'dark');
            else
                localStorage.setItem('theme', 'light');

            this.$router.go();
        }
    },
    mounted() {
        this.isLightTheme = localStorage.getItem('theme') === 'light';
    }
}
</script>

<style scoped>

</style>