import moment from 'moment'

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function hasPassedUtc(timeVal) {
    return moment.utc(String(timeVal)).isBefore(moment().utc());
}

export function degToStr(deg) {
    var retVal = "";
    if (deg < 0)
    {
    retVal = "-";
    deg = -deg;
    }
    retVal = retVal + Math.floor(deg) + "° ";
    deg = (deg - Math.floor(deg)) * 60;
    retVal = retVal + Math.floor(deg) + "' ";
    deg = (deg - Math.floor(deg)) * 60;
    retVal = retVal + Math.floor(deg) + '"';

    return retVal;
} 

const debug = process.env.NODE_ENV != 'production';

const REPORT_NOT_REPORTED = 0;
const REPORT_MISS = 1;
const REPORT_CLOUDED = 2;
const REPORT_FAILED = 3;
const REPORT_POSITIVE = 4;
const REPORT_NOT_OBSERVED = 5;
const REPORT_NOT_REDUCED = 6;  

export default {
    methods: {
        showToast(message, title, delay){

            if (title === 'ERROR' && message && ((message + '').endsWith('status code 401') || (message.response && message.response.status === 401)))
            {
                // 401 should have been handled already
                // by restoring session or redirecting to login page
                return;
            }

            if (debug && title === 'ERROR')
            {
                console.trace(message);
            }

            if (!delay)
            {
                delay = title === 'SUCCESS' ? 1500 : 5000;
            }

            this.$bvToast.toast(message, {
                title: title,
                variant: title === 'SUCCESS' ? 'success' : 'danger',
                solid: true,
                autoHideDelay: delay,
                toaster: 'b-toaster-top-right'
            });
        },
        addOwStationClasses(stations){
            if (stations)
            {
                const hasReportData = stations.filter(x => x.report !== null).length > 0
                for(var st of stations)
                {
                    st.cls = this.getStationClass(st, hasReportData);
                }
            }                
        },
        getStationClass(st, hasReportData){
            if (hasReportData)
            {
                if (st.report)
                {
                    switch (st.report)
                    {
                        case REPORT_MISS:
                            return "miss";
                        case REPORT_POSITIVE:
                            return "hit";    
                        case REPORT_CLOUDED:                    
                        case REPORT_FAILED:
                        case REPORT_NOT_OBSERVED:
                            return "noobs";
                        case REPORT_NOT_REPORTED:
                        case REPORT_NOT_REDUCED:
                            return "norep";
                    }
                }
            }
            else if (!hasReportData &&st.cloud)
            {
                var cloud0to9 = Math.round(st.cloud / 10);
                switch(cloud0to9)
                {
                    case 0:
                        return "cloud0";
                    case 1:
                        return "cloud1";
                    case 2:
                        return "cloud2";
                    case 3:
                        return "cloud3";
                    case 4:
                        return "cloud4";
                    case 5:
                        return "cloud5";
                    case 6:
                        return "cloud6";
                    case 7:
                        return "cloud7";
                    case 8:
                        return "cloud8";
                    case 9:
                        return "cloud9";
                    default:
                        return "cloud10";                                                                                                                                                                                                                        
                }
            }

            return "site";
        }          
    }
}