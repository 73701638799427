<template>
    <span style="height: 14px; width: 22px;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 14"  width="22" height="14">
            <rect class="shadow" x="0" y="0" width="22" height="14" v-if="pos == 0"/>
            <rect class="sigma" x="0" y="0" width="22" height="14" v-if="pos == 1"/>
            <rect class="outside" x="0" y="0" width="22" height="14" v-if="pos == 2"/>
        </svg>
    </span>
</template>

<script>
export default {
    props: ['pos']
}
</script>

<style scoped>
  .shadow {
      fill:rgb(72, 209, 204);
      stroke: #C0C0C0;
      stroke-width: 2;
  }
  .sigma {
      fill:rgb(240, 128, 128);
      stroke: #C0C0C0;
      stroke-width: 2;
  }
  .outside {
      fill:rgb(255, 255, 255);
      stroke: #C0C0C0;
      stroke-width: 2;
  }    
</style>