<template>
    <div>
        <nav class="nav navbar-expand-sm" id="top-nav">
            <router-link  class="navbar-brand mx-4 pb-1" to="/">OW Cloud</router-link>
            <div class="collapse navbar-collapse pb-1 mx-3" id="mynav">
                <div class="pt-2 yellow-highlight mr-auto" v-if="!showLoader && foundEvent && !errored"  style="margin-left: 35px">{{foundEvent.asteroid}} occults {{foundEvent.star}} on {{foundEvent.date}} at {{foundEvent.time}}</div>
                <ThemeSelector />
                <LoggedInUserActions 
                    :viewPortVisible="false" 
                    :delayedShow="true" 
                    :redirectToCurrent="true" 
                    ref="logginComponent"/>
            </div>             
        </nav>           
        <div class="container-float mx-4" v-if="!showLoader && foundEvent && !errored">           
            <div class="row pt-2 mt-3 justify-content-center">
                <div class="col-lg-2 col-md-6">
                    <div>Closest Approach</div>
                </div>
                <div class="col-lg-1 col-md-6">
                    <div>Rank</div>
                </div>                
                <div class="col-lg-2 col-md-4">
                    <div>{{foundEvent.objClass}}</div>
                </div>
                <div class="col-lg-1 col-md-4">&nbsp;</div>                
                <div class="col-lg-3 col-md-6">
                    <div>Star</div>
                </div>    
                <div class="col-lg-2 col-md-6" v-if="magDropAffectedByNearbyStars">
                     <div class="row">
                        <div class="col-6 text-right">
                            Star Mag
                        </div>
                        <div class="col-3">
                            Comb.
                        </div>
                        <div class="col-3">
                            Drop
                        </div>            
                    </div>  
                </div>            
                <div class="col-lg-2 col-md-6" v-if="!magDropAffectedByNearbyStars">
                     <div class="row">
                        <div class="col-4">
                            Mag
                        </div>
                        <div class="col-4">
                            Comb.
                        </div>
                        <div class="col-4">
                            Drop
                        </div>            
                    </div>  
                </div>                    
                <div class="col-lg-1 col-md-6">
                    <div>Max Dur</div>
                </div>                                                                                               
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <hr style="height:1px; width:100%; border-width:0; color: rgb(65, 65, 65); background-color: rgb(65, 65, 65)">
                </div>    
            </div>  
            <div class="row justify-content-center pb-2">
                <div class="col-lg-2 col-md-6">
                    {{foundEvent.date}}, {{foundEvent.time}}
                </div>
                <div class="col-lg-1 col-md-6">
                    <a href="http://www.asteroidoccultation.com/asteroid_help.htm#Rank" title="See definition of Rank" target=_blank>{{foundEvent.rank}}</a>
                </div>                
                <div class="col-lg-2 col-md-4">
                    <ObjectLink :isAster="foundEvent.isAster" :number="this.foundEvent.astNo" :name="foundEvent.asteroid" />
                    <span class="pl-2 text-muted">({{foundEvent.asterMag | formatFixed1}} <sup>m</sup>)</span>
                </div>
                <div class="col-lg-1 col-md-2">
                    <a v-if="foundEvent.encodedImage" 
                        :href="`/aladinViewer.html?starId=${foundEvent.aladinTarget}&amp;fov=0.02&amp;catalogue=1&amp;ra=${foundEvent.starRa}&amp;de=${foundEvent.starDec}`" 
                        target="_blank" 
                        title='AladinLite image with GaiaEDR3 overlay and marked 4" and 8" apertures'>
                        <img :src="`data:image/png;base64,${foundEvent.encodedImage}`" class="border border-success pull-right"/>
                    </a> 
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="row">
                        <div class="col-12" v-if="foundEvent.star || foundEvent.gaia">
                            <StarLink :starName="foundEvent.star" :gaiaStar="foundEvent.gaia"/>
                            <span class="pl-2 text-muted">({{foundEvent.starMag | formatMag}} <sup>m</sup>)</span>
                            <small class="text-muted align-top" v-if="ruwe"> [RUWE: <strong class="text-light"><a href="https://gea.esac.esa.int/archive/documentation/GDR2/Gaia_archive/chap_datamodel/sec_dm_main_tables/ssec_dm_ruwe.html" title="See definition of RUWE" target=_blank>{{ruwe | formatFixed2}}</a></strong>]</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span class="text-muted">Star Chart: </span>
                            <a :href="`/aladinViewer.html?starId=${foundEvent.aladinTarget}&amp;fov=15`" target="_blank" class="pl-3">15&deg;</a>
                            <a :href="`/aladinViewer.html?starId=${foundEvent.aladinTarget}&amp;fov=5`" target="_blank" class="pl-3">5&deg;</a>
                            <a :href="`/aladinViewer.html?starId=${foundEvent.aladinTarget}&amp;fov=2`" target="_blank" class="pl-3">2&deg;</a>
                            <a :href="`/aladinViewer.html?starId=${foundEvent.aladinTarget}&amp;fov=0.5`" target="_blank" class="pl-3">0.5&deg;</a>    
                            <a :href="`/aladinViewer.html?starId=${foundEvent.aladinTarget}&amp;fov=0.1`" target="_blank" class="pl-3">0.1&deg;</a>  
                            <span class="aladin" title="Star charts by AladinLite">&nbsp;</span>
                        </div>
                    </div>
                    <div class="row" v-if="foundEvent.nearby">
                        <div class="col-12 pt-2">
                            <NearbyStarSummary :nearby="foundEvent.nearby"/>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6" v-if="magDropAffectedByNearbyStars">
                    <div class="row">
                        <div class="col-3 text-center"><span class="text-muted"><small title="Target star only">*</small></span></div>  
                        <div class="col-3 border-bottom border-secondary">
                            <small>{{foundEvent.nearby.star | formatFixed1}} <sup>m</sup></small>
                        </div>
                        <div class="col-3 border-bottom border-secondary">
                            <small>{{foundEvent.nearby.comb | formatFixed1}} <sup>m</sup></small>
                        </div>
                        <div class="col-3 border-bottom border-secondary">
                            <small>{{foundEvent.nearby.drop | formatFixed1}} <sup>m</sup></small>
                        </div>            
                    </div>                     
                    <div class="row">
                        <div class="col-3 text-center"><small class="text-muted" title='Measuring aperture of 4" radius'>4"</small></div>  
                         <div class="col-3 border-bottom border-secondary">
                            <small>{{foundEvent.nearby.star4 | formatFixed1}} <sup>m</sup></small>
                        </div>
                        <div class="col-3 border-bottom border-secondary">
                            <small>{{foundEvent.nearby.comb4 | formatFixed1}} <sup>m</sup></small>
                        </div>
                        <div class="col-3 border-bottom border-secondary">
                            <small>{{foundEvent.nearby.drop4 | formatFixed1}} <sup>m</sup></small>
                        </div>                
                    </div>
                    <div class="row">
                        <div class="col-3 text-center"><small class="text-muted" title='Measuring aperture of 8" radius'>8"</small></div>  
                        <div class="col-3">
                            <small>{{foundEvent.nearby.star8 | formatFixed1}} <sup>m</sup></small>
                        </div>
                        <div class="col-3">
                            <small>{{foundEvent.nearby.comb8 | formatFixed1}} <sup>m</sup></small>
                        </div>
                        <div class="col-3">
                            <small>{{foundEvent.nearby.drop8 | formatFixed1}} <sup>m</sup></small>
                        </div>  
                    </div>                    
                </div>
                <div class="col-lg-2 col-md-6" v-if="!magDropAffectedByNearbyStars">
                    <div class="row">
                        <div class="col-4">
                            {{foundEvent.starMag | formatFixed1}} <sup>m</sup>
                        </div>
                        <div class="col-4">
                            {{foundEvent.mag | formatFixed1}} <sup>m</sup>
                        </div>
                        <div class="col-4">
                            {{foundEvent.magDrop | formatFixed1}} <sup>m</sup>
                        </div>            
                    </div>                     
                </div>                
                <div class="col-lg-1 col-md-6">
                    {{foundEvent.maxDur}} <small>sec</small>
                </div>                                                                                            
            </div> 
            <div class="row pb-4 justify-content-center">   
                <div class="col-lg-12">
                    <hr style="height:1px; width:100%; border-width:0; color: rgb(165, 165, 165); background-color: rgb(165, 165, 165)">
                </div>      
                 <div class="col-lg-7">
                    <div class="row"> 
                         <div class="col-12">
                            <div class="pl-1 pb-3">
                                Predictions
                                <span class="mx-2" v-if="this.currentUser && foundEvent.updReqAllowed && !updateRequestSubmitted && !showSubmitLoader">
                                    <b-dropdown variant="secondary" size="sm">
                                        <template #button-content>
                                            <i class="fa fa-bars"></i>
                                        </template>
                                        <b-dropdown-item-button @click="requestUpdate">
                                            Request Prediction Update
                                        </b-dropdown-item-button>
                                        <b-dropdown-divider v-if="isOwcAdmin"></b-dropdown-divider>
                                        <b-dropdown-group id="dropdown-group-1" header="Change Default" v-if="isOwcAdmin"> 
                                            <b-dropdown-item-button v-for="pred in foundEvent.predictions.filter(x => !x.default)" @click="requestChangeDefault(pred)" :key="pred.id">
                                                {{pred.src | formatPredictionSource}} <small class="text-muted pl-1" :key="pred.id">(by {{pred.user}})</small>
                                            </b-dropdown-item-button>
                                        </b-dropdown-group>
                                    </b-dropdown>
                                </span>
                            </div>                        
                            <div>
                                <PredictionsSummary :predictions="foundEvent.predictions" :eventId="eventId" :pendingUpdate="foundEvent.updPending" :messages="foundEvent.updMessages"/>  
                            </div>
                            <div>
                                <TagAgreements :tags="foundEvent.tags"/>
                            </div>                             
                         </div>
                     </div>                                            
                     <div class="row pt-4"> 
                         <div class="col-6">
                            <div class="pl-1 pb-3">
                                Countries in 1-&sigma; Zone ({{foundEvent.geo !== undefined ? foundEvent.geo.length : ''}})
                            </div>                        
                            <div>
                                <span v-for="ctryName in foundEvent.geo" :key="ctryName" class="badge badge-secondary mx-1">{{ctryName}}</span>
                            </div>
                         </div>
                         <div class="col-6">
                            <div class="pl-1 pb-3">
                                Tags ({{foundEvent.tags !== undefined ? foundEvent.tags.length : ''}})
                            </div>                        
                            <div>
                                <TagList v-if="foundEvent.tags" :tags="foundEvent.tags" />
                            </div>
                            <div class="pt-4">
                                <b-spinner v-if="showSubmitLoader" class="justify-content-center" varient="secondary" style="width: 2rem; height: 2rem;" ></b-spinner>

                                <CommunityTagEvent :eventId="foundEvent.id"/>
                            </div>
                         </div>                                                                     
                     </div>                                   
                 </div>
                 <div class="col-lg-5">
                     <div class="row">
                        <div class="col-12">
                            <a href="#" @click="openDefaultPrediction()">
                                <EarthPlot :occel="occel"/>
                            </a>
                        </div>
                        <div class="col-12 pt-2 text-center">
                            <span class="text-muted">Shadow crossing the Earth for <strong class="text-light">{{2 * foundEvent.shadowDur | formatMinSecDuration}}</strong> from <strong class="text-light">{{foundEvent.from}}</strong> to <strong class="text-light">{{foundEvent.to}}</strong></span>
                        </div>                             
                     </div>    
                     <div class="row">
                        <div class="col-12 pt-2">
                            <OccultWatcherPlot :occel="foundEvent.data" :owstations="stations"/> 
                        </div>
                     </div>    
                     <div class="row" v-if="stations">
                        <div class="col-12">
                            <StationSort :owstations="stations"/>
                        </div>
                    </div>                     
                </div> 
                <div class="col-lg-12">
                    <hr style="height:1px; width:100%; border-width:0; color: rgb(65, 65, 65); background-color: rgb(65, 65, 65)">
                    <span class="text-muted"><small>If you want to see more information on this page, you can simply request it!</small></span>
                </div>                               
            </div>                     
            <div class="row pb-4 justify-content-center" >           
                 <div class="col-lg-12 text-center">
                     <button class="btn btn-primary" v-if="!isOccultMapLoaded && !isOccultMapLoading" @click="showOccultMap">Show Occult Map</button>
                     <div v-if="isOccultMapLoading" class="loading-image" :style="{ backgroundImage: `url(${imageSpinnerUrl})`}"></div>
                     <a v-show="isOccultMapLoaded && !occultMapErrored" :href="occulMapImgUrl" target=_blank>
                         <img class="mx-auto" style="filter: brightness(70%)" v-show="isOccultMapLoaded && !occultMapErrored" :src="occulMapImgUrl" width="99%" height="99%"><img>
                     </a>
                     <div class="text-center">
                         <h6 class="text-danger my-4" v-if="occultMapErrored">Error loading Occult Map</h6>
                     </div>
                 </div>
            </div>                                 
        </div>
        <div class="text-center">
            <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
            <h5 class="text-danger mt-3" v-if="!foundEvent && !showLoader">No event found</h5>
            <h5 class="text-danger mt-3" v-if="errored && !showLoader">Error getting event: {{this.eventId}}</h5>
        </div>          
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EarthPlot from '@/components/EarthPlot.vue'
import PredictionsSummary from '@/components/PredictionsSummary.vue'
import TagList from '@/components/TagList.vue'
import TagAgreements from '@/components/TagAgreements.vue'
import CommunityTagEvent from '@/components/CommunityTagEvent.vue'
import ObjectLink from '@/components/ObjectLink.vue'
import StarLink from '@/components/StarLink.vue'
import LoggedInUserActions from '@/components/LoggedInUserActions.vue'
import OccultWatcherPlot from '@/components/OccultWatcherPlot.vue'
import StationSort from '@/components/StationSort.vue'
import ThemeSelector from '@/components/ThemeSelector.vue'
import NearbyStarSummary from '@/components/NearbyStarSummary.vue'

export default {
    data() {
        return {
            showLoader: false,
            eventId: null,
            occulMapImgUrl: '',
            isOccultMapLoading: false,
            isOccultMapLoaded: false,
            errored: false,
            occultMapErrored: false,
            occel: null,
            updateRequestSubmitted: false,
            showSubmitLoader: false,
            stations: null
        }
    },    
    components: {
        EarthPlot,
        PredictionsSummary,
        TagList,
        TagAgreements,
        CommunityTagEvent,
        ObjectLink,
        StarLink,
        LoggedInUserActions,
        OccultWatcherPlot,
        StationSort,
        ThemeSelector,
        NearbyStarSummary
    },         
    methods: {
        ...mapActions(['searchEventById', 'requestEventPredictionUpdate', 'requestChangeDefaultPrediction', 'loginUserWithOwSessionId']),
        showOccultMap() {
                this.isOccultMapLoaded = false;
                this.isOccultMapLoading = true;

                let occultMap = new Image();
                occultMap.src = `${process.env.VUE_APP_OCCULT_MAP_URL}?id=${this.eventId}`;
                occultMap.onload = () => {
                    this.occulMapImgUrl = occultMap.src;
                    this.isOccultMapLoaded = true;
                    this.isOccultMapLoading = false;                       
                };
                occultMap.onerror = () => {
                    this.isOccultMapLoaded = true;
                    this.isOccultMapLoading = false;                    
                    this.occultMapErrored = true;
                };
        },
        openDefaultPrediction(){
            if (this.foundEvent && this.foundEvent.predictions)
            {
                var defPred = this.foundEvent.predictions.find(x => x.default === true);

                if (defPred && defPred.id)
                {
                    let routeData = this.$router.resolve({ name: 'Prediction', params: { eventId: this.foundEvent.id, predSrc: defPred.id} });
                    window.open(routeData.href, '_blank');  
                }
              
            }         
        },      
        async requestUpdate()
        {
            try 
            {
                this.showSubmitLoader = true;

                let succcess = await this.requestEventPredictionUpdate(this.eventId);

                if (succcess === true)
                {
                    this.foundEvent.updPending = true;
                    this.showToast("Prediction update requested. If new orbit is available it could take 10 or more minutes before the prediction is updated.", 'SUCCESS', 10000);
                }

                this.updateRequestSubmitted = true;
                this.showSubmitLoader = false;

            }
            catch(error)
            {
                this.showSubmitLoader = false;
                this.updateRequestSubmitted = true;
                this.showToast(error.message, 'ERROR');                    
            }            
        },
        async requestChangeDefault(prd)
        {
            try 
            {
                this.showSubmitLoader = true;

                let succcess = await this.requestChangeDefaultPrediction({eventId: this.eventId, predId: prd.id});

                this.updateRequestSubmitted = true;
                this.showSubmitLoader = false;

                if (succcess === true)
                {
                    // Refresh page
                    this.$router.go(0);
                }                
            }
            catch(error)
            {
                this.showSubmitLoader = false;
                this.updateRequestSubmitted = true;
                this.showToast(error.message, 'ERROR');                    
            }  
        }
    },
    async mounted() {
        this.eventId = this.$route.params.eventId;

        if (this.eventId !== undefined)
        {
            this.showLoader = true;

            try 
            {
                await this.searchEventById(this.eventId);

                this.occel = this.$store.getters.currentEventResult.data;
                this.stations = this.$store.getters.currentEventResult.stations;

                this.addOwStationClasses(this.stations);

                this.showLoader = false;
                this.$refs.logginComponent.readyToShow = true;
            }
            catch(error)
            {
                this.showLoader = false;
                this.errored = true;
                this.showToast(error.message, 'ERROR');                    
            }

            if (this.foundEvent && 
                this.foundEvent.predictions && 
                this.$route.query.action === "editStations")
            {
                var data = {
                    eventId: this.eventId,
                    predictionId: this.$route.query.predictionId, 
                    lat: this.$route.query.lat, 
                    lng: this.$route.query.lng
                };
                localStorage.setItem("editStationsData", JSON.stringify(data));

                if (this.$route.query.owcSessionId)
                {
                    await this.loginUserWithOwSessionId(this.$route.query.owcSessionId);
                }

                this.$router.push({ name: 'EditStations' });
            }
        }
    },
    computed: {
        ruwe(){
            if (this.foundEvent && this.foundEvent.predictions)
            {
                var ruweL = this.foundEvent.predictions.filter(x => x.ruwe !== null && x.ruwe > 0);
                if (ruweL.length > 0) 
                    return Math.max(...ruweL.map(x => x.ruwe));
            }

            return null;   
        },
        foundEvent(){            
            return this.$store.getters.currentEventResult;
        },
        hasStations() {
            var stations = this.$store.getters.currentEventResult?.stations;
            return stations && stations.length > 0;
        },
        hasReportData(){
            return this.hasStations && this.foundEvent.stations.filter(x => x.report !== null).length > 0;
        },
        imageSpinnerUrl(){
            return require('@/assets/images/imageLoadSpinner.gif');
        },
        currentUser(){
            return this.$store.getters.currentUser;
        },
        isOwcAdmin(){
            return this.$store.getters.isOwcAdmin;
        },        
        magDropAffectedByNearbyStars(){
            return this.foundEvent.nearby 
                && this.foundEvent.nearby.magAffected 
                && (this.foundEvent.nearby.drop - this.foundEvent.nearby.drop8 >= 0.2) 
                && (this.foundEvent.nearby.comb8 + this.foundEvent.nearby.drop8 < 16.0);
        }
    }
}
</script>

<style scoped>
    .loading-image {
        background: transparent center no-repeat;
        height: 768px;
        width: 1024px;
    }

    .aladin {
        background-image: url(https://vizier.u-strasbg.fr/vizier/CDS/aladin_40x35.png);
        background-position: left center;
        background-color: white;
        background-repeat: no-repeat;
        padding-left: 40px;
        margin-left: 10px;
    } 
</style>