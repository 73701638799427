<template>
    <div>
        <span class="badge mx-1 selected-tag remove-badge badge-owc" 
            v-for="tag in tags" 
            :key="tag.id">{{tag.name}}<a class="pl-2" href="#" @click="toggleTag(tag)"><i class="fa fa-window-close"></i></a>
        </span>
        <input type="hidden" v-validate="{required: true}" v-model="tags" data-vv-name="selectedTags" />
        <span v-if="this.$props.validated" class="text-danger">
           {{errors.first('selectedTags')}}
        </span>
    </div>    
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: ['validated'], 
    methods: {
        ...mapActions(['toggleSelectedTag']),
        toggleTag(tag) {
            this.toggleSelectedTag(tag.id);
        }
    },
    created () {
      this.$validator = this.$parent.$validator
    },
    computed: {
        tags(){
            return this.$store.getters.selectedTags;
        },
    }
}
</script>

<style scoped>
.selected-tag {
    border: 1px solid #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
}
.remove-badge a {
    color: #577492;
}

.remove-badge a:hover {
    color: #d899ac;
}
</style>