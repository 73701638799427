<template>   
    <div>        
        <div class="container">
            <div class="mt-2">               
                <b-tabs content-class="mt-3" v-model="profileTab" v-on:activate-tab="activeTabChanged">
                    <b-tab title="User Profile" active>
                        <div class="row pt-4"  style="display: flex;">
                            <div class="col-3"></div>
                            <div class="col-6">
                                <div class="row border" v-if="currentUserProfile" :class="showSubmitLoader ? 'disabled-control' : ''">
                                    <div class="col-11 text-white text-center mx-auto pb-2 pt-2 font-weight-bold" style="font-family: 'Arial Narrow Bold', sans-serif">{{currentUserMainRole | formatUpperCase }}</div>
                                    <div class="col-12 text-center mt-2" >
                                        <img src="@/assets/images/user.png" width="120px" alt="User">
                                    </div>
                                    <div class="col-12 text-center pt-2 pb-2 font-weight-bold" style="font-family: Verdana, Geneva, Tahoma, sans-serif">{{currentUserProfile.name}}</div>

                                    <div class="col-12 text-center pt-4 pb-2 text-muted" style="font-family: Arial, Helvetica, sans-serif">USER PROFILE</div>
                                    <div class="col-12 mb-3">
                                        <ul class="list-group">                         
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                Account Display Name
                                                <input class="form-control" type="text" v-model="form.name"/>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                Observer Name
                                                <input class="form-control" type="text" v-model="form.observer"/>
                                            </li>                            
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                Country
                                                <input class="form-control" type="text" v-model="form.country"/>
                                            </li>
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                City
                                                <input class="form-control" type="text" v-model="form.city"/>
                                            </li>                            
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                Affiliation
                                            <input class="form-control" type="text" v-model="form.affiliation"/>
                                            </li>                           
                                        </ul>
                                    </div>
                                    <div class="col-12">
                                        <small class="text-muted"><strong class="text-light">NOTE: </strong> These are shared with OccultWatcher</small>
                                    </div>                    
                                    <div class="col-12 mb-3 mt-3">
                                        <b-spinner v-if="showSubmitLoader" class="justify-content-center" varient="secondary" style="width: 2rem; height: 2rem;" ></b-spinner>

                                        <button 
                                            type="button" 
                                            @click="onUpdateProfile" 
                                            :disabled="showLoader"
                                            class="btn btn-primary pull-right">Update Profile</button>
                                    </div>     
                                </div> 
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="User Permissions">
                        <div class="row pt-4"  style="display: flex;">
                            <div class="col-3"></div>
                            <div class="col-6">
                                <div class="row border mt-4" v-if="currentUserProfile">  
                                    <div class="col-12 text-center pt-4 pb-2 text-muted" style="font-family: Arial, Helvetica, sans-serif">CREDENIALS</div>
                                    <div class="col-12 mb-3">
                                        <ul class="list-group">
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                Login
                                                <span>{{currentUserProfile.login}}</span>
                                            </li>    
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                OWC Role
                                                <span v-show="currentUserProfile.owcRoles.length > 0">{{currentUserProfile.owcRoles | formatCommaList}}</span>
                                                <span v-show="currentUserProfile.owcRoles.length === 0">User</span>
                                            </li>                            
                                            <li v-if="currentUserProfile.adminFlag > 0" class="list-group-item d-flex justify-content-between align-items-center">
                                                Occult Watcher Role
                                            <span v-show="currentUserProfile.adminFlag === 2">Admin</span>
                                            <span v-show="currentUserProfile.adminFlag === 1">Coordinator</span>
                                            </li> 
                                        </ul>
                                    </div>                      
                                </div>   
                                <div class="row border mt-4" v-if="currentUserOAuthClient">  
                                    <div class="col-12 text-center pt-4 pb-2 text-muted" style="font-family: Arial, Helvetica, sans-serif">OAUTH CLIENT</div>
                                    <div class="col-12 mb-3">
                                        <ul class="list-group">
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                Client Name
                                                <span>{{currentUserOAuthClient.Name}}</span>
                                            </li>    
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                Client Id
                                                <span>{{currentUserOAuthClient.ClientId}}</span>
                                            </li>                              
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                Client Secret
                                                <span>{{currentUserOAuthClient.ClientSecret}}</span>
                                            </li>                              
                                        </ul>
                                    </div>                      
                                </div>                                                                   
                            </div>
                        </div>
                    </b-tab>                    
                    <b-tab title="Saved Sites">
                        <div class="row pt-4"  style="display: flex;">
                            <div class="col-12 justify-content-center">
                                <div class="table-responsive-md">
                                    <table class="table table-dark table-bordered table-hover">
                                        <thead class="thead-dark text-center">
                                            <tr>
                                                <td>Site Name</td>
                                                <td>Longitude</td>
                                                <td>Latitude</td>
                                                <td>Elevation, MSL</td>
                                            </tr>    
                                        </thead>
                                        <tbody>
                                            <tr v-for="site in this.currentUserSites" :key="site.Name" @click="selectSite(site)" :class="selectedSite && site.Name === selectedSite.Name ? 'highlight' : ''">
                                                <td>{{site.Name}}</td>
                                                <td class="text-center">{{site.Lng | formatLng}}</td>
                                                <td class="text-center">{{site.Lat | formatLat}}</td>
                                                <td class="text-center">{{site.Alt}} m</td>
                                            </tr>    
                                        </tbody> 
                                    </table>
                                </div>
                                <b-button v-if="selectedSite" @click="editSite()" title="Edit"><i class="fa fa-edit pr-2"></i>Edit '{{selectedSite.Name}}'</b-button>
                                <b-button v-if="selectedSite" @click="deleteSite()" title="Edit" class="ml-2"><i class="fa fa-trash pr-2"></i>Delete '{{selectedSite.Name}}'</b-button>
                                <b-button @click="addSite()" title="Edit" class="ml-2"><i class="fa fa-plus-square pr-2"></i>Add New Site</b-button>
                            </div> 
                        </div>
                    </b-tab>
                </b-tabs>
            </div>                        
        </div>            
        <div class="text-center">
            <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
        </div>
        <b-modal 
            v-model="modalShow"
            v-if="siteForm"
            :title="this.addSiteMode ? 'Add Site': 'Update Site'" 
            @show="showModal"
            @hidden="resetModal"
            @ok="handleOk">
                <div class="col-12">
                    <div class="row pt-2"  style="display: flex;">
                        <div class="col-lg-12">
                            <div class="bs-component">
                                <form>
                                    <fieldset>                           
                                        <div class="form-row">
                                            <div class="form-group col-md-12 pt-2">
                                                <label for="siteName" class="">Site Name</label>
                                                <input 
                                                    class="form-control" 
                                                    :class="(this.addSiteMode === true && this.isSubmitting === false) ? '' : 'disabled-control'" 
                                                    id="siteNameInput" 
                                                    name="Site Name" 
                                                    v-model="siteForm.name"
                                                    v-validate="{required: true}"
                                                    autofocus>
                                                <div v-if="validated" class="text-danger">
                                                    {{errors.first('Site Name')}}
                                                </div> 
                                            </div>                                                 
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label for="longitude" class="">Longitude</label>
                                                <input 
                                                    class="form-control" 
                                                    :class="this.isSubmitting ? 'disabled-control' : ''" 
                                                    id="longitude" 
                                                    name="Longitude" 
                                                    v-model="siteForm.lng"
                                                    v-validate="{required: true, decimal: true, min_value:-180, max_value:180}">
                                                <div v-if="validated" class="text-danger">
                                                    {{errors.first('Longitude')}}
                                                </div>                                                     
                                            </div>                                         
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label for="latitude" class="">Latitude</label>
                                                <input 
                                                    class="form-control" 
                                                    :class="this.isSubmitting ? 'disabled-control' : ''" 
                                                    id="latitude" 
                                                    name="Latitude" 
                                                    v-model="siteForm.lat"
                                                    v-validate="{required: true, decimal: true, min_value:-90, max_value:90}">
                                                <div v-if="validated" class="text-danger">
                                                    {{errors.first('Latitude')}}
                                                </div>  
                                            </div>                                         
                                        </div>                                          
                                        <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <label for="altitude" class="">Elevation, MSL (m)</label>
                                                <input 
                                                    class="form-control" 
                                                    :class="this.isSubmitting ? 'disabled-control' : ''" 
                                                    id="tagUrl" 
                                                    name="Elevation" 
                                                    v-model="siteForm.alt"
                                                    v-validate="{required: true, decimal: true, min_value:-500, max_value:9000}">
                                                <div v-if="validated" class="text-danger">
                                                    {{errors.first('Elevation')}}
                                                </div>
                                                <b-button @click="setAutoAltitude()" title="Get the elevation for this location" class="mt-2"><i class="fa fa-map pr-2"></i>Find & set elevation</b-button>
                                                <b-spinner v-if="showAltitudeLoader" class="justify-content-center pt-2 mt-2 ml-3" varient="secondary" style="width: 1rem; height: 1rem;" ></b-spinner>
                                            </div>                                         
                                        </div>                                   
                                    </fieldset>
                                </form>
                            </div>                        
                        </div>
                    </div> 
                </div>
                <template #modal-footer>
                    <button v-b-modal.modal-close_visit class="btn btn-primary btn-sm m-1" @click="handleCancel()">Close</button>
                    <button v-b-modal.modal-close_visit class="btn btn-primary btn-sm m-1" @click="handleOk()"><b-spinner v-if="isSubmitting" class="justify-content-center mr-2" varient="secondary" style="width: 1rem; height: 1rem;"></b-spinner>Submit</button>
                </template>                
        </b-modal>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>      
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmDialogue from '../components/ConfirmDialogue.vue'

export default {
    data() {
        return {
            form: {
               name: null,
               observer: null,
               country: null,
               city: null,
               affiliation: null
            },
            siteForm: {
               name: null,
               alt: null ,
               lng: null,
               lat: null
            },
            profileTab: 0,
            showLoader: false,
            showAltitudeLoader: false,
            showSubmitLoader: false,
            selectedSite: null,
            modalShow: false,
            addSiteMode: false,
            validated: false,
            isSubmitting: false
        }
    },
    components: {
        ConfirmDialogue
    },
    methods: {
        ...mapActions(['loadUserProfile', 'clearUserProfile', 'saveUserProfile', 'loadUserSites', 'getOpenElevation', 'updateSavedSite', 'deleteSavedSite', 'addSavedSite', 'loadUserOAuthClient']),
        async onUpdateProfile(){
            
            this.showSubmitLoader = true;

            try 
            {
                await this.saveUserProfile({
                    name: this.form.name,
                    observer: this.form.observer,
                    country: this.form.country,
                    city: this.form.city,
                    affiliation: this.form.affiliation
                });

                this.mapCurrentProfile();

                this.showSubmitLoader = false;
            }
            catch(error)
            {
                this.showSubmitLoader = false;
                this.showToast(error.message, 'ERROR');                    
            }
        },
        mapCurrentProfile() {
            var currProfile = this.currentUserProfile;
            if (currProfile)
            {
                this.form.name = currProfile.name;
                this.form.observer = currProfile.observer;
                this.form.country = currProfile.country;
                this.form.city = currProfile.city;
                this.form.affiliation = currProfile.affiliation;
            }
        },
        async activeTabChanged(newTabIndex){
            localStorage.setItem('owc-selected-profile-tab-index', newTabIndex);
        },        
        async selectSite(site) {
            this.selectedSite = site;
        },
        async deleteSite() {
            if (this.selectedSite)
            {
                const ok = await this.$refs.confirmDialogue.show({
                    title: 'Delete Site',
                    message: `Are you sure you want to delete '${this.selectedSite.Name}'?`,
                    okButton: 'Delete'
                });
                if (ok)
                {
                    this.isSubmitting = true;
                    try
                    { 
                        await this.deleteSavedSite({ Name: this.selectedSite.Name })

                        this.showToast('Site deleted successfully.', 'SUCCESS');

                        setTimeout(() => {
                                // Refresh page
                                this.$router.go(0);
                                }, 2000);                        
                    }
                    catch(error)
                    {
                        this.showToast(error.message, 'ERROR');
                    }
                    finally
                    {
                        this.isSubmitting = false;
                    }
                    this.modalShow = false;
                }
            }
        },
        async addSite() {
            this.resetModal();            
            this.addSiteMode = true;
            this.modalShow = true;
        },
        async editSite() {
            if (this.selectedSite)
            {
                this.siteForm.name = this.selectedSite.Name;
                this.siteForm.lat = this.selectedSite.Lat;
                this.siteForm.lng = this.selectedSite.Lng;
                this.siteForm.alt = this.selectedSite.Alt;
                this.addSiteMode = false;
                this.modalShow = true;
            }
        },
        resetModal() {
            this.siteForm.name = null;
            this.siteForm.lat = null;
            this.siteForm.lng = null;
            this.siteForm.alt = null;
        },   
        async showModal() {
            if (this.addSiteMode) {
                // TODO: focus Site Name input
            }
        },
        async handleCancel() {
            this.modalShow = false;
            this.resetModal();
        },
        async handleOk() {
            this.validated = true;
            var valid = await this.$validator.validate();
            if (valid)
            {   
                if (this.addSiteMode)
                {
                    let existing = this.currentUserSites.find(s => s.Name.toUpperCase() === this.siteForm.name.toUpperCase());
                    if (existing)
                    {
                        this.showToast(`Site called ${existing.Name} already exists.`, 'ERROR');
                    }
                    else
                    {
                        this.isSubmitting = true;
                        try
                        { 
                            await this.addSavedSite({ Name: this.siteForm.name, Lat: this.siteForm.lat, Lng: this.siteForm.lng, Alt: this.siteForm.alt })

                            this.showToast('Site added successfully.', 'SUCCESS');

                            setTimeout(() => {
                                // Refresh page
                                this.$router.go(0);
                                }, 2000);                              
                        }
                        catch(error)
                        {
                            this.showToast(error.message, 'ERROR');
                        }
                        finally
                        {
                            this.isSubmitting = false;
                        }
                        this.modalShow = false;
                    }                    
                }
                else
                {
                    this.isSubmitting = true;
                    try
                    { 
                        await this.updateSavedSite({ Name: this.siteForm.name, Lat: this.siteForm.lat, Lng: this.siteForm.lng, Alt: this.siteForm.alt })

                        this.showToast('Site updated successfully.', 'SUCCESS');

                        this.selectSite.Lng = this.siteForm.lng;
                        this.selectSite.Lat = this.siteForm.lat;
                        this.selectSite.Alt = this.siteForm.alt;

                        setTimeout(() => {
                                // Refresh page
                                this.$router.go(0);
                                }, 2000);                               
                    }
                    catch(error)
                    {
                        this.showToast(error.message, 'ERROR');
                    }
                    finally
                    {
                        this.isSubmitting = false;
                    }                    
                    this.modalShow = false;
                }
            }        
        },
        async setAutoAltitude() {
            if (this.siteForm)
            {
                this.showAltitudeLoader = true;
                try
                {
                    let elevationMeters = await this.getOpenElevation({lat: this.siteForm.lat, lng: this.siteForm.lng});
                    console.log(`Elevation for (${this.siteForm.lat}, ${this.siteForm.lng}): ${elevationMeters}`);
                    this.siteForm.alt = elevationMeters;
                }
                finally
                {
                    this.showAltitudeLoader = false;
                }
            }
        }
    },
    async mounted() {
        this.showLoader = true;

        try 
        {
            await this.clearUserProfile();

            let initialTabIndex = 0;
            let savedTabIndex = localStorage.getItem('owc-selected-profile-tab-index');
            if (savedTabIndex && Number.isInteger(savedTabIndex)) 
                initialTabIndex = Number(savedTabIndex);
            if (this.$route.hash == '#saved-sites')
                initialTabIndex = 2;
            this.profileTab =initialTabIndex;

            await this.loadUserProfile();
            await this.loadUserSites();

            this.mapCurrentProfile();

            if (this.currentUserSites && this.currentUserSites.length > 0)
            {
                this.selectSite(this.currentUserSites[0]);
            }

            if (this.isOwcAdmin) 
            {
                await this.loadUserOAuthClient();
            }

            this.showLoader = false;
        }
        catch(error)
        {
            this.showLoader = false;
            this.showToast(error.message, 'ERROR');                    
        }
    },
    computed: {
        currentUserProfile() {
            return this.$store.getters.currentUserProfile;
        },
        currentUserMainRole() {
            return this.$store.getters.currentUserMainRole;
        },
        currentUserSites() {
            return this.$store.getters.currentUserSites;
        },
        currentUserOAuthClient() {
            return this.$store.getters.currentUserOAuthClient;
        },
        isOwcAdmin(){
            return this.$store.getters.isOwcAdmin;
        }
    }
}
</script>

<style scoped>
li input.form-control {
    width: 50%
}
</style>