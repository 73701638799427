<template>
    <span>
        <a v-if="gaiaStar && gaiaStar.ra16 && gaiaStar.de16" :href="`https://vizier.u-strasbg.fr/viz-bin/VizieR-5?-ref=VIZOWC&-out.add=.&-source=I/350/gaiaedr3&-c=${gaiaStar.ra16} ${gaiaStar.de16},eq=ICRS,rs=2&-out.orig=o`" :title="`Open Gaia Source in VizierR`" target=_blank>{{starName}}<span v-if="!starName">{{gaiaStar.id}}</span></a>
        <a v-if="!gaiaStar && !(starName.startsWith('G'))" :href="`http://vizier.u-strasbg.fr/viz-bin/VizieR-S?${starName}`" title="Open in VizierR" target=_blank>{{starName}}</a>
        <span v-if="!gaiaStar && starName.startsWith('G')">{{starName}}</span>
    </span>
</template>

<script>
export default {
    props: ['starName', 'gaiaStar']
}
</script>