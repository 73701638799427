<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 pb-3 pt-4 mx-2" >
              <UserDashboardTile />
              <TagUsageStats />
            </div>           
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import UserDashboardTile from '@/components/UserDashboardTile.vue'
import TagUsageStats from '@/components/TagUsageStats.vue'

export default {
  name: 'Home',
  components: {
    UserDashboardTile,
    TagUsageStats
  }
}
</script>
