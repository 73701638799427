import axios from 'axios';
import * as owcMixin from '@/mixins/owcMixin.js';

export default {
    async loginUser ({commit}, userAndPass) {
        try
        {
            await owcMixin.sleep(1000);

            let getUrl = `${process.env.VUE_APP_OW_AUTH_BASE_URL}/login`;
            let result = await axios.get(getUrl, 
                { 
                    auth: {
                        username: userAndPass.user,
                        password: userAndPass.pass
                    },
                    withCredentials: true 
                });

            if (result && result.status === 200)
            {
                console.log(`Logged in successfully: ${userAndPass.user}, remember me: ${userAndPass.save}, session duration: ${result.data.sessionDurMin} min`, result.data);
                
                localStorage.setItem('owc-user-remember', userAndPass.save ? 't' : 'f');
                if (userAndPass.save && 
                    (localStorage.getItem('owc-user-name') !== userAndPass.user ||
                    localStorage.getItem('owc-user-pass') !== userAndPass.pass))
                {
                    localStorage.setItem('owc-user-name', userAndPass.user);
                    localStorage.setItem('owc-user-pass', userAndPass.pass);
                    console.log('Remembered owc-user credentials');
                }
                else if (!userAndPass.save && localStorage.getItem('owc-user-name'))
                {
                    localStorage.setItem('owc-user-name', null);
                    localStorage.setItem('owc-user-pass', null);
                    console.log('Cleared owc-user credentials');
                }

                commit('SET_LOGGED_IN_USER', result.data);
                commit('SET_NEW_USER_SESSION', result.data);
            }
            else
            {
                commit('SET_LOGGED_IN_USER', null);
                commit('SET_NEW_USER_SESSION', null);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async loginUserWithOwSessionId ({commit}, owSessionId) {
        try
        {
            await owcMixin.sleep(1000);

            let getUrl = `${process.env.VUE_APP_OW_AUTH_BASE_URL}/login`;
            let result = await axios.get(getUrl, 
                { 
                    headers: {
                        "OW-Session": owSessionId
                    },
                    withCredentials: true 
                });

            if (result && result.status === 200)
            {
                console.log(`Logged in successfully: ${result.data.login}, session duration: ${result.data.sessionDurMin} min`, result.data);

                commit('SET_LOGGED_IN_USER', result.data);
                commit('SET_NEW_USER_SESSION', result.data);
            }
            else
            {
                commit('SET_LOGGED_IN_USER', null);
                commit('SET_NEW_USER_SESSION', null);
            }
        }
        catch(error){
            throw new Error(error);
        }        
    },
    async logoutUser ({commit}) {
        commit('SET_LOGGED_IN_USER', null);
        commit('SET_NEW_USER_SESSION', null);
    },   
    async ensureCurrentUser({commit}) {
        let getUrl = `${process.env.VUE_APP_OW_AUTH_BASE_URL}/user`;
        let result = await axios.get(getUrl, { withCredentials: true, gettingUserInfo: true });
        
        if (result && result.status === 200)
        {
            commit('SET_LOGGED_IN_USER', result.data);
            commit('SET_NEW_USER_SESSION', result.data);
        }
    },       
    async loadUserProfile ({commit}) {
        try
        {
            let getUrl = `${process.env.VUE_APP_OW_AUTH_BASE_URL}/user-profile`;
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                commit('SET_USER_PROFILE', result.data);
            }
        }
        catch(error){
            throw new Error(error);
        }
    },
    async saveUserProfile ({commit}, profile) {
        try
        {
            await owcMixin.sleep(250);

            let getUrl = `${process.env.VUE_APP_OW_AUTH_BASE_URL}/user-profile`;
            let result = await axios.post(getUrl, profile, { withCredentials: true });

            if (result && result.status === 200)
            {
                commit('SET_USER_PROFILE', result.data);
            }            
        }
        catch(error){
            throw new Error(error);
        }
    },
    async loadUserOAuthClient ({commit}) {
        try
        {
            let getUrl = `${process.env.VUE_APP_PROFILE_BASE_URL}/owned-oauth-client`;
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                commit('SET_USER_OAUTH_CLIENT', result.data);                
            }
        }
        catch(error){
            throw new Error(error);
        }
    },    
    async loadUserSites ({commit}) {
        try
        {
            let getUrl = `${process.env.VUE_APP_PROFILE_BASE_URL}/saved-sites`;
            let result = await axios.get(getUrl, { withCredentials: true });

            if (result && result.status === 200)
            {
                commit('SET_USER_SITES', result.data);                
            }
        }
        catch(error){
            throw new Error(error);
        }
    },    
    async deleteSavedSite (_, site) {
        try
        {
            await owcMixin.sleep(250);

            let postUrl = `${process.env.VUE_APP_PROFILE_BASE_URL}/saved-site/delete`;
            await axios.post(postUrl, site, { withCredentials: true });          
        }
        catch(error){
            throw new Error(error);
        }
    },
    async updateSavedSite (_, site) {
        try
        {
            await owcMixin.sleep(250);

            let postUrl = `${process.env.VUE_APP_PROFILE_BASE_URL}/saved-site`;
            await axios.post(postUrl, site, { withCredentials: true });          
        }
        catch(error){
            throw new Error(error);
        }
    },
    async addSavedSite (_, site) {
        try
        {
            await owcMixin.sleep(250);

            let putUrl = `${process.env.VUE_APP_PROFILE_BASE_URL}/saved-site`;
            await axios.put(putUrl, site, { withCredentials: true });          
        }
        catch(error){
            throw new Error(error);
        }
    },    
    async createUser (_, user) {
        try
        {
            await owcMixin.sleep(250);

            let getUrl = `${process.env.VUE_APP_OW_AUTH_BASE_URL}/create-user`;
            await axios.post(getUrl, user, { withCredentials: false });          
        }
        catch(error){
            throw new Error(error);
        }
    },
    async resetPassword(_, request){
        try
        {
            await owcMixin.sleep(250);

            let postUrl = `${process.env.VUE_APP_OW_AUTH_BASE_URL}/reset-password`;
            await axios.post(postUrl, request, { withCredentials: false });          
        }
        catch(error){
            throw new Error(error);
        }
    },
    async clearUserProfile ({commit}) {
        commit('SET_USER_PROFILE', null);
    },
    async setRestoreSessionFlag ({commit}, flag) {
        commit('SET_RESTORE_SESSION_FLAG', flag);
    },  
}