<template>
    <div v-if="closestStarBrightEnoughtToMention || magDropAffectedByMoreThanHalfMag">
        <span class="text-muted" v-if="nearby.nbMags.length == 1">
            <i v-if="this.nearby.magAffected" class="fa fa-warning pr-2" :class="magDropAffectedByMoreThanHalfMag ? 'warn-strong' : 'warn-soft'" :title="magDropAffectedHint"></i><small><strong :class="this.nearby.magAffected ? 'text-light' : ''">Nearby star ({{nearby.nbMags[0] | formatFixed1}}<sup>m</sup>) at {{nearby.nbDist | formatFixed1}}"</strong></small>
        </span>
        <span class="text-muted" v-if="nearby.nbMags.length > 1 && !magsTruncated">
            <i v-if="this.nearby.magAffected" class="fa fa-warning pr-2" :class="magDropAffectedByMoreThanHalfMag ? 'warn-strong' : 'warn-soft'" :title="magDropAffectedHint"></i><small><strong :class="this.nearby.magAffected ? 'text-light' : ''">{{nearby.nbMags.length}} nearby stars (<template v-for="(nbMg, index) in displayMags"><span v-bind:key="index">{{nbMg | formatFixed1}}<sup>m</sup><span v-if="index != displayMags.length -1">,&nbsp;</span></span></template>) at {{nearby.nbDist | formatFixed1}}"+</strong></small>
        </span>
        <span class="text-muted" v-if="nearby.nbMags.length > 1 && magsTruncated">
            <i v-if="this.nearby.magAffected" class="fa fa-warning pr-2" :class="magDropAffectedByMoreThanHalfMag ? 'warn-strong' : 'warn-soft'" :title="magDropAffectedHint"></i><small><strong :class="this.nearby.magAffected ? 'text-light' : ''">Nearby stars (<template v-for="(nbMg, index) in displayMags"><span v-bind:key="index">{{nbMg | formatFixed1}}<sup>m</sup><span v-if="index != displayMags.length -1">,&nbsp;</span></span></template><span v-if="magsTruncated">&nbsp;+</span>) at {{displayDistance | formatFixed1}}"+</strong></small>
        </span>            
    </div>
</template>

<script>
export default {
    props: ['nearby'],
    computed: {
        displayMags(){
            if (this.nearby.nbMags < 4)
                return this.nearby.nbMags;
            else
            {
                let copyMags = this.nearby.nbMags.map(x => x);
                copyMags.sort((x, y) => x - y);
                return copyMags.slice(0, 3);
            }
        },
        magsTruncated(){
            return this.displayMags.length < this.nearby.nbMags.length;
        },
        displayDistance(){
            return this.magsTruncated ? this.nearby.nbBrDist : this.nearby.nbDist;
        },
        magDropAffectedByMoreThanHalfMag(){
            return this.nearby.drop - this.nearby.drop8 >= 0.5;
        },
        magDropAffectedHint(){
            return this.nearby.magAffected ? `Mag drop affected by ${(this.nearby.drop - this.nearby.drop8).toFixed(1)} mags` : '';
        },
        closestStarBrightEnoughtToMention(){
            return this.magsTruncated 
                ? this.displayMags[0] < this.nearby.star + 1.5 
                : this.nearby.nbMags[0] < this.nearby.star + 1.5;
        }
    }
}
</script>