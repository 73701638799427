<template>
    <div class="pull-right">
        <ul class="navbar-nav pull-right" v-if="this.canShow">
            <li v-if="viewPortVisible" class="pt-2 pr-3" style="color: #444411">[{{viewPortSize}}]</li>                    
            <li v-if="!currentUser" class="nav-item">
                <a href="#" class="nav-link" @click="logIn">Login</a>
            </li>                      
            <li v-if="currentUser && currentUser.id" class="nav-item dropdown">
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                            <i class="fa fa-user pr-1"></i>Welcome, {{currentUser.name}} <i class="fa fa-angle-down pr-1"></i>
                    </template>
                    <b-dropdown-item href="#" @click="userProfile"><i class="fa fa-cog pr-1"></i> User Profile</b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item href="#" @click="logOut"><i class="fa fa-power-off pr-1"></i> Logout</b-dropdown-item>
                </b-dropdown>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: ['viewPortVisible', 'delayedShow', 'redirectToCurrent'],
    data(){
        return {
            readyToShow: false
        }
    },
    methods: {
        ...mapActions(['logoutUser']),
        logIn(){
            if (this.redirectToCurrent)
                this.$router.push(`/login?redirectTo=${encodeURIComponent(this.$router.currentRoute.path)}`);
            else
                this.$router.push(`/login`);
        },
        logOut(){
            this.logoutUser();
            if (!this.redirectToCurrent)
                this.$router.push({ name: 'Login' });
        },
        userProfile(){
            this.$router.push({ name: 'UserProfile' });
        }
    },
    computed: {
        viewPortSize(){
            return this.$store.getters.viewPortSize;
        },
        currentUser(){
            return this.$store.getters.currentUser;
        },
        canShow(){
            return !this.$props.delayedShow || this.readyToShow;
        }
    }
}
</script>