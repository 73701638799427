<template>
    <div>
        <div class="row pt-2 mt-3 " v-bind:class="{ 'grayout': showLoader }">
            <div class="col-xl-2 col-lg-3 col-md-3">
                <label for="asteroidNumber" class="">Object Id</label>
                <span class="text-muted" style="cursor: pointer;"
                    @click="showObjectIdHint = !showObjectIdHint">
                    <small>
                        <strong class="text-light"> (&nbsp;</strong>
                            <i class="fa pr-1 fa-question-circle"></i>
                            <span :style="showObjectIdHint ? 'color: #cccccc;' : ''" class="pr-1">Help</span>
                            <i class="fa pr-1 fa-chevron-up" v-if="showObjectIdHint"></i>
                            <i class="fa pr-1 fa-chevron-down" v-if="!showObjectIdHint"></i>
                        <strong class="text-light">)</strong>
                    </small>
                </span>
                <b-popover 
                    variant="secondary"
                    target="objectId" 
                    triggers=""
                    placement="left"
                    :show.sync="showObjectIdHint">
                    <template #title>Supported Object Id Formats <a @click="showObjectIdHint = false" class="pl-2"><i class="fa pr-1 fa-close"></i></a></template>
                    <strong>Asteroid Number</strong>: 17237 <br/>
                    <strong>Unnumbered Asteroid</strong>: 2015 BZ518 <br/>
                    <strong>Periodic Comet</strong>: 252P<br/>
                    <strong>Provisional Comet</strong>: C/2017 Y2<br/>
                    <strong>Interstellar Object</strong>:2I<br/>
                    <strong>Planetary Moon</strong>: P6M14<br/>
                </b-popover>
                <input 
                    v-validate="{required: true, regex: /^(\d+(P|I)?|P\dM\d+|((P|C|A)\/)?\d{4}\s[\w\d]+)$/i }" 
                    v-model="form.objectId" 
                    class="form-control mb-3" 
                    :disabled="showLoader"
                    id="objectId" 
                    name="objectId">
                <div v-if="validated" class="text-danger">
                        {{errors.first('objectId')}}
                </div>                     
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3">
                <label for="eventDate" class="">Event Date</label>
                <b-input-group class="mb-3">
                    <input 
                        class="form-control" 
                        v-model="form.eventDateFormatted" type="text"
                        :disabled="showLoader"
                        autocomplete="off" id="eventDate" name="eventDate"/>
                    <b-input-group-append>
                        <b-form-datepicker 
                            v-model="form.eventDate" 
                            v-validate="{required: true}" 
                            data-vv-name="eventDatePicker"
                            button-only right locale="en-AU" 
                            aria-controls="example-input"
                            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                            @context="onDatePick">
                        </b-form-datepicker>
                    </b-input-group-append>
                </b-input-group>  
                <div v-if="validated" class="text-danger">
                        {{errors.first('eventDatePicker')}}
                </div>                                       
            </div>   
            <div class="col-xl-2 col-lg-3 col-md-4">
                <label for="bufferDays" class="">Buffer (days)</label>
                <b-button-toolbar key-nav>
                    <b-button-group>
                        <b-button style="margin-right: 1px" ref="d0" @click="setBuffer(0)" :selected="this.form.bufferDays === 0">0</b-button>
                        <b-button style="margin-right: 1px" ref="d1" @click="setBuffer(1)" :selected="this.form.bufferDays === 1">&#177;1</b-button>
                        <b-button style="margin-right: 1px" ref="d2" @click="setBuffer(3)" :selected="this.form.bufferDays === 3">&#177;3</b-button>
                        <b-button ref="d7" @click="setBuffer(7)" :selected="this.form.bufferDays === 7">&#177;7</b-button>
                    </b-button-group>
                </b-button-toolbar>
            </div>                                
            <div class="col-xl-2 col-lg-2 col-md-2">
                <label for="searchEvents" class="">&nbsp;</label>
                <button 
                    type="button" 
                    @click="onSearchEvents" 
                    :disabled="showLoader"
                    class="btn btn-primary align-self-end btn-block mt-2">Search</button>    
            </div>        
            <div class="col-xl-2 col-lg-3 col-md-3">
                <label for="taggedOnly" class=""></label>
                <div class="non-tab-top-align">                    
                    <input type="checkbox" v-model="form.taggedOnly">
                    <label for="taggedOnly" class="mx-2">Tagged Only</label>
                </div>
            </div>                                               
        </div>
        <div class="row mb-4">
            <div class="col-12">
                <b-spinner v-if="!totalEventsInfo" class="justify-content-center" type="grow" varient="secondary" style="width: 1rem; height: 1rem;" ></b-spinner>                    
                <span class="text-muted" v-if="totalEventsInfo"><small>Searching <strong class="text-light">{{totalEventsInfo.totTE | numberWithGroupSeparators}}</strong> events from <strong class="text-light">{{totalEventsInfo.fromDate | formatDayMonth}}</strong> to <strong class="text-light">{{totalEventsInfo.toDate | formatDayMonth}}</strong>, last updated on <strong class="text-light">{{totalEventsInfo.updated | formatDate}}</strong> at <strong class="text-light">{{totalEventsInfo.updated | formatHourMinute}} UT</strong></small></span>
            </div>    
        </div>           
        <div class="row pt-2 mt-3">
            <div class="col-12 justify-content-center">
                <div class="table-responsive-md" v-if="(submitted || foundEventsList) && !showLoader">
                    <table class="table table-dark table-bordered table-hover">
                        <thead class="thead-dark text-center">
                            <tr>
                                <td>Closest Approach, UT</td>
                                <td>{{this.objectClass}}</td>
                                <td>Star</td>
                                <td>Mag</td>
                                <td>Mag Drop</td>
                                <td>Max Dur.</td>
                                <td>Rank</td>
                            </tr>    
                        </thead>
                        <tbody>
                            <tr style="cursor: pointer;" v-for="evt in foundEventsList" :key="evt.id" @click="openEvent(evt)">
                                <td>{{evt.time}} <TagList v-if="evt.tags" :tags="evt.tags" /></td>
                                <td>{{evt.asteroid}}</td>
                                <td>{{evt.star}}</td>
                                <td class="text-center">{{evt.mag | formatMag}}</td>
                                <td class="text-center">{{evt.magDrop | formatMag}}</td>
                                <td class="text-center">{{evt.maxDur | formatMag}}</td>
                                <td class="text-center">{{evt.rank}}</td>
                            </tr>    
                        </tbody> 
                    </table>
                    <div class="text-muted pt-2" v-if="submitted && foundEventsList"><small>Found <strong class="text-light">{{foundEventsList.length}}</strong> event{{foundEventsList.length > 1 ? 's' : ''}} in <strong class="text-light">{{msElapsed / 1000}}</strong> sec</small></div>
                </div>                   
                <div class="text-center">
                    <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
                    <div v-if="showLoader" class="text-muted pt-4">This search may take several seconds...</div>
                    <h5 class="text-danger" v-if="foundEventsList && foundEventsList.length < 1 && submitted && !showLoader">No events found</h5>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import TagList from '@/components/TagList.vue'

export default {
    data() {
        return {
            form: {
                objectId: '',
                eventDate: '',
                eventDateFormatted: '',
                bufferDays: 1,
                taggedOnly: false
            },
            showLoader: false,
            validated: false,
            submitted: false,
            msElapsed: 0,
            foundEventsList: null,
            showObjectIdHint: false,
            objectClass: "Object"
        }
    },
    components: {
        TagList
    },
    methods: {
        ...mapActions(['searchEventsByObjectId']),
        onDatePick(ctx) {
            this.form.eventDateFormatted = ctx.selectedFormatted;
        },
        openEvent(evt) {
            let routeData = this.$router.resolve({ name: 'Event', params: { eventId: evt.id } });
            window.open(routeData.href, '_blank');
        },
        async onSearchEvents() 
        {
            this.showObjectIdHint = false;
            this.validated = true;
            var result = await this.$validator.validate();
            
            if (0 !== this.$validator.errors.items.filter(x => x.field == 'objectId' && x.rule == 'regex').length)
            {
                this.showObjectIdHint = true;
            }
            
            if (result)
            {
                this.showLoader = true;

                try 
                {
                    const startTime = Date.now();
                    await this.searchEventsByObjectId(
                        { 
                            objectId: this.form.objectId, 
                            date: this.form.eventDate,
                            bufferDays: this.form.bufferDays,
                            taggedOnly: this.form.taggedOnly
                        });
                    this.msElapsed = Date.now() - startTime;

                    this.showLoader = false;
                    this.submitted = true;

                    this.foundEventsList = this.$store.getters.eventSearchResult;

                    if (this.foundEventsList.length > 0)
                        this.objectClass = this.foundEventsList[0].objClass;
                }
                catch(error)
                {
                    this.showLoader = false;
                    this.showToast(error.message, 'ERROR');                    
                }

                this.validated = false;
            }
        },
        setBuffer(days){
            this.form.bufferDays = days;
        }        
    },
    computed: {
        totalEventsInfo(){
            return this.$store.getters.totalEventsInfoResult ?? null;
        }
    },
    async mounted() {
        try
        {
            if (this.$store.state.eventList && this.$store.state.eventList.form && this.$store.state.eventList.form.type === 'obj')
            {
                this.form.objectId = this.$store.state.eventList.form.objectId;
                this.form.eventDate =  this.$store.state.eventList.form.date;
                this.form.bufferDays =  this.$store.state.eventList.form.bufferDays;
                this.form.taggedOnly =  this.$store.state.eventList.form.taggedOnly;

                this.foundEventsList = this.$store.getters.eventSearchResult;
            }
            
            // Default initial search date to Today
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var mmm = "JanFebMarAprMayJunJulAugSepOctNovDec".substring(today.getMonth() * 3, today.getMonth() * 3 + 3);
            var yyyy = today.getFullYear();

            this.form.eventDateFormatted = dd + ' ' + mmm + ' ' +  yyyy;
            this.form.eventDate = yyyy + '-' + mm + '-' + dd ;
        }
        catch(error)
        {
            this.showToast(error.message, 'ERROR');                    
        }
    }
}
</script>

<style scoped>
 .btn-group button[selected='selected']
 {
    border-color: #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
 }

.btn-group button:focus {
    border-color: #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
}

.non-tab-top-align {
    padding-top: 14px;
}

</style>

