<template>
    <div class="container">
        <div class="row pt-4">
            <div class="col-12" v-if="!showLoader && foundTags">
                <div class="row pt-2">
                    <div class="col">
                        <span>
                            <input type="radio" v-model="selectedTagGroup" value="m" name="tagSelection" id="myTags" @change="onChange()"> My Tags
                        </span>                
                        <span class="pl-4">
                            <input type="radio" v-model="selectedTagGroup" value="a"  name="tagSelection" id="allTags" @change="onChange()"> All Feed Tags
                        </span>
                        <span class="pl-4">
                            <input type="radio" v-model="selectedTagGroup" value="c"  name="tagSelection" id="communityTags" @change="onChange()"> Community Tags
                        </span>                        
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-12">
                        <div class="table-responsive-md">
                            <table class="table table-dark table-bordered table-hover">
                                <thead class="thead-dark text-center">
                                    <tr>
                                        <td>Tag</td>
                                        <td>Description</td>
                                        <td>Status</td>
                                        <td v-if="isSystemAdmin || selectedTagGroup === 'm'">Actions</td>
                                    </tr>    
                                </thead>
                                <tbody>
                                    <tr v-for="tag in foundTags" :key="tag.id">
                                        <td><span class="badge badge-owc">{{tag.name}}</span></td>
                                        <td><p>&nbsp;&nbsp;&nbsp;&nbsp;{{tag.descr}}</p></td>
                                        <td nowrap><i class="fa pr-2" :class="tag.state == 1 ? 'fa-rss' : 'fa-hourglass'"></i>{{tag.state == 1 ? 'Live' : 'Pending'}}</td>
                                        <td v-if="isSystemAdmin || selectedTagGroup === 'm'"><UpdateTag :tag="tag"/></td>
                                    </tr>
                                </tbody>
                            </table>
                            <ul class="pagination justify-content-end pt-2" v-if="pagerVisible">
                                <li class="page-item disabled"><a href="#" class="page-link">&laquo;</a></li>
                                <li class="page-item active"><a href="#" class="page-link">1</a></li>
                                <li class="page-item disabled"><a href="#" class="page-link">&raquo;</a></li>
                            </ul>                    
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                <b-spinner v-if="showLoader" class="justify-content-center mt-5" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
                <h5 class="text-danger" v-if="foundTags.length < 1 && !showLoader">No tags found</h5>
            </div>            
        </div>
    </div>      
</template>

<script>
import { mapActions } from 'vuex';
import UpdateTag from '@/components/UpdateTag.vue'

export default {
    data() {
        return {
            createTagsVisible: (process.env.NODE_ENV != 'production'),
            pagerVisible: false,
            showLoader: false,
            selectedTagGroup: 'm'
        }
    },     
    components: {
        UpdateTag
    },     
    methods: {
        ...mapActions(['loadTags', 'loadCommunityTags']),
        onChange() {
              this.updateTags();
        },
        async updateTags() {
            this.showLoader = true;

            try 
            {
                if (this.selectedTagGroup === 'c')
                {
                    await this.loadCommunityTags();
                }
                else
                {
                    var allTags = this.selectedTagGroup === 'a';
                    await this.loadTags(allTags);
                }

                this.showLoader = false;
            }
            catch(error)
            {
                this.showLoader = false;
                this.showToast(error.message, 'ERROR');                    
            }
        }
    },
    async mounted(){
        if (this.$store.state.tagList && this.$store.state.tagList.form)
        {
            this.selectedTagGroup = this.$store.state.tagList.form.selectedTagGroup;
        }
        else
        {
            await this.updateTags();
        }
    },
    computed: {
        foundTags(){
            return this.$store.getters.loadedTags;
        },
        isSystemAdmin(){
            return this.$store.getters.isSystemAdmin;
        }
    }
}
</script>

<style scoped>

</style>