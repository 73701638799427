<template>
    <table class="table table-dark table-bordered" v-if="tag">
        <tbody>
            <tr>
                <td>
                    <img class="mx-3" v-if="tag.imgUrl" :src="tag.imgUrl" style="float:left"/>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{tag.descr}}
                    <span v-if="tag.url" class="pl-1">
                        [<a :href="tag.url" target="_blank"><small>External Web Site</small></a>]
                    </span>                    
                    </p>
                    
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: ['tag']
}
</script>
