<template>
    <span>
        <span v-for="tag in tags" :key="tag.id">
            <span class="badge badge-owc mx-1" v-b-tooltip.hover :title="tag.descr">{{tag.name}}<span v-if="tag.url"> [<a :href="tag.url" target=_blank class="alt">web page</a>]</span></span>
            <span v-if="tag.taggedBy" class="text-muted"><small>(Tagged by <strong class="text-light">{{tag.taggedBy}}</strong>)</small></span>
        </span>
    </span>
</template>

<script>
export default {
    props: ['tags']
}
</script>