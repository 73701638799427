<template>
    <div v-if="userDashVisible" class="row border">
        <div class="col-11 text-white text-center mx-auto pb-2 pt-2 font-weight-bold" style="font-family: 'Arial Narrow Bold', sans-serif">FEED MAINTAINER</div>
        <div class="col-12 text-center mt-2" >
            <img src="@/assets/images/user.png" width="120px" alt="User">
        </div>
        <div class="col-12 text-center pt-2 pb-2 font-weight-bold" style="font-family: Verdana, Geneva, Tahoma, sans-serif">John User</div>

        <div class="col-12 text-center pt-4 pb-2" style="font-family: Arial, Helvetica, sans-serif">TAG STATISTICS</div>
        <div class="col-12 mb-3">
            <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    Tagged Active Events
                    <span class="badge badge-dark" style="font-size:13px">75</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    Tagged Active Unique Events
                    <span class="badge badge-dark" style="font-size:13px">58 %</span>
                </li>                            
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    User Subscriptions
                    <span class="badge badge-secondary" style="font-size:13px">34</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    Total Observed Events (past 30 days)
                    <span class="badge badge-secondary" style="font-size:13px">35 %</span>
                </li>                            
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    Total Tagged Events
                    <span class="badge badge-secondary" style="font-size:13px">427</span>
                </li>
            </ul>
        </div>

        <div class="col-12 text-center pt-4 pb-2" style="font-family: Arial, Helvetica, sans-serif">ACTIONS REQUIRED</div>
        <div class="col-12 mb-3">
            <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="" >Tag events for next period</a>
                    <span class="badge badge-secondary" style="font-size:13px">due in 12 days</span>
                </li>
            </ul>
        </div>
    </div> 
</template>

<script>
export default {
    data(){
        return {
            userDashVisible: (process.env.NODE_ENV != 'production')
        }
    }
}
</script>

<style scoped>

</style>