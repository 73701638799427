<template>
    <div class="container">
        <div class="row pt-4"  style="display: flex;">
            <div class="col-3"></div>
            <div class="col-6">
                <div class="row border" :class="showSubmitLoader ? 'disabled-control' : ''">
                    <div class="col-12 text-white text-center mx-auto pb-2 pt-2 font-weight-bold" style="font-family: 'Arial Narrow Bold', sans-serif">Create New Account</div>
                    
                    <div class="col-12 mb-3 pt-4">
                        <ul class="list-group">
                            <li class="list-group-item">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>Email Address<span class="text-danger pl-2">*</span></span>
                                    <input 
                                        v-model="form.login" 
                                        v-validate="{required: true, email: true}" 
                                        data-vv-name="Email Address"                                
                                        class="form-control" 
                                        type="text"/>                                    
                                </div>
                                <div v-if="validated" class="text-danger pull-right">
                                        {{errors.first('Email Address')}}
                                </div> 
                            </li>    
                            <li class="list-group-item">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>Password<span class="text-danger pl-2">*</span></span>
                                    <input 
                                        v-model="form.password"
                                        v-validate="{required: true, confirmed: form.password2}" 
                                        data-vv-name="Password"                                           
                                        class="form-control" 
                                        type="password"/>                                    
                                </div>
                                <div v-if="validated" class="text-danger pull-right">
                                        {{errors.first('Password')}}
                                </div>      
                            </li>     
                            <li class="list-group-item">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>Confirm Password<span class="text-danger pl-2">*</span></span>
                                    <input 
                                        v-model="form.password2"
                                        v-validate="{required: true}" 
                                        data-vv-name="Confirm Password"   
                                        class="form-control" 
                                        type="password"/>                                    
                                </div>
                                <div v-if="validated" class="text-danger pull-right">
                                        {{errors.first('Confirm Password')}}
                                </div>                                      
                            </li>                        
                            <li class="list-group-item">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>Account Display Name<span class="text-danger pl-2">*</span></span>
                                    <input 
                                     v-model="form.name"
                                        v-validate="{required: true}" 
                                        data-vv-name="Account Display Name"  
                                        class="form-control" 
                                        type="text"/>                                    
                                </div>
                                <div v-if="validated" class="text-danger pull-right">
                                        {{errors.first('Account Display Name')}}
                                </div>
                            </li>                                
                        </ul>
                    </div> 

                    <div class="col-12 mb-3">
                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Observer Name
                                <input class="form-control" type="text" v-model="form.observer"/>
                            </li>                            
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Country
                                <input class="form-control" type="text" v-model="form.country"/>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                City
                                <input class="form-control" type="text" v-model="form.city"/>
                            </li>                            
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Affiliation
                               <input class="form-control" type="text" v-model="form.affiliation"/>
                            </li>                           
                        </ul>
                    </div>
                    <div class="col-12">
                        <small class="text-muted"><strong class="text-light">NOTE: </strong> OWC accounts are shared with OccultWatcher</small>
                    </div>                    
                    <div class="col-12 mb-3 mt-3">
                        <b-spinner v-if="showSubmitLoader" class="justify-content-center" varient="secondary" style="width: 2rem; height: 2rem;" ></b-spinner>

                        <button 
                            type="button" 
                            @click="onCreateAccount" 
                            class="btn btn-primary pull-right">Create Account</button>
                    </div>     
                </div> 
            </div> 
        </div>       
    </div>     
</template>

<script>
import { mapActions } from 'vuex';

export default {
      data() {
        return {
            form: {
               login: null,
               password: null,
               password2: null,
               name: null,
               observer: null,
               country: null,
               city: null,
               affiliation: null
            },
            showSubmitLoader: false,
            validated: false,
            submitted: false
        }
    },
    methods: {
        ...mapActions(['createUser']),
        async onCreateAccount(){
            this.validated = true;
            var result = await this.$validator.validate();

            if (result)
            {
                this.showSubmitLoader = true;

                try 
                {
                    await this.createUser({
                        login: this.form.login,
                        password: this.form.password,
                        name: this.form.name,
                        observer: this.form.observer,
                        country: this.form.country,
                        city: this.form.city,
                        affiliation: this.form.affiliation
                    });

                    this.showSubmitLoader = false;
                    this.submitted = true;
                    
                    this.form = {
                        login: null,
                        password: null,
                        password2: null,
                        name: null,
                        observer: null,
                        country: null,
                        city: null,
                        affiliation: null
                    };

                    this.showToast('New user created', 'SUCCESS');
                    setTimeout(this.$router.push({ name: 'Login' }), 2000);
                }
                catch(error)
                {
                    this.showSubmitLoader = false;
                    this.showToast(error.message, 'ERROR');
                }

                this.validated = false;
            }            
        }
    }  
}
</script>

<style scoped>
li input.form-control {
    width: 50%
}
</style>