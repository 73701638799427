<template>
    <div>
        <b-form-tags id="tags-with-dropdown" v-model="pickedTags" no-outer-focus class="mb-2">
                <template v-slot="{ tags, disabled, addTag, removeTag }">
                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                        @remove="removeTag(tag)"
                        :title="tag"
                        :disabled="disabled"
                        variant="info"
                    >{{ tag }}</b-form-tag>
                    </li>
                </ul>

                <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                    <template #button-content>
                    Choose {{alias}}
                    </template>
                    <b-dropdown-form @submit.stop.prevent="() => {}">
                    <b-form-group
                        :label="`Search ${alias}`"
                        label-for="tag-search-input"
                        label-cols-md="auto"
                        class="mb-0"
                        label-size="sm"
                        :description="searchDesc"
                        :disabled="disabled"
                    >
                        <b-form-input
                            v-model="search"
                            id="tag-search-input"
                            type="search"
                            size="sm"
                            autocomplete="off"
                        ></b-form-input>
                    </b-form-group>
                    </b-dropdown-form>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item-button
                        v-for="option in availableOptions"
                        :key="option"
                        @click="onOptionClick({ option, addTag })"
                        >
                        {{ option }}
                    </b-dropdown-item-button>
                    <b-dropdown-text v-if="availableOptions.length === 0">
                    There are no {{alias}} available to select
                    </b-dropdown-text>
                </b-dropdown>
                </template>
        </b-form-tags>           
    </div>
</template>

<script>
export default {
    props: ['title', 'loadedTags', 'selectedTags'],
    events: ['pickedTagsChanged'],
    data() {
        return {
            alias: 'tags',
            search: '',
            pickedTags: []
        }
    }, 
    watch: {
        pickedTags: function (val) {
            this.$emit('pickedTagsChanged', val);
        }
    },
    methods: {
        onOptionClick({ option, addTag }) {
            addTag(option);
            this.search = '';
        } 
    },
    computed: {
        criteria() {
            // Compute the search criteria
            return this.search.trim().toLowerCase()
        },
        availableOptions() {
            const criteria = this.criteria
            // Filter out already selected options
            const options = this.options.filter(opt => this.pickedTags.indexOf(opt) === -1)
            if (criteria) {
            // Show only options that match criteria
            return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
            }
            // Show all options available
            return options
        },
        searchDesc() {
            if (this.criteria && this.availableOptions.length === 0) {
            return `There are no ${this.alias} matching your search criteria`
            }
            return ''
        },
        options() {
            return this.loadedTags?.map(x => x.name) ?? [];
        }    
    },
    mounted() {
        this.pickedTags = this.$props.selectedTags ?? [];
        this.alias = this.$props.title;
    }
}
</script>

<style scoped>

</style>