import Login from '../views/Login.vue'
import UserProfile from '../views/UserProfile.vue'
import CreateAccount from '../views/CreateAccount.vue'
import ResetPassword from '../views/ResetPassword.vue'
import RestoreSession from '../views/RestoreSession.vue'
import EditStations from '../views/EditStations.vue'

export default [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { title: "Login", public: true}
      },
      {
        path: '/user-profile',
        name: 'UserProfile',
        component: UserProfile,
        meta: { title: "User Profile"}
      },
      {
        path: '/create-account',
        name: 'CreateAccount',
        component: CreateAccount,
        meta: { title: "Create Account", public: true}
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: { title: "Reset Password", public: true}
      },  
      {
        path: '/restore-session',
        name: 'RestoreSession',
        component: RestoreSession,
        meta: { title: "OWC", headerless: true, public: true}
      },   
      {
        path: '/editStations',
        name: 'EditStations',
        component: EditStations,
        meta: { title: "OWC", headerless: true, public: false}
      } 
]